export default {
  'app.title': 'Console RustDesk',
  'navBar.lang': 'Lingua',
  'layout.user.link.help': 'Aiuto',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Termini',
  'app.preview.down.block': 'Scarica questa pagina nel progetto locale',
  'app.welcome.link.fetch-blocks': 'Ottieni tutti i blocchi',
  'app.welcome.link.block-list': 'Sviluppa rapidamente pagine standard basate su blocchi',
  'The verification code is incorrect or has expired': 'Il codice di verifica non è corretto o è scaduto',
  Type: 'Tipo',
  Remote: 'Dispositivo remoto',
  remoteTip: 'Computer o altro terminale controllato da remoto',
  Local: 'Dispositivo locale',
  'Start Time': 'Ora di inizio',
  'End Time': 'Ora di fine',
  Note: 'Note',
  'Log List': 'Elenco log',
  'Device List': 'Elenco dispositivi',
  'User List': 'Elenco utenti',
  'User Name': 'Nome utente',
  Updating: 'Aggiornamento in corso',
  Adding: 'Aggiunta in corso',
  Deleting: 'Eliminazione in corso',
  Create: 'Crea',
  Add: 'Aggiungi',
  Confirm: 'Conferma',
  Submit: 'Invia',
  Warning: 'Attenzione',
  Error: 'Errore',
  Email: 'Email',
  Status: 'Stato',
  Action: 'Azione',
  Password: 'Password',
  'New Password': 'Nuova password',
  Edit: 'Modifica',
  Normal: 'Normale',
  Disabled: 'Disabilitato',
  Disable: 'Disabilita',
  Enable: 'Abilita',
  Chosen: 'Selezionato',
  Items: 'elementi',
  User: 'Utente',
  Info: 'Informazioni',
  'Log out': 'Esci',
  Settings: 'Impostazioni',
  'Basic Settings': 'Impostazioni di base',
  'Remote Desktop': 'Desktop remoto',
  'File Transfer': 'Trasferimento file',
  'Port Transfer': 'Inoltro porta',
  'View Camera': 'Visualizza webcam',
  'Not Logged In': 'Non connesso',
  'Created Time': 'Data di creazione',
  Delete: 'Elimina',
  Group: 'Gruppo',
  'Device Group': 'Gruppo di dispositivi',
  'User Group': 'Gruppo di utenti',
  Name: 'Nome',
  'Group List': 'Elenco gruppi',
  deleteConfirm: 'Sei sicuro di voler eliminare?',
  groupTip: 'Gli utenti dello stesso gruppo possono accedere reciprocamente',
  document: 'Documentazione',
  Detail: 'Dettagli',
  Direction: 'Direzione',
  File: 'File',
  Directory: 'Cartella',
  Disconnect: 'Disconnetti',
  Time: 'Tempo',
  'Export as csv': 'Esporta come CSV',
  exportCsvTip: 'Massimo 1000 record possono essere esportati contemporaneamente',
  'Not binded only': 'Mostra solo dispositivi non associati',
  Close: 'Chiudi',
  'Default Strategy': 'Strategia predefinita',
  'applied device num': 'Numero di dispositivi applicati',
  'Device Name': 'Nome dispositivo',
  'Strategy': 'Strategia',
  'Device Strategy': 'Strategia dispositivo',
  'User Strategy': 'Strategia utente',
  'Group Strategy': 'Strategia gruppo',
  'strategy_tooltip': 'Formato: "Strategia dispositivo / Strategia utente / Strategia gruppo". "-" da solo significa utilizzare la strategia predefinita, "-" in una posizione significa che nessuna strategia è impostata a quel livello',
  Search: 'Cerca',
  Select: 'Seleziona',
  Assign: 'Assegna',
  Device: 'Dispositivo',
  'Device Username': 'Nome utente dispositivo',
  Role: 'Ruolo',
  'Auth Type': 'Tipo di autenticazione',
  Invite: 'Invita',
  'Please configure Email SMTP settings first': 'Configura prima le impostazioni SMTP dell\'email',
  'Security Settings': 'Impostazioni di sicurezza',
  'Manage Signed Devices': 'Gestisci dispositivi autenticati',
  'Since 2FA is enabled, the email login verification option is not used. 2FA login verification will be used instead.': 'Poiché 2FA è abilitato, l\'opzione di verifica login tramite email non viene utilizzata. Verrà utilizzata invece la verifica login 2FA.',
  'Enable alarm event email notification': 'Abilita notifica email degli eventi di allarme',
  'Enable email login verification': 'Abilita verifica login tramite email',
  'License Type': 'Tipo di licenza',
  'Renew / Upgrade License': 'Rinnova / Aggiorna licenza',
  Save: 'Salva',
  Back: 'Indietro',
  'Confirm Password': 'Conferma password',
  'username@device_name': 'utente@nome_dispositivo',
  'Hide username': 'Nascondi nome utente',
  'Remote device': 'Dispositivo remoto',
  'Within-group connections': 'Connessioni all\'interno del gruppo',
  'Cross-group connections': 'Connessioni tra gruppi',
  welcomeTitle: 'Benvenuto in RustDesk',
  Cancel: 'Annulla',
  OK: 'OK',

  'pages.GroupList.User Group': 'Gruppo di utenti',
  'pages.GroupList.User': 'Utente',

  'pages.CustomClient.Application name': 'Nome dell\'applicazione',
  'pages.CustomClient.Name': 'Nome',
  'pages.CustomClient.Build': 'Build',
  'pages.CustomClient.Note': 'Note',
  'pages.CustomClient.Action': 'Azione',
  'pages.CustomClient.Delete': 'Elimina',
  'pages.CustomClient.Edit': 'Modifica',
  'pages.CustomClient.Duplicate': 'Duplica',
  'pages.CustomClient.Create': 'Crea',
  'pages.CustomClient.Custom Clients': 'Client personalizzati',
  'pages.CustomClient.General': 'Generale',
  'pages.CustomClient.Name of the configuration': 'Nome della configurazione',
  'pages.CustomClient.MSI installer': 'Installer MSI',
  'pages.CustomClient.Connection type': 'Tipo di connessione',
  'pages.CustomClient.Bidirectional': 'Bidirezionale',
  'pages.CustomClient.Incoming': 'In entrata',
  'pages.CustomClient.Outgoing': 'In uscita',
  'pages.CustomClient.Options': 'Opzioni',
  'pages.CustomClient.Disable installation': 'Disabilita installazione',
  'pages.CustomClient.Disable settings': 'Disabilita impostazioni',
  'pages.CustomClient.Disable address book': 'Disabilita rubrica',
  'pages.CustomClient.Disable TCP listen port': 'Disabilita porta di ascolto TCP',
  'pages.CustomClient.Disable user account': 'Disabilita account utente',
  'pages.CustomClient.Some note about this configuration': 'Alcune note su questa configurazione',
  'pages.CustomClient.Custom server': 'Server personalizzato',
  'pages.CustomClient.Visual': 'Visuale',
  'pages.CustomClient.Application icon': 'Icona dell\'applicazione',
  'pages.CustomClient.Security': 'Sicurezza',
  'pages.CustomClient.Preset password': 'Password predefinita',
  'pages.CustomClient.Advanced': 'Avanzate',
  'pages.CustomClient.Override settings': 'Sostituisci impostazioni',
  'pages.CustomClient.Default settings': 'Impostazioni predefinite',
  'pages.CustomClient.Note: ': 'Nota: ',
  'pages.CustomClient.Click or drag to upload': 'Clicca o trascina per caricare',
  'pages.CustomClient.app_icon_desc_tip': 'Carica un\'immagine PNG quadrata di 512x512 pixel come icona dell\'applicazione.',
  'pages.CustomClient.app_name_desc_tip': 'Specifica un nome univoco che apparirà nel menu Start, Pannello di controllo, ecc. per il client RustDesk installato.',
  'pages.CustomClient.logo_desc_tip': 'Carica un\'immagine PNG che verrà visualizzata nella parte superiore della finestra principale del client personalizzato. La dimensione verrà ridimensionata automaticamente. Consigliamo un PNG di 200x40 pixel.',
  'pages.CustomClient.This password will be used as a default permanent password for all new clients. It can be changed by the client.': 'Questa password verrà utilizzata come password permanente predefinita per tutti i nuovi client. Può essere modificata dal client.',
  'pages.CustomClient.The application icon must be a PNG file.': 'L\'icona dell\'applicazione deve essere un file PNG.',
  'component.tagSelect.expand': 'Espandi',
  'component.tagSelect.collapse': 'Comprimi',
  'component.tagSelect.all': 'Tutti',
  'component.LicenseAlert.Your license is invalid.': 'La tua licenza non è valida.',
  'component.LicenseAlert.Your license is not set.': 'La tua licenza non è impostata.',
  'component.LicenseAlert.Your license is about to expire in 2 weeks.': 'La tua licenza scadrà tra 2 settimane.',
  'component.LicenseAlert.Your license has expired.': 'La tua licenza è scaduta.',
  'pages.CustomClient.Refresh status manually': 'Aggiorna stato manualmente',
  'pages.CustomClient.the building task': 'il task di build',
  'pages.CustomClient.Stop': 'Ferma',
  'pages.CustomClient.Cancel': 'Annulla',
  'pages.CustomClient.Completed at %s, this file will be deleted in 1 day.': 'Completato il %s, questo file verrà eliminato tra 1 giorno.',

  'component.globalHeader.search': 'Cerca nel sito',
  'component.globalHeader.help': 'Documentazione',
  'component.globalHeader.notification': 'Notifiche',
  'component.globalHeader.notification.empty': 'Hai visualizzato tutte le notifiche',
  'component.globalHeader.message': 'Messaggi',
  'component.globalHeader.message.empty': 'Hai letto tutti i messaggi',
  'component.globalHeader.event': 'Attività',
  'component.globalHeader.event.empty': 'Hai completato tutte le attività',
  'component.noticeIcon.clear': 'Cancella',
  'component.noticeIcon.cleared': 'Cancellato',
  'component.noticeIcon.empty': 'Nessun dato',
  'component.noticeIcon.view-more': 'Vedi altro',
  'Token List': 'Elenco token',
  Description: 'Descrizione',
  'Device Permission': 'Permesso dispositivo',
  'Audit Log Permission': 'Permesso log di audit',
  'User Permission': 'Permesso utente',
  'Group Permission': 'Permesso gruppo',
  'Strategy Permission': 'Permesso strategia',

  'menu.welcome': 'Benvenuto',
  'menu.more-blocks': 'Altri blocchi',
  'menu.home': 'Home',
  'menu.admin': 'Amministrazione',
  'menu.admin.sub-page': 'Sottopagina',
  'menu.login': 'Accesso',
  'menu.register': 'Registrazione',
  'menu.register-result': 'Risultato registrazione',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analisi',
  'menu.dashboard.monitor': 'Monitoraggio',
  'menu.dashboard.workplace': 'Workplace',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Form',
  'menu.form.basic-form': 'Form base',
  'menu.form.step-form': 'Form a passi',
  'menu.form.step-form.info': 'Form a passi (informazioni trasferimento)',
  'menu.form.step-form.confirm': 'Form a passi (conferma trasferimento)',
  'menu.form.step-form.result': 'Form a passi (completato)',
  'menu.form.advanced-form': 'Form avanzato',
  'menu.list': 'Liste',
  'menu.list.audit-list': 'Audit di sicurezza',
  'menu.list.audit-list.Alarm': 'Allarmi rischio/anomalia',
  'menu.list.audit-list.Connection': 'Log connessioni',
  'menu.list.audit-list.File': 'Log trasferimento file',
  'menu.list.audit-list.Console': 'Log operazioni console',
  'menu.list.device-list': 'Dispositivi',
  'menu.list.user-list': 'Utenti',
  'menu.list.group-list': 'Gruppi',
  'menu.list.group-list.user': 'Gruppi utente',
  'menu.list.group-list.device': 'Gruppi dispositivo',
  'menu.list.table-list': 'Tabella di ricerca',
  'menu.list.basic-list': 'Lista base',
  'menu.list.card-list': 'Lista schede',
  'menu.list.search-list': 'Lista di ricerca',
  'menu.list.search-list.articles': 'Lista di ricerca (articoli)',
  'menu.list.search-list.projects': 'Lista di ricerca (progetti)',
  'menu.list.search-list.applications': 'Lista di ricerca (applicazioni)',
  'menu.profile': 'Profilo',
  'menu.profile.basic': 'Profilo base',
  'menu.profile.advanced': 'Profilo avanzato',
  'menu.result': 'Risultato',
  'menu.result.success': 'Successo',
  'menu.result.fail': 'Fallimento',
  'menu.exception': 'Eccezione',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger errore',
  'menu.account': 'Account',
  'menu.account.center': 'Centro account',
  'menu.account.settings': 'Impostazioni account',
  'menu.account.trigger': 'Trigger errore',
  'menu.account.logout': 'Esci',
  'menu.editor': 'Editor grafico',
  'menu.editor.flow': 'Editor di flusso',
  'menu.editor.mind': 'Editor mappa mentale',
  'menu.editor.koni': 'Editor topologia',
  'menu.Settings': 'Impostazioni',
  'menu.Strategy': 'Strategia',
  'menu.Settings.Key': 'Chiave',
  'menu.Settings.License': 'Licenza',
  'menu.Settings.Relay': 'Gestione relay',
  'menu.Settings.Others': 'Altro',
  'menu.Settings.Tokens': 'Token',
  'menu.Address Books': 'Rubriche',
  'menu.Strategies': 'Strategie',
  'menu.Custom Clients': 'Client personalizzati',

  'pages.login.accountLogin.tab': 'Login account',
  'pages.login.accountLogin.errorMessage': 'Nome utente o password errati',
  'pages.login.failure': 'Login fallito, riprova!',
  'pages.login.success': 'Login riuscito!',
  'pages.login.username.placeholder': 'Nome utente: admin o user',
  'pages.login.username.required': 'Inserisci il tuo nome utente!',
  'pages.login.password.placeholder': 'Password: ant.design',
  'pages.login.password.required': 'Inserisci la tua password!',
  'pages.login.phoneLogin.tab': 'Login con telefono',
  'pages.login.phoneLogin.errorMessage': 'Codice di verifica errato',
  'pages.login.phoneNumber.placeholder': 'Inserisci il numero di telefono!',
  'pages.login.phoneNumber.required': 'Inserisci il numero di telefono!',
  'pages.login.phoneNumber.invalid': 'Numero di telefono non valido!',
  'pages.login.captcha.placeholder': 'Inserisci il codice di verifica!',
  'pages.login.captcha.required': 'Inserisci il codice di verifica!',
  'pages.login.phoneLogin.getVerificationCode': 'Ottieni codice',
  'pages.getCaptchaSecondText': 'secondi prima di poter richiedere nuovamente',
  'pages.login.rememberMe': 'Ricordami',
  'pages.login.forgotPassword': 'Password dimenticata?',
  'pages.login.submit': 'Accedi',
  'pages.login.loginWith': 'Accedi con:',
  'pages.login.loginWithGoogle': 'Accedi con Google',
  'pages.login.loginWithGithub': 'Accedi con Github',
  'pages.login.loginWithOkta': 'Accedi con Okta',
  'pages.login.loginWith.requesting_auth': 'Richiesta pagina di autorizzazione',
  'pages.login.loginWith.waiting_auth': 'Per favore completa l\'autorizzazione dell\'account',
  'pages.login.registerAccount': 'Crea account',
  'pages.welcome.advancedComponent': 'Componente avanzato',
  'pages.welcome.link': 'Benvenuto',
  'pages.welcome.advancedLayout': 'Layout avanzato',
  'pages.welcome.alertMessage': 'Componenti più rapidi e potenti sono stati rilasciati.',
  'pages.account.tfaComponent.Enable 2FA': 'Abilita 2FA',
  'pages.account.tfaComponent.2 Factor Authentication is enabled.': 'L\'autenticazione a due fattori è abilitata',
  'pages.account.tfaComponent.Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.': 'La tua chiave 2FA è in uso da un periodo prolungato. Ti consigliamo di aggiornare la tua chiave 2FA per garantire la massima sicurezza dell\'account.',
  'pages.account.tfaComponent.Enable 2 Factor Authentication.': 'Abilita autenticazione a due fattori.',
  'pages.account.tfa.The code must be 6, 8, 10 digits.': 'Il codice deve essere di 6, 8 o 10 cifre.',
  'pages.account.tfa.The code must be 6 or 8 digits.': 'Il codice deve essere di 6 o 8 cifre.',
  'pages.admin.subPage.title': 'Questa pagina può essere visualizzata solo dagli amministratori',
  'pages.admin.subPage.alertMessage': 'umi ui è disponibile, puoi iniziare con npm run ui.',
  'pages.AuditList.FileAuditList.files': 'file',
  'pages.AuditList.FileAuditList.pathTip': 'Percorso sul dispositivo controllato',
  'pages.AuditList.FileAuditList.limitedTip': 'Visualizzazione limitata a 10 file',
  'pages.AuditList.FileAuditList.exportFilename': 'Log trasferimento file',
  'pages.AuditList.ConnAuditList.modal_title': 'Conferma azione',
  'pages.AuditList.ConnAuditList.diconnect_confirm_tip': 'Sei sicuro di voler disconnettere questo dispositivo?',
  'pages.AuditList.ConnAuditList.disconnect_succ': 'Disconnessione riuscita!',
  'pages.AuditList.ConnAuditList.disconnect_fail': 'Disconnessione fallita!',
  'pages.AuditList.ConnAuditList.exportFilename': 'Log connessioni',
  'pages.AuditList.AlarmAuditList.Login Device / Local Device': 'Dispositivo di login / Dispositivo locale',
  'pages.AuditList.AlarmAuditList.Login Account / Remote Device': 'Account di login / Dispositivo remoto',
  'pages.AuditList.AlarmAuditList.Login Account': 'Account di login',
  'pages.AuditList.AlarmAuditList.Device ID': 'ID dispositivo',
  'pages.AuditList.AlarmAuditList.Device Name': 'Nome dispositivo',
  'pages.AuditList.AlarmAuditList.Remote Device': 'Dispositivo remoto',
  'pages.AuditList.AlarmAuditList.Event Time': 'Ora evento',
  'pages.AuditList.AlarmAuditList.IP whiltelist': 'Tentativo di accesso da IP non in whitelist',
  'pages.AuditList.AlarmAuditList.ExceedThirtyAccessAttempts': 'Tentativi di accesso consecutivi (30)',
  'pages.AuditList.AlarmAuditList.MultipleAccessAttemptsWithinOneMinute': 'Molteplici tentativi di accesso in 1 minuto',
  'pages.AuditList.AlarmAuditList.ExceedThirtyLoginAttempts': 'Tentativi di login consecutivi (30)',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneMinute': 'Molteplici tentativi di login in 1 minuto',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneHour': 'Molteplici tentativi di login in 1 ora',
  'pages.AuditList.AlarmAuditList.exportFilename': 'Log allarmi rischio/anomalia',
  'pages.AuditList.ConsoleAuditList.Operation': 'Operazione',
  'pages.AuditList.ConsoleAuditList.Name': 'Nome',
  'pages.AuditList.ConsoleAuditList.Name list': 'Elenco nomi',
  'pages.AuditList.ConsoleAuditList.ID list': 'Elenco ID',
  'pages.AuditList.ConsoleAuditList.Result': 'Risultato',
  'pages.AuditList.ConsoleAuditList.Remote': 'Remoto',
  'pages.AuditList.ConsoleAuditList.Local': 'Locale',
  'pages.AuditList.ConsoleAuditList.User Login': 'Login utente alla console',
  'pages.AuditList.ConsoleAuditList.Add Group': 'Aggiungi gruppo',
  'pages.AuditList.ConsoleAuditList.Add User': 'Aggiungi utente',
  'pages.AuditList.ConsoleAuditList.Add Device': 'Aggiungi dispositivo',
  'pages.AuditList.ConsoleAuditList.Delete Groups': 'Elimina gruppi',
  'pages.AuditList.ConsoleAuditList.Disconnect Device': 'Disconnetti dispositivo',
  'pages.AuditList.ConsoleAuditList.Enable Users': 'Abilita utenti',
  'pages.AuditList.ConsoleAuditList.Disable Users': 'Disabilita utenti',
  'pages.AuditList.ConsoleAuditList.Enable Devices': 'Abilita dispositivi',
  'pages.AuditList.ConsoleAuditList.Disable Devices': 'Disabilita dispositivi',
  'pages.AuditList.ConsoleAuditList.Update Group': 'Aggiorna gruppo',
  'pages.AuditList.ConsoleAuditList.Update User': 'Aggiorna utente',
  'pages.AuditList.ConsoleAuditList.Update Device': 'Aggiorna dispositivo',
  'pages.AuditList.ConsoleAuditList.Delete User': 'Elimina utente',
  'pages.AuditList.ConsoleAuditList.Delete Device': 'Elimina dispositivo',
  'pages.AuditList.ConsoleAuditList.Add Address Book': 'Aggiungi rubrica',
  'pages.AuditList.ConsoleAuditList.Delete Address Book': 'Elimina rubrica',
  'pages.AuditList.ConsoleAuditList.Change Address Book Name': 'Cambia nome rubrica',
  'pages.AuditList.ConsoleAuditList.Group Management': 'Gestione gruppi',
  'pages.AuditList.ConsoleAuditList.User Management': 'Gestione utenti',
  'pages.AuditList.ConsoleAuditList.Device Management': 'Gestione dispositivi',
  'pages.AuditList.ConsoleAuditList.Address Book Management': 'Gestione rubriche',
  'pages.AuditList.ConsoleAuditList.exportFilename': 'Log console',
  'pages.AuditList.ConsoleAuditList.Delete Devices in the Address Book Recycle Bin': 'Elimina dispositivi nel cestino della rubrica',
  'pages.AuditList.ConsoleAuditList.Empty Address Book Recycle Bin': 'Svuota cestino della rubrica',
  'pages.AuditList.ConsoleAuditList.Add Address Book Permission': 'Aggiungi permesso rubrica',
  'pages.AuditList.ConsoleAuditList.Delete Address Book Permission': 'Elimina permesso rubrica',
  'pages.AuditList.ConsoleAuditList.Update Address Book Permission': 'Aggiorna permesso rubrica',
  'pages.Settings.Strategy.Strategy list': 'Elenco strategie',
  'pages.Settings.Strategy.Automatically close incoming sessions on user inactivity': 'Chiudi automaticamente le sessioni in entrata in caso di inattività dell\'utente',
  'pages.Settings.Strategy.delete_strategy_tip': 'Sei sicuro di voler eliminare questa strategia?',
  'pages.Settings.Strategy.New strategy': 'Nuova strategia',
  'pages.Settings.Strategy.Rename strategy': 'Rinomina strategia',
  'pages.Settings.Strategy.Duplicate strategy': 'Duplica strategia',
  'pages.Settings.Strategy.Edit Devices': 'Modifica dispositivi',
  'pages.Settings.Strategy.Edit Users': 'Modifica utenti',
  'pages.Settings.Strategy.Rename': 'Rinomina',
  'pages.Settings.Strategy.Duplicate': 'Duplica',
  'pages.Settings.Strategy.Delete': 'Elimina',
  'pages.Settings.Strategy.Groups': 'Gruppi',
  'pages.Settings.Strategy.Select All': 'Seleziona tutto',
  'pages.Settings.Strategy.Permissions': 'Permessi',
  'pages.Settings.Strategy.Password': 'Password',
  'pages.Settings.Strategy.Security': 'Sicurezza',
  'pages.Settings.Strategy.Other': 'Altro',
  'pages.Settings.Strategy.IP whitelist': 'Whitelist IP',
  'pages.Settings.Strategy.Cancel': 'Annulla',
  'pages.Settings.Strategy.Submit': 'Conferma',
  'pages.Settings.Strategy.Edit': 'Modifica',
  'pages.Settings.Strategy.Custom': 'Personalizzata',
  'pages.Settings.Strategy.Full access': 'Accesso completo',
  'pages.Settings.Strategy.Screen share': 'Solo condivisione schermo',
  'pages.Settings.Strategy.Enable keyboard/mouse': 'Permetti controllo tastiera/mouse',
  'pages.Settings.Strategy.Enable clipboard': 'Permetti sincronizzazione clipboard',
  'pages.Settings.Strategy.Enable file transfer': 'Permetti trasferimento file',
  'pages.Settings.Strategy.Enable audio': 'Permetti trasmissione audio',
  'pages.Settings.Strategy.Enable TCP tunneling': 'Permetti tunneling TCP',
  'pages.Settings.Strategy.Enable remote restart': 'Permetti riavvio remoto',
  'pages.Settings.Strategy.Enable recording session': 'Permetti registrazione sessione',
  'pages.Settings.Strategy.Enable blocking user input': 'Permetti blocco input utente',
  'pages.Settings.Strategy.Enable remote configuration modification': 'Permetti modifica configurazione remota',
  'pages.Settings.Strategy.Permission type': 'Tipo di permesso',
  'pages.Settings.Strategy.Approve mode': 'Modalità di approvazione',
  'pages.Settings.Strategy.Password type': 'Tipo di password',
  'pages.Settings.Strategy.Hide connection management window': 'Nascondi finestra di gestione connessione',
  'pages.Settings.Strategy.One-time password length': 'Lunghezza password monouso',
  'pages.Settings.Strategy.Accept sessions via password': 'Accetta sessioni solo tramite password',
  'pages.Settings.Strategy.Accept sessions via click': 'Accetta sessioni solo tramite clic',
  'pages.Settings.Strategy.Accept sessions via both': 'Accetta sessioni tramite password o clic',
  'pages.Settings.Strategy.Use one-time password': 'Usa password monouso',
  'pages.Settings.Strategy.Use permanent password': 'Usa password permanente',
  'pages.Settings.Strategy.Use both passwords': 'Usa entrambi i tipi di password',
  'pages.Settings.Strategy.Deny LAN discovery': 'Nega rilevamento LAN',
  'pages.Settings.Strategy.Enable direct IP access': 'Permetti accesso diretto IP',
  'pages.Settings.Strategy.auto_disconnect_option_tip': 'Chiudi automaticamente sessioni inattive',
  'pages.Settings.Strategy.deny_lan_discovery_desc_tip': 'Il dispositivo non sarà rilevato da altri dispositivi nella stessa rete locale',
  'pages.Settings.Strategy.enable_direct_ip_access_desc_tip': 'Permetti connessione diretta IP al dispositivo',
  'pages.Settings.Strategy.Remove wallpaper during incoming sessions': 'Rimuovi sfondo durante sessioni in entrata',
  'pages.Settings.Strategy.minutes': 'minuti',
  'pages.Settings.Strategy.Enable': 'Abilita',
  'pages.Settings.Strategy.Disable': 'Disabilita',
  'pages.Settings.Strategy.disabled': 'disabilitato',
  'pages.Settings.Strategy.Default': 'Strategia predefinita',
  'pages.Settings.Strategy.Apply changes of this page': 'Applica modifiche di questa pagina',
  'pages.Settings.Strategy.Invalid IP': 'IP non valido',
  'pages.Settings.Strategy.IP Whitelisting': 'Whitelist IP',
  'pages.Settings.Strategy.Clear': 'Cancella',
  'pages.Settings.Strategy.OK': 'OK',
  'pages.Settings.Strategy.whitelist_sep': 'Puoi usare virgole, punto e virgola, spazi o interruzioni di riga come separatori',
  'pages.Settings.Strategy.Use IP whitelisting': 'Consenti solo IP dalla whitelist',
  'pages.Settings.Strategy.whitelist_tip': 'Solo gli IP nella whitelist possono accedere a questo dispositivo',
  'pages.CustomClient.bidirectional_desc_tip': 'Crea un client che può connettersi a un altro dispositivo e ricevere richieste di connessione',
  'pages.CustomClient.incoming_desc_tip': 'Crea un client che può solo ricevere richieste di connessione',
  'pages.CustomClient.outgoing_desc_tip': 'Crea un client che può connettersi a un altro dispositivo',
  'pages.CustomClient.disable_installation_desc_tip': 'Impedisci agli utenti di installare il client personalizzato (solo Windows)',
  'pages.CustomClient.disable_settings_desc_tip': 'Disabilita l\'accesso alle impostazioni nel client personalizzato',
  'pages.CustomClient.disable_ab_desc_tip': 'Disabilita la funzionalità rubrica nel client personalizzato',
  'pages.CustomClient.disable_tcp_desc_tip': 'Evita la finestra di dialogo del firewall di Windows all\'avvio di RustDesk, questo salterà i tentativi di connessione P2P diretta e utilizzerà direttamente la connessione relay',
  'pages.CustomClient.disable_account_desc_tip': 'Rimuovi la funzionalità di login account nel client RustDesk',
  'pages.CustomClient.override_settings_desc_tip': 'Sostituisci impostazioni, non possono essere modificate in RustDesk',
  'pages.CustomClient.default_settings_desc_tip': 'Modifica le impostazioni predefinite utilizzate da RustDesk',
  'pages.Settings.KeyPage.failedToFetchKey': 'Impossibile recuperare la chiave.',
  'pages.Settings.KeyPage.keyResetSuccess': 'Reset della chiave riuscito, riavvia hbbr per applicare le modifiche.',
  'pages.Settings.KeyPage.errorResettingKey': 'Errore durante il reset della chiave.',
  'pages.Settings.KeyPage.keyUpdateSuccess': 'Aggiornamento della chiave riuscito, riavvia hbbr per applicare le modifiche.',
  'pages.Settings.KeyPage.errorUpdatingKey': 'Errore durante l\'aggiornamento della chiave, verifica la chiave',
  'pages.Settings.KeyPage.key': 'Chiave',
  'pages.Settings.KeyPage.privateKey': 'Chiave privata',
  'pages.Settings.KeyPage.publicKey': 'Chiave pubblica',
  'pages.Settings.KeyPage.editKey': 'Aggiorna chiave',
  'pages.Settings.KeyPage.reset': 'Reset',
  'pages.Settings.KeyPage.custom': 'Inserimento manuale',
  'pages.Settings.KeyPage.pleaseInputPrivateKey': 'Inserisci la tua chiave privata!',
  'pages.Settings.License.settingsUpdatedSuccess': 'Impostazioni aggiornate con successo.',
  'pages.Settings.License.licenseCannotBeEmpty': 'La licenza non può essere vuota.',
  'pages.Settings.License.license': 'Licenza',
  'pages.Settings.Relay.GeoOverrideDescription': 'La funzione di sovrascrittura posizione può essere utilizzata solo quando esistono più server relay.',
  'pages.Settings.GeoOverride.ipOrDomain': 'IP/Dominio',
  'pages.Settings.GeoOverride.latitude': 'Latitudine',
  'pages.Settings.GeoOverride.longitude': 'Longitudine',
  'pages.Settings.GeoOverride.deleteSelectedConfirm': 'Sei sicuro di voler eliminare le sovrascritture di posizione selezionate?',
  'pages.Settings.GeoOverride.deleteConfirm': 'Sei sicuro di voler eliminare questa sovrascrittura di posizione?',
  'pages.Settings.GeoOverride.editGeoOverride': 'Modifica sovrascrittura',
  'pages.Settings.GeoOverride.addGeoOverride': 'Aggiungi sovrascrittura',
  'pages.Settings.GeoOverride.ipOrDomainRequired': 'IP/Dominio non può essere vuoto.',
  'pages.Settings.GeoOverride.latitudeRequired': 'Latitudine non può essere vuota.',
  'pages.Settings.GeoOverride.longitudeRequired': 'Longitudine non può essere vuota.',
  'pages.Settings.GeoOverride.testGeo': 'Testa posizione',
  'pages.Settings.GeoOverride.geofileFetchFailed': 'Impossibile recuperare le informazioni GeoFile.',
  'pages.Settings.GeoOverride.loadGeoOverrides': 'Carica sovrascritture',
  'pages.Settings.GeoOverride.geoOverride': 'Sovrascrittura posizione',
  'pages.Settings.GeoOverride.title': 'Sovrascrittura posizione',
  'pages.Settings.GeoOverride.RelaySettings': 'Impostazioni',
  'pages.Settings.GeoOverride.RelayServers': 'Server relay',
  'pages.Settings.GeoOverride.AddServer': 'Aggiungi server',
  'pages.Settings.GeoOverride.AlwaysUseRelay': 'Usa sempre relay',
  'pages.Settings.GeoOverride.UpdateServers': 'Importa modifiche',
  'pages.Settings.GeoOverride.UpdatedAlwaysUseRelay': 'Impostazione "Usa sempre relay" aggiornata',
  'pages.Settings.GeoOverride.FailedToUpdateAlwaysUseRelay': 'Impossibile aggiornare "Usa sempre relay"',
  'pages.Settings.GeoOverride.UpdatedRelayServers': 'Server relay aggiornati',
  'pages.Settings.GeoOverride.FailedToUpdateRelayServers': 'Impossibile aggiornare i server relay',
  'pages.Settings.GeoOverride.UpdatedChangeIDSetting': 'Impostazione modifica ID aggiornata',
  'pages.Settings.GeoOverride.FailedToUpdateChangeIDSetting': 'Impossibile aggiornare l\'impostazione modifica ID',
  "pages.Settings.Others.Allow change ID": "Permetti cambio ID",
  "pages.Settings.Others.Disable new devices": "Disabilita nuovi dispositivi",
  "pages.Settings.Others.The control end need to login before access": "Il controller deve effettuare il login prima dell'accesso",
  "pages.Settings.Others.Only admin can access unassigned devices": "Solo l'amministratore può accedere ai dispositivi non assegnati",
  "pages.Settings.Others.Allow non-admin to see other groups": "Permetti ai non amministratori di vedere altri gruppi",
  "pages.Settings.Others.allow_non_admin_see_other_group_tip": "Quando questa opzione è disabilitata, i non amministratori possono condividere rubriche solo all'interno del proprio gruppo.",
  'pages.Settings.OIDC.Maximum Number of Authorized Users': 'Numero massimo di utenti autorizzati',
  'pages.Settings.OIDC.emptyPrompt': 'Attualmente non ci sono provider di autenticazione di terze parti, aggiungine uno per utilizzare questa funzionalità.',
  'pages.Settings.OIDC.confirm-delete-title': 'Conferma eliminazione',
  'pages.Settings.OIDC.confirm-delete-content': 'Gli utenti autorizzati da questo provider di terze parti non potranno più accedere. Puoi accedere all\'interfaccia di gestione utenti per eliminare questi utenti.',
  'pages.Settings.Others.Only admin can access logs': 'Solo l\'amministratore può accedere ai log non assegnati',
  'pages.Welcome.Max Devices': 'Numero massimo dispositivi',
  'pages.Welcome.Max Users': 'Numero massimo utenti',
  'pages.Welcome.Not set': 'Non impostato',
  'pages.Welcome.Invalid': 'Non valido',
  'pages.Welcome.Demo': 'Demo',
  'pages.Welcome.Personal': 'Personale',
  'pages.Welcome.Business': 'Business',
  'pages.Welcome.Unknown': 'Altro',
  'pages.Welcome.Max Peers': 'Numero massimo dispositivi',
  'pages.Welcome.RustDesk Server Status': 'Stato server RustDesk',
  'pages.Welcome.License Expire Time': 'Data scadenza licenza',
  'pages.Welcome.Document': 'Documentazione',
  'pages.AddressBook.Permission': 'Permesso',
  'pages.AddressBook.Device count': 'Numero dispositivi',
  'pages.AddressBook.Address Books': 'Rubriche',
  'pages.AddressBook.Tags': 'Tag',
  'pages.AddressBook.Devices': 'Dispositivi',
  'pages.AddressBook.Device': 'Dispositivo',
  'pages.AddressBook.Alias': 'Alias',
  'pages.AddressBook.Whether a password is set': 'Se è stata impostata una password',
  'pages.AddressBook.Recycle Bin': 'Cestino',
  'pages.AddressBook.Empty Recycle Bin': 'Svuota cestino',
  'pages.AddressBook.Personal': 'Personale',
  'pages.AddressBook.Personal (My address book)': 'Personale (La mia rubrica)',
  'pages.AddressBook.Shared': 'Condivisa',
  'pages.AddressBook.Share': 'Condividi',
  'pages.AddressBook.Delete': 'Elimina',
  'pages.AddressBook.Settings': 'Impostazioni',
  'pages.AddressBook.Owner': 'Proprietario',
  'pages.AddressBook.My Permission': 'Il mio permesso',
  'pages.AddressBook.Read-only': 'Sola lettura',
  'pages.AddressBook.Read/Write': 'Lettura/Scrittura',
  'pages.AddressBook.Full Control': 'Controllo completo',
  'pages.AddressBook.share_warning_tip': 'I campi sopra sono condivisi e visibili ad altre persone.',
  'pages.AddressBook.permission_tip': 'Sola lettura: può vedere dispositivi e tag; Lettura/Scrittura: può modificare dispositivi e tag; Controllo completo: può ricondividere, eliminare o rinominare la rubrica.',
  'pages.AddressBook.rule_priority_tip': 'Priorità: Utente > Gruppo > Tutti',
  'pages.AddressBook.Remove password': 'Rimuovi password',
  'pages.AddressBook.Import': 'Importa',
  'pages.AddressBook.Filter by intersection': 'Filtra per intersezione',
  'pages.AddressBook.Permissions': 'Permessi',
  'pages.AddressBook.Type': 'Tipo',
  'pages.AddressBook.Untagged': 'Senza tag',

  'app.pwa.offline': 'Sei offline',
  'app.pwa.serviceworker.updated': 'Nuovo contenuto disponibile',
  'app.pwa.serviceworker.updated.hint': 'Clicca il pulsante "Aggiorna" o aggiorna manualmente la pagina',
  'app.pwa.serviceworker.updated.ok': 'Aggiorna',

  'app.setting.pagestyle': 'Impostazioni stile globale',
  'app.setting.pagestyle.dark': 'Stile menu scuro',
  'app.setting.pagestyle.light': 'Stile menu chiaro',
  'app.setting.content-width': 'Larghezza area contenuto',
  'app.setting.content-width.fixed': 'Fissa',
  'app.setting.content-width.fluid': 'Fluida',
  'app.setting.themecolor': 'Colore tema',
  'app.setting.themecolor.dust': 'Polvere',
  'app.setting.themecolor.volcano': 'Vulcano',
  'app.setting.themecolor.sunset': 'Tramonto',
  'app.setting.themecolor.cyan': 'Ciano',
  'app.setting.themecolor.green': 'Verde aurora',
  'app.setting.themecolor.daybreak': 'Blu alba (predefinito)',
  'app.setting.themecolor.geekblue': 'Blu geek',
  'app.setting.themecolor.purple': 'Viola',
  'app.setting.navigationmode': 'Modalità navigazione',
  'app.setting.sidemenu': 'Layout menu laterale',
  'app.setting.topmenu': 'Layout menu superiore',
  'app.setting.fixedheader': 'Header fisso',
  'app.setting.fixedsidebar': 'Menu laterale fisso',
  'app.setting.fixedsidebar.hint': 'Funziona con layout menu laterale',
  'app.setting.hideheader': 'Nascondi header durante lo scorrimento',
  'app.setting.hideheader.hint': 'Funziona quando header è fisso',
  'app.setting.othersettings': 'Altre impostazioni',
  'app.setting.weakmode': 'Modalità daltonici',
  'app.setting.copy': 'Copia impostazioni',
  'app.setting.copyinfo': 'Copiato con successo, sostituisci la configurazione predefinita in config/defaultSettings.js',
  'app.setting.production.hint': 'La barra di configurazione è visualizzata solo in ambiente di sviluppo per l\'anteprima. Modifica manualmente il file di configurazione in produzione.',

  'app.settings.menuMap.basic': 'Impostazioni di base',
  'app.settings.menuMap.security': 'Impostazioni di sicurezza',
  'app.settings.menuMap.binding': 'Collegamento account',
  'app.settings.menuMap.notification': 'Notifiche nuovi messaggi',
  'app.settings.basic.avatar': 'Avatar',
  'app.settings.basic.change-avatar': 'Cambia avatar',
  'app.settings.basic.email': 'Email',
  'app.settings.basic.email-message': 'Inserisci la tua email!',
  'app.settings.basic.nickname': 'Nickname',
  'app.settings.basic.nickname-message': 'Inserisci il tuo nickname!',
  'app.settings.basic.profile': 'Profilo personale',
  'app.settings.basic.profile-message': 'Inserisci il tuo profilo personale!',
  'app.settings.basic.profile-placeholder': 'Profilo personale',
  'app.settings.basic.country': 'Paese/Regione',
  'app.settings.basic.country-message': 'Inserisci il tuo paese o regione!',
  'app.settings.basic.geographic': 'Provincia e città',
  'app.settings.basic.geographic-message': 'Inserisci la tua provincia e città!',
  'app.settings.basic.address': 'Indirizzo',
  'app.settings.basic.address-message': 'Inserisci il tuo indirizzo!',
  'app.settings.basic.phone': 'Telefono',
  'app.settings.basic.phone-message': 'Inserisci il tuo numero di telefono!',
  'app.settings.basic.update': 'Aggiorna informazioni base',
  'app.settings.security.strong': 'Forte',
  'app.settings.security.medium': 'Media',
  'app.settings.security.weak': 'Debole',
  'app.settings.security.password': 'Password account',
  'app.settings.security.password-description': 'Forza attuale della password',
  'app.settings.security.phone': 'Telefono di sicurezza',
  'app.settings.security.phone-description': 'Telefono collegato',
  'app.settings.security.question': 'Domanda di sicurezza',
  'app.settings.security.question-description': 'Domanda di sicurezza non impostata, può proteggere efficacemente la sicurezza dell\'account',
  'app.settings.security.email': 'Email di backup',
  'app.settings.security.email-description': 'Email collegata',
  'app.settings.security.mfa': 'Dispositivo MFA',
  'app.settings.security.mfa-description': 'Dispositivo MFA non collegato, dopo il collegamento può essere effettuata una verifica secondaria',
  'app.settings.security.modify': 'Modifica',
  'app.settings.security.set': 'Imposta',
  'app.settings.security.bind': 'Collega',
  'app.settings.binding.taobao': 'Collega Taobao',
  'app.settings.binding.taobao-description': 'Account Taobao non collegato attualmente',
  'app.settings.binding.alipay': 'Collega Alipay',
  'app.settings.binding.alipay-description': 'Account Alipay non collegato attualmente',
  'app.settings.binding.dingding': 'Collega DingTalk',
  'app.settings.binding.dingding-description': 'Account DingTalk non collegato attualmente',
  'app.settings.binding.bind': 'Collega',
  'app.settings.notification.password': 'Password account',
  'app.settings.notification.password-description': 'I messaggi da altri utenti verranno notificati come messaggi interni',
  'app.settings.notification.messages': 'Messaggi di sistema',
  'app.settings.notification.messages-description': 'I messaggi di sistema verranno notificati come messaggi interni',
  'app.settings.notification.todo': 'Attività',
  'app.settings.notification.todo-description': 'Le attività verranno notificate come messaggi interni',
  'app.settings.open': 'Attivo',
  'app.settings.close': 'Disattivo',
  'app.copyright.produced': 'Prodotto dal team RustDesk',
  'pages.account.Settings.Security.Password': 'Password',
  'pages.account.Settings.Security.Change password': 'Cambia password',
  'pages.account.tfa.Enable 2FA': 'Abilita 2FA',
  'pages.account.tfa.2 Factor Authentication is enabled.': 'L\'autenticazione a due fattori è abilitata',
  'pages.account.tfa.Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.': 'La tua chiave 2FA è in uso da un periodo prolungato. Ti consigliamo di aggiornare la tua chiave 2FA per garantire la massima sicurezza dell\'account.',
  'pages.account.tfa.Enable 2 Factor Authentication.': 'Abilita autenticazione a due fattori.',

  'Enable Two-Factor Authentication': 'Abilita autenticazione a due fattori',
  'Make your RustDesk account more secure by requiring an extra authentication code when you log in.': 'Rendi il tuo account RustDesk più sicuro richiedendo un codice di autenticazione aggiuntivo quando accedi.',
  '1. Download an authenticator app': '1. Scarica un\'app di autenticazione',
  'Download an authenticator app such as': 'Scarica un\'app di autenticazione come Google Authenticator o Authy',
  '2FA code': 'Codice 2FA',
  'Authenticator': 'Autenticatore',
  'on your phone or desktop.': 'sul tuo telefono o desktop.',
  '2. Scan the QR code': '2. Scansiona il codice QR',
  'Open your authenticator app, scan the QR code and enter the code that your app shows.': 'Apri la tua app di autenticazione, scansiona il codice QR e inserisci il codice mostrato dalla tua app.',
  'You can change or remove 2FA at any time in your account settings.': 'Puoi modificare o rimuovere 2FA in qualsiasi momento nelle impostazioni del tuo account.',
  'Please verify your 2FA code to disable current 2FA first.': 'Verifica il tuo codice 2FA per disabilitare prima l\'attuale 2FA.',
  'Can\'t verify the code. Check that code and local time settings are correct!': 'Impossibile verificare il codice. Controlla che il codice e le impostazioni dell\'ora locale siano corretti!',
  'First sign-in:': 'Primo accesso:',
  'Recent activity:': 'Attività recente:',
  'pages.Settings.GeoOverride.reloadGeo': 'Ricarica dati di geolocalizzazione',
  'pages.Settings.GeoOverride.getRelayServer': 'Ottieni server relay',
  'pages.Settings.GeoOverride.relayServer': 'Server relay',
  'SMTP Host': 'Host SMTP',
  'SMTP Port': 'Porta SMTP',
  'My Server Requires Authentication': 'Il mio server richiede autenticazione',
  'smtp.From': 'Mittente',
  'Mail Account': 'Account email',
  'Mail Password': 'Password email',
  'Custom Certificate PEM File Path (on the server)': 'Percorso file PEM certificato personalizzato (sul server)',
  Update: 'Aggiorna',
  'Submit and Test Email': 'Invia e testa email',
  'The Receiver Email': 'Email destinatario',
  'LDAP Host': 'Host LDAP',
  'LDAP Port': 'Porta LDAP',
  'Filter': 'Filtro',
  'Username Attribute': 'Attributo nome utente',
  'The attribute used as the username when logging in. Default is "dn".': 'L\'attributo usato come nome utente durante il login. Il valore predefinito è "dn".',
  'DraggableTab.Drag tab to reorder': 'Trascina scheda per riordinare',
  'pages.Settings.OIDC.new.Auth Type': 'Tipo di autenticazione',
  'pages.Settings.OIDC.New Auth Provider': 'Nuovo provider di autenticazione',
  'pages.Settings.OIDC.new.New Auth Provider': 'Nuovo provider di autenticazione',
  'pages.Settings.OIDC.Delete This Provider': 'Elimina questo provider',
  'pages.Settings.OIDC.new.Enable PKCE': 'Abilita PKCE',
  'pages.Settings.OIDC.Enable PKCE': 'Abilita PKCE',
  'pages.Settings.OIDC.new.Timeout(seconds) to force a refresh of metadata(e.g. JWKS) from OIDC providers': 'Timeout (secondi) per forzare un aggiornamento dei metadati (es. JWKS) dai provider OIDC',
  'pages.Settings.OIDC.new.0 means no timeout, empty means default (3600)': '0 significa nessun timeout, vuoto significa predefinito (3600)',
  'Callback URL': 'URL di callback',
  'pages.Settings.Others.Session expiration time (Day)': 'Tempo di scadenza sessione (giorni)',
  'Or': 'Oppure',
};
