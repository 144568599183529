export default {
  'app.title': 'RustDesk 콘솔',
  'navBar.lang': '언어',
  'layout.user.link.help': '도움말',
  'layout.user.link.privacy': '개인정보',
  'layout.user.link.terms': '약관',
  'app.preview.down.block': '이 페이지를 로컬 프로젝트에 다운로드',
  'app.welcome.link.fetch-blocks': '모든 블록 가져오기',
  'app.welcome.link.block-list': '블록 기반 개발, 표준 페이지를 빠르게 구축',
  'The verification code is incorrect or has expired': '인증 코드가 잘못되었거나 만료되었습니다',
  Type: '유형',
  Remote: '원격',
  remoteTip: '원격으로 제어되는 컴퓨터 또는 기타 장치',
  Local: '주 제어',
  'Start Time': '시작 시간',
  'End Time': '종료 시간',
  Note: '비고',
  'Log List': '로그 목록',
  'Device List': '장치 목록',
  'User List': '사용자 목록',
  'User Name': '사용자 이름',
  Updating: '업데이트 중',
  Adding: '추가 중',
  Deleting: '삭제 중',
  Create: '생성',
  Add: '추가',
  Confirm: '확인',
  Submit: '제출',
  Warning: '경고',
  Error: '오류',
  Email: '이메일',
  Status: '상태',
  Action: '작업',
  Password: '비밀번호',
  'New Password': '새 비밀번호',
  Edit: '편집',
  Normal: '정상',
  Disabled: '비활성화',
  Disable: '비활성화',
  Enable: '활성화',
  Chosen: '선택됨',
  Items: '항목',
  User: '사용자',
  Info: '정보',
  'Log out': '로그아웃',
  Settings: '설정',
  'Basic Settings': '기본 설정',
  'Remote Desktop': '원격 데스크탑',
  'File Transfer': '파일 전송',
  'Port Transfer': '포트 포워딩',
  'View Camera': '카메라 보기',
  'Not Logged In': '로그인하지 않음',
  'Created Time': '생성 시간',
  Delete: '삭제',
  Group: '그룹',
  'Device Group': '장치 그룹',
  'User Group': '사용자 그룹',
  Name: '이름',
  'Group List': '그룹 목록',
  deleteConfirm: '삭제하시겠습니까?',
  groupTip: '같은 그룹의 사용자는 서로 접근할 수 있습니다',
  document: '문서',
  Detail: '상세 정보',
  Direction: '방향',
  File: '파일',
  Directory: '디렉토리',
  Disconnect: '연결 끊기',
  Time: '시간',
  'Export as csv': 'CSV로 내보내기',
  exportCsvTip: '최대 1000개의 기록을 한 번에 내보낼 수 있습니다',
  'Not binded only': '바인딩되지 않은 장치만 표시',
  Close: '닫기',
  'Default Strategy': '기본 전략',
  'applied device num': '적용된 장치 수',
  'Device Name': '장치 이름',
  'Strategy': '전략',
  'Device Strategy': '장치 전략',
  'User Strategy': '사용자 전략',
  'Group Strategy': '장치 그룹 전략',
  'strategy_tooltip': "형식: \"장치 전략 / 사용자 전략 / 장치 그룹 전략\". 단독으로 '-' 표시 시 기본 전략 사용, 특정 위치에 '-' 표시 시 해당 수준에서 전략이 설정되지 않음",
  Search: '검색',
  Select: '선택',
  Assign: '할당',
  Device: '장치',
  'Device Username': '장치 사용자 이름',
  Role: '역할',
  'Auth Type': '인증 방식',
  Invite: '초대',
  'Please configure Email SMTP settings first': '먼저 이메일 SMTP 설정을 구성하십시오',
  'Security Settings': '보안 설정',
  'Manage Signed Devices': '서명된 장치 관리',
  'Since 2FA is enabled, the email login verification option is not used. 2FA login verification will be used instead.': '2FA가 활성화되어 있으므로 이메일 로그인 확인 옵션은 사용되지 않습니다. 대신 2FA 로그인 확인이 사용됩니다.',
  'Enable alarm event email notification': '알람 이벤트 이메일 알림 활성화',
  'Enable email login verification': '이메일 로그인 확인 활성화',
  'License Type': '라이센스 유형',
  'Renew / Upgrade License': '갱신 / 업그레이드 라이센스',
  Save: '저장',
  Back: '뒤로',
  'Confirm Password': '비밀번호 확인',
  'username@device_name': '사용자 이름@장치 이름',
  'Hide username': '사용자 이름 숨기기',
  'Remote device': '원격 장치',
  'Within-group connections': '그룹 내 연결',
  'Cross-group connections': '그룹 간 연결',
  welcomeTitle: 'RustDesk 사용을 환영합니다',
  Cancel: '취소',
  OK: '확인',

  'pages.GroupList.User Group': '사용자 그룹',
  'pages.GroupList.User': '사용자',

  'pages.CustomClient.Application name': '응용 프로그램 이름',
  'pages.CustomClient.Name': '이름',
  'pages.CustomClient.Build': '빌드',
  'pages.CustomClient.Note': '비고',
  'pages.CustomClient.Action': '작업',
  'pages.CustomClient.Delete': '삭제',
  'pages.CustomClient.Edit': '편집',
  'pages.CustomClient.Duplicate': '복사',
  'pages.CustomClient.Create': '생성',
  'pages.CustomClient.Custom Clients': '사용자 정의 클라이언트',
  'pages.CustomClient.General': '일반',
  'pages.CustomClient.Name of the configuration': '구성 이름',
  'pages.CustomClient.MSI installer': 'MSI 설치 프로그램',
  'pages.CustomClient.Connection type': '연결 유형',
  'pages.CustomClient.Bidirectional': '양방향',
  'pages.CustomClient.Incoming': '수신 (Incoming)',
  'pages.CustomClient.Outgoing': '발신 (Outgoing)',
  'pages.CustomClient.Options': '옵션',
  'pages.CustomClient.Disable installation': '설치 비활성화',
  'pages.CustomClient.Disable settings': '설정 비활성화',
  'pages.CustomClient.Disable address book': '주소록 비활성화',
  'pages.CustomClient.Disable TCP listen port': 'TCP 수신 포트 비활성화',
  'pages.CustomClient.Disable user account': '사용자 계정 비활성화',
  'pages.CustomClient.Some note about this configuration': '이 구성에 대한 주의 사항',
  'pages.CustomClient.Custom server': '사용자 정의 서버',
  'pages.CustomClient.Visual': '비주얼',
  'pages.CustomClient.Application icon': '응용 프로그램 아이콘',
  'pages.CustomClient.Security': '보안',
  'pages.CustomClient.Preset password': '미리 설정된 비밀번호',
  'pages.CustomClient.Advanced': '고급',
  'pages.CustomClient.Override settings': '설정 덮어쓰기',
  'pages.CustomClient.Default settings': '기본 설정',
  'pages.CustomClient.Note: ': '주의:',
  'pages.CustomClient.Click or drag to upload': '클릭하거나 드래그하여 업로드',
  'pages.CustomClient.app_icon_desc_tip': '512x512 픽셀 정사각형 PNG 이미지를 응용 프로그램 아이콘으로 업로드하십시오.',
  'pages.CustomClient.app_name_desc_tip': '시작 메뉴, 제어판 등 설치된 RustDesk 클라이언트에 표시될 고유한 이름을 지정하십시오.',
  'pages.CustomClient.logo_desc_tip': '사용자 정의 클라이언트의 주 창 상단에 표시될 PNG 이미지를 업로드하십시오. 크기는 자동으로 조정됩니다. 200x40 픽셀의 PNG 사용을 권장합니다.',
  'pages.CustomClient.This password will be used as a default permanent password for all new clients. It can be changed by the client.': '이 비밀번호는 모든 신규 클라이언트의 기본 영구 비밀번호로 사용됩니다. 클라이언트에 의해 변경될 수 있습니다.',
  'pages.CustomClient.The application icon must be a PNG file.': '응용 프로그램 아이콘은 PNG 파일이어야 합니다.',
  'component.tagSelect.expand': '확장',
  'component.tagSelect.collapse': '축소',
  'component.tagSelect.all': '모두',
  'component.LicenseAlert.Your license is invalid.': '라이센스가 유효하지 않습니다.',
  'component.LicenseAlert.Your license is not set.': '라이센스가 설정되지 않았습니다.',
  'component.LicenseAlert.Your license is about to expire in 2 weeks.': '라이센스가 2주 후에 만료됩니다.',
  'component.LicenseAlert.Your license has expired.': '라이센스가 만료되었습니다.',
  'pages.CustomClient.Refresh status manually': '상태 수동으로 새로 고침',
  'pages.CustomClient.the building task': '빌드 작업',
  'pages.CustomClient.Stop': '중지',
  'pages.CustomClient.Cancel': '취소',
  'pages.CustomClient.Completed at %s, this file will be deleted in 1 day.': '%s에 완료되었습니다. 이 파일은 1일 후에 삭제됩니다.',

  'component.globalHeader.search': '사이트 내 검색',
  'component.globalHeader.help': '사용 문서',
  'component.globalHeader.notification': '알림',
  'component.globalHeader.notification.empty': '모든 알림을 확인했습니다.',
  'component.globalHeader.message': '메시지',
  'component.globalHeader.message.empty': '모든 메시지를 읽었습니다.',
  'component.globalHeader.event': '할 일',
  'component.globalHeader.event.empty': '모든 할 일을 완료했습니다.',
  'component.noticeIcon.clear': '지우기',
  'component.noticeIcon.cleared': '지워졌습니다.',
  'component.noticeIcon.empty': '데이터가 없습니다.',
  'component.noticeIcon.view-more': '더 보기',
  'Token List': '토큰 목록',
  Description: '설명',
  'Device Permission': '장치 권한',
  'Audit Log Permission': '감사 로그 권한',
  'User Permission': '사용자 권한',
  'Group Permission': '그룹 권한',
  'Strategy Permission': '전략 권한',

  'menu.welcome': '환영합니다',
  'menu.more-blocks': '더 많은 블록',
  'menu.home': '홈',
  'menu.admin': '관리 페이지',
  'menu.admin.sub-page': '2차 관리 페이지',
  'menu.login': '로그인',
  'menu.register': '등록',
  'menu.register-result': '등록 결과',
  'menu.dashboard': '대시보드',
  'menu.dashboard.analysis': '분석 페이지',
  'menu.dashboard.monitor': '모니터링 페이지',
  'menu.dashboard.workplace': '작업 공간',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': '양식 페이지',
  'menu.form.basic-form': '기본 양식',
  'menu.form.step-form': '단계 양식',
  'menu.form.step-form.info': '단계 양식 (이체 정보 입력)',
  'menu.form.step-form.confirm': '단계 양식 (이체 정보 확인)',
  'menu.form.step-form.result': '단계 양식 (완료)',
  'menu.form.advanced-form': '고급 양식',
  'menu.list': '목록 페이지',
  'menu.list.audit-list': '보안 감사',
  'menu.list.audit-list.Alarm': '위험/이상 경고',
  'menu.list.audit-list.Connection': '연결 로그',
  'menu.list.audit-list.File': '파일 전송 로그',
  'menu.list.audit-list.Console': '콘솔 작업 로그',
  'menu.list.device-list': '장치',
  'menu.list.user-list': '사용자',
  'menu.list.group-list': '그룹',
  'menu.list.group-list.user': '사용자 그룹',
  'menu.list.group-list.device': '장치 그룹',
  'menu.list.table-list': '조회 표',
  'menu.list.basic-list': '표준 목록',
  'menu.list.card-list': '카드 목록',
  'menu.list.search-list': '검색 목록',
  'menu.list.search-list.articles': '검색 목록 (기사)',
  'menu.list.search-list.projects': '검색 목록 (프로젝트)',
  'menu.list.search-list.applications': '검색 목록 (응용 프로그램)',
  'menu.profile': '상세 페이지',
  'menu.profile.basic': '기본 상세 페이지',
  'menu.profile.advanced': '고급 상세 페이지',
  'menu.result': '결과 페이지',
  'menu.result.success': '성공 페이지',
  'menu.result.fail': '실패 페이지',
  'menu.exception': '예외 페이지',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '오류 발생',
  'menu.account': '개인 페이지',
  'menu.account.center': '개인 센터',
  'menu.account.settings': '개인 설정',
  'menu.account.trigger': '오류 발생',
  'menu.account.logout': '로그아웃',
  'menu.editor': '그래픽 편집기',
  'menu.editor.flow': '흐름 편집기',
  'menu.editor.mind': '마인드 맵 편집기',
  'menu.editor.koni': '토폴로지 편집기',
  'menu.Settings': '설정',
  'menu.Strategy': '전략',
  'menu.Settings.Key': '키',
  'menu.Settings.License': '라이센스',
  'menu.Settings.Relay': '중계 관리',
  'menu.Settings.Others': '기타',
  'menu.Settings.Tokens': '토큰',
  'menu.Address Books': '주소록',
  'menu.Strategies': '전략',
  'menu.Custom Clients': '사용자 정의 클라이언트',

  'pages.login.accountLogin.tab': '계정 비밀번호 로그인',
  'pages.login.accountLogin.errorMessage': '잘못된 사용자 이름 또는 비밀번호',
  'pages.login.failure': '로그인 실패, 다시 시도하십시오!',
  'pages.login.success': '로그인 성공!',
  'pages.login.username.placeholder': '사용자 이름: admin 또는 user',
  'pages.login.username.required': '사용자 이름은 필수 항목입니다!',
  'pages.login.password.placeholder': '비밀번호: ant.design',
  'pages.login.password.required': '비밀번호는 필수 항목입니다!',
  'pages.login.phoneLogin.tab': '휴대폰 로그인',
  'pages.login.phoneLogin.errorMessage': '인증 코드 오류',
  'pages.login.phoneNumber.placeholder': '휴대폰 번호를 입력하십시오!',
  'pages.login.phoneNumber.required': '휴대폰 번호는 필수 항목입니다!',
  'pages.login.phoneNumber.invalid': '유효하지 않은 휴대폰 번호입니다!',
  'pages.login.captcha.placeholder': '인증 코드를 입력하십시오!',
  'pages.login.captcha.required': '인증 코드는 필수 항목입니다!',
  'pages.login.phoneLogin.getVerificationCode': '인증 코드 받기',
  'pages.getCaptchaSecondText': '초 후에 다시 받기',
  'pages.login.rememberMe': '자동 로그인',
  'pages.login.forgotPassword': '비밀번호를 잊으셨나요?',
  'pages.login.submit': '로그인',
  'pages.login.loginWith': '다른 로그인 방법:',
  'pages.login.loginWithGoogle': 'Google로 로그인',
  'pages.login.loginWithGithub': 'Github로 로그인',
  'pages.login.loginWithOkta': 'Okta로 로그인',
  'pages.login.loginWith.requesting_auth': '인증 페이지 요청 중',
  'pages.login.loginWith.waiting_auth': '계정 인증을 완료하십시오',
  'pages.login.registerAccount': '계정 등록',
  'pages.welcome.advancedComponent': '고급 표',
  'pages.welcome.link': '환영합니다',
  'pages.welcome.advancedLayout': '고급 레이아웃',
  'pages.welcome.alertMessage': '더 빠르고 강력한 중형 구성 요소가 출시되었습니다.',
  'pages.account.tfaComponent.Enable 2FA': '2FA 활성화',
  'pages.account.tfaComponent.2 Factor Authentication is enabled.': '이중 인증이 활성화되었습니다.',
  'pages.account.tfaComponent.Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.': '귀하의 2FA 키는 오랜 기간 사용되었습니다. 최대 계정 보안을 보장하기 위해 2FA 키를 업데이트하는 것이 좋습니다.',
  'pages.account.tfaComponent.Enable 2 Factor Authentication.': '이중 인증 활성화',
  'pages.account.tfa.The code must be 6, 8, 10 digits.': '코드는 6, 8, 10자리여야 합니다.',
  'pages.account.tfa.The code must be 6 or 8 digits.': '코드는 6자리 또는 8자리여야 합니다.',
  'pages.admin.subPage.title': '이 페이지는 관리자 권한이 있어야 볼 수 있습니다.',
  'pages.admin.subPage.alertMessage': 'umi ui가 출시되었습니다. npm run ui로 시작하여 사용해 보십시오.',
  'pages.AuditList.FileAuditList.files': '개 파일',
  'pages.AuditList.FileAuditList.pathTip': '원격 장치의 경로',
  'pages.AuditList.FileAuditList.limitedTip': '최대 10개 파일 표시',
  'pages.AuditList.FileAuditList.exportFilename': '파일 전송 로그',
  'pages.AuditList.ConnAuditList.modal_title': '작업 확인',
  'pages.AuditList.ConnAuditList.diconnect_confirm_tip': '정말로 이 장치를 연결 해제하시겠습니까?',
  'pages.AuditList.ConnAuditList.disconnect_succ': '연결 해제 성공!',
  'pages.AuditList.ConnAuditList.disconnect_fail': '연결 해제 실패!',
  'pages.AuditList.ConnAuditList.exportFilename': '연결 로그',
  'pages.AuditList.AlarmAuditList.Login Device / Local Device': '로그인 장치 / 주 장치',
  'pages.AuditList.AlarmAuditList.Login Account / Remote Device': '로그인 계정 / 원격 장치',
  'pages.AuditList.AlarmAuditList.Login Account': '로그인 계정',
  'pages.AuditList.AlarmAuditList.Device ID': '장치 ID',
  'pages.AuditList.AlarmAuditList.Device Name': '장치 사용자 이름',
  'pages.AuditList.AlarmAuditList.Remote Device': '원격 장치',
  'pages.AuditList.AlarmAuditList.Event Time': '발생 시간',
  'pages.AuditList.AlarmAuditList.IP whiltelist': '화이트리스트 외의 IP 접근 시도',
  'pages.AuditList.AlarmAuditList.ExceedThirtyAccessAttempts': '30회 이상 접근 시도',
  'pages.AuditList.AlarmAuditList.MultipleAccessAttemptsWithinOneMinute': '1분 이내 다수의 접근 시도',
  'pages.AuditList.AlarmAuditList.ExceedThirtyLoginAttempts': '30회 이상 로그인 시도',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneMinute': '1분 이내 다수의 로그인 시도',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneHour': '1시간 이내 다수의 로그인 시도',
  'pages.AuditList.AlarmAuditList.exportFilename': '위험 이상 경고 로그',
  'pages.AuditList.ConsoleAuditList.Operation': '작업',
  'pages.AuditList.ConsoleAuditList.Name': '이름',
  'pages.AuditList.ConsoleAuditList.Name list': '이름 목록',
  'pages.AuditList.ConsoleAuditList.ID list': 'ID 목록',
  'pages.AuditList.ConsoleAuditList.Result': '결과',
  'pages.AuditList.ConsoleAuditList.Remote': '원격',
  'pages.AuditList.ConsoleAuditList.Local': '주',
  'pages.AuditList.ConsoleAuditList.User Login': '사용자 로그인 콘솔',
  'pages.AuditList.ConsoleAuditList.Add Group': '새 그룹 추가',
  'pages.AuditList.ConsoleAuditList.Add User': '사용자 추가',
  'pages.AuditList.ConsoleAuditList.Add Device': '장치 추가',
  'pages.AuditList.ConsoleAuditList.Delete Groups': '그룹 일괄 삭제',
  'pages.AuditList.ConsoleAuditList.Disconnect Device': '장치 연결 해제',
  'pages.AuditList.ConsoleAuditList.Enable Users': '사용자 활성화',
  'pages.AuditList.ConsoleAuditList.Disable Users': '사용자 비활성화',
  'pages.AuditList.ConsoleAuditList.Enable Devices': '장치 일괄 활성화',
  'pages.AuditList.ConsoleAuditList.Disable Devices': '장치 일괄 비활성화',
  'pages.AuditList.ConsoleAuditList.Update Group': '그룹 업데이트',
  'pages.AuditList.ConsoleAuditList.Update User': '사용자 업데이트',
  'pages.AuditList.ConsoleAuditList.Update Device': '장치 업데이트',
  'pages.AuditList.ConsoleAuditList.Delete User': '사용자 삭제',
  'pages.AuditList.ConsoleAuditList.Delete Device': '장치 삭제',
  'pages.AuditList.ConsoleAuditList.Add Address Book': '주소록 추가',
  'pages.AuditList.ConsoleAuditList.Delete Address Book': '주소록 삭제',
  'pages.AuditList.ConsoleAuditList.Change Address Book Name': '주소록 이름 변경',
  'pages.AuditList.ConsoleAuditList.Group Management': '그룹 관리',
  'pages.AuditList.ConsoleAuditList.User Management': '사용자 관리',
  'pages.AuditList.ConsoleAuditList.Device Management': '장치 관리',
  'pages.AuditList.ConsoleAuditList.Address Book Management': '주소록 관리',
  'pages.AuditList.ConsoleAuditList.exportFilename': '콘솔 로그',
  'pages.AuditList.ConsoleAuditList.Delete Devices in the Address Book Recycle Bin': '주소록 휴지통에서 장치 삭제',
  'pages.AuditList.ConsoleAuditList.Empty Address Book Recycle Bin': '주소록 휴지통 비우기',
  'pages.AuditList.ConsoleAuditList.Add Address Book Permission': '주소록 권한 추가',
  'pages.AuditList.ConsoleAuditList.Delete Address Book Permission': '주소록 권한 삭제',
  'pages.AuditList.ConsoleAuditList.Update Address Book Permission': '주소록 권한 수정',
  'pages.Settings.Strategy.Strategy list': '전략 목록',
  'pages.Settings.Strategy.Automatically close incoming sessions on user inactivity': '사용자 비활성 시 자동으로 수신 세션 종료',
  'pages.Settings.Strategy.delete_strategy_tip': '이 전략을 삭제하시겠습니까?',
  'pages.Settings.Strategy.New strategy': '새 전략',
  'pages.Settings.Strategy.Rename strategy': '전략 이름 변경',
  'pages.Settings.Strategy.Duplicate strategy': '전략 복사',
  'pages.Settings.Strategy.Edit Devices': '장치 편집',
  'pages.Settings.Strategy.Edit Users': '사용자 편집',
  'pages.Settings.Strategy.Rename': '이름 변경',
  'pages.Settings.Strategy.Duplicate': '복사',
  'pages.Settings.Strategy.Delete': '삭제',
  'pages.Settings.Strategy.Groups': '그룹',
  'pages.Settings.Strategy.Select All': '모두 선택',
  'pages.Settings.Strategy.Permissions': '권한',
  'pages.Settings.Strategy.Password': '비밀번호',
  'pages.Settings.Strategy.Security': '보안',
  'pages.Settings.Strategy.Other': '기타',
  'pages.Settings.Strategy.IP whitelist': 'IP 화이트리스트',
  'pages.Settings.Strategy.Cancel': '취소',
  'pages.Settings.Strategy.Submit': '확인',
  'pages.Settings.Strategy.Edit': '편집',
  'pages.Settings.Strategy.Custom': '사용자 정의',
  'pages.Settings.Strategy.Full access': '전체 접근',
  'pages.Settings.Strategy.Screen share': '화면 공유만',
  'pages.Settings.Strategy.Enable keyboard/mouse': '키보드/마우스 제어 허용',
  'pages.Settings.Strategy.Enable clipboard': '클립보드 동기화 허용',
  'pages.Settings.Strategy.Enable file transfer': '파일 전송 허용',
  'pages.Settings.Strategy.Enable audio': '오디오 전송 허용',
  'pages.Settings.Strategy.Enable TCP tunneling': 'TCP 터널링 허용',
  'pages.Settings.Strategy.Enable remote restart': '원격 재시작 허용',
  'pages.Settings.Strategy.Enable recording session': '세션 녹화 허용',
  'pages.Settings.Strategy.Enable blocking user input': '사용자 입력 차단 허용',
  'pages.Settings.Strategy.Enable remote configuration modification': '원격 구성 수정 허용',
  'pages.Settings.Strategy.Permission type': '권한 유형',
  'pages.Settings.Strategy.Approve mode': '인증 방식',
  'pages.Settings.Strategy.Password type': '비밀번호 유형',
  'pages.Settings.Strategy.Hide connection management window': '연결 관리 창 숨기기',
  'pages.Settings.Strategy.One-time password length': '일회용 비밀번호 길이',
  'pages.Settings.Strategy.Accept sessions via password': '비밀번호로만 접근 허용',
  'pages.Settings.Strategy.Accept sessions via click': '클릭으로만 접근 허용',
  'pages.Settings.Strategy.Accept sessions via both': '비밀번호 또는 클릭으로 접근 허용',
  'pages.Settings.Strategy.Use one-time password': '일회용 비밀번호 사용',
  'pages.Settings.Strategy.Use permanent password': '영구 비밀번호 사용',
  'pages.Settings.Strategy.Use both passwords': '두 가지 비밀번호 모두 사용',
  'pages.Settings.Strategy.Deny LAN discovery': 'LAN 발견 거부',
  'pages.Settings.Strategy.Enable direct IP access': '직접 IP 접근 허용',
  'pages.Settings.Strategy.auto_disconnect_option_tip': '비활성 세션 자동 종료',
  'pages.Settings.Strategy.deny_lan_discovery_desc_tip': '장치는 동일한 LAN 내의 다른 장치에서 발견되지 않습니다.',
  'pages.Settings.Strategy.enable_direct_ip_access_desc_tip': '장치에 직접 IP 연결을 허용합니다.',
  'pages.Settings.Strategy.Remove wallpaper during incoming sessions': '수신 세션 중 바탕화면 제거',
  'pages.Settings.Strategy.minutes': '분',
  'pages.Settings.Strategy.Enable': '활성화',
  'pages.Settings.Strategy.Disable': '비활성화',
  'pages.Settings.Strategy.disabled': '비활성화',
  'pages.Settings.Strategy.Default': '기본 전략',
  'pages.Settings.Strategy.Apply changes of this page': '이 페이지의 변경 사항 적용',
  'pages.Settings.Strategy.Invalid IP': '유효하지 않은 IP',
  'pages.Settings.Strategy.IP Whitelisting': 'IP 화이트리스트',
  'pages.Settings.Strategy.Clear': '지우기',
  'pages.Settings.Strategy.OK': '확인',
  'pages.Settings.Strategy.whitelist_sep': '쉼표, 세미콜론, 공백 또는 줄 바꿈을 구분자로 사용할 수 있습니다.',
  'pages.Settings.Strategy.Use IP whitelisting': '화이트리스트에 있는 IP만 접근 허용',
  'pages.Settings.Strategy.whitelist_tip': '화이트리스트에 있는 IP만 이 장치에 접근할 수 있습니다.',
  'pages.CustomClient.bidirectional_desc_tip': '다른 장치에 연결할 수 있고 연결 요청을 받을 수 있는 클라이언트를 생성합니다.',
  'pages.CustomClient.incoming_desc_tip': '연결 요청만 받을 수 있는 클라이언트를 생성합니다.',
  'pages.CustomClient.outgoing_desc_tip': '다른 장치에 연결할 수 있는 클라이언트를 생성합니다.',
  'pages.CustomClient.disable_installation_desc_tip': '사용자가 사용자 정의 클라이언트를 설치하지 못하도록 합니다 (Windows 전용).',
  'pages.CustomClient.disable_settings_desc_tip': '사용자가 사용자 정의 클라이언트의 설정에 접근하지 못하도록 합니다.',
  'pages.CustomClient.disable_ab_desc_tip': '사용자 정의 클라이언트의 주소록 기능을 비활성화합니다.',
  'pages.CustomClient.disable_tcp_desc_tip': 'RustDesk 시작 시 Windows 방화벽 대화 상자를 피하고 P2P 직접 연결 시도를 건너뛰고 중계 연결을 사용합니다.',
  'pages.CustomClient.disable_account_desc_tip': '사용자가 RustDesk 클라이언트에서 로그인하는 기능을 제거합니다.',
  'pages.CustomClient.override_settings_desc_tip': '설정을 덮어쓰며 RustDesk에서 변경할 수 없습니다.',
  'pages.CustomClient.default_settings_desc_tip': 'RustDesk에서 기본적으로 사용하는 기본 설정을 변경합니다.',
  'pages.Settings.KeyPage.failedToFetchKey': '키를 가져오는 데 실패했습니다.',
  'pages.Settings.KeyPage.keyResetSuccess': '키가 성공적으로 재설정되었습니다. 변경 사항을 적용하려면 hbbr를 재시작하십시오.',
  'pages.Settings.KeyPage.errorResettingKey': '키 재설정 중 오류가 발생했습니다.',
  'pages.Settings.KeyPage.keyUpdateSuccess': '키가 성공적으로 업데이트되었습니다. 변경 사항을 적용하려면 hbbr를 재시작하십시오.',
  'pages.Settings.KeyPage.errorUpdatingKey': '키 업데이트 중 오류가 발생했습니다. 키를 확인하십시오.',
  'pages.Settings.KeyPage.key': '키',
  'pages.Settings.KeyPage.privateKey': '개인 키',
  'pages.Settings.KeyPage.publicKey': '공개 키',
  'pages.Settings.KeyPage.editKey': '키 업데이트',
  'pages.Settings.KeyPage.reset': '재설정',
  'pages.Settings.KeyPage.custom': '수동 입력',
  'pages.Settings.KeyPage.pleaseInputPrivateKey': '개인 키를 입력하십시오!',
  'pages.Settings.License.settingsUpdatedSuccess': '설정이 성공적으로 업데이트되었습니다.',
  'pages.Settings.License.licenseCannotBeEmpty': '라이센스는 비워둘 수 없습니다.',
  'pages.Settings.License.license': '라이센스',
  'pages.Settings.Relay.GeoOverrideDescription': '여러 중계 서버가 존재할 때만 위치 덮어쓰기 기능을 사용할 수 있습니다.',
  'pages.Settings.GeoOverride.ipOrDomain': 'IP/도메인',
  'pages.Settings.GeoOverride.latitude': '위도',
  'pages.Settings.GeoOverride.longitude': '경도',
  'pages.Settings.GeoOverride.deleteSelectedConfirm': '선택한 위치 덮어쓰기를 삭제하시겠습니까?',
  'pages.Settings.GeoOverride.deleteConfirm': '이 위치 덮어쓰기를 삭제하시겠습니까?',
  'pages.Settings.GeoOverride.editGeoOverride': '덮어쓰기 편집',
  'pages.Settings.GeoOverride.addGeoOverride': '덮어쓰기 추가',
  'pages.Settings.GeoOverride.ipOrDomainRequired': 'IP/도메인은 비워둘 수 없습니다.',
  'pages.Settings.GeoOverride.latitudeRequired': '위도는 비워둘 수 없습니다.',
  'pages.Settings.GeoOverride.longitudeRequired': '경도는 비워둘 수 없습니다.',
  'pages.Settings.GeoOverride.testGeo': '위치 테스트',
  'pages.Settings.GeoOverride.geofileFetchFailed': 'GeoFile 정보를 가져오는 데 실패했습니다.',
  'pages.Settings.GeoOverride.loadGeoOverrides': '덮어쓰기 로드',
  'pages.Settings.GeoOverride.geoOverride': '위치 덮어쓰기',
  'pages.Settings.GeoOverride.title': '위치 덮어쓰기',
  'pages.Settings.GeoOverride.RelaySettings': '설정',
  'pages.Settings.GeoOverride.RelayServers': '중계 서버',
  'pages.Settings.GeoOverride.AddServer': '서버 추가',
  'pages.Settings.GeoOverride.AlwaysUseRelay': '항상 중계 사용',
  'pages.Settings.GeoOverride.UpdateServers': '수정 가져오기',
  'pages.Settings.GeoOverride.UpdatedAlwaysUseRelay': '항상 중계 사용이 업데이트되었습니다.',
  'pages.Settings.GeoOverride.FailedToUpdateAlwaysUseRelay': '항상 중계 사용 업데이트 실패',
  'pages.Settings.GeoOverride.UpdatedRelayServers': '중계 서버가 업데이트되었습니다.',
  'pages.Settings.GeoOverride.FailedToUpdateRelayServers': '중계 서버 업데이트 실패',
  'pages.Settings.GeoOverride.UpdatedChangeIDSetting': '변경 ID 설정이 업데이트되었습니다.',
  'pages.Settings.GeoOverride.FailedToUpdateChangeIDSetting': '변경 ID 설정 업데이트 실패',
  "pages.Settings.Others.Allow change ID": "ID 변경 허용",
  "pages.Settings.Others.Disable new devices": "새 장치 비활성화",
  "pages.Settings.Others.The control end need to login before access": "제어 단말이 연결하기 전에 로그인해야 합니다.",
  "pages.Settings.Others.Only admin can access unassigned devices": "관리자만 할당되지 않은 장치에 접근할 수 있습니다.",
  "pages.Settings.Others.Allow non-admin to see other groups": "비관리자가 다른 그룹을 볼 수 있도록 허용",
  "pages.Settings.Others.allow_non_admin_see_other_group_tip": "이 옵션이 꺼져 있으면 비관리자는 자신의 그룹 내에서만 주소록을 공유할 수 있습니다.",
  'pages.Settings.OIDC.Maximum Number of Authorized Users': '최대 인증 사용자 수',
  'pages.Settings.OIDC.emptyPrompt': '현재 제3자 인증 제공자가 없습니다. 이 기능을 사용하려면 하나를 추가하십시오.',
  'pages.Settings.OIDC.confirm-delete-title': '삭제 확인',
  'pages.Settings.OIDC.confirm-delete-content': '관련된 제3자 인증 사용자가 더 이상 로그인할 수 없습니다. 사용자 관리 인터페이스로 이동하여 이러한 사용자를 삭제할 수 있습니다.',
  'pages.Settings.Others.Only admin can access logs': '관리자만 로그에 접근할 수 있습니다.',
  'pages.Welcome.Max Devices': '최대 장치 수',
  'pages.Welcome.Max Users': '최대 사용자 수',
  'pages.Welcome.Not set': '설정되지 않음',
  'pages.Welcome.Invalid': '유효하지 않음',
  'pages.Welcome.Demo': '데모',
  'pages.Welcome.Personal': '개인',
  'pages.Welcome.Business': '비즈니스',
  'pages.Welcome.Unknown': '기타',
  'pages.Welcome.Max Peers': '최대 동료 수',
  'pages.Welcome.RustDesk Server Status': 'RustDesk 서버 상태',
  'pages.Welcome.License Expire Time': '라이센스 만료 시간',
  'pages.Welcome.Document': '문서',
  'pages.AddressBook.Permission': '권한',
  'pages.AddressBook.Device count': '장치 수',
  'pages.AddressBook.Address Books': '주소록',
  'pages.AddressBook.Tags': '태그',
  'pages.AddressBook.Devices': '장치',
  'pages.AddressBook.Device': '장치',
  'pages.AddressBook.Alias': '별명',
  'pages.AddressBook.Whether a password is set': '비밀번호가 설정되었는지 여부',
  'pages.AddressBook.Recycle Bin': '휴지통',
  'pages.AddressBook.Empty Recycle Bin': '휴지통 비우기',
  'pages.AddressBook.Personal': '개인',
  'pages.AddressBook.Personal (My address book)': '개인 (내 주소록)',
  'pages.AddressBook.Shared': '공유',
  'pages.AddressBook.Share': '공유',
  'pages.AddressBook.Delete': '삭제',
  'pages.AddressBook.Settings': '설정',
  'pages.AddressBook.Owner': '소유자',
  'pages.AddressBook.My Permission': '내 권한',
  'pages.AddressBook.Read-only': '읽기 전용',
  'pages.AddressBook.Read/Write': '읽기/쓰기',
  'pages.AddressBook.Full Control': '전체 제어',
  'pages.AddressBook.share_warning_tip': '위의 필드는 공유되며 다른 사람에게 표시됩니다.',
  'pages.AddressBook.permission_tip': '읽기 전용: 장치 및 태그를 볼 수 있습니다; 읽기/쓰기: 장치 및 태그를 편집할 수 있습니다; 전체 제어: 주소록을 다시 공유, 삭제 또는 이름 변경할 수 있습니다.',
  'pages.AddressBook.rule_priority_tip': '우선 순위: 사용자 > 그룹 > 모든 사람',
  'pages.AddressBook.Remove password': '비밀번호 제거',
  'pages.AddressBook.Import': '가져오기',
  'pages.AddressBook.Filter by intersection': '교차 필터링',
  'pages.AddressBook.Permissions': '권한',
  'pages.AddressBook.Type': '유형',
  'pages.AddressBook.Untagged': '태그 없음',

  'app.pwa.offline': '현재 오프라인 상태입니다.',
  'app.pwa.serviceworker.updated': '새로운 내용이 있습니다.',
  'app.pwa.serviceworker.updated.hint': '새로 고침 버튼을 클릭하거나 페이지를 수동으로 새로 고치십시오.',
  'app.pwa.serviceworker.updated.ok': '새로 고침',

  'app.setting.pagestyle': '전체 스타일 설정',
  'app.setting.pagestyle.dark': '어두운 메뉴 스타일',
  'app.setting.pagestyle.light': '밝은 메뉴 스타일',
  'app.setting.content-width': '내용 영역 너비',
  'app.setting.content-width.fixed': '고정 너비',
  'app.setting.content-width.fluid': '유동적',
  'app.setting.themecolor': '테마 색상',
  'app.setting.themecolor.dust': '먼지',
  'app.setting.themecolor.volcano': '화산',
  'app.setting.themecolor.sunset': '일몰',
  'app.setting.themecolor.cyan': '청록색',
  'app.setting.themecolor.green': '오로라 그린',
  'app.setting.themecolor.daybreak': '여명 블루 (기본)',
  'app.setting.themecolor.geekblue': '기크 블루',
  'app.setting.themecolor.purple': '자주색',
  'app.setting.navigationmode': '내비게이션 모드',
  'app.setting.sidemenu': '측면 메뉴 레이아웃',
  'app.setting.topmenu': '상단 메뉴 레이아웃',
  'app.setting.fixedheader': '고정 헤더',
  'app.setting.fixedsidebar': '고정 측면 메뉴',
  'app.setting.fixedsidebar.hint': '측면 메뉴 레이아웃에서 구성 가능',
  'app.setting.hideheader': '스크롤 시 헤더 숨기기',
  'app.setting.hideheader.hint': '고정 헤더일 때 구성 가능',
  'app.setting.othersettings': '기타 설정',
  'app.setting.weakmode': '색약 모드',
  'app.setting.copy': '설정 복사',
  'app.setting.copyinfo': '복사 성공, config/defaultSettings.js에서 기본 설정을 교체하십시오.',
  'app.setting.production.hint':
    '구성 영역은 개발 환경에서 미리 보기 용도로만 사용되며, 생산 환경에서는 표시되지 않습니다. 복사 후 수동으로 구성 파일을 수정하십시오.',

  'app.settings.menuMap.basic': '기본 설정',
  'app.settings.menuMap.security': '보안 설정',
  'app.settings.menuMap.binding': '계정 바인딩',
  'app.settings.menuMap.notification': '새 메시지 알림',
  'app.settings.basic.avatar': '아바타',
  'app.settings.basic.change-avatar': '아바타 변경',
  'app.settings.basic.email': '이메일',
  'app.settings.basic.email-message': '이메일을 입력하십시오!',
  'app.settings.basic.nickname': '닉네임',
  'app.settings.basic.nickname-message': '닉네임을 입력하십시오!',
  'app.settings.basic.profile': '개인 소개',
  'app.settings.basic.profile-message': '개인 소개를 입력하십시오!',
  'app.settings.basic.profile-placeholder': '개인 소개',
  'app.settings.basic.country': '국가/지역',
  'app.settings.basic.country-message': '국가 또는 지역을 입력하십시오!',
  'app.settings.basic.geographic': '소재지',
  'app.settings.basic.geographic-message': '소재지를 입력하십시오!',
  'app.settings.basic.address': '거리 주소',
  'app.settings.basic.address-message': '거리 주소를 입력하십시오!',
  'app.settings.basic.phone': '연락처',
  'app.settings.basic.phone-message': '연락처를 입력하십시오!',
  'app.settings.basic.update': '기본 정보 업데이트',
  'app.settings.security.strong': '강함',
  'app.settings.security.medium': '중간',
  'app.settings.security.weak': '약함',
  'app.settings.security.password': '계정 비밀번호',
  'app.settings.security.password-description': '현재 비밀번호 강도',
  'app.settings.security.phone': '보안 전화',
  'app.settings.security.phone-description': '바인딩된 전화',
  'app.settings.security.question': '보안 질문',
  'app.settings.security.question-description': '보안 질문이 설정되지 않았습니다. 보안 질문은 계정 보안을 효과적으로 보호할 수 있습니다.',
  'app.settings.security.email': '대체 이메일',
  'app.settings.security.email-description': '바인딩된 이메일',
  'app.settings.security.mfa': 'MFA 장치',
  'app.settings.security.mfa-description': 'MFA 장치가 바인딩되지 않았습니다. 바인딩 후 이중 확인을 수행할 수 있습니다.',
  'app.settings.security.modify': '수정',
  'app.settings.security.set': '설정',
  'app.settings.security.bind': '바인딩',
  'app.settings.binding.taobao': '타오바오 바인딩',
  'app.settings.binding.taobao-description': '현재 타오바오 계정이 바인딩되지 않았습니다.',
  'app.settings.binding.alipay': '알리페이 바인딩',
  'app.settings.binding.alipay-description': '현재 알리페이 계정이 바인딩되지 않았습니다.',
  'app.settings.binding.dingding': '딩딩 바인딩',
  'app.settings.binding.dingding-description': '현재 딩딩 계정이 바인딩되지 않았습니다.',
  'app.settings.binding.bind': '바인딩',
  'app.settings.notification.password': '계정 비밀번호',
  'app.settings.notification.password-description': '다른 사용자의 메시지는 사이트 내 메시지로 알림됩니다.',
  'app.settings.notification.messages': '시스템 메시지',
  'app.settings.notification.messages-description': '시스템 메시지는 사이트 내 메시지로 알림됩니다.',
  'app.settings.notification.todo': '할 일',
  'app.settings.notification.todo-description': '할 일은 사이트 내 메시지로 알림됩니다.',
  'app.settings.open': '켜기',
  'app.settings.close': '끄기',
  'app.copyright.produced': 'RustDesk 팀에서 개발',
  'pages.account.Settings.Security.Password': '비밀번호',
  'pages.account.Settings.Security.Change password': '비밀번호 변경',
  'pages.account.tfa.Enable 2FA': '2FA 활성화',
  'pages.account.tfa.2 Factor Authentication is enabled.': '이중 인증이 활성화되었습니다.',
  'pages.account.tfa.Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.': '귀하의 2FA 키는 오랜 기간 사용되었습니다. 최대 계정 보안을 보장하기 위해 2FA 키를 업데이트하는 것이 좋습니다.',
  'pages.account.tfa.Enable 2 Factor Authentication.': '이중 인증 활성화',

  'Enable Two-Factor Authentication': '이중 인증 활성화',
  'Make your RustDesk account more secure by requiring an extra authentication code when you log in.': '로그인 시 추가 인증 코드를 요구하여 RustDesk 계정을 더욱 안전하게 만드십시오.',
  '1. Download an authenticator app': '1. 인증기 앱 다운로드',
  'Download an authenticator app such as': 'Google Authenticator 또는 Authy와 같은 인증기 앱을 다운로드하십시오.',
  '2FA code': '2FA 코드',
  'Authenticator': '인증기',
  'on your phone or desktop.': '휴대폰이나 데스크탑에서.',
  '2. Scan the QR code': '2. QR 코드 스캔',
  'Open your authenticator app, scan the QR code and enter the code that your app shows.': '인증기 앱을 열고 QR 코드를 스캔한 후 앱에서 표시되는 코드를 입력하십시오.',
  'You can change or remove 2FA at any time in your account settings.': '계정 설정에서 언제든지 2FA를 변경하거나 제거할 수 있습니다.',
  'Please verify your 2FA code to disable current 2FA first.': '현재 2FA를 비활성화하려면 먼저 2FA 코드를 확인하십시오.',
  'Can\'t verify the code. Check that code and local time settings are correct!': '코드를 확인할 수 없습니다. 코드와 로컬 시간 설정이 올바른지 확인하십시오!',
  'First sign-in:': '첫 로그인:',
  'Recent activity:': '최근 활동:',
  'pages.Settings.GeoOverride.reloadGeo': '지리 데이터 다시 로드',
  'pages.Settings.GeoOverride.getRelayServer': '중계 서버 가져오기',
  'pages.Settings.GeoOverride.relayServer': '중계 서버',
  'SMTP Host': 'SMTP 호스트',
  'SMTP Port': 'SMTP 포트',
  'My Server Requires Authentication': '내 서버는 인증이 필요합니다.',
  'smtp.From': '발신자',
  'Mail Account': '메일 계정',
  'Mail Password': '메일 비밀번호',
  'Custom Certificate PEM File Path (on the server)': '사용자 정의 인증서 PEM 파일 경로 (서버에서)',
  Update: '업데이트',
  'Submit and Test Email': '이메일 제출 및 테스트',
  'he Receiver Email': '수신자 이메일',
  'LDAP Host': 'LDAP 호스트',
  'LDAP Port': 'LDAP 포트',
  'Filter': '필터',
  'Username Attribute': '사용자 이름 속성',
  'The attribute used as the username when logging in. Default is "dn".': '로그인 시 사용자 이름으로 사용되는 속성. 기본값은 "dn".',
  'DraggableTab.Drag tab to reorder': '탭을 드래그하여 순서 변경',
  'pages.Settings.OIDC.new.Auth Type': '인증 유형',
  'pages.Settings.OIDC.New Auth Provider': '새 인증 제공자',
  'pages.Settings.OIDC.new.New Auth Provider': '새 인증 제공자',
  'pages.Settings.OIDC.Delete This Provider': '이 제공자 삭제',
  'pages.Settings.OIDC.new.Enable PKCE': 'PKCE 활성화',
  'pages.Settings.OIDC.Enable PKCE': 'PKCE 활성화',
  'pages.Settings.OIDC.new.Timeout(seconds) to force a refresh of metadata(e.g. JWKS) from OIDC providers': 'OIDC 제공자로부터 메타데이터(예: JWKS)를 강제로 새로 고치기 위한 타임아웃(초)',
  'pages.Settings.OIDC.new.0 means no timeout, empty means default (3600)': '0은 타임아웃이 없음을 의미하며, 비어 있으면 기본값(3600)을 의미합니다.',
  'Callback URL': '콜백 URL',
  'pages.Settings.Others.Session expiration time (Day)': '세션 만료 시간 (일)',
  'Or': '또는',
};
