import { PageLoading } from '@ant-design/pro-components';
import { useEffect } from 'react';
import { dark_init_theme } from './global';

export default () => {
  useEffect(() => {
    document.documentElement.className = dark_init_theme() ? 'dark-theme' : 'light-theme';
  }, []);

  return (
    <div className={dark_init_theme() ? 'dark-theme' : 'light-theme'}>
      <PageLoading />
    </div>
  );
};
