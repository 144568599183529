import { GithubOutlined } from '@ant-design/icons';
import { DefaultFooter } from '@ant-design/pro-layout';
import { theme } from 'antd';
import { t } from '../../global';
import { useEmotionCss } from '@ant-design/use-emotion-css';

const Footer: React.FC = () => {
  const defaultMessage = t('app.copyright.produced', true, 'Purslane Ltd. Produced');
  const { token: themeToken } = theme.useToken();
  const currentYear = new Date().getFullYear();

  const footerClassName = useEmotionCss(({ token }) => {
    return {
      a: {
        color: token.colorLink,
        '&:hover': {
          color: token.colorLinkHover,
        },
      },
      '.ant-pro-global-footer': {
        color: token.colorText,
        '.ant-pro-global-footer-copyright': {
          color: token.colorTextSecondary,
        },
      },
    };
  });

  return (
    <div className={footerClassName} style={{ backgroundColor: themeToken.colorBgContainer }}>
      <DefaultFooter
        style={{
          backgroundColor: 'transparent',
          color: themeToken.colorText,
        }}
        copyright={`${currentYear} ${defaultMessage}`}
        links={[
          {
            key: 'github',
            title: <GithubOutlined />,
            href: 'https://github.com/rustdesk/rustdesk',
            blankTarget: true,
          },
          {
            key: 'RustDesk',
            title: 'rustdesk.com',
            href: 'https://rustdesk.com',
            blankTarget: true,
          },
        ]}
      />
    </div>
  );
};

export default Footer;
