export default {
  'app.title': 'Console RustDesk',
  'navBar.lang': 'Idioma',
  'layout.user.link.help': 'Ajuda',
  'layout.user.link.privacy': 'Privacidade',
  'layout.user.link.terms': 'Termos',
  'app.preview.down.block': 'Baixar esta página para o projeto local',
  'app.welcome.link.fetch-blocks': 'Obter todos os blocos',
  'app.welcome.link.block-list': 'Desenvolver rapidamente páginas padrão com base em blocos',
  'The verification code is incorrect or has expired': 'O código de verificação está incorreto ou expirou',
  Type: 'Tipo',
  Remote: 'Dispositivo remoto',
  remoteTip: 'Computador ou outro terminal controlado remotamente',
  Local: 'Dispositivo local',
  'Start Time': 'Hora de início',
  'End Time': 'Hora de término',
  Note: 'Observação',
  'Log List': 'Lista de logs',
  'Device List': 'Lista de dispositivos',
  'User List': 'Lista de usuários',
  'User Name': 'Nome de usuário',
  Updating: 'Atualizando',
  Adding: 'Adicionando',
  Deleting: 'Excluindo',
  Create: 'Criar',
  Add: 'Adicionar',
  Confirm: 'Confirmar',
  Submit: 'Enviar',
  Warning: 'Aviso',
  Error: 'Erro',
  Email: 'E-mail',
  Status: 'Status',
  Action: 'Ação',
  Password: 'Senha',
  'New Password': 'Nova senha',
  Edit: 'Editar',
  Normal: 'Normal',
  Disabled: 'Desativado',
  Disable: 'Desativar',
  Enable: 'Ativar',
  Chosen: 'Escolhido',
  Items: 'itens',
  User: 'Usuário',
  Info: 'Informação',
  'Log out': 'Sair',
  Settings: 'Configurações',
  'Basic Settings': 'Configurações básicas',
  'Remote Desktop': 'Desktop remoto',
  'File Transfer': 'Transferência de arquivos',
  'Port Transfer': 'Redirecionamento de porta',
  'View Camera': 'Visualizar câmera',
  'Not Logged In': 'Não conectado',
  'Created Time': 'Data de criação',
  Delete: 'Excluir',
  Group: 'Grupo',
  'Device Group': 'Grupo de dispositivos',
  'User Group': 'Grupo de usuários',
  Name: 'Nome',
  'Group List': 'Lista de grupos',
  deleteConfirm: 'Tem certeza que deseja excluir?',
  groupTip: 'Usuários do mesmo grupo podem acessar uns aos outros',
  document: 'Documentação',
  Detail: 'Detalhes',
  Direction: 'Direção',
  File: 'Arquivo',
  Directory: 'Diretório',
  Disconnect: 'Desconectar',
  Time: 'Tempo',
  'Export as csv': 'Exportar como CSV',
  exportCsvTip: 'No máximo 1000 registros podem ser exportados de uma vez',
  'Not binded only': 'Mostrar apenas dispositivos não vinculados',
  Close: 'Fechar',
  'Default Strategy': 'Estratégia padrão',
  'applied device num': 'Número de dispositivos aplicados',
  'Device Name': 'Nome do dispositivo',
  'Strategy': 'Estratégia',
  'Device Strategy': 'Estratégia de dispositivo',
  'User Strategy': 'Estratégia de usuário',
  'Group Strategy': 'Estratégia de grupo',
  'strategy_tooltip': 'Formato: "Estratégia de dispositivo / Estratégia de usuário / Estratégia de grupo". "-" sozinho significa usar a estratégia padrão, "-" em uma posição significa que nenhuma estratégia está definida nesse nível',
  Search: 'Pesquisar',
  Select: 'Selecionar',
  Assign: 'Atribuir',
  Device: 'Dispositivo',
  'Device Username': 'Nome de usuário do dispositivo',
  Role: 'Função',
  'Auth Type': 'Tipo de autenticação',
  Invite: 'Convidar',
  'Please configure Email SMTP settings first': 'Configure primeiro as configurações de SMTP de e-mail',
  'Security Settings': 'Configurações de segurança',
  'Manage Signed Devices': 'Gerenciar dispositivos assinados',
  'Since 2FA is enabled, the email login verification option is not used. 2FA login verification will be used instead.': 'Como o 2FA está ativado, a opção de verificação de login por e-mail não é usada. A verificação de login 2FA será usada em seu lugar.',
  'Enable alarm event email notification': 'Ativar notificação por e-mail de eventos de alarme',
  'Enable email login verification': 'Ativar verificação de login por e-mail',
  'License Type': 'Tipo de licença',
  'Renew / Upgrade License': 'Renovar / Atualizar licença',
  Save: 'Salvar',
  Back: 'Voltar',
  'Confirm Password': 'Confirmar senha',
  'username@device_name': 'usuário@nome_dispositivo',
  'Hide username': 'Ocultar nome de usuário',
  'Remote device': 'Dispositivo remoto',
  'Within-group connections': 'Conexões dentro do grupo',
  'Cross-group connections': 'Conexões entre grupos',
  welcomeTitle: 'Bem-vindo ao RustDesk',
  Cancel: 'Cancelar',
  OK: 'OK',

  'pages.GroupList.User Group': 'Grupo de usuários',
  'pages.GroupList.User': 'Usuário',

  'pages.CustomClient.Application name': 'Nome da aplicação',
  'pages.CustomClient.Name': 'Nome',
  'pages.CustomClient.Build': 'Compilar',
  'pages.CustomClient.Note': 'Observação',
  'pages.CustomClient.Action': 'Ação',
  'pages.CustomClient.Delete': 'Excluir',
  'pages.CustomClient.Edit': 'Editar',
  'pages.CustomClient.Duplicate': 'Duplicar',
  'pages.CustomClient.Create': 'Criar',
  'pages.CustomClient.Custom Clients': 'Clientes personalizados',
  'pages.CustomClient.General': 'Geral',
  'pages.CustomClient.Name of the configuration': 'Nome da configuração',
  'pages.CustomClient.MSI installer': 'Instalador MSI',
  'pages.CustomClient.Connection type': 'Tipo de conexão',
  'pages.CustomClient.Bidirectional': 'Bidirecional',
  'pages.CustomClient.Incoming': 'Entrada',
  'pages.CustomClient.Outgoing': 'Saída',
  'pages.CustomClient.Options': 'Opções',
  'pages.CustomClient.Disable installation': 'Desativar instalação',
  'pages.CustomClient.Disable settings': 'Desativar configurações',
  'pages.CustomClient.Disable address book': 'Desativar catálogo de endereços',
  'pages.CustomClient.Disable TCP listen port': 'Desativar porta de escuta TCP',
  'pages.CustomClient.Disable user account': 'Desativar conta de usuário',
  'pages.CustomClient.Some note about this configuration': 'Alguma observação sobre esta configuração',
  'pages.CustomClient.Custom server': 'Servidor personalizado',
  'pages.CustomClient.Visual': 'Visual',
  'pages.CustomClient.Application icon': 'Ícone da aplicação',
  'pages.CustomClient.Security': 'Segurança',
  'pages.CustomClient.Preset password': 'Senha predefinida',
  'pages.CustomClient.Advanced': 'Avançado',
  'pages.CustomClient.Override settings': 'Substituir configurações',
  'pages.CustomClient.Default settings': 'Configurações padrão',
  'pages.CustomClient.Note: ': 'Nota: ',
  'pages.CustomClient.Click or drag to upload': 'Clique ou arraste para carregar',
  'pages.CustomClient.app_icon_desc_tip': 'Carregue uma imagem PNG quadrada de 512x512 pixels como ícone da aplicação.',
  'pages.CustomClient.app_name_desc_tip': 'Especifique um nome único que aparecerá no menu Iniciar, Painel de Controle, etc. para o cliente RustDesk instalado.',
  'pages.CustomClient.logo_desc_tip': 'Carregue uma imagem PNG que será exibida no topo da janela principal do cliente personalizado. O tamanho será automaticamente ajustado. Recomendamos um PNG de 200x40 pixels.',
  'pages.CustomClient.This password will be used as a default permanent password for all new clients. It can be changed by the client.': 'Esta senha será usada como senha permanente padrão para todos os novos clientes. Pode ser alterada pelo cliente.',
  'pages.CustomClient.The application icon must be a PNG file.': 'O ícone da aplicação deve ser um arquivo PNG.',
  'component.tagSelect.expand': 'Expandir',
  'component.tagSelect.collapse': 'Recolher',
  'component.tagSelect.all': 'Todos',
  'component.LicenseAlert.Your license is invalid.': 'Sua licença é inválida.',
  'component.LicenseAlert.Your license is not set.': 'Sua licença não está configurada.',
  'component.LicenseAlert.Your license is about to expire in 2 weeks.': 'Sua licença expirará em 2 semanas.',
  'component.LicenseAlert.Your license has expired.': 'Sua licença expirou.',
  'pages.CustomClient.Refresh status manually': 'Atualizar status manualmente',
  'pages.CustomClient.the building task': 'a tarefa de compilação',
  'pages.CustomClient.Stop': 'Parar',
  'pages.CustomClient.Cancel': 'Cancelar',
  'pages.CustomClient.Completed at %s, this file will be deleted in 1 day.': 'Concluído em %s, este arquivo será excluído em 1 dia.',

  'component.globalHeader.search': 'Pesquisar no site',
  'component.globalHeader.help': 'Documentação',
  'component.globalHeader.notification': 'Notificações',
  'component.globalHeader.notification.empty': 'Você visualizou todas as notificações',
  'component.globalHeader.message': 'Mensagens',
  'component.globalHeader.message.empty': 'Você leu todas as mensagens',
  'component.globalHeader.event': 'Tarefas pendentes',
  'component.globalHeader.event.empty': 'Você concluiu todas as tarefas',
  'component.noticeIcon.clear': 'Limpar',
  'component.noticeIcon.cleared': 'Limpo',
  'component.noticeIcon.empty': 'Sem dados',
  'component.noticeIcon.view-more': 'Ver mais',
  'Token List': 'Lista de tokens',
  Description: 'Descrição',
  'Device Permission': 'Permissão de dispositivo',
  'Audit Log Permission': 'Permissão de log de auditoria',
  'User Permission': 'Permissão de usuário',
  'Group Permission': 'Permissão de grupo',
  'Strategy Permission': 'Permissão de estratégia',

  'menu.welcome': 'Bem-vindo',
  'menu.more-blocks': 'Mais blocos',
  'menu.home': 'Início',
  'menu.admin': 'Administração',
  'menu.admin.sub-page': 'Subpágina',
  'menu.login': 'Login',
  'menu.register': 'Registro',
  'menu.register-result': 'Resultado do registro',
  'menu.dashboard': 'Painel',
  'menu.dashboard.analysis': 'Análise',
  'menu.dashboard.monitor': 'Monitoramento',
  'menu.dashboard.workplace': 'Área de trabalho',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Formulários',
  'menu.form.basic-form': 'Formulário básico',
  'menu.form.step-form': 'Formulário em etapas',
  'menu.form.step-form.info': 'Formulário em etapas (informações de transferência)',
  'menu.form.step-form.confirm': 'Formulário em etapas (confirmar informações de transferência)',
  'menu.form.step-form.result': 'Formulário em etapas (concluído)',
  'menu.form.advanced-form': 'Formulário avançado',
  'menu.list': 'Listas',
  'menu.list.audit-list': 'Auditoria de segurança',
  'menu.list.audit-list.Alarm': 'Alarmes de risco/anomalia',
  'menu.list.audit-list.Connection': 'Log de conexões',
  'menu.list.audit-list.File': 'Log de transferência de arquivos',
  'menu.list.audit-list.Console': 'Log de operações do console',
  'menu.list.device-list': 'Dispositivos',
  'menu.list.user-list': 'Usuários',
  'menu.list.group-list': 'Grupos',
  'menu.list.group-list.user': 'Grupos de usuários',
  'menu.list.group-list.device': 'Grupos de dispositivos',
  'menu.list.table-list': 'Tabela de pesquisa',
  'menu.list.basic-list': 'Lista básica',
  'menu.list.card-list': 'Lista de cartões',
  'menu.list.search-list': 'Lista de pesquisa',
  'menu.list.search-list.articles': 'Lista de pesquisa (artigos)',
  'menu.list.search-list.projects': 'Lista de pesquisa (projetos)',
  'menu.list.search-list.applications': 'Lista de pesquisa (aplicações)',
  'menu.profile': 'Perfil',
  'menu.profile.basic': 'Perfil básico',
  'menu.profile.advanced': 'Perfil avançado',
  'menu.result': 'Resultado',
  'menu.result.success': 'Sucesso',
  'menu.result.fail': 'Falha',
  'menu.exception': 'Exceção',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Disparar erro',
  'menu.account': 'Conta',
  'menu.account.center': 'Centro da conta',
  'menu.account.settings': 'Configurações da conta',
  'menu.account.trigger': 'Disparar erro',
  'menu.account.logout': 'Sair',
  'menu.editor': 'Editor gráfico',
  'menu.editor.flow': 'Editor de fluxo',
  'menu.editor.mind': 'Editor de mapa mental',
  'menu.editor.koni': 'Editor de topologia',
  'menu.Settings': 'Configurações',
  'menu.Strategy': 'Estratégia',
  'menu.Settings.Key': 'Chave',
  'menu.Settings.License': 'Licença',
  'menu.Settings.Relay': 'Gerenciamento de relay',
  'menu.Settings.Others': 'Outros',
  'menu.Settings.Tokens': 'Tokens',
  'menu.Address Books': 'Catálogos de endereços',
  'menu.Strategies': 'Estratégias',
  'menu.Custom Clients': 'Clientes personalizados',

  'pages.login.accountLogin.tab': 'Login da conta',
  'pages.login.accountLogin.errorMessage': 'Nome de usuário ou senha incorretos',
  'pages.login.failure': 'Falha no login, tente novamente!',
  'pages.login.success': 'Login bem-sucedido!',
  'pages.login.username.placeholder': 'Nome de usuário: admin ou user',
  'pages.login.username.required': 'Por favor, insira seu nome de usuário!',
  'pages.login.password.placeholder': 'Senha: ant.design',
  'pages.login.password.required': 'Por favor, insira sua senha!',
  'pages.login.phoneLogin.tab': 'Login por telefone',
  'pages.login.phoneLogin.errorMessage': 'Código de verificação incorreto',
  'pages.login.phoneNumber.placeholder': 'Por favor, insira o número de telefone!',
  'pages.login.phoneNumber.required': 'Por favor, insira o número de telefone!',
  'pages.login.phoneNumber.invalid': 'Número de telefone inválido!',
  'pages.login.captcha.placeholder': 'Por favor, insira o código de verificação!',
  'pages.login.captcha.required': 'Por favor, insira o código de verificação!',
  'pages.login.phoneLogin.getVerificationCode': 'Obter código',
  'pages.getCaptchaSecondText': 'segundos antes de reenviar',
  'pages.login.rememberMe': 'Lembrar-me',
  'pages.login.forgotPassword': 'Esqueceu a senha?',
  'pages.login.submit': 'Entrar',
  'pages.login.loginWith': 'Entrar com:',
  'pages.login.loginWithGoogle': 'Entrar com Google',
  'pages.login.loginWithGithub': 'Entrar com Github',
  'pages.login.loginWithOkta': 'Entrar com Okta',
  'pages.login.loginWith.requesting_auth': 'Solicitando página de autorização',
  'pages.login.loginWith.waiting_auth': 'Por favor, conclua a autorização da conta',
  'pages.login.registerAccount': 'Criar conta',
  'pages.welcome.advancedComponent': 'Componente avançado',
  'pages.welcome.link': 'Bem-vindo',
  'pages.welcome.advancedLayout': 'Layout avançado',
  'pages.welcome.alertMessage': 'Componentes mais rápidos e poderosos foram lançados.',
  'pages.account.tfaComponent.Enable 2FA': 'Ativar 2FA',
  'pages.account.tfaComponent.2 Factor Authentication is enabled.': 'Autenticação de dois fatores está ativada',
  'pages.account.tfaComponent.Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.': 'Sua chave 2FA está em uso por um período prolongado. Recomendamos atualizar sua chave 2FA para garantir a máxima segurança da conta.',
  'pages.account.tfaComponent.Enable 2 Factor Authentication.': 'Ativar autenticação de dois fatores.',
  'pages.account.tfa.The code must be 6, 8, 10 digits.': 'O código deve ter 6, 8 ou 10 dígitos.',
  'pages.account.tfa.The code must be 6 or 8 digits.': 'O código deve ter 6 ou 8 dígitos.',
  'pages.admin.subPage.title': 'Esta página só pode ser visualizada por administradores',
  'pages.admin.subPage.alertMessage': 'umi ui está disponível, você pode iniciar com npm run ui.',
  'pages.AuditList.FileAuditList.files': 'arquivos',
  'pages.AuditList.FileAuditList.pathTip': 'Caminho no dispositivo controlado',
  'pages.AuditList.FileAuditList.limitedTip': 'Exibindo no máximo 10 arquivos',
  'pages.AuditList.FileAuditList.exportFilename': 'Log de transferência de arquivos',
  'pages.AuditList.ConnAuditList.modal_title': 'Confirmar ação',
  'pages.AuditList.ConnAuditList.diconnect_confirm_tip': 'Tem certeza que deseja desconectar este dispositivo?',
  'pages.AuditList.ConnAuditList.disconnect_succ': 'Desconexão bem-sucedida!',
  'pages.AuditList.ConnAuditList.disconnect_fail': 'Falha na desconexão!',
  'pages.AuditList.ConnAuditList.exportFilename': 'Log de conexões',
  'pages.AuditList.AlarmAuditList.Login Device / Local Device': 'Dispositivo de login / Dispositivo local',
  'pages.AuditList.AlarmAuditList.Login Account / Remote Device': 'Conta de login / Dispositivo remoto',
  'pages.AuditList.AlarmAuditList.Login Account': 'Conta de login',
  'pages.AuditList.AlarmAuditList.Device ID': 'ID do dispositivo',
  'pages.AuditList.AlarmAuditList.Device Name': 'Nome do dispositivo',
  'pages.AuditList.AlarmAuditList.Remote Device': 'Dispositivo remoto',
  'pages.AuditList.AlarmAuditList.Event Time': 'Hora do evento',
  'pages.AuditList.AlarmAuditList.IP whiltelist': 'Tentativa de acesso de IP fora da lista branca',
  'pages.AuditList.AlarmAuditList.ExceedThirtyAccessAttempts': 'Tentativas de acesso consecutivas (30)',
  'pages.AuditList.AlarmAuditList.MultipleAccessAttemptsWithinOneMinute': 'Múltiplas tentativas de acesso em 1 minuto',
  'pages.AuditList.AlarmAuditList.ExceedThirtyLoginAttempts': 'Tentativas de login consecutivas (30)',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneMinute': 'Múltiplas tentativas de login em 1 minuto',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneHour': 'Múltiplas tentativas de login em 1 hora',
  'pages.AuditList.AlarmAuditList.exportFilename': 'Log de alarmes de risco/anomalia',
  'pages.AuditList.ConsoleAuditList.Operation': 'Operação',
  'pages.AuditList.ConsoleAuditList.Name': 'Nome',
  'pages.AuditList.ConsoleAuditList.Name list': 'Lista de nomes',
  'pages.AuditList.ConsoleAuditList.ID list': 'Lista de IDs',
  'pages.AuditList.ConsoleAuditList.Result': 'Resultado',
  'pages.AuditList.ConsoleAuditList.Remote': 'Remoto',
  'pages.AuditList.ConsoleAuditList.Local': 'Local',
  'pages.AuditList.ConsoleAuditList.User Login': 'Login de usuário no console',
  'pages.AuditList.ConsoleAuditList.Add Group': 'Adicionar grupo',
  'pages.AuditList.ConsoleAuditList.Add User': 'Adicionar usuário',
  'pages.AuditList.ConsoleAuditList.Add Device': 'Adicionar dispositivo',
  'pages.AuditList.ConsoleAuditList.Delete Groups': 'Excluir grupos',
  'pages.AuditList.ConsoleAuditList.Disconnect Device': 'Desconectar dispositivo',
  'pages.AuditList.ConsoleAuditList.Enable Users': 'Ativar usuários',
  'pages.AuditList.ConsoleAuditList.Disable Users': 'Desativar usuários',
  'pages.AuditList.ConsoleAuditList.Enable Devices': 'Ativar dispositivos',
  'pages.AuditList.ConsoleAuditList.Disable Devices': 'Desativar dispositivos',
  'pages.AuditList.ConsoleAuditList.Update Group': 'Atualizar grupo',
  'pages.AuditList.ConsoleAuditList.Update User': 'Atualizar usuário',
  'pages.AuditList.ConsoleAuditList.Update Device': 'Atualizar dispositivo',
  'pages.AuditList.ConsoleAuditList.Delete User': 'Excluir usuário',
  'pages.AuditList.ConsoleAuditList.Delete Device': 'Excluir dispositivo',
  'pages.AuditList.ConsoleAuditList.Add Address Book': 'Adicionar catálogo de endereços',
  'pages.AuditList.ConsoleAuditList.Delete Address Book': 'Excluir catálogo de endereços',
  'pages.AuditList.ConsoleAuditList.Change Address Book Name': 'Alterar nome do catálogo de endereços',
  'pages.AuditList.ConsoleAuditList.Group Management': 'Gerenciamento de grupos',
  'pages.AuditList.ConsoleAuditList.User Management': 'Gerenciamento de usuários',
  'pages.AuditList.ConsoleAuditList.Device Management': 'Gerenciamento de dispositivos',
  'pages.AuditList.ConsoleAuditList.Address Book Management': 'Gerenciamento de catálogos de endereços',
  'pages.AuditList.ConsoleAuditList.exportFilename': 'Log do console',
  'pages.AuditList.ConsoleAuditList.Delete Devices in the Address Book Recycle Bin': 'Excluir dispositivos na lixeira do catálogo de endereços',
  'pages.AuditList.ConsoleAuditList.Empty Address Book Recycle Bin': 'Esvaziar lixeira do catálogo de endereços',
  'pages.AuditList.ConsoleAuditList.Add Address Book Permission': 'Adicionar permissão de catálogo de endereços',
  'pages.AuditList.ConsoleAuditList.Delete Address Book Permission': 'Excluir permissão de catálogo de endereços',
  'pages.AuditList.ConsoleAuditList.Update Address Book Permission': 'Atualizar permissão de catálogo de endereços',
  'pages.Settings.Strategy.Strategy list': 'Lista de estratégias',
  'pages.Settings.Strategy.Automatically close incoming sessions on user inactivity': 'Fechar automaticamente sessões de entrada quando usuário estiver inativo',
  'pages.Settings.Strategy.delete_strategy_tip': 'Tem certeza que deseja excluir esta estratégia?',
  'pages.Settings.Strategy.New strategy': 'Nova estratégia',
  'pages.Settings.Strategy.Rename strategy': 'Renomear estratégia',
  'pages.Settings.Strategy.Duplicate strategy': 'Duplicar estratégia',
  'pages.Settings.Strategy.Edit Devices': 'Editar dispositivos',
  'pages.Settings.Strategy.Edit Users': 'Editar usuários',
  'pages.Settings.Strategy.Rename': 'Renomear',
  'pages.Settings.Strategy.Duplicate': 'Duplicar',
  'pages.Settings.Strategy.Delete': 'Excluir',
  'pages.Settings.Strategy.Groups': 'Grupos',
  'pages.Settings.Strategy.Select All': 'Selecionar todos',
  'pages.Settings.Strategy.Permissions': 'Permissões',
  'pages.Settings.Strategy.Password': 'Senha',
  'pages.Settings.Strategy.Security': 'Segurança',
  'pages.Settings.Strategy.Other': 'Outros',
  'pages.Settings.Strategy.IP whitelist': 'Lista branca de IP',
  'pages.Settings.Strategy.Cancel': 'Cancelar',
  'pages.Settings.Strategy.Submit': 'Confirmar',
  'pages.Settings.Strategy.Edit': 'Editar',
  'pages.Settings.Strategy.Custom': 'Personalizada',
  'pages.Settings.Strategy.Full access': 'Acesso completo',
  'pages.Settings.Strategy.Screen share': 'Apenas compartilhamento de tela',
  'pages.Settings.Strategy.Enable keyboard/mouse': 'Permitir controle de teclado/mouse',
  'pages.Settings.Strategy.Enable clipboard': 'Permitir sincronização de área de transferência',
  'pages.Settings.Strategy.Enable file transfer': 'Permitir transferência de arquivos',
  'pages.Settings.Strategy.Enable audio': 'Permitir transmissão de áudio',
  'pages.Settings.Strategy.Enable TCP tunneling': 'Permitir túnel TCP',
  'pages.Settings.Strategy.Enable remote restart': 'Permitir reinicialização remota',
  'pages.Settings.Strategy.Enable recording session': 'Permitir gravação de sessão',
  'pages.Settings.Strategy.Enable blocking user input': 'Permitir bloquear entrada do usuário',
  'pages.Settings.Strategy.Enable remote configuration modification': 'Permitir modificação de configuração remota',
  'pages.Settings.Strategy.Permission type': 'Tipo de permissão',
  'pages.Settings.Strategy.Approve mode': 'Modo de aprovação',
  'pages.Settings.Strategy.Password type': 'Tipo de senha',
  'pages.Settings.Strategy.Hide connection management window': 'Ocultar janela de gerenciamento de conexão',
  'pages.Settings.Strategy.One-time password length': 'Comprimento da senha de uso único',
  'pages.Settings.Strategy.Accept sessions via password': 'Aceitar sessões apenas por senha',
  'pages.Settings.Strategy.Accept sessions via click': 'Aceitar sessões apenas por clique',
  'pages.Settings.Strategy.Accept sessions via both': 'Aceitar sessões por senha ou clique',
  'pages.Settings.Strategy.Use one-time password': 'Usar senha de uso único',
  'pages.Settings.Strategy.Use permanent password': 'Usar senha permanente',
  'pages.Settings.Strategy.Use both passwords': 'Usar ambos os tipos de senha',
  'pages.Settings.Strategy.Deny LAN discovery': 'Negar descoberta na LAN',
  'pages.Settings.Strategy.Enable direct IP access': 'Permitir acesso direto por IP',
  'pages.Settings.Strategy.auto_disconnect_option_tip': 'Fechar automaticamente sessões inativas',
  'pages.Settings.Strategy.deny_lan_discovery_desc_tip': 'O dispositivo não será descoberto por outros dispositivos na mesma rede local',
  'pages.Settings.Strategy.enable_direct_ip_access_desc_tip': 'Permitir conexão direta por IP ao dispositivo',
  'pages.Settings.Strategy.Remove wallpaper during incoming sessions': 'Remover papel de parede durante sessões de entrada',
  'pages.Settings.Strategy.minutes': 'minutos',
  'pages.Settings.Strategy.Enable': 'Ativar',
  'pages.Settings.Strategy.Disable': 'Desativar',
  'pages.Settings.Strategy.disabled': 'desativado',
  'pages.Settings.Strategy.Default': 'Estratégia padrão',
  'pages.Settings.Strategy.Apply changes of this page': 'Aplicar alterações desta página',
  'pages.Settings.Strategy.Invalid IP': 'IP inválido',
  'pages.Settings.Strategy.IP Whitelisting': 'Lista branca de IP',
  'pages.Settings.Strategy.Clear': 'Limpar',
  'pages.Settings.Strategy.OK': 'OK',
  'pages.Settings.Strategy.whitelist_sep': 'Você pode usar vírgulas, ponto e vírgula, espaços ou quebras de linha como separadores',
  'pages.Settings.Strategy.Use IP whitelisting': 'Permitir apenas IPs da lista branca',
  'pages.Settings.Strategy.whitelist_tip': 'Apenas IPs da lista branca podem acessar este dispositivo',
  'pages.CustomClient.bidirectional_desc_tip': 'Crie um cliente que pode se conectar a outro dispositivo e receber solicitações de conexão',
  'pages.CustomClient.incoming_desc_tip': 'Crie um cliente que pode apenas receber solicitações de conexão',
  'pages.CustomClient.outgoing_desc_tip': 'Crie um cliente que pode se conectar a outro dispositivo',
  'pages.CustomClient.disable_installation_desc_tip': 'Impedir usuários de instalar o cliente personalizado (apenas Windows)',
  'pages.CustomClient.disable_settings_desc_tip': 'Desativar acesso às configurações no cliente personalizado',
  'pages.CustomClient.disable_ab_desc_tip': 'Desativar funcionalidade de catálogo de endereços no cliente personalizado',
  'pages.CustomClient.disable_tcp_desc_tip': 'Evite o diálogo do firewall do Windows ao iniciar o RustDesk, isso pulará tentativas de conexão P2P direta e usará diretamente a conexão de relay',
  'pages.CustomClient.disable_account_desc_tip': 'Remover funcionalidade de login de conta no cliente RustDesk',
  'pages.CustomClient.override_settings_desc_tip': 'Substituir configurações, não podem ser alteradas no RustDesk',
  'pages.CustomClient.default_settings_desc_tip': 'Alterar configurações padrão utilizadas pelo RustDesk',
  'pages.Settings.KeyPage.failedToFetchKey': 'Falha ao buscar chave.',
  'pages.Settings.KeyPage.keyResetSuccess': 'Redefinição de chave bem-sucedida, reinicie o hbbr para aplicar as alterações.',
  'pages.Settings.KeyPage.errorResettingKey': 'Erro ao redefinir a chave.',
  'pages.Settings.KeyPage.keyUpdateSuccess': 'Atualização de chave bem-sucedida, reinicie o hbbr para aplicar as alterações.',
  'pages.Settings.KeyPage.errorUpdatingKey': 'Erro ao atualizar a chave, verifique a chave',
  'pages.Settings.KeyPage.key': 'Chave',
  'pages.Settings.KeyPage.privateKey': 'Chave privada',
  'pages.Settings.KeyPage.publicKey': 'Chave pública',
  'pages.Settings.KeyPage.editKey': 'Atualizar chave',
  'pages.Settings.KeyPage.reset': 'Redefinir',
  'pages.Settings.KeyPage.custom': 'Inserir manualmente',
  'pages.Settings.KeyPage.pleaseInputPrivateKey': 'Por favor, insira sua chave privada!',
  'pages.Settings.License.settingsUpdatedSuccess': 'Configurações atualizadas com sucesso.',
  'pages.Settings.License.licenseCannotBeEmpty': 'A licença não pode estar vazia.',
  'pages.Settings.License.license': 'Licença',
  'pages.Settings.Relay.GeoOverrideDescription': 'A função de substituição de localização só pode ser usada quando existem vários servidores de relay.',
  'pages.Settings.GeoOverride.ipOrDomain': 'IP/Domínio',
  'pages.Settings.GeoOverride.latitude': 'Latitude',
  'pages.Settings.GeoOverride.longitude': 'Longitude',
  'pages.Settings.GeoOverride.deleteSelectedConfirm': 'Tem certeza que deseja excluir as substituições de localização selecionadas?',
  'pages.Settings.GeoOverride.deleteConfirm': 'Tem certeza que deseja excluir esta substituição de localização?',
  'pages.Settings.GeoOverride.editGeoOverride': 'Editar substituição',
  'pages.Settings.GeoOverride.addGeoOverride': 'Adicionar substituição',
  'pages.Settings.GeoOverride.ipOrDomainRequired': 'IP/Domínio não pode estar vazio.',
  'pages.Settings.GeoOverride.latitudeRequired': 'Latitude não pode estar vazia.',
  'pages.Settings.GeoOverride.longitudeRequired': 'Longitude não pode estar vazia.',
  'pages.Settings.GeoOverride.testGeo': 'Testar localização',
  'pages.Settings.GeoOverride.geofileFetchFailed': 'Falha ao buscar informações do GeoFile.',
  'pages.Settings.GeoOverride.loadGeoOverrides': 'Carregar substituições',
  'pages.Settings.GeoOverride.geoOverride': 'Substituição de localização',
  'pages.Settings.GeoOverride.title': 'Substituição de localização',
  'pages.Settings.GeoOverride.RelaySettings': 'Configurações',
  'pages.Settings.GeoOverride.RelayServers': 'Servidores de relay',
  'pages.Settings.GeoOverride.AddServer': 'Adicionar servidor',
  'pages.Settings.GeoOverride.AlwaysUseRelay': 'Sempre usar relay',
  'pages.Settings.GeoOverride.UpdateServers': 'Importar alterações',
  'pages.Settings.GeoOverride.UpdatedAlwaysUseRelay': 'Sempre usar relay atualizado',
  'pages.Settings.GeoOverride.FailedToUpdateAlwaysUseRelay': 'Falha ao atualizar Sempre usar relay',
  'pages.Settings.GeoOverride.UpdatedRelayServers': 'Servidores de relay atualizados',
  'pages.Settings.GeoOverride.FailedToUpdateRelayServers': 'Falha ao atualizar servidores de relay',
  'pages.Settings.GeoOverride.UpdatedChangeIDSetting': 'Configuração de alteração de ID atualizada',
  'pages.Settings.GeoOverride.FailedToUpdateChangeIDSetting': 'Falha ao atualizar configuração de alteração de ID',
  "pages.Settings.Others.Allow change ID": "Permitir alteração de ID",
  "pages.Settings.Others.Disable new devices": "Desativar novos dispositivos",
  "pages.Settings.Others.The control end need to login before access": "O controlador precisa fazer login antes de acessar",
  "pages.Settings.Others.Only admin can access unassigned devices": "Apenas administrador pode acessar dispositivos não atribuídos",
  "pages.Settings.Others.Allow non-admin to see other groups": "Permitir que não administradores vejam outros grupos",
  "pages.Settings.Others.allow_non_admin_see_other_group_tip": "Quando esta opção está desativada, não administradores só podem compartilhar catálogos de endereços dentro do seu próprio grupo.",
  'pages.Settings.OIDC.Maximum Number of Authorized Users': 'Número máximo de usuários autorizados',
  'pages.Settings.OIDC.emptyPrompt': 'Não há provedores de autenticação de terceiros atualmente, adicione um para usar esta funcionalidade.',
  'pages.Settings.OIDC.confirm-delete-title': 'Confirmar exclusão',
  'pages.Settings.OIDC.confirm-delete-content': 'Usuários autorizados por este terceiro não poderão mais fazer login. Você pode acessar a interface de gerenciamento de usuários para excluir esses usuários.',
  'pages.Settings.Others.Only admin can access logs': 'Apenas administrador pode acessar logs não atribuídos',
  'pages.Welcome.Max Devices': 'Máximo de dispositivos',
  'pages.Welcome.Max Users': 'Máximo de usuários',
  'pages.Welcome.Not set': 'Não definido',
  'pages.Welcome.Invalid': 'Inválido',
  'pages.Welcome.Demo': 'Demo',
  'pages.Welcome.Personal': 'Pessoal',
  'pages.Welcome.Business': 'Empresarial',
  'pages.Welcome.Unknown': 'Outro',
  'pages.Welcome.Max Peers': 'Máximo de dispositivos',
  'pages.Welcome.RustDesk Server Status': 'Status do servidor RustDesk',
  'pages.Welcome.License Expire Time': 'Data de expiração da licença',
  'pages.Welcome.Document': 'Documentação',
  'pages.AddressBook.Permission': 'Permissão',
  'pages.AddressBook.Device count': 'Número de dispositivos',
  'pages.AddressBook.Address Books': 'Catálogos de endereços',
  'pages.AddressBook.Tags': 'Tags',
  'pages.AddressBook.Devices': 'Dispositivos',
  'pages.AddressBook.Device': 'Dispositivo',
  'pages.AddressBook.Alias': 'Alias',
  'pages.AddressBook.Whether a password is set': 'Se uma senha está definida',
  'pages.AddressBook.Recycle Bin': 'Lixeira',
  'pages.AddressBook.Empty Recycle Bin': 'Esvaziar lixeira',
  'pages.AddressBook.Personal': 'Pessoal',
  'pages.AddressBook.Personal (My address book)': 'Pessoal (Meu catálogo de endereços)',
  'pages.AddressBook.Shared': 'Compartilhado',
  'pages.AddressBook.Share': 'Compartilhar',
  'pages.AddressBook.Delete': 'Excluir',
  'pages.AddressBook.Settings': 'Configurações',
  'pages.AddressBook.Owner': 'Proprietário',
  'pages.AddressBook.My Permission': 'Minha permissão',
  'pages.AddressBook.Read-only': 'Somente leitura',
  'pages.AddressBook.Read/Write': 'Leitura/Escrita',
  'pages.AddressBook.Full Control': 'Controle total',
  'pages.AddressBook.share_warning_tip': 'Os campos acima são compartilhados e visíveis para outras pessoas.',
  'pages.AddressBook.permission_tip': 'Somente leitura: pode ver dispositivos e tags; Leitura/Escrita: pode editar dispositivos e tags; Controle total: pode compartilhar novamente, excluir ou renomear o catálogo de endereços.',
  'pages.AddressBook.rule_priority_tip': 'Prioridade: Usuário > Grupo > Todos',
  'pages.AddressBook.Remove password': 'Remover senha',
  'pages.AddressBook.Import': 'Importar',
  'pages.AddressBook.Filter by intersection': 'Filtrar por interseção',
  'pages.AddressBook.Permissions': 'Permissões',
  'pages.AddressBook.Type': 'Tipo',
  'pages.AddressBook.Untagged': 'Sem tag',

  'app.pwa.offline': 'Você está offline agora',
  'app.pwa.serviceworker.updated': 'Novo conteúdo disponível',
  'app.pwa.serviceworker.updated.hint': 'Por favor, clique no botão "Atualizar" ou atualize manualmente a página',
  'app.pwa.serviceworker.updated.ok': 'Atualizar',

  'app.setting.pagestyle': 'Configurações de estilo global',
  'app.setting.pagestyle.dark': 'Estilo de menu escuro',
  'app.setting.pagestyle.light': 'Estilo de menu claro',
  'app.setting.content-width': 'Largura da área de conteúdo',
  'app.setting.content-width.fixed': 'Fixa',
  'app.setting.content-width.fluid': 'Fluida',
  'app.setting.themecolor': 'Cor do tema',
  'app.setting.themecolor.dust': 'Poeira',
  'app.setting.themecolor.volcano': 'Vulcão',
  'app.setting.themecolor.sunset': 'Pôr do sol',
  'app.setting.themecolor.cyan': 'Ciano',
  'app.setting.themecolor.green': 'Verde aurora',
  'app.setting.themecolor.daybreak': 'Azul alvorada (padrão)',
  'app.setting.themecolor.geekblue': 'Azul geek',
  'app.setting.themecolor.purple': 'Roxo',
  'app.setting.navigationmode': 'Modo de navegação',
  'app.setting.sidemenu': 'Layout com menu lateral',
  'app.setting.topmenu': 'Layout com menu superior',
  'app.setting.fixedheader': 'Cabeçalho fixo',
  'app.setting.fixedsidebar': 'Menu lateral fixo',
  'app.setting.fixedsidebar.hint': 'Funciona com layout de menu lateral',
  'app.setting.hideheader': 'Ocultar cabeçalho ao rolar',
  'app.setting.hideheader.hint': 'Funciona quando o cabeçalho está fixo',
  'app.setting.othersettings': 'Outras configurações',
  'app.setting.weakmode': 'Modo daltônico',
  'app.setting.copy': 'Copiar configurações',
  'app.setting.copyinfo': 'Copiado com sucesso, substitua a configuração padrão em config/defaultSettings.js',
  'app.setting.production.hint':
    'A barra de configuração é exibida apenas no ambiente de desenvolvimento para visualização. Modifique manualmente o arquivo de configuração na produção.',

  'app.settings.menuMap.basic': 'Configurações básicas',
  'app.settings.menuMap.security': 'Configurações de segurança',
  'app.settings.menuMap.binding': 'Vinculação de conta',
  'app.settings.menuMap.notification': 'Notificações de novas mensagens',
  'app.settings.basic.avatar': 'Avatar',
  'app.settings.basic.change-avatar': 'Alterar avatar',
  'app.settings.basic.email': 'E-mail',
  'app.settings.basic.email-message': 'Por favor, insira seu e-mail!',
  'app.settings.basic.nickname': 'Apelido',
  'app.settings.basic.nickname-message': 'Por favor, insira seu apelido!',
  'app.settings.basic.profile': 'Perfil pessoal',
  'app.settings.basic.profile-message': 'Por favor, insira seu perfil pessoal!',
  'app.settings.basic.profile-placeholder': 'Perfil pessoal',
  'app.settings.basic.country': 'País/Região',
  'app.settings.basic.country-message': 'Por favor, insira seu país ou região!',
  'app.settings.basic.geographic': 'Estado e cidade',
  'app.settings.basic.geographic-message': 'Por favor, insira seu estado e cidade!',
  'app.settings.basic.address': 'Endereço',
  'app.settings.basic.address-message': 'Por favor, insira seu endereço!',
  'app.settings.basic.phone': 'Telefone',
  'app.settings.basic.phone-message': 'Por favor, insira seu telefone!',
  'app.settings.basic.update': 'Atualizar informações básicas',
  'app.settings.security.strong': 'Forte',
  'app.settings.security.medium': 'Médio',
  'app.settings.security.weak': 'Fraco',
  'app.settings.security.password': 'Senha da conta',
  'app.settings.security.password-description': 'Força atual da senha',
  'app.settings.security.phone': 'Telefone de segurança',
  'app.settings.security.phone-description': 'Telefone vinculado',
  'app.settings.security.question': 'Pergunta de segurança',
  'app.settings.security.question-description': 'Pergunta de segurança não definida, que pode proteger efetivamente a segurança da conta',
  'app.settings.security.email': 'E-mail de backup',
  'app.settings.security.email-description': 'E-mail vinculado',
  'app.settings.security.mfa': 'Dispositivo MFA',
  'app.settings.security.mfa-description': 'Dispositivo MFA não vinculado, após a vinculação, uma verificação secundária pode ser realizada',
  'app.settings.security.modify': 'Modificar',
  'app.settings.security.set': 'Definir',
  'app.settings.security.bind': 'Vincular',
  'app.settings.binding.taobao': 'Vincular Taobao',
  'app.settings.binding.taobao-description': 'Conta Taobao não vinculada atualmente',
  'app.settings.binding.alipay': 'Vincular Alipay',
  'app.settings.binding.alipay-description': 'Conta Alipay não vinculada atualmente',
  'app.settings.binding.dingding': 'Vincular DingTalk',
  'app.settings.binding.dingding-description': 'Conta DingTalk não vinculada atualmente',
  'app.settings.binding.bind': 'Vincular',
  'app.settings.notification.password': 'Senha da conta',
  'app.settings.notification.password-description': 'Mensagens de outros usuários serão notificadas como mensagens internas',
  'app.settings.notification.messages': 'Mensagens do sistema',
  'app.settings.notification.messages-description': 'Mensagens do sistema serão notificadas como mensagens internas',
  'app.settings.notification.todo': 'Tarefas pendentes',
  'app.settings.notification.todo-description': 'Tarefas pendentes serão notificadas como mensagens internas',
  'app.settings.open': 'Ativado',
  'app.settings.close': 'Desativado',
  'app.copyright.produced': 'Desenvolvido pela equipe RustDesk',
  'pages.account.Settings.Security.Password': 'Senha',
  'pages.account.Settings.Security.Change password': 'Alterar senha',
  'pages.account.tfa.Enable 2FA': 'Ativar 2FA',
  'pages.account.tfa.2 Factor Authentication is enabled.': 'Autenticação de dois fatores está ativada',
  'pages.account.tfa.Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.': 'Sua chave 2FA está em uso por um período prolongado. Recomendamos atualizar sua chave 2FA para garantir a máxima segurança da conta.',
  'pages.account.tfa.Enable 2 Factor Authentication.': 'Ativar autenticação de dois fatores.',

  'Enable Two-Factor Authentication': 'Ativar autenticação de dois fatores',
  'Make your RustDesk account more secure by requiring an extra authentication code when you log in.': 'Torne sua conta RustDesk mais segura exigindo um código de autenticação adicional ao fazer login.',
  '1. Download an authenticator app': '1. Baixe um aplicativo autenticador',
  'Download an authenticator app such as': 'Baixe um aplicativo autenticador como Google Authenticator ou Authy',
  '2FA code': 'Código 2FA',
  'Authenticator': 'Autenticador',
  'on your phone or desktop.': 'no seu telefone ou desktop.',
  '2. Scan the QR code': '2. Escaneie o código QR',
  'Open your authenticator app, scan the QR code and enter the code that your app shows.': 'Abra seu aplicativo autenticador, escaneie o código QR e digite o código que seu aplicativo mostra.',
  'You can change or remove 2FA at any time in your account settings.': 'Você pode alterar ou remover o 2FA a qualquer momento nas configurações da sua conta.',
  'Please verify your 2FA code to disable current 2FA first.': 'Por favor, verifique seu código 2FA para desativar o 2FA atual primeiro.',
  'Can\'t verify the code. Check that code and local time settings are correct!': 'Não é possível verificar o código. Verifique se o código e as configurações de hora local estão corretos!',
  'First sign-in:': 'Primeiro login:',
  'Recent activity:': 'Atividade recente:',
  'pages.Settings.GeoOverride.reloadGeo': 'Recarregar dados de geolocalização',
  'pages.Settings.GeoOverride.getRelayServer': 'Obter servidor de relay',
  'pages.Settings.GeoOverride.relayServer': 'Servidor de relay',
  'SMTP Host': 'Host SMTP',
  'SMTP Port': 'Porta SMTP',
  'My Server Requires Authentication': 'Meu servidor requer autenticação',
  'smtp.From': 'Remetente',
  'Mail Account': 'Conta de e-mail',
  'Mail Password': 'Senha de e-mail',
  'Custom Certificate PEM File Path (on the server)': 'Caminho do arquivo PEM do certificado personalizado (no servidor)',
  Update: 'Atualizar',
  'Submit and Test Email': 'Enviar e testar e-mail',
  'he Receiver Email': 'E-mail do destinatário',
  'LDAP Host': 'Host LDAP',
  'LDAP Port': 'Porta LDAP',
  'Filter': 'Filtro',
  'Username Attribute': 'Atributo de nome de usuário',
  'The attribute used as the username when logging in. Default is "dn".': 'O atributo usado como nome de usuário ao fazer login. O padrão é "dn".',
  'DraggableTab.Drag tab to reorder': 'Arraste a aba para reordenar',
  'pages.Settings.OIDC.new.Auth Type': 'Tipo de autenticação',
  'pages.Settings.OIDC.New Auth Provider': 'Novo provedor de autenticação',
  'pages.Settings.OIDC.new.New Auth Provider': 'Novo provedor de autenticação',
  'pages.Settings.OIDC.Delete This Provider': 'Excluir este provedor',
  'pages.Settings.OIDC.new.Enable PKCE': 'Ativar PKCE',
  'pages.Settings.OIDC.Enable PKCE': 'Ativar PKCE',
  'pages.Settings.OIDC.new.Timeout(seconds) to force a refresh of metadata(e.g. JWKS) from OIDC providers': 'Tempo limite (segundos) para forçar uma atualização de metadados (ex. JWKS) de provedores OIDC',
  'pages.Settings.OIDC.new.0 means no timeout, empty means default (3600)': '0 significa sem tempo limite, vazio significa padrão (3600)',
  'Callback URL': 'URL de retorno',
  'pages.Settings.Others.Session expiration time (Day)': 'Tempo de expiração da sessão (dias)',
  'Or': 'Ou',
};
