import { t } from "@/global";
import { addSharedAb, updateSharedAbProfile } from "@/services/api";
import { API } from "@/services/typings";
import { ModalForm, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import { ActionType } from "@ant-design/pro-table";
import { message } from "antd";

type CreateOrUpdateFormProps = {
  isCreate: boolean;
  visible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  actionRef: React.MutableRefObject<ActionType | undefined>;
  initialValues: API.SharedAbProfile | undefined;
}

const CreateOrUpdateForm: React.FC<CreateOrUpdateFormProps> = (props) => {
  const handleCreate = async (data: any) => {
    const hide = message.loading(t('Adding'));
    try {
      const PersonalAddressBookName = "My address book";
      const LegacyAddressBookName = "Legacy address book";
      if (data.name === PersonalAddressBookName) {
        message.success(t(`Name can not be ${PersonalAddressBookName}`));
        return;
      }
      if (data.name === LegacyAddressBookName) {
        message.success(t(`Name can not be ${LegacyAddressBookName}`));
        return;
      }
      await addSharedAb({ data });
      hide();
      message.success(t('Added successfully'));
      return true;
    } catch (error) {
      hide();
      message.error(
        typeof error == 'string' ? (error as string) : t('Adding failed, please try again!'),
      );
      return false;
    }
  };

  const handleUpdate = async (data: any) => {
    const hide = message.loading(t('Updating'));
    try {
      data['guid'] = props.initialValues?.guid;
      await updateSharedAbProfile({ data });
      hide();
      message.success(t('Update is successful'));
      return true;
    } catch (error) {
      hide();
      message.error(
        typeof error == 'string' ? (error as string) : t('Update failed, please try again!'),
      );
      return false;
    }
  };

  return (
    <ModalForm
      title={t(props.isCreate ? 'Create' : 'Edit')}
      width="400px"
      open={props.visible}
      initialValues={props.initialValues}
      onOpenChange={props.setModalVisible}
      onFinish={async (values) => {
        const success = await (props.isCreate ? handleCreate : handleUpdate)(values);
        if (success) {
          if (props.actionRef.current) {
            props.actionRef.current.reload();
          }
          props.setModalVisible(false);
        }
      }}
      modalProps={{
        maskClosable: false,
        destroyOnClose: true,
        onCancel: () => {
          props.setModalVisible(false);
        },
      }}
    >
      <ProFormText
        fieldProps={{ autoComplete: 'off' }}
        rules={[
          {
            required: true,
            whitespace: true,
          },
          {
            min: 4,
            max: 30,
          },
        ]}
        width="md"
        name="name"
        label={t('Name', true)}
      />
      <ProFormTextArea
        fieldProps={{ autoComplete: 'off' }}
        rules={[
          {
            max: 300,
          },
        ]}
        width="md"
        name="note"
        label={t('Note', true)}
      />
    </ModalForm>
  )
}

export default CreateOrUpdateForm;
