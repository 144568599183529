export default {
  'app.title': 'RustDesk Консоль',
  'navBar.lang': 'Язык',
  'layout.user.link.help': 'Помощь',
  'layout.user.link.privacy': 'Конфиденциальность',
  'layout.user.link.terms': 'Условия',
  'app.preview.down.block': 'Скачать эту страницу в локальный проект',
  'app.welcome.link.fetch-blocks': 'Получить все блоки',
  'app.welcome.link.block-list': 'Быстрое создание стандартных страниц на основе блоков',
  'The verification code is incorrect or has expired': 'Код подтверждения неверен или истек',
  Type: 'Тип',
  Remote: 'Удаленный',
  remoteTip: 'Компьютер или другое устройство, находящееся под контролем',
  Local: 'Локальный',
  'Start Time': 'Время начала',
  'End Time': 'Время окончания',
  Note: 'Заметка',
  'Log List': 'Список журналов',
  'Device List': 'Список устройств',
  'User List': 'Список пользователей',
  'User Name': 'Имя пользователя',
  Updating: 'Обновление',
  Adding: 'Добавление',
  Deleting: 'Удаление',
  Create: 'Создать',
  Add: 'Добавить',
  Confirm: 'Подтвердить',
  Submit: 'Отправить',
  Warning: 'Предупреждение',
  Error: 'Ошибка',
  Email: 'Электронная почта',
  Status: 'Статус',
  Action: 'Действие',
  Password: 'Пароль',
  'New Password': 'Новый пароль',
  Edit: 'Редактировать',
  Normal: 'Нормально',
  Disabled: 'Отключено',
  Disable: 'Отключить',
  Enable: 'Включить',
  Chosen: 'Выбрано',
  Items: 'Элементы',
  User: 'Пользователь',
  Info: 'Информация',
  'Log out': 'Выйти',
  Settings: 'Настройки',
  'Basic Settings': 'Основные настройки',
  'Remote Desktop': 'Удаленный рабочий стол',
  'File Transfer': 'Передача файлов',
  'Port Transfer': 'Перенаправление порта',
  'View Camera': 'Просмотр камеры',
  'Not Logged In': 'Не вошел в систему',
  'Created Time': 'Время создания',
  Delete: 'Удалить',
  Group: 'Группа',
  'Device Group': 'Группа устройств',
  'User Group': 'Группа пользователей',
  Name: 'Имя',
  'Group List': 'Список групп',
  deleteConfirm: 'Вы уверены, что хотите удалить?',
  groupTip: 'Пользователи в одной группе могут взаимно получать доступ',
  document: 'Документ',
  Detail: 'Подробная информация',
  Direction: 'Направление',
  File: 'Файл',
  Directory: 'Каталог',
  Disconnect: 'Отключить',
  Time: 'Время',
  'Export as csv': 'Экспорт в csv',
  exportCsvTip: 'Максимум 1000 записей за один раз',
  'Not binded only': 'Показать только не привязанные устройства',
  Close: 'Закрыть',
  'Default Strategy': 'Стратегия по умолчанию',
  'applied device num': 'Количество примененных устройств',
  'Device Name': 'Имя устройства',
  'Strategy': 'Стратегия',
  'Device Strategy': 'Стратегия устройства',
  'User Strategy': 'Стратегия пользователя',
  'Group Strategy': 'Стратегия группы устройств',
  'strategy_tooltip': "Формат: \"Стратегия устройства / Стратегия пользователя / Стратегия группы устройств\". Отдельное отображение '-' означает использование стратегии по умолчанию, отображение '-' на каком-либо уровне означает, что стратегия на этом уровне не установлена",
  Search: 'Поиск',
  Select: 'Выбрать',
  Assign: 'Назначить',
  Device: 'Устройство',
  'Device Username': 'Имя пользователя устройства',
  Role: 'Роль',
  'Auth Type': 'Тип аутентификации',
  Invite: 'Пригласить',
  'Please configure Email SMTP settings first': 'Сначала настройте параметры SMTP электронной почты',
  'Security Settings': 'Настройки безопасности',
  'Manage Signed Devices': 'Управление подписанными устройствами',
  'Since 2FA is enabled, the email login verification option is not used. 2FA login verification will be used instead.': 'Поскольку 2FA включен, опция проверки входа по электронной почте не используется. Вместо этого будет использоваться проверка входа 2FA.',
  'Enable alarm event email notification': 'Включить уведомление по электронной почте о событиях тревоги',
  'Enable email login verification': 'Включить проверку входа по электронной почте',
  'License Type': 'Тип лицензии',
  'Renew / Upgrade License': 'Продлить / Обновить лицензию',
  Save: 'Сохранить',
  Back: 'Назад',
  'Confirm Password': 'Подтвердите пароль',
  'username@device_name': 'имя_пользователя@имя_устройства',
  'Hide username': 'Скрыть имя пользователя',
  'Remote device': 'Удаленное устройство',
  'Within-group connections': 'Соединения внутри группы',
  'Cross-group connections': 'Соединения между группами',
  welcomeTitle: 'Добро пожаловать в RustDesk',
  Cancel: 'Отмена',
  OK: 'ОК',

  'pages.GroupList.User Group': 'Группа пользователей',
  'pages.GroupList.User': 'Пользователь',

  'pages.CustomClient.Application name': 'Имя приложения',
  'pages.CustomClient.Name': 'Имя',
  'pages.CustomClient.Build': 'Сборка',
  'pages.CustomClient.Note': 'Заметка',
  'pages.CustomClient.Action': 'Действие',
  'pages.CustomClient.Delete': 'Удалить',
  'pages.CustomClient.Edit': 'Редактировать',
  'pages.CustomClient.Duplicate': 'Дублировать',
  'pages.CustomClient.Create': 'Создать',
  'pages.CustomClient.Custom Clients': 'Пользовательские клиенты',
  'pages.CustomClient.General': 'Общие',
  'pages.CustomClient.Name of the configuration': 'Имя конфигурации',
  'pages.CustomClient.MSI installer': 'MSI установщик',
  'pages.CustomClient.Connection type': 'Тип соединения',
  'pages.CustomClient.Bidirectional': 'Двунаправленный',
  'pages.CustomClient.Incoming': 'Входящий',
  'pages.CustomClient.Outgoing': 'Исходящий',
  'pages.CustomClient.Options': 'Опции',
  'pages.CustomClient.Disable installation': 'Отключить установку',
  'pages.CustomClient.Disable settings': 'Отключить настройки',
  'pages.CustomClient.Disable address book': 'Отключить адресную книгу',
  'pages.CustomClient.Disable TCP listen port': 'Отключить TCP порт прослушивания',
  'pages.CustomClient.Disable user account': 'Отключить учетную запись пользователя',
  'pages.CustomClient.Some note about this configuration': 'Некоторые заметки об этой конфигурации',
  'pages.CustomClient.Custom server': 'Пользовательский сервер',
  'pages.CustomClient.Visual': 'Визуализация',
  'pages.CustomClient.Application icon': 'Иконка приложения',
  'pages.CustomClient.Security': 'Безопасность',
  'pages.CustomClient.Preset password': 'Предустановленный пароль',
  'pages.CustomClient.Advanced': 'Расширенные',
  'pages.CustomClient.Override settings': 'Переопределить настройки',
  'pages.CustomClient.Default settings': 'Настройки по умолчанию',
  'pages.CustomClient.Note: ': 'Заметка:',
  'pages.CustomClient.Click or drag to upload': 'Нажмите или перетащите для загрузки',
  'pages.CustomClient.app_icon_desc_tip': 'Загрузите квадратное изображение PNG размером 512x512 пикселей в качестве иконки приложения.',
  'pages.CustomClient.app_name_desc_tip': 'Укажите уникальное имя, которое будет отображаться в меню "Пуск", "Панели управления" и т.д. на установленных клиентах RustDesk.',
  'pages.CustomClient.logo_desc_tip': 'Загрузите изображение PNG, которое будет отображаться в верхней части главного окна пользовательского клиента. Размер будет автоматически изменен. Рекомендуется использовать PNG размером 200x40 пикселей.',
  'pages.CustomClient.This password will be used as a default permanent password for all new clients. It can be changed by the client.': 'Этот пароль будет использоваться в качестве постоянного пароля по умолчанию для всех новых клиентов. Его можно изменить клиентом.',
  'pages.CustomClient.The application icon must be a PNG file.': 'Иконка приложения должна быть файлом PNG.',
  'component.tagSelect.expand': 'Развернуть',
  'component.tagSelect.collapse': 'Свернуть',
  'component.tagSelect.all': 'Все',
  'component.LicenseAlert.Your license is invalid.': 'Ваша лицензия недействительна.',
  'component.LicenseAlert.Your license is not set.': 'Ваша лицензия не установлена.',
  'component.LicenseAlert.Your license is about to expire in 2 weeks.': 'Ваша лицензия истечет через 2 недели.',
  'component.LicenseAlert.Your license has expired.': 'Ваша лицензия истекла.',
  'pages.CustomClient.Refresh status manually': 'Обновить статус вручную',
  'pages.CustomClient.the building task': 'Задача сборки',
  'pages.CustomClient.Stop': 'Остановить',
  'pages.CustomClient.Cancel': 'Отмена',
  'pages.CustomClient.Completed at %s, this file will be deleted in 1 day.': 'Завершено в %s, этот файл будет удален через 1 день.',

  'component.globalHeader.search': 'Поиск по сайту',
  'component.globalHeader.help': 'Использовать документацию',
  'component.globalHeader.notification': 'Уведомление',
  'component.globalHeader.notification.empty': 'Вы просмотрели все уведомления',
  'component.globalHeader.message': 'Сообщение',
  'component.globalHeader.message.empty': 'Вы прочитали все сообщения',
  'component.globalHeader.event': 'Задача',
  'component.globalHeader.event.empty': 'Вы выполнили все задачи',
  'component.noticeIcon.clear': 'Очистить',
  'component.noticeIcon.cleared': 'Очистили',
  'component.noticeIcon.empty': 'Нет данных',
  'component.noticeIcon.view-more': 'Смотреть больше',
  'Token List': 'Список токенов',
  Description: 'Описание',
  'Device Permission': 'Разрешение на устройство',
  'Audit Log Permission': 'Разрешение на аудит журналов',
  'User Permission': 'Разрешение на пользователя',
  'Group Permission': 'Разрешение на группу',
  'Strategy Permission': 'Разрешение на стратегию',

  'menu.welcome': 'Добро пожаловать',
  'menu.more-blocks': 'Больше блоков',
  'menu.home': 'Главная',
  'menu.admin': 'Страница администратора',
  'menu.admin.sub-page': 'Второстепенная страница администратора',
  'menu.login': 'Вход',
  'menu.register': 'Регистрация',
  'menu.register-result': 'Результат регистрации',
  'menu.dashboard': 'Панель управления',
  'menu.dashboard.analysis': 'Страница анализа',
  'menu.dashboard.monitor': 'Мониторинг',
  'menu.dashboard.workplace': 'Рабочее место',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Страница формы',
  'menu.form.basic-form': 'Основная форма',
  'menu.form.step-form': 'Пошаговая форма',
  'menu.form.step-form.info': 'Пошаговая форма (ввод информации о переводе)',
  'menu.form.step-form.confirm': 'Пошаговая форма (подтверждение информации о переводе)',
  'menu.form.step-form.result': 'Пошаговая форма (завершение)',
  'menu.form.advanced-form': 'Расширенная форма',
  'menu.list': 'Страница списка',
  'menu.list.audit-list': 'Аудит безопасности',
  'menu.list.audit-list.Alarm': 'Риск/Аномалия',
  'menu.list.audit-list.Connection': 'Журнал соединений',
  'menu.list.audit-list.File': 'Журнал передачи файлов',
  'menu.list.audit-list.Console': 'Журнал операций консоли',
  'menu.list.device-list': 'Устройства',
  'menu.list.user-list': 'Пользователи',
  'menu.list.group-list': 'Группы',
  'menu.list.group-list.user': 'Группа пользователей',
  'menu.list.group-list.device': 'Группа устройств',
  'menu.list.table-list': 'Запрос таблицы',
  'menu.list.basic-list': 'Стандартный список',
  'menu.list.card-list': 'Карточный список',
  'menu.list.search-list': 'Список поиска',
  'menu.list.search-list.articles': 'Список поиска (статьи)',
  'menu.list.search-list.projects': 'Список поиска (проекты)',
  'menu.list.search-list.applications': 'Список поиска (приложения)',
  'menu.profile': 'Страница деталей',
  'menu.profile.basic': 'Основная страница деталей',
  'menu.profile.advanced': 'Расширенная страница деталей',
  'menu.result': 'Страница результатов',
  'menu.result.success': 'Страница успеха',
  'menu.result.fail': 'Страница неудачи',
  'menu.exception': 'Страница исключений',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Вызвать ошибку',
  'menu.account': 'Личный кабинет',
  'menu.account.center': 'Личный центр',
  'menu.account.settings': 'Личные настройки',
  'menu.account.trigger': 'Вызвать ошибку',
  'menu.account.logout': 'Выйти',
  'menu.editor': 'Графический редактор',
  'menu.editor.flow': 'Редактор потоков',
  'menu.editor.mind': 'Редактор умственных карт',
  'menu.editor.koni': 'Редактор топологии',
  'menu.Settings': 'Настройки',
  'menu.Strategy': 'Стратегия',
  'menu.Settings.Key': 'Ключ',
  'menu.Settings.License': 'Лицензия',
  'menu.Settings.Relay': 'Управление реле',
  'menu.Settings.Others': 'Прочее',
  'menu.Settings.Tokens': 'Токены',
  'menu.Address Books': 'Адресная книга',
  'menu.Strategies': 'Стратегии',
  'menu.Custom Clients': 'Пользовательские клиенты',

  'pages.login.accountLogin.tab': 'Вход по учетной записи и паролю',
  'pages.login.accountLogin.errorMessage': 'Неверное имя пользователя или пароль',
  'pages.login.failure': 'Ошибка входа, попробуйте еще раз!',
  'pages.login.success': 'Успешный вход!',
  'pages.login.username.placeholder': 'Имя пользователя: admin или user',
  'pages.login.username.required': 'Имя пользователя обязательно!',
  'pages.login.password.placeholder': 'Пароль: ant.design',
  'pages.login.password.required': 'Пароль обязателен!',
  'pages.login.phoneLogin.tab': 'Вход по номеру телефона',
  'pages.login.phoneLogin.errorMessage': 'Код подтверждения неверен',
  'pages.login.phoneNumber.placeholder': 'Введите номер телефона!',
  'pages.login.phoneNumber.required': 'Номер телефона обязателен!',
  'pages.login.phoneNumber.invalid': 'Недействительный номер телефона!',
  'pages.login.captcha.placeholder': 'Введите код подтверждения!',
  'pages.login.captcha.required': 'Код подтверждения обязателен!',
  'pages.login.phoneLogin.getVerificationCode': 'Получить код подтверждения',
  'pages.getCaptchaSecondText': 'Секунд для повторного получения',
  'pages.login.rememberMe': 'Запомнить меня',
  'pages.login.forgotPassword': 'Забыли пароль?',
  'pages.login.submit': 'Войти',
  'pages.login.loginWith': 'Другие способы входа:',
  'pages.login.loginWithGoogle': 'Войти с помощью Google',
  'pages.login.loginWithGithub': 'Войти с помощью Github',
  'pages.login.loginWithOkta': 'Войти с помощью Okta',
  'pages.login.loginWith.requesting_auth': 'Запрос авторизации',
  'pages.login.loginWith.waiting_auth': 'Пожалуйста, завершите авторизацию учетной записи',
  'pages.login.registerAccount': 'Зарегистрировать учетную запись',
  'pages.welcome.advancedComponent': 'Расширенная таблица',
  'pages.welcome.link': 'Добро пожаловать',
  'pages.welcome.advancedLayout': 'Расширенная компоновка',
  'pages.welcome.alertMessage': 'Быстрые и мощные компоненты уже выпущены.',
  'pages.account.tfaComponent.Enable 2FA': 'Включить 2FA',
  'pages.account.tfaComponent.2 Factor Authentication is enabled.': 'Двухфакторная аутентификация включена.',
  'pages.account.tfaComponent.Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.': 'Ваш ключ 2FA используется длительное время. Мы рекомендуем обновить ваш ключ 2FA для обеспечения максимальной безопасности учетной записи.',
  'pages.account.tfaComponent.Enable 2 Factor Authentication.': 'Включить двухфакторную аутентификацию',
  'pages.account.tfa.The code must be 6, 8, 10 digits.': 'Код должен состоять из 6, 8 или 10 цифр.',
  'pages.account.tfa.The code must be 6 or 8 digits.': 'Код должен состоять из 6 или 8 цифр.',
  'pages.admin.subPage.title': 'Эта страница доступна только с правами администратора',
  'pages.admin.subPage.alertMessage': 'Umi UI теперь выпущен, добро пожаловать в использование npm run ui для запуска.',
  'pages.AuditList.FileAuditList.files': 'файлов',
  'pages.AuditList.FileAuditList.pathTip': 'Путь на контролируемом устройстве',
  'pages.AuditList.FileAuditList.limitedTip': 'Показать максимум 10 файлов',
  'pages.AuditList.FileAuditList.exportFilename': 'Журнал передачи файлов',
  'pages.AuditList.ConnAuditList.modal_title': 'Подтвердите действие',
  'pages.AuditList.ConnAuditList.diconnect_confirm_tip': 'Вы действительно хотите отключить это устройство?',
  'pages.AuditList.ConnAuditList.disconnect_succ': 'Успешно отключено!',
  'pages.AuditList.ConnAuditList.disconnect_fail': 'Ошибка отключения!',
  'pages.AuditList.ConnAuditList.exportFilename': 'Журнал соединений',
  'pages.AuditList.AlarmAuditList.Login Device / Local Device': 'Устройство входа / Локальное устройство',
  'pages.AuditList.AlarmAuditList.Login Account / Remote Device': 'Учетная запись входа / Удаленное устройство',
  'pages.AuditList.AlarmAuditList.Login Account': 'Учетная запись входа',
  'pages.AuditList.AlarmAuditList.Device ID': 'ID устройства',
  'pages.AuditList.AlarmAuditList.Device Name': 'Имя устройства',
  'pages.AuditList.AlarmAuditList.Remote Device': 'Удаленное устройство',
  'pages.AuditList.AlarmAuditList.Event Time': 'Время события',
  'pages.AuditList.AlarmAuditList.IP whiltelist': 'IP, пытающиеся получить доступ вне белого списка',
  'pages.AuditList.AlarmAuditList.ExceedThirtyAccessAttempts': 'Более 30 попыток доступа подряд',
  'pages.AuditList.AlarmAuditList.MultipleAccessAttemptsWithinOneMinute': 'Несколько попыток доступа в течение одной минуты',
  'pages.AuditList.AlarmAuditList.ExceedThirtyLoginAttempts': 'Более 30 попыток входа подряд',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneMinute': 'Несколько попыток входа в течение одной минуты',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneHour': 'Несколько попыток входа в течение одного часа',
  'pages.AuditList.AlarmAuditList.exportFilename': 'Журнал рисков и аномалий',
  'pages.AuditList.ConsoleAuditList.Operation': 'Операция',
  'pages.AuditList.ConsoleAuditList.Name': 'Имя',
  'pages.AuditList.ConsoleAuditList.Name list': 'Список имен',
  'pages.AuditList.ConsoleAuditList.ID list': 'Список ID',
  'pages.AuditList.ConsoleAuditList.Result': 'Результат',
  'pages.AuditList.ConsoleAuditList.Remote': 'Удаленный',
  'pages.AuditList.ConsoleAuditList.Local': 'Локальный',
  'pages.AuditList.ConsoleAuditList.User Login': 'Вход пользователя в консоль',
  'pages.AuditList.ConsoleAuditList.Add Group': 'Создать группу',
  'pages.AuditList.ConsoleAuditList.Add User': 'Добавить пользователя',
  'pages.AuditList.ConsoleAuditList.Add Device': 'Добавить устройство',
  'pages.AuditList.ConsoleAuditList.Delete Groups': 'Пакетное удаление групп',
  'pages.AuditList.ConsoleAuditList.Disconnect Device': 'Отключить устройство',
  'pages.AuditList.ConsoleAuditList.Enable Users': 'Включить пользователей',
  'pages.AuditList.ConsoleAuditList.Disable Users': 'Отключить пользователей',
  'pages.AuditList.ConsoleAuditList.Enable Devices': 'Пакетное включение устройств',
  'pages.AuditList.ConsoleAuditList.Disable Devices': 'Пакетное отключение устройств',
  'pages.AuditList.ConsoleAuditList.Update Group': 'Обновить группу',
  'pages.AuditList.ConsoleAuditList.Update User': 'Обновить пользователя',
  'pages.AuditList.ConsoleAuditList.Update Device': 'Обновить устройство',
  'pages.AuditList.ConsoleAuditList.Delete User': 'Удалить пользователя',
  'pages.AuditList.ConsoleAuditList.Delete Device': 'Удалить устройство',
  'pages.AuditList.ConsoleAuditList.Add Address Book': 'Добавить адресную книгу',
  'pages.AuditList.ConsoleAuditList.Delete Address Book': 'Удалить адресную книгу',
  'pages.AuditList.ConsoleAuditList.Change Address Book Name': 'Изменить имя адресной книги',
  'pages.AuditList.ConsoleAuditList.Group Management': 'Управление группами',
  'pages.AuditList.ConsoleAuditList.User Management': 'Управление пользователями',
  'pages.AuditList.ConsoleAuditList.Device Management': 'Управление устройствами',
  'pages.AuditList.ConsoleAuditList.Address Book Management': 'Управление адресной книгой',
  'pages.AuditList.ConsoleAuditList.exportFilename': 'Журнал консоли',
  'pages.AuditList.ConsoleAuditList.Delete Devices in the Address Book Recycle Bin': 'Удалить устройства из корзины адресной книги',
  'pages.AuditList.ConsoleAuditList.Empty Address Book Recycle Bin': 'Очистить корзину адресной книги',
  'pages.AuditList.ConsoleAuditList.Add Address Book Permission': 'Добавить разрешение на адресную книгу',
  'pages.AuditList.ConsoleAuditList.Delete Address Book Permission': 'Удалить разрешение на адресную книгу',
  'pages.AuditList.ConsoleAuditList.Update Address Book Permission': 'Изменить разрешение на адресную книгу',
  'pages.Settings.Strategy.Strategy list': 'Список стратегий',
  'pages.Settings.Strategy.Automatically close incoming sessions on user inactivity': 'Автоматически закрывать входящие сессии при неактивности пользователя',
  'pages.Settings.Strategy.delete_strategy_tip': 'Вы уверены, что хотите удалить эту стратегию?',
  'pages.Settings.Strategy.New strategy': 'Новая стратегия',
  'pages.Settings.Strategy.Rename strategy': 'Переименовать стратегию',
  'pages.Settings.Strategy.Duplicate strategy': 'Дублировать стратегию',
  'pages.Settings.Strategy.Edit Devices': 'Редактировать устройства',
  'pages.Settings.Strategy.Edit Users': 'Редактировать пользователей',
  'pages.Settings.Strategy.Rename': 'Переименовать',
  'pages.Settings.Strategy.Duplicate': 'Дублировать',
  'pages.Settings.Strategy.Delete': 'Удалить',
  'pages.Settings.Strategy.Groups': 'Группы',
  'pages.Settings.Strategy.Select All': 'Выбрать все',
  'pages.Settings.Strategy.Permissions': 'Разрешения',
  'pages.Settings.Strategy.Password': 'Пароль',
  'pages.Settings.Strategy.Security': 'Безопасность',
  'pages.Settings.Strategy.Other': 'Прочее',
  'pages.Settings.Strategy.IP whitelist': 'Белый список IP',
  'pages.Settings.Strategy.Cancel': 'Отмена',
  'pages.Settings.Strategy.Submit': 'Подтвердить',
  'pages.Settings.Strategy.Edit': 'Редактировать',
  'pages.Settings.Strategy.Custom': 'Пользовательский',
  'pages.Settings.Strategy.Full access': 'Полный доступ',
  'pages.Settings.Strategy.Screen share': 'Только совместное использование экрана',
  'pages.Settings.Strategy.Enable keyboard/mouse': 'Разрешить управление клавиатурой/мышью',
  'pages.Settings.Strategy.Enable clipboard': 'Разрешить синхронизацию буфера обмена',
  'pages.Settings.Strategy.Enable file transfer': 'Разрешить передачу файлов',
  'pages.Settings.Strategy.Enable audio': 'Разрешить передачу аудио',
  'pages.Settings.Strategy.Enable TCP tunneling': 'Разрешить создание TCP туннеля',
  'pages.Settings.Strategy.Enable remote restart': 'Разрешить удаленную перезагрузку',
  'pages.Settings.Strategy.Enable recording session': 'Разрешить запись сессии',
  'pages.Settings.Strategy.Enable blocking user input': 'Разрешить блокировку ввода пользователя',
  'pages.Settings.Strategy.Enable remote configuration modification': 'Разрешить удаленное изменение конфигурации',
  'pages.Settings.Strategy.Permission type': 'Тип разрешения',
  'pages.Settings.Strategy.Approve mode': 'Режим одобрения',
  'pages.Settings.Strategy.Password type': 'Тип пароля',
  'pages.Settings.Strategy.Hide connection management window': 'Скрыть окно управления соединением',
  'pages.Settings.Strategy.One-time password length': 'Длина одноразового пароля',
  'pages.Settings.Strategy.Accept sessions via password': 'Разрешить доступ только по паролю',
  'pages.Settings.Strategy.Accept sessions via click': 'Разрешить доступ только по клику',
  'pages.Settings.Strategy.Accept sessions via both': 'Разрешить доступ по паролю или клику',
  'pages.Settings.Strategy.Use one-time password': 'Использовать одноразовый пароль',
  'pages.Settings.Strategy.Use permanent password': 'Использовать постоянный пароль',
  'pages.Settings.Strategy.Use both passwords': 'Использовать оба пароля',
  'pages.Settings.Strategy.Deny LAN discovery': 'Запретить обнаружение в локальной сети',
  'pages.Settings.Strategy.Enable direct IP access': 'Разрешить прямой доступ по IP',
  'pages.Settings.Strategy.auto_disconnect_option_tip': 'Автоматически закрывать неактивные сессии',
  'pages.Settings.Strategy.deny_lan_discovery_desc_tip': 'Устройство не будет обнаружено другими устройствами в одной локальной сети',
  'pages.Settings.Strategy.enable_direct_ip_access_desc_tip': 'Разрешить прямое IP-соединение с устройством',
  'pages.Settings.Strategy.Remove wallpaper during incoming sessions': 'Удалить обои при входящих сессиях',
  'pages.Settings.Strategy.minutes': 'минуты',
  'pages.Settings.Strategy.Enable': 'Включить',
  'pages.Settings.Strategy.Disable': 'Отключить',
  'pages.Settings.Strategy.disabled': 'Отключено',
  'pages.Settings.Strategy.Default': 'Стратегия по умолчанию',
  'pages.Settings.Strategy.Apply changes of this page': 'Применить изменения на этой странице',
  'pages.Settings.Strategy.Invalid IP': 'Недействительный IP',
  'pages.Settings.Strategy.IP Whitelisting': 'Белый список IP',
  'pages.Settings.Strategy.Clear': 'Очистить',
  'pages.Settings.Strategy.OK': 'Подтвердить',
  'pages.Settings.Strategy.whitelist_sep': 'Можно использовать запятую, точку с запятой, пробел или перенос строки в качестве разделителя',
  'pages.Settings.Strategy.Use IP whitelisting': 'Разрешить доступ только с IP из белого списка',
  'pages.Settings.Strategy.whitelist_tip': 'Только IP из белого списка могут получить доступ к этому устройству',
  'pages.CustomClient.bidirectional_desc_tip': 'Создайте клиент, который может подключаться к другому устройству и принимать запросы на подключение',
  'pages.CustomClient.incoming_desc_tip': 'Создайте клиент, который может только принимать запросы на подключение',
  'pages.CustomClient.outgoing_desc_tip': 'Создайте клиент, который может подключаться к другому устройству',
  'pages.CustomClient.disable_installation_desc_tip': 'Запретить пользователю устанавливать пользовательский клиент (только для Windows)',
  'pages.CustomClient.disable_settings_desc_tip': 'Отключить доступ пользователя к настройкам пользовательского клиента',
  'pages.CustomClient.disable_ab_desc_tip': 'Отключить функцию адресной книги в пользовательском клиенте',
  'pages.CustomClient.disable_tcp_desc_tip': 'Избежать диалогового окна брандмауэра Windows при запуске RustDesk, это пропустит попытки прямого соединения P2P и сразу использовать реле-соединение',
  'pages.CustomClient.disable_account_desc_tip': 'Удалить возможность входа пользователя в клиент RustDesk',
  'pages.CustomClient.override_settings_desc_tip': 'Переопределить настройки, которые нельзя изменить в RustDesk',
  'pages.CustomClient.default_settings_desc_tip': 'Изменить настройки, которые RustDesk использует по умолчанию',
  'pages.Settings.KeyPage.failedToFetchKey': 'Не удалось получить ключ.',
  'pages.Settings.KeyPage.keyResetSuccess': 'Сброс ключа выполнен успешно, перезапустите hbbr, чтобы изменения вступили в силу.',
  'pages.Settings.KeyPage.errorResettingKey': 'Произошла ошибка при сбросе ключа.',
  'pages.Settings.KeyPage.keyUpdateSuccess': 'Обновление ключа выполнено успешно, перезапустите hbbr, чтобы изменения вступили в силу.',
  'pages.Settings.KeyPage.errorUpdatingKey': 'Произошла ошибка при обновлении ключа, проверьте ключ',
  'pages.Settings.KeyPage.key': 'Ключ',
  'pages.Settings.KeyPage.privateKey': 'Приватный ключ',
  'pages.Settings.KeyPage.publicKey': 'Публичный ключ',
  'pages.Settings.KeyPage.editKey': 'Обновить ключ',
  'pages.Settings.KeyPage.reset': 'Сбросить',
  'pages.Settings.KeyPage.custom': 'Ввести вручную',
  'pages.Settings.KeyPage.pleaseInputPrivateKey': 'Пожалуйста, введите ваш приватный ключ!',
  'pages.Settings.License.settingsUpdatedSuccess': 'Настройки успешно обновлены.',
  'pages.Settings.License.licenseCannotBeEmpty': 'Лицензия не может быть пустой.',
  'pages.Settings.License.license': 'Лицензия',
  'pages.Settings.Relay.GeoOverrideDescription': 'Функция переопределения местоположения доступна только при наличии нескольких реле-серверов.',
  'pages.Settings.GeoOverride.ipOrDomain': 'IP/домен',
  'pages.Settings.GeoOverride.latitude': 'Широта',
  'pages.Settings.GeoOverride.longitude': 'Долгота',
  'pages.Settings.GeoOverride.deleteSelectedConfirm': 'Вы уверены, что хотите удалить выбранное переопределение местоположения?',
  'pages.Settings.GeoOverride.deleteConfirm': 'Вы уверены, что хотите удалить это переопределение местоположения?',
  'pages.Settings.GeoOverride.editGeoOverride': 'Редактировать переопределение',
  'pages.Settings.GeoOverride.addGeoOverride': 'Добавить переопределение',
  'pages.Settings.GeoOverride.ipOrDomainRequired': 'IP/домен не может быть пустым.',
  'pages.Settings.GeoOverride.latitudeRequired': 'Широта не может быть пустой.',
  'pages.Settings.GeoOverride.longitudeRequired': 'Долгота не может быть пустой.',
  'pages.Settings.GeoOverride.testGeo': 'Тестировать местоположение',
  'pages.Settings.GeoOverride.geofileFetchFailed': 'Не удалось получить информацию о GeoFile.',
  'pages.Settings.GeoOverride.loadGeoOverrides': 'Загрузить переопределения',
  'pages.Settings.GeoOverride.geoOverride': 'Переопределение местоположения',
  'pages.Settings.GeoOverride.title': 'Переопределение местоположения',
  'pages.Settings.GeoOverride.RelaySettings': 'Настройки',
  'pages.Settings.GeoOverride.RelayServers': 'Реле-серверы',
  'pages.Settings.GeoOverride.AddServer': 'Добавить сервер',
  'pages.Settings.GeoOverride.AlwaysUseRelay': 'Всегда использовать реле',
  'pages.Settings.GeoOverride.UpdateServers': 'Импортировать изменения',
  'pages.Settings.GeoOverride.UpdatedAlwaysUseRelay': 'Обновлено: всегда использовать реле',
  'pages.Settings.GeoOverride.FailedToUpdateAlwaysUseRelay': 'Не удалось обновить: всегда использовать реле',
  'pages.Settings.GeoOverride.UpdatedRelayServers': 'Обновлены реле-серверы',
  'pages.Settings.GeoOverride.FailedToUpdateRelayServers': 'Не удалось обновить реле-серверы',
  'pages.Settings.GeoOverride.UpdatedChangeIDSetting': 'Обновлено: изменение ID',
  'pages.Settings.GeoOverride.FailedToUpdateChangeIDSetting': 'Не удалось обновить изменение ID',
  "pages.Settings.Others.Allow change ID": "Разрешить изменение ID",
  "pages.Settings.Others.Disable new devices": "Отключить новые устройства",
  "pages.Settings.Others.The control end need to login before access": "Контрольный конец должен войти в систему перед доступом",
  "pages.Settings.Others.Only admin can access unassigned devices": "Только администратор может получить доступ к не назначенным устройствам",
  "pages.Settings.Others.Allow non-admin to see other groups": "Разрешить неадминистратору видеть другие группы",
  "pages.Settings.Others.allow_non_admin_see_other_group_tip": "Когда эта опция отключена, неадминистраторы могут делиться адресной книгой только в своей группе.",
  'pages.Settings.OIDC.Maximum Number of Authorized Users': 'Максимальное количество авторизованных пользователей',
  'pages.Settings.OIDC.emptyPrompt': 'В настоящее время нет сторонних поставщиков аутентификации, добавьте одного, чтобы использовать эту функцию.',
  'pages.Settings.OIDC.confirm-delete-title': 'Подтвердите удаление',
  'pages.Settings.OIDC.confirm-delete-content': 'Связанные пользователи третьей стороны не смогут войти в систему. Вы можете удалить этих пользователей в интерфейсе управления пользователями.',
  'pages.Settings.Others.Only admin can access logs': 'Только администратор может получить доступ к журналам',
  'pages.Welcome.Max Devices': 'Максимальное количество устройств',
  'pages.Welcome.Max Users': 'Максимальное количество пользователей',
  'pages.Welcome.Not set': 'Не установлено',
  'pages.Welcome.Invalid': 'Недействительно',
  'pages.Welcome.Demo': 'Демо',
  'pages.Welcome.Personal': 'Личное',
  'pages.Welcome.Business': 'Бизнес',
  'pages.Welcome.Unknown': 'Другое',
  'pages.Welcome.Max Peers': 'Максимальное количество пиров',
  'pages.Welcome.RustDesk Server Status': 'Статус сервера RustDesk',
  'pages.Welcome.License Expire Time': 'Время истечения лицензии',
  'pages.Welcome.Document': 'Документация',
  'pages.AddressBook.Permission': 'Разрешение',
  'pages.AddressBook.Device count': 'Количество устройств',
  'pages.AddressBook.Address Books': 'Адресные книги',
  'pages.AddressBook.Tags': 'Теги',
  'pages.AddressBook.Devices': 'Устройства',
  'pages.AddressBook.Device': 'Устройство',
  'pages.AddressBook.Alias': 'Псевдоним',
  'pages.AddressBook.Whether a password is set': 'Установлен ли пароль',
  'pages.AddressBook.Recycle Bin': 'Корзина',
  'pages.AddressBook.Empty Recycle Bin': 'Очистить корзину',
  'pages.AddressBook.Personal': 'Личное',
  'pages.AddressBook.Personal (My address book)': 'Личное (Моя адресная книга)',
  'pages.AddressBook.Shared': 'Общий',
  'pages.AddressBook.Share': 'Поделиться',
  'pages.AddressBook.Delete': 'Удалить',
  'pages.AddressBook.Settings': 'Настройки',
  'pages.AddressBook.Owner': 'Владелец',
  'pages.AddressBook.My Permission': 'Мое разрешение',
  'pages.AddressBook.Read-only': 'Только для чтения',
  'pages.AddressBook.Read/Write': 'Чтение/Запись',
  'pages.AddressBook.Full Control': 'Полный контроль',
  'pages.AddressBook.share_warning_tip': 'Указанные поля являются общими и видимыми для других.',
  'pages.AddressBook.permission_tip': 'Только для чтения: можно просматривать устройства и теги; Чтение/Запись: можно редактировать устройства и теги; Полный контроль: можно повторно делиться, удалять или переименовывать адресную книгу.',
  'pages.AddressBook.rule_priority_tip': 'Приоритет: пользователь > группа > все',
  'pages.AddressBook.Remove password': 'Удалить пароль',
  'pages.AddressBook.Import': 'Импортировать',
  'pages.AddressBook.Filter by intersection': 'Фильтровать по пересечению',
  'pages.AddressBook.Permissions': 'Разрешения',
  'pages.AddressBook.Type': 'Тип',
  'pages.AddressBook.Untagged': 'Без тегов',

  'app.pwa.offline': 'В настоящее время вы находитесь в оффлайн-режиме',
  'app.pwa.serviceworker.updated': 'Есть новый контент',
  'app.pwa.serviceworker.updated.hint': 'Пожалуйста, нажмите кнопку "Обновить" или обновите страницу вручную',
  'app.pwa.serviceworker.updated.ok': 'Обновить',

  'app.setting.pagestyle': 'Настройки общего стиля',
  'app.setting.pagestyle.dark': 'Темный стиль меню',
  'app.setting.pagestyle.light': 'Светлый стиль меню',
  'app.setting.content-width': 'Ширина области содержимого',
  'app.setting.content-width.fixed': 'Фиксированная ширина',
  'app.setting.content-width.fluid': 'Жидкая ширина',
  'app.setting.themecolor': 'Цвет темы',
  'app.setting.themecolor.dust': 'Пыльный',
  'app.setting.themecolor.volcano': 'Вулкан',
  'app.setting.themecolor.sunset': 'Закат',
  'app.setting.themecolor.cyan': 'Циан',
  'app.setting.themecolor.green': 'Ярко-зеленый',
  'app.setting.themecolor.daybreak': 'Светло-синий (по умолчанию)',
  'app.setting.themecolor.geekblue': 'Гик-синий',
  'app.setting.themecolor.purple': 'Фиолетовый',
  'app.setting.navigationmode': 'Режим навигации',
  'app.setting.sidemenu': 'Макет бокового меню',
  'app.setting.topmenu': 'Макет верхнего меню',
  'app.setting.fixedheader': 'Фиксированный заголовок',
  'app.setting.fixedsidebar': 'Фиксированное боковое меню',
  'app.setting.fixedsidebar.hint': 'Можно настроить при макете бокового меню',
  'app.setting.hideheader': 'Скрыть заголовок при прокрутке вниз',
  'app.setting.hideheader.hint': 'Можно настроить при фиксированном заголовке',
  'app.setting.othersettings': 'Другие настройки',
  'app.setting.weakmode': 'Режим для людей с нарушениями цветового восприятия',
  'app.setting.copy': 'Скопировать настройки',
  'app.setting.copyinfo': 'Копирование успешно, пожалуйста, замените настройки по умолчанию в config/defaultSettings.js',
  'app.setting.production.hint':
    'Панель конфигурации отображается только в среде разработки для предварительного просмотра, в производственной среде она не будет отображаться, пожалуйста, скопируйте и вручную измените файл конфигурации',

  'app.settings.menuMap.basic': 'Основные настройки',
  'app.settings.menuMap.security': 'Настройки безопасности',
  'app.settings.menuMap.binding': 'Привязка учетной записи',
  'app.settings.menuMap.notification': 'Уведомление о новых сообщениях',
  'app.settings.basic.avatar': 'Аватар',
  'app.settings.basic.change-avatar': 'Сменить аватар',
  'app.settings.basic.email': 'Электронная почта',
  'app.settings.basic.email-message': 'Пожалуйста, введите вашу электронную почту!',
  'app.settings.basic.nickname': 'Псевдоним',
  'app.settings.basic.nickname-message': 'Пожалуйста, введите ваш псевдоним!',
  'app.settings.basic.profile': 'Личная информация',
  'app.settings.basic.profile-message': 'Пожалуйста, введите личную информацию!',
  'app.settings.basic.profile-placeholder': 'Личная информация',
  'app.settings.basic.country': 'Страна/регион',
  'app.settings.basic.country-message': 'Пожалуйста, введите вашу страну или регион!',
  'app.settings.basic.geographic': 'Область',
  'app.settings.basic.geographic-message': 'Пожалуйста, введите вашу область!',
  'app.settings.basic.address': 'Уличный адрес',
  'app.settings.basic.address-message': 'Пожалуйста, введите ваш уличный адрес!',
  'app.settings.basic.phone': 'Контактный телефон',
  'app.settings.basic.phone-message': 'Пожалуйста, введите ваш контактный телефон!',
  'app.settings.basic.update': 'Обновить основную информацию',
  'app.settings.security.strong': 'Сильный',
  'app.settings.security.medium': 'Средний',
  'app.settings.security.weak': 'Слабый',
  'app.settings.security.password': 'Пароль учетной записи',
  'app.settings.security.password-description': 'Текущая сила пароля',
  'app.settings.security.phone': 'Телефон для безопасности',
  'app.settings.security.phone-description': 'Телефон, связанный с учетной записью',
  'app.settings.security.question': 'Вопрос безопасности',
  'app.settings.security.question-description': 'Вопрос безопасности не установлен, вопрос безопасности может эффективно защитить учетную запись',
  'app.settings.security.email': 'Резервная электронная почта',
  'app.settings.security.email-description': 'Электронная почта, связанная с учетной записью',
  'app.settings.security.mfa': 'Устройство MFA',
  'app.settings.security.mfa-description': 'Устройство MFA не связано, после привязки можно будет выполнить вторичное подтверждение',
  'app.settings.security.modify': 'Изменить',
  'app.settings.security.set': 'Установить',
  'app.settings.security.bind': 'Привязать',
  'app.settings.binding.taobao': 'Привязать Taobao',
  'app.settings.binding.taobao-description': 'Текущая учетная запись Taobao не привязана',
  'app.settings.binding.alipay': 'Привязать Alipay',
  'app.settings.binding.alipay-description': 'Текущая учетная запись Alipay не привязана',
  'app.settings.binding.dingding': 'Привязать DingDing',
  'app.settings.binding.dingding-description': 'Текущая учетная запись DingDing не привязана',
  'app.settings.binding.bind': 'Привязать',
  'app.settings.notification.password': 'Пароль учетной записи',
  'app.settings.notification.password-description': 'Сообщения от других пользователей будут уведомлять в виде внутреннего сообщения',
  'app.settings.notification.messages': 'Системные сообщения',
  'app.settings.notification.messages-description': 'Системные сообщения будут уведомлять в виде внутреннего сообщения',
  'app.settings.notification.todo': 'Задачи',
  'app.settings.notification.todo-description': 'Задачи будут уведомлять в виде внутреннего сообщения',
  'app.settings.open': 'Включить',
  'app.settings.close': 'Выключить',
  'app.copyright.produced': 'Разработано командой RustDesk',
  'pages.account.Settings.Security.Password': 'Пароль',
  'pages.account.Settings.Security.Change password': 'Изменить пароль',
  'pages.account.tfa.Enable 2FA': 'Включить 2FA',
  'pages.account.tfa.2 Factor Authentication is enabled.': 'Двухфакторная аутентификация включена.',
  'pages.account.tfa.Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.': 'Ваш ключ 2FA используется длительное время. Мы рекомендуем обновить ваш ключ 2FA для обеспечения максимальной безопасности учетной записи.',
  'pages.account.tfa.Enable 2 Factor Authentication.': 'Включить двухфакторную аутентификацию',

  'Enable Two-Factor Authentication': 'Включить двухфакторную аутентификацию',
  'Make your RustDesk account more secure by requiring an extra authentication code when you log in.': 'Сделайте вашу учетную запись RustDesk более безопасной, требуя дополнительный код аутентификации при входе.',
  '1. Download an authenticator app': '1. Скачайте приложение аутентификатора',
  'Download an authenticator app such as': 'Скачайте приложение аутентификатора, такое как',
  '2FA code': 'Код 2FA',
  'Authenticator': 'Аутентификатор',
  'on your phone or desktop.': 'на вашем телефоне или компьютере.',
  '2. Scan the QR code': '2. Сканируйте QR-код',
  'Open your authenticator app, scan the QR code and enter the code that your app shows.': 'Откройте приложение аутентификатора, отсканируйте QR-код и введите код, который показывает ваше приложение.',
  'You can change or remove 2FA at any time in your account settings.': 'Вы можете в любое время изменить или удалить 2FA в настройках вашей учетной записи.',
  'Please verify your 2FA code to disable current 2FA first.': 'Пожалуйста, сначала подтвердите ваш код 2FA, чтобы отключить текущий 2FA.',
  'Can\'t verify the code. Check that code and local time settings are correct!': 'Не удается подтвердить код. Проверьте, что код и настройки местного времени правильные!',
  'First sign-in:': 'Первый вход:',
  'Recent activity:': 'Недавняя активность:',
  'pages.Settings.GeoOverride.reloadGeo': 'Перезагрузить данные о геолокации',
  'pages.Settings.GeoOverride.getRelayServer': 'Получить реле-сервер',
  'pages.Settings.GeoOverride.relayServer': 'Реле-сервер',
  'SMTP Host': 'SMTP хост',
  'SMTP Port': 'SMTP порт',
  'My Server Requires Authentication': 'Мой сервер требует аутентификации',
  'smtp.From': 'Отправитель',
  'Mail Account': 'Почтовый аккаунт',
  'Mail Password': 'Пароль почты',
  'Custom Certificate PEM File Path (on the server)': 'Путь к пользовательскому файлу сертификата PEM (на сервере)',
  Update: 'Обновить',
  'Submit and Test Email': 'Отправить и протестировать электронную почту',
  'he Receiver Email': 'Электронная почта получателя',
  'LDAP Host': 'LDAP хост',
  'LDAP Port': 'LDAP порт',
  'Filter': 'Фильтр',
  'Username Attribute': 'Атрибут имени пользователя',
  'The attribute used as the username when logging in. Default is "dn".': 'Атрибут, используемый в качестве имени пользователя при входе. По умолчанию "dn".',
  'DraggableTab.Drag tab to reorder': 'Перетащите вкладку, чтобы изменить порядок',
  'pages.Settings.OIDC.new.Auth Type': 'Тип аутентификации',
  'pages.Settings.OIDC.New Auth Provider': 'Новый поставщик аутентификации',
  'pages.Settings.OIDC.new.New Auth Provider': 'Новый поставщик аутентификации',
  'pages.Settings.OIDC.Delete This Provider': 'Удалить этого поставщика',
  'pages.Settings.OIDC.new.Enable PKCE': 'Включить PKCE',
  'pages.Settings.OIDC.Enable PKCE': 'Включить PKCE',
  'pages.Settings.OIDC.new.Timeout(seconds) to force a refresh of metadata(e.g. JWKS) from OIDC providers': 'Таймаут (в секундах) для принудительного обновления метаданных (например, JWKS) от поставщиков OIDC',
  'pages.Settings.OIDC.new.0 means no timeout, empty means default (3600)': '0 означает отсутствие таймаута, пустое значение означает по умолчанию (3600)',
  'Callback URL': 'URL обратного вызова',
  'pages.Settings.Others.Session expiration time (Day)': 'Время истечения сессии (день)',
  'Or': 'Или',
};
