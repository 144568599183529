import { theme } from 'antd';
import React from 'react';
import Footer from '@/components/Footer';
import { LangAndTheme } from '@/components/RightContent';


const ResultPage: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const { token: themeToken } = theme.useToken();

    return (
        <div style={{ backgroundColor: themeToken.colorBgContainer, height: '100vh' }}>
            <div style={{ position: 'absolute', top: '16px', right: '16px' }}>
                <LangAndTheme />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                {children}
            </div>
            <div style={{ position: 'fixed', bottom: 0, width: '100%' }}>
                <Footer />
            </div>
        </div>
    );
};

export default ResultPage;
