import { Space } from 'antd';
// import { QuestionCircleOutlined } from '@ant-design/icons';
import React from 'react';
import {
  useModel, SelectLang,
} from '@umijs/max';
import Avatar from './AvatarDropdown';
import styles from './index.less';
import { ThemeSwitchAction } from './ThemeSwitch';
import { useEmotionCss } from '@ant-design/use-emotion-css';

export type SiderTheme = 'light' | 'dark';

const GlobalHeaderRight: React.FC = () => {
  const { initialState } = useModel('@@initialState');
  const actionClassName = useEmotionCss(({ token }) => ({
    display: "flex",
    float: "right",
    height: "48px",
    marginLeft: "auto",
    overflow: "hidden",
    cursor: "pointer",
    padding: "0 12px",
    alignItems: "center",
    borderRadius: token.borderRadius,
    "&:hover": {
      backgroundColor: token.colorBgTextHover,
    },
    ">span": {
      verticalAlign: 'middle',
    },
  }));

  if (!initialState || !initialState.layoutSettings) {
    return null;
  }

  return (
    <Space className={styles.right}>
      <Avatar menu={true} actionClassName={actionClassName} />
      <SelectLang className={actionClassName} />
      <ThemeSwitchAction actionClassName={actionClassName} />
    </Space >
  );
};
export default GlobalHeaderRight;

export const LangAndTheme: React.FC = () => {
  const actionClassName = useEmotionCss(({ token }) => ({
    display: "flex",
    float: "right",
    height: "48px",
    marginLeft: "auto",
    overflow: "hidden",
    cursor: "pointer",
    padding: "0 12px",
    alignItems: "center",
    borderRadius: token.borderRadius,
    "&:hover": {
      backgroundColor: token.colorBgTextHover,
    },
    ">span": {
      verticalAlign: 'middle',
    },
  }));

  return (
    <div>
      <Space className={styles.right}>
        {SelectLang && <SelectLang className={actionClassName} />}
        <ThemeSwitchAction actionClassName={actionClassName} />
      </Space >
    </div>
  );
};

