export default {
  'app.title': 'RustDesk コントロールパネル',
  'navBar.lang': '言語',
  'layout.user.link.help': 'ヘルプ',
  'layout.user.link.privacy': 'プライバシー',
  'layout.user.link.terms': '利用規約',
  'app.preview.down.block': 'このページをローカルプロジェクトにダウンロード',
  'app.welcome.link.fetch-blocks': 'すべてのブロックを取得',
  'app.welcome.link.block-list': 'ブロックに基づいて開発し、標準ページを迅速に構築',
  'The verification code is incorrect or has expired': '確認コードが間違っているか、期限切れです',
  Type: 'タイプ',
  Remote: 'リモート',
  remoteTip: 'リモートで制御されるコンピュータまたは他の端末',
  Local: 'ローカル',
  'Start Time': '開始時間',
  'End Time': '終了時間',
  Note: 'ノート',
  'Log List': 'ログリスト',
  'Device List': 'デバイスリスト',
  'User List': 'ユーザーリスト',
  'User Name': 'ユーザー名',
  Updating: '更新中',
  Adding: '追加中',
  Deleting: '削除中',
  Create: '作成',
  Add: '追加',
  Confirm: '確認',
  Submit: '送信',
  Warning: '警告',
  Error: 'エラー',
  Email: 'メール',
  Status: 'ステータス',
  Action: 'アクション',
  Password: 'パスワード',
  'New Password': '新しいパスワード',
  Edit: '編集',
  Normal: '通常',
  Disabled: '無効',
  Disable: '無効',
  Enable: '有効',
  Chosen: '選択',
  Items: '項目',
  User: 'ユーザー',
  Info: '情報',
  'Log out': 'ログアウト',
  Settings: '設定',
  'Basic Settings': '基本設定',
  'Remote Desktop': 'リモートデスクトップ',
  'File Transfer': 'ファイル転送',
  'Port Transfer': 'ポート転送',
  'View Camera': 'カメラを表示',
  'Not Logged In': 'ログインしていません',
  'Created Time': '作成時間',
  Delete: '削除',
  Group: 'グループ',
  'Device Group': 'デバイスグループ',
  'User Group': 'ユーザーグループ',
  Name: '名前',
  'Group List': 'グループリスト',
  deleteConfirm: '削除してもよろしいですか？',
  groupTip: '同じグループのユーザーは相互にアクセスできます',
  document: 'ドキュメント',
  Detail: '詳細情報',
  Direction: '方向',
  File: 'ファイル',
  Directory: 'ディレクトリ',
  Disconnect: '切断',
  Time: '時間',
  'Export as csv': 'CSVとしてエクスポート',
  exportCsvTip: '一度に最大1000件の記録をエクスポート',
  'Not binded only': '未バインドデバイスのみ表示',
  Close: '閉じる',
  'Default Strategy': 'デフォルト戦略',
  'applied device num': '適用されたデバイス数',
  'Device Name': 'デバイス名',
  'Strategy': '戦略',
  'Device Strategy': 'デバイス戦略',
  'User Strategy': 'ユーザー戦略',
  'Group Strategy': 'グループ戦略',
  'strategy_tooltip': "フォーマット：\"デバイス戦略 / ユーザー戦略 / グループ戦略\"。単独で '-' を表示することはデフォルト戦略を使用することを意味し、特定の位置に '-' が表示されることはそのレベルで戦略が設定されていないことを意味します",
  Search: '検索',
  Select: '選択',
  Assign: '割り当て',
  Device: 'デバイス',
  'Device Username': 'デバイスユーザー名',
  Role: '役割',
  'Auth Type': '認証タイプ',
  Invite: '招待',
  'Please configure Email SMTP settings first': '最初にメールSMTP設定を構成してください',
  'Security Settings': 'セキュリティ設定',
  'Manage Signed Devices': '署名されたデバイスを管理',
  'Since 2FA is enabled, the email login verification option is not used. 2FA login verification will be used instead.': '2FAが有効になっているため、メールログイン確認オプションは使用されません。代わりに2FAログイン確認が使用されます。',
  'Enable alarm event email notification': 'アラームイベントのメール通知を有効にする',
  'Enable email login verification': 'メールログイン確認を有効にする',
  'License Type': 'ライセンスタイプ',
  'Renew / Upgrade License': 'ライセンスの更新 / アップグレード',
  Save: '保存',
  Back: '戻る',
  'Confirm Password': 'パスワードを確認',
  'username@device_name': 'ユーザー名@デバイス名',
  'Hide username': 'ユーザー名を隠す',
  'Remote device': 'リモートデバイス',
  'Within-group connections': 'グループ内接続',
  'Cross-group connections': 'グループ間接続',
  welcomeTitle: 'RustDeskへようこそ',
  Cancel: 'キャンセル',
  OK: 'OK',

  'pages.GroupList.User Group': 'ユーザーグループ',
  'pages.GroupList.User': 'ユーザー',

  'pages.CustomClient.Application name': 'アプリケーション名',
  'pages.CustomClient.Name': '名前',
  'pages.CustomClient.Build': 'ビルド',
  'pages.CustomClient.Note': 'ノート',
  'pages.CustomClient.Action': 'アクション',
  'pages.CustomClient.Delete': '削除',
  'pages.CustomClient.Edit': '編集',
  'pages.CustomClient.Duplicate': '複製',
  'pages.CustomClient.Create': '作成',
  'pages.CustomClient.Custom Clients': 'カスタムクライアント',
  'pages.CustomClient.General': '一般',
  'pages.CustomClient.Name of the configuration': '構成名',
  'pages.CustomClient.MSI installer': 'MSIインストーラー',
  'pages.CustomClient.Connection type': '接続タイプ',
  'pages.CustomClient.Bidirectional': '双方向',
  'pages.CustomClient.Incoming': '受信 (Incoming)',
  'pages.CustomClient.Outgoing': '送信 (Outgoing)',
  'pages.CustomClient.Options': 'オプション',
  'pages.CustomClient.Disable installation': 'インストールを無効にする',
  'pages.CustomClient.Disable settings': '設定を無効にする',
  'pages.CustomClient.Disable address book': 'アドレス帳を無効にする',
  'pages.CustomClient.Disable TCP listen port': 'TCPリッスンポートを無効にする',
  'pages.CustomClient.Disable user account': 'ユーザーアカウントを無効にする',
  'pages.CustomClient.Some note about this configuration': 'この構成に関する注意事項',
  'pages.CustomClient.Custom server': 'カスタムサーバー',
  'pages.CustomClient.Visual': 'ビジュアル',
  'pages.CustomClient.Application icon': 'アプリケーションアイコン',
  'pages.CustomClient.Security': 'セキュリティ',
  'pages.CustomClient.Preset password': 'プリセットパスワード',
  'pages.CustomClient.Advanced': '高度',
  'pages.CustomClient.Override settings': '設定を上書き',
  'pages.CustomClient.Default settings': 'デフォルト設定',
  'pages.CustomClient.Note: ': '注意：',
  'pages.CustomClient.Click or drag to upload': 'クリックまたはドラッグしてアップロード',
  'pages.CustomClient.app_icon_desc_tip': 'アプリケーションアイコンとして512x512ピクセルの正方形PNG画像をアップロードしてください。',
  'pages.CustomClient.app_name_desc_tip': '開始メニュー、コントロールパネルなどに表示される一意の名前を指定してください。',
  'pages.CustomClient.logo_desc_tip': 'カスタムクライアントのメインウィンドウの上部に表示されるPNG画像をアップロードしてください。サイズは自動的にスケーリングされます。200x40ピクセルのPNGを使用することをお勧めします。',
  'pages.CustomClient.This password will be used as a default permanent password for all new clients. It can be changed by the client.': 'このパスワードはすべての新しいクライアントのデフォルトの永久パスワードとして使用されます。クライアントによって変更できます。',
  'pages.CustomClient.The application icon must be a PNG file.': 'アプリケーションアイコンはPNGファイルでなければなりません。',
  'component.tagSelect.expand': '展開',
  'component.tagSelect.collapse': '折りたたむ',
  'component.tagSelect.all': 'すべて',
  'component.LicenseAlert.Your license is invalid.': 'ライセンスが無効です',
  'component.LicenseAlert.Your license is not set.': 'ライセンスが設定されていません',
  'component.LicenseAlert.Your license is about to expire in 2 weeks.': 'ライセンスは2週間以内に期限切れになります',
  'component.LicenseAlert.Your license has expired.': 'ライセンスが期限切れです',
  'pages.CustomClient.Refresh status manually': '手動でステータスを更新',
  'pages.CustomClient.the building task': 'ビルドタスク',
  'pages.CustomClient.Stop': '停止',
  'pages.CustomClient.Cancel': 'キャンセル',
  'pages.CustomClient.Completed at %s, this file will be deleted in 1 day.': '%sに完了しました。このファイルは1日後に削除されます。',

  'component.globalHeader.search': 'サイト内検索',
  'component.globalHeader.help': '使用ドキュメント',
  'component.globalHeader.notification': '通知',
  'component.globalHeader.notification.empty': 'すべての通知を確認しました',
  'component.globalHeader.message': 'メッセージ',
  'component.globalHeader.message.empty': 'すべてのメッセージを読みました',
  'component.globalHeader.event': 'タスク',
  'component.globalHeader.event.empty': 'すべてのタスクを完了しました',
  'component.noticeIcon.clear': 'クリア',
  'component.noticeIcon.cleared': 'クリアしました',
  'component.noticeIcon.empty': 'データはありません',
  'component.noticeIcon.view-more': 'もっと見る',
  'Token List': 'トークンリスト',
  Description: '説明',
  'Device Permission': 'デバイス権限',
  'Audit Log Permission': '監査ログ権限',
  'User Permission': 'ユーザー権限',
  'Group Permission': 'グループ権限',
  'Strategy Permission': '戦略権限',

  'menu.welcome': 'ようこそ',
  'menu.more-blocks': 'もっとブロック',
  'menu.home': 'ホーム',
  'menu.admin': '管理ページ',
  'menu.admin.sub-page': 'サブ管理ページ',
  'menu.login': 'ログイン',
  'menu.register': '登録',
  'menu.register-result': '登録結果',
  'menu.dashboard': 'ダッシュボード',
  'menu.dashboard.analysis': '分析ページ',
  'menu.dashboard.monitor': 'モニターページ',
  'menu.dashboard.workplace': '作業場',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'フォームページ',
  'menu.form.basic-form': '基本フォーム',
  'menu.form.step-form': 'ステップフォーム',
  'menu.form.step-form.info': 'ステップフォーム（送金情報の入力）',
  'menu.form.step-form.confirm': 'ステップフォーム（送金情報の確認）',
  'menu.form.step-form.result': 'ステップフォーム（完了）',
  'menu.form.advanced-form': '高度なフォーム',
  'menu.list': 'リストページ',
  'menu.list.audit-list': 'セキュリティ監査',
  'menu.list.audit-list.Alarm': 'リスク/異常アラーム',
  'menu.list.audit-list.Connection': '接続ログ',
  'menu.list.audit-list.File': 'ファイル転送ログ',
  'menu.list.audit-list.Console': 'コンソール操作ログ',
  'menu.list.device-list': 'デバイス',
  'menu.list.user-list': 'ユーザー',
  'menu.list.group-list': 'グループ',
  'menu.list.group-list.user': 'ユーザーグループ',
  'menu.list.group-list.device': 'デバイスグループ',
  'menu.list.table-list': 'クエリテーブル',
  'menu.list.basic-list': '標準リスト',
  'menu.list.card-list': 'カードリスト',
  'menu.list.search-list': '検索リスト',
  'menu.list.search-list.articles': '検索リスト（記事）',
  'menu.list.search-list.projects': '検索リスト（プロジェクト）',
  'menu.list.search-list.applications': '検索リスト（アプリケーション）',
  'menu.profile': '詳細ページ',
  'menu.profile.basic': '基本詳細ページ',
  'menu.profile.advanced': '高度な詳細ページ',
  'menu.result': '結果ページ',
  'menu.result.success': '成功ページ',
  'menu.result.fail': '失敗ページ',
  'menu.exception': '例外ページ',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'エラーをトリガー',
  'menu.account': '個人ページ',
  'menu.account.center': '個人センター',
  'menu.account.settings': '個人設定',
  'menu.account.trigger': 'エラーをトリガー',
  'menu.account.logout': 'ログアウト',
  'menu.editor': 'グラフィックエディタ',
  'menu.editor.flow': 'フローチャートエディタ',
  'menu.editor.mind': 'マインドマップエディタ',
  'menu.editor.koni': 'トポロジーエディタ',
  'menu.Settings': '設定',
  'menu.Strategy': '戦略',
  'menu.Settings.Key': 'キー',
  'menu.Settings.License': 'ライセンス',
  'menu.Settings.Relay': '中継管理',
  'menu.Settings.Others': 'その他',
  'menu.Settings.Tokens': 'トークン',
  'menu.Address Books': 'アドレス帳',
  'menu.Strategies': '戦略',
  'menu.Custom Clients': 'カスタムクライアント',

  'pages.login.accountLogin.tab': 'アカウントパスワードログイン',
  'pages.login.accountLogin.errorMessage': '無効なユーザー名またはパスワード',
  'pages.login.failure': 'ログインに失敗しました。再試行してください！',
  'pages.login.success': 'ログイン成功！',
  'pages.login.username.placeholder': 'ユーザー名: admin または user',
  'pages.login.username.required': 'ユーザー名は必須項目です！',
  'pages.login.password.placeholder': 'パスワード: ant.design',
  'pages.login.password.required': 'パスワードは必須項目です！',
  'pages.login.phoneLogin.tab': '電話番号ログイン',
  'pages.login.phoneLogin.errorMessage': '確認コードが無効です',
  'pages.login.phoneNumber.placeholder': '電話番号を入力してください！',
  'pages.login.phoneNumber.required': '電話番号は必須項目です！',
  'pages.login.phoneNumber.invalid': '無効な電話番号です！',
  'pages.login.captcha.placeholder': '確認コードを入力してください！',
  'pages.login.captcha.required': '確認コードは必須項目です！',
  'pages.login.phoneLogin.getVerificationCode': '確認コードを取得',
  'pages.getCaptchaSecondText': '秒後に再取得',
  'pages.login.rememberMe': '自動ログイン',
  'pages.login.forgotPassword': 'パスワードを忘れましたか？',
  'pages.login.submit': 'ログイン',
  'pages.login.loginWith': '他のログイン方法:',
  'pages.login.loginWithGoogle': 'Googleでログイン',
  'pages.login.loginWithGithub': 'Githubでログイン',
  'pages.login.loginWithOkta': 'Oktaでログイン',
  'pages.login.loginWith.requesting_auth': '認証ページをリクエスト中',
  'pages.login.loginWith.waiting_auth': 'アカウント認証を完了してください',
  'pages.login.registerAccount': 'アカウントを登録',
  'pages.welcome.advancedComponent': '高度なテーブル',
  'pages.welcome.link': 'ようこそ',
  'pages.welcome.advancedLayout': '高度なレイアウト',
  'pages.welcome.alertMessage': 'より速く、より強力な重いコンポーネントがリリースされました。',
  'pages.account.tfaComponent.Enable 2FA': '2FAを有効にする',
  'pages.account.tfaComponent.2 Factor Authentication is enabled.': '二要素認証が有効になっています',
  'pages.account.tfaComponent.Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.': 'あなたの2FAキーは長期間使用されています。最大のアカウントセキュリティを確保するために、2FAキーを更新することをお勧めします。',
  'pages.account.tfaComponent.Enable 2 Factor Authentication.': '二要素認証を有効にする',
  'pages.account.tfa.The code must be 6, 8, 10 digits.': 'コードは6、8、10桁でなければなりません。',
  'pages.account.tfa.The code must be 6 or 8 digits.': 'コードは6または8桁でなければなりません。',
  'pages.admin.subPage.title': 'このページはadmin権限が必要です',
  'pages.admin.subPage.alertMessage': 'umi uiがリリースされました。npm run uiで体験を開始してください。',
  'pages.AuditList.FileAuditList.files': 'ファイル',
  'pages.AuditList.FileAuditList.pathTip': '制御デバイス上のパス',
  'pages.AuditList.FileAuditList.limitedTip': '最大10個のファイルを表示',
  'pages.AuditList.FileAuditList.exportFilename': 'ファイル転送ログ',
  'pages.AuditList.ConnAuditList.modal_title': '操作を確認',
  'pages.AuditList.ConnAuditList.diconnect_confirm_tip': '本当にこのデバイスを切断しますか？',
  'pages.AuditList.ConnAuditList.disconnect_succ': '切断成功！',
  'pages.AuditList.ConnAuditList.disconnect_fail': '切断失敗！',
  'pages.AuditList.ConnAuditList.exportFilename': '接続ログ',
  'pages.AuditList.AlarmAuditList.Login Device / Local Device': 'ログインデバイス / ローカルデバイス',
  'pages.AuditList.AlarmAuditList.Login Account / Remote Device': 'ログインアカウント / リモートデバイス',
  'pages.AuditList.AlarmAuditList.Login Account': 'ログインアカウント',
  'pages.AuditList.AlarmAuditList.Device ID': 'デバイスID',
  'pages.AuditList.AlarmAuditList.Device Name': 'デバイスユーザー名',
  'pages.AuditList.AlarmAuditList.Remote Device': 'リモートデバイス',
  'pages.AuditList.AlarmAuditList.Event Time': '発生時間',
  'pages.AuditList.AlarmAuditList.IP whiltelist': 'ホワイトリスト外のIPがアクセスを試みました',
  'pages.AuditList.AlarmAuditList.ExceedThirtyAccessAttempts': '30回以上のアクセス試行',
  'pages.AuditList.AlarmAuditList.MultipleAccessAttemptsWithinOneMinute': '1分以内に複数のアクセス試行',
  'pages.AuditList.AlarmAuditList.ExceedThirtyLoginAttempts': '30回以上のログイン試行',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneMinute': '1分以内に複数のログイン試行',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneHour': '1時間以内に複数のログイン試行',
  'pages.AuditList.AlarmAuditList.exportFilename': 'リスク異常アラームログ',
  'pages.AuditList.ConsoleAuditList.Operation': '操作',
  'pages.AuditList.ConsoleAuditList.Name': '名前',
  'pages.AuditList.ConsoleAuditList.Name list': '名前リスト',
  'pages.AuditList.ConsoleAuditList.ID list': 'IDリスト',
  'pages.AuditList.ConsoleAuditList.Result': '結果',
  'pages.AuditList.ConsoleAuditList.Remote': 'リモート',
  'pages.AuditList.ConsoleAuditList.Local': 'ローカル',
  'pages.AuditList.ConsoleAuditList.User Login': 'ユーザーがコンソールにログイン',
  'pages.AuditList.ConsoleAuditList.Add Group': '新しいグループを作成',
  'pages.AuditList.ConsoleAuditList.Add User': 'ユーザーを追加',
  'pages.AuditList.ConsoleAuditList.Add Device': 'デバイスを追加',
  'pages.AuditList.ConsoleAuditList.Delete Groups': 'グループを一括削除',
  'pages.AuditList.ConsoleAuditList.Disconnect Device': 'デバイス接続を切断',
  'pages.AuditList.ConsoleAuditList.Enable Users': 'ユーザーを有効にする',
  'pages.AuditList.ConsoleAuditList.Disable Users': 'ユーザーを無効にする',
  'pages.AuditList.ConsoleAuditList.Enable Devices': 'デバイスを一括有効にする',
  'pages.AuditList.ConsoleAuditList.Disable Devices': 'デバイスを一括無効にする',
  'pages.AuditList.ConsoleAuditList.Update Group': 'グループを更新',
  'pages.AuditList.ConsoleAuditList.Update User': 'ユーザーを更新',
  'pages.AuditList.ConsoleAuditList.Update Device': 'デバイスを更新',
  'pages.AuditList.ConsoleAuditList.Delete User': 'ユーザーを削除',
  'pages.AuditList.ConsoleAuditList.Delete Device': 'デバイスを削除',
  'pages.AuditList.ConsoleAuditList.Add Address Book': 'アドレス帳を追加',
  'pages.AuditList.ConsoleAuditList.Delete Address Book': 'アドレス帳を削除',
  'pages.AuditList.ConsoleAuditList.Change Address Book Name': 'アドレス帳名を変更',
  'pages.AuditList.ConsoleAuditList.Group Management': 'グループ管理',
  'pages.AuditList.ConsoleAuditList.User Management': 'ユーザー管理',
  'pages.AuditList.ConsoleAuditList.Device Management': 'デバイス管理',
  'pages.AuditList.ConsoleAuditList.Address Book Management': 'アドレス帳管理',
  'pages.AuditList.ConsoleAuditList.exportFilename': 'コンソールログ',
  'pages.AuditList.ConsoleAuditList.Delete Devices in the Address Book Recycle Bin': 'アドレス帳のごみ箱からデバイスを削除',
  'pages.AuditList.ConsoleAuditList.Empty Address Book Recycle Bin': 'アドレス帳のごみ箱を空にする',
  'pages.AuditList.ConsoleAuditList.Add Address Book Permission': 'アドレス帳権限を追加',
  'pages.AuditList.ConsoleAuditList.Delete Address Book Permission': 'アドレス帳権限を削除',
  'pages.AuditList.ConsoleAuditList.Update Address Book Permission': 'アドレス帳権限を変更',
  'pages.Settings.Strategy.Strategy list': '戦略リスト',
  'pages.Settings.Strategy.Automatically close incoming sessions on user inactivity': 'ユーザーが非アクティブな場合、受信セッションを自動的に閉じる',
  'pages.Settings.Strategy.delete_strategy_tip': 'この戦略を削除してもよろしいですか？',
  'pages.Settings.Strategy.New strategy': '新しい戦略',
  'pages.Settings.Strategy.Rename strategy': '戦略の名前を変更',
  'pages.Settings.Strategy.Duplicate strategy': '戦略を複製',
  'pages.Settings.Strategy.Edit Devices': 'デバイスを編集',
  'pages.Settings.Strategy.Edit Users': 'ユーザーを編集',
  'pages.Settings.Strategy.Rename': '名前を変更',
  'pages.Settings.Strategy.Duplicate': '複製',
  'pages.Settings.Strategy.Delete': '削除',
  'pages.Settings.Strategy.Groups': 'グループ',
  'pages.Settings.Strategy.Select All': 'すべて選択',
  'pages.Settings.Strategy.Permissions': '権限',
  'pages.Settings.Strategy.Password': 'パスワード',
  'pages.Settings.Strategy.Security': 'セキュリティ',
  'pages.Settings.Strategy.Other': 'その他',
  'pages.Settings.Strategy.IP whitelist': 'IPホワイトリスト',
  'pages.Settings.Strategy.Cancel': 'キャンセル',
  'pages.Settings.Strategy.Submit': '確認',
  'pages.Settings.Strategy.Edit': '編集',
  'pages.Settings.Strategy.Custom': 'カスタム',
  'pages.Settings.Strategy.Full access': '完全アクセス',
  'pages.Settings.Strategy.Screen share': '画面共有のみ',
  'pages.Settings.Strategy.Enable keyboard/mouse': 'キーボード/マウスの制御を許可',
  'pages.Settings.Strategy.Enable clipboard': 'クリップボードの同期を許可',
  'pages.Settings.Strategy.Enable file transfer': 'ファイル転送を許可',
  'pages.Settings.Strategy.Enable audio': '音声転送を許可',
  'pages.Settings.Strategy.Enable TCP tunneling': 'TCPトンネリングを許可',
  'pages.Settings.Strategy.Enable remote restart': 'リモート再起動を許可',
  'pages.Settings.Strategy.Enable recording session': 'セッションの録画を許可',
  'pages.Settings.Strategy.Enable blocking user input': 'ユーザー入力のブロックを許可',
  'pages.Settings.Strategy.Enable remote configuration modification': 'リモート構成の変更を許可',
  'pages.Settings.Strategy.Permission type': '権限タイプ',
  'pages.Settings.Strategy.Approve mode': '認証方式',
  'pages.Settings.Strategy.Password type': 'パスワードタイプ',
  'pages.Settings.Strategy.Hide connection management window': '接続管理ウィンドウを隠す',
  'pages.Settings.Strategy.One-time password length': 'ワンタイムパスワードの長さ',
  'pages.Settings.Strategy.Accept sessions via password': 'パスワードによるアクセスのみを許可',
  'pages.Settings.Strategy.Accept sessions via click': 'クリックによるアクセスのみを許可',
  'pages.Settings.Strategy.Accept sessions via both': 'パスワードまたはクリックによるアクセスを許可',
  'pages.Settings.Strategy.Use one-time password': 'ワンタイムパスワードを使用',
  'pages.Settings.Strategy.Use permanent password': '固定パスワードを使用',
  'pages.Settings.Strategy.Use both passwords': '両方のパスワードを使用',
  'pages.Settings.Strategy.Deny LAN discovery': 'LAN発見を拒否',
  'pages.Settings.Strategy.Enable direct IP access': 'IP直接アクセスを許可',
  'pages.Settings.Strategy.auto_disconnect_option_tip': '非アクティブなセッションを自動的に閉じる',
  'pages.Settings.Strategy.deny_lan_discovery_desc_tip': 'デバイスは同じLAN内の他のデバイスに発見されません',
  'pages.Settings.Strategy.enable_direct_ip_access_desc_tip': 'デバイスへの直接IP接続を許可',
  'pages.Settings.Strategy.Remove wallpaper during incoming sessions': '受信セッション中にデスクトップの壁紙を削除',
  'pages.Settings.Strategy.minutes': '分',
  'pages.Settings.Strategy.Enable': '有効',
  'pages.Settings.Strategy.Disable': '無効',
  'pages.Settings.Strategy.disabled': '無効',
  'pages.Settings.Strategy.Default': 'デフォルト戦略',
  'pages.Settings.Strategy.Apply changes of this page': 'このページの変更を適用',
  'pages.Settings.Strategy.Invalid IP': '無効なIP',
  'pages.Settings.Strategy.IP Whitelisting': 'IPホワイトリスト',
  'pages.Settings.Strategy.Clear': 'クリア',
  'pages.Settings.Strategy.OK': '確認',
  'pages.Settings.Strategy.whitelist_sep': 'カンマ、セミコロン、スペース、または改行を区切り文字として使用できます',
  'pages.Settings.Strategy.Use IP whitelisting': 'ホワイトリスト上のIPのみアクセスを許可',
  'pages.Settings.Strategy.whitelist_tip': 'ホワイトリスト内のIPのみがこのマシンにアクセスできます',
  'pages.CustomClient.bidirectional_desc_tip': '他のデバイスに接続でき、接続要求を受け取ることができるクライアントを作成します',
  'pages.CustomClient.incoming_desc_tip': '接続要求のみを受け取ることができるクライアントを作成します',
  'pages.CustomClient.outgoing_desc_tip': '他のデバイスに接続できるクライアントを作成します',
  'pages.CustomClient.disable_installation_desc_tip': 'カスタムクライアントのインストールを禁止します（Windowsのみ）',
  'pages.CustomClient.disable_settings_desc_tip': 'ユーザーがカスタムクライアントの設定にアクセスするのを無効にします',
  'pages.CustomClient.disable_ab_desc_tip': 'カスタムクライアント内のアドレス帳機能を無効にします',
  'pages.CustomClient.disable_tcp_desc_tip': 'RustDesk起動時にWindowsファイアウォールのダイアログを回避し、P2P直接接続の試行をスキップして中継接続を使用します',
  'pages.CustomClient.disable_account_desc_tip': 'RustDeskクライアント内でのユーザーログイン機能を削除します',
  'pages.CustomClient.override_settings_desc_tip': '設定を上書きし、RustDesk内で変更できなくします',
  'pages.CustomClient.default_settings_desc_tip': 'RustDeskがデフォルトで使用する設定を変更します',
  'pages.Settings.KeyPage.failedToFetchKey': 'キーの取得に失敗しました。',
  'pages.Settings.KeyPage.keyResetSuccess': 'キーのリセットに成功しました。変更を有効にするにはhbbrを再起動してください。',
  'pages.Settings.KeyPage.errorResettingKey': 'キーのリセット中にエラーが発生しました。',
  'pages.Settings.KeyPage.keyUpdateSuccess': 'キーの更新に成功しました。変更を有効にするにはhbbrを再起動してください。',
  'pages.Settings.KeyPage.errorUpdatingKey': 'キーの更新中にエラーが発生しました。キーを確認してください',
  'pages.Settings.KeyPage.key': 'キー',
  'pages.Settings.KeyPage.privateKey': '秘密鍵',
  'pages.Settings.KeyPage.publicKey': '公開鍵',
  'pages.Settings.KeyPage.editKey': 'キーを更新',
  'pages.Settings.KeyPage.reset': 'リセット',
  'pages.Settings.KeyPage.custom': '手動入力',
  'pages.Settings.KeyPage.pleaseInputPrivateKey': '秘密鍵を入力してください！',
  'pages.Settings.License.settingsUpdatedSuccess': '設定が正常に更新されました。',
  'pages.Settings.License.licenseCannotBeEmpty': 'ライセンスは空にできません。',
  'pages.Settings.License.license': 'ライセンス',
  'pages.Settings.Relay.GeoOverrideDescription': '複数の中継サーバーが存在する場合にのみ、位置オーバーライド機能を使用できます。',
  'pages.Settings.GeoOverride.ipOrDomain': 'IP/ドメイン',
  'pages.Settings.GeoOverride.latitude': '緯度',
  'pages.Settings.GeoOverride.longitude': '経度',
  'pages.Settings.GeoOverride.deleteSelectedConfirm': '選択した位置オーバーライドを削除してもよろしいですか？',
  'pages.Settings.GeoOverride.deleteConfirm': 'この位置オーバーライドを削除してもよろしいですか？',
  'pages.Settings.GeoOverride.editGeoOverride': 'オーバーライドを編集',
  'pages.Settings.GeoOverride.addGeoOverride': 'オーバーライドを追加',
  'pages.Settings.GeoOverride.ipOrDomainRequired': 'IP/ドメインは必須です。',
  'pages.Settings.GeoOverride.latitudeRequired': '緯度は必須です。',
  'pages.Settings.GeoOverride.longitudeRequired': '経度は必須です。',
  'pages.Settings.GeoOverride.testGeo': '位置をテスト',
  'pages.Settings.GeoOverride.geofileFetchFailed': 'GeoFile情報の取得に失敗しました。',
  'pages.Settings.GeoOverride.loadGeoOverrides': 'オーバーライドを読み込む',
  'pages.Settings.GeoOverride.geoOverride': '位置オーバーライド',
  'pages.Settings.GeoOverride.title': '位置オーバーライド',
  'pages.Settings.GeoOverride.RelaySettings': '設定',
  'pages.Settings.GeoOverride.RelayServers': '中継サーバー',
  'pages.Settings.GeoOverride.AddServer': 'サーバーを追加',
  'pages.Settings.GeoOverride.AlwaysUseRelay': '常に中継を使用',
  'pages.Settings.GeoOverride.UpdateServers': 'インポート修正',
  'pages.Settings.GeoOverride.UpdatedAlwaysUseRelay': '常に中継を使用が更新されました',
  'pages.Settings.GeoOverride.FailedToUpdateAlwaysUseRelay': '常に中継を使用の更新に失敗しました',
  'pages.Settings.GeoOverride.UpdatedRelayServers': '中継サーバーが更新されました',
  'pages.Settings.GeoOverride.FailedToUpdateRelayServers': '中継サーバーの更新に失敗しました',
  'pages.Settings.GeoOverride.UpdatedChangeIDSetting': '変更ID設定が更新されました',
  'pages.Settings.GeoOverride.FailedToUpdateChangeIDSetting': '変更ID設定の更新に失敗しました',
  "pages.Settings.Others.Allow change ID": "IDの変更を許可",
  "pages.Settings.Others.Disable new devices": "新しいデバイスを無効にする",
  "pages.Settings.Others.The control end need to login before access": "制御端は接続前にログインする必要があります",
  "pages.Settings.Others.Only admin can access unassigned devices": "管理者のみが未割り当てデバイスにアクセスできます",
  "pages.Settings.Others.Allow non-admin to see other groups": "非管理者が他のグループを見ることを許可",
  "pages.Settings.Others.allow_non_admin_see_other_group_tip": "このオプションがオフの場合、非管理者は自分のグループ内でのみアドレス帳を共有できます。",
  'pages.Settings.OIDC.Maximum Number of Authorized Users': '最大認証ユーザー数',
  'pages.Settings.OIDC.emptyPrompt': '現在、サードパーティの認証プロバイダーはありません。使用するには追加してください。',
  'pages.Settings.OIDC.confirm-delete-title': '削除を確認',
  'pages.Settings.OIDC.confirm-delete-content': '関連するサードパーティの認証ユーザーは再度ログインできなくなります。これらのユーザーを削除するには、ユーザー管理インターフェースに移動できます。',
  'pages.Settings.Others.Only admin can access logs': '管理者のみが未割り当てのログにアクセスできます',
  'pages.Welcome.Max Devices': '最大デバイス数',
  'pages.Welcome.Max Users': '最大ユーザー数',
  'pages.Welcome.Not set': '未設定',
  'pages.Welcome.Invalid': '無効',
  'pages.Welcome.Demo': 'デモ',
  'pages.Welcome.Personal': '個人',
  'pages.Welcome.Business': 'ビジネス',
  'pages.Welcome.Unknown': 'その他',
  'pages.Welcome.Max Peers': '最大デバイス数',
  'pages.Welcome.RustDesk Server Status': 'RustDeskサーバーの状態',
  'pages.Welcome.License Expire Time': 'ライセンスの有効期限',
  'pages.Welcome.Document': 'ドキュメント',
  'pages.AddressBook.Permission': '権限',
  'pages.AddressBook.Device count': 'デバイス数',
  'pages.AddressBook.Address Books': 'アドレス帳',
  'pages.AddressBook.Tags': 'タグ',
  'pages.AddressBook.Devices': 'デバイス',
  'pages.AddressBook.Device': 'デバイス',
  'pages.AddressBook.Alias': 'エイリアス',
  'pages.AddressBook.Whether a password is set': 'パスワードが設定されているか',
  'pages.AddressBook.Recycle Bin': 'ごみ箱',
  'pages.AddressBook.Empty Recycle Bin': 'ごみ箱を空にする',
  'pages.AddressBook.Personal': '個人',
  'pages.AddressBook.Personal (My address book)': '個人（私のアドレス帳）',
  'pages.AddressBook.Shared': '共有',
  'pages.AddressBook.Share': '共有',
  'pages.AddressBook.Delete': '削除',
  'pages.AddressBook.Settings': '設定',
  'pages.AddressBook.Owner': '所有者',
  'pages.AddressBook.My Permission': '私の権限',
  'pages.AddressBook.Read-only': '読み取り専用',
  'pages.AddressBook.Read/Write': '読み書き',
  'pages.AddressBook.Full Control': '完全制御',
  'pages.AddressBook.share_warning_tip': '上記のフィールドは共有され、他の人に見えることがあります。',
  'pages.AddressBook.permission_tip': '読み取り専用：デバイスとタグを表示できます；読み書き：デバイスとタグを編集できます；完全制御：再共有、削除、またはアドレス帳の名前を変更できます。',
  'pages.AddressBook.rule_priority_tip': '優先順位: ユーザー > グループ > すべての人',
  'pages.AddressBook.Remove password': 'パスワードを削除',
  'pages.AddressBook.Import': 'インポート',
  'pages.AddressBook.Filter by intersection': '交差でフィルタリング',
  'pages.AddressBook.Permissions': '権限',
  'pages.AddressBook.Type': 'タイプ',
  'pages.AddressBook.Untagged': 'タグなし',

  'app.pwa.offline': '現在オフラインです',
  'app.pwa.serviceworker.updated': '新しいコンテンツがあります',
  'app.pwa.serviceworker.updated.hint': '「更新」ボタンをクリックするか、手動でページを更新してください',
  'app.pwa.serviceworker.updated.ok': '更新',

  'app.setting.pagestyle': '全体スタイル設定',
  'app.setting.pagestyle.dark': 'ダークメニュースタイル',
  'app.setting.pagestyle.light': 'ライトメニュースタイル',
  'app.setting.content-width': 'コンテンツ領域の幅',
  'app.setting.content-width.fixed': '固定幅',
  'app.setting.content-width.fluid': '流動的',
  'app.setting.themecolor': 'テーマカラー',
  'app.setting.themecolor.dust': '薄暮',
  'app.setting.themecolor.volcano': '火山',
  'app.setting.themecolor.sunset': '日暮れ',
  'app.setting.themecolor.cyan': '明青',
  'app.setting.themecolor.green': '極光緑',
  'app.setting.themecolor.daybreak': '拂晓蓝（デフォルト）',
  'app.setting.themecolor.geekblue': '極客青',
  'app.setting.themecolor.purple': '紫',
  'app.setting.navigationmode': 'ナビゲーションモード',
  'app.setting.sidemenu': 'サイドメニューのレイアウト',
  'app.setting.topmenu': 'トップメニューのレイアウト',
  'app.setting.fixedheader': '固定ヘッダー',
  'app.setting.fixedsidebar': '固定サイドメニュー',
  'app.setting.fixedsidebar.hint': 'サイドメニューのレイアウト時に設定可能',
  'app.setting.hideheader': 'スクロール時にヘッダーを隠す',
  'app.setting.hideheader.hint': '固定ヘッダー時に設定可能',
  'app.setting.othersettings': 'その他の設定',
  'app.setting.weakmode': '色弱モード',
  'app.setting.copy': '設定をコピー',
  'app.setting.copyinfo': 'コピー成功。config/defaultSettings.jsでデフォルト設定を置き換えてください',
  'app.setting.production.hint':
    '設定バーは開発環境でのみプレビュー用であり、製品環境では表示されません。手動で設定ファイルを変更してください',

  'app.settings.menuMap.basic': '基本設定',
  'app.settings.menuMap.security': 'セキュリティ設定',
  'app.settings.menuMap.binding': 'アカウントバインディング',
  'app.settings.menuMap.notification': '新着通知',
  'app.settings.basic.avatar': 'アバター',
  'app.settings.basic.change-avatar': 'アバターを変更',
  'app.settings.basic.email': 'メール',
  'app.settings.basic.email-message': 'メールアドレスを入力してください！',
  'app.settings.basic.nickname': 'ニックネーム',
  'app.settings.basic.nickname-message': 'ニックネームを入力してください！',
  'app.settings.basic.profile': '自己紹介',
  'app.settings.basic.profile-message': '自己紹介を入力してください！',
  'app.settings.basic.profile-placeholder': '自己紹介',
  'app.settings.basic.country': '国/地域',
  'app.settings.basic.country-message': '国または地域を入力してください！',
  'app.settings.basic.geographic': '所在省市',
  'app.settings.basic.geographic-message': '所在省市を入力してください！',
  'app.settings.basic.address': '街道住所',
  'app.settings.basic.address-message': '街道住所を入力してください！',
  'app.settings.basic.phone': '連絡先電話番号',
  'app.settings.basic.phone-message': '連絡先電話番号を入力してください！',
  'app.settings.basic.update': '基本情報を更新',
  'app.settings.security.strong': '強',
  'app.settings.security.medium': '中',
  'app.settings.security.weak': '弱',
  'app.settings.security.password': 'アカウントパスワード',
  'app.settings.security.password-description': '現在のパスワードの強度',
  'app.settings.security.phone': 'セキュリティ携帯電話',
  'app.settings.security.phone-description': 'バインドされた携帯電話',
  'app.settings.security.question': 'セキュリティ質問',
  'app.settings.security.question-description': 'セキュリティ質問が設定されていません。セキュリティ質問はアカウントの安全を効果的に保護します',
  'app.settings.security.email': '予備メール',
  'app.settings.security.email-description': 'バインドされたメール',
  'app.settings.security.mfa': 'MFAデバイス',
  'app.settings.security.mfa-description': 'MFAデバイスがバインドされていません。バインド後、二次確認ができます',
  'app.settings.security.modify': '変更',
  'app.settings.security.set': '設定',
  'app.settings.security.bind': 'バインド',
  'app.settings.binding.taobao': 'タオバオをバインド',
  'app.settings.binding.taobao-description': '現在タオバオアカウントはバインドされていません',
  'app.settings.binding.alipay': 'アリペイをバインド',
  'app.settings.binding.alipay-description': '現在アリペイアカウントはバインドされていません',
  'app.settings.binding.dingding': 'ディンディンをバインド',
  'app.settings.binding.dingding-description': '現在ディンディンアカウントはバインドされていません',
  'app.settings.binding.bind': 'バインド',
  'app.settings.notification.password': 'アカウントパスワード',
  'app.settings.notification.password-description': '他のユーザーのメッセージはサイト内メッセージで通知されます',
  'app.settings.notification.messages': 'システムメッセージ',
  'app.settings.notification.messages-description': 'システムメッセージはサイト内メッセージで通知されます',
  'app.settings.notification.todo': 'タスク',
  'app.settings.notification.todo-description': 'タスクはサイト内メッセージで通知されます',
  'app.settings.open': '開',
  'app.settings.close': '閉',
  'app.copyright.produced': 'RustDeskチームによって開発されました',
  'pages.account.Settings.Security.Password': 'パスワード',
  'pages.account.Settings.Security.Change password': 'パスワードを変更',
  'pages.account.tfa.Enable 2FA': '2FAを有効にする',
  'pages.account.tfa.2 Factor Authentication is enabled.': '二要素認証が有効になっています',
  'pages.account.tfa.Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.': 'あなたの2FAキーは長期間使用されています。最大のアカウントセキュリティを確保するために、2FAキーを更新することをお勧めします。',
  'pages.account.tfa.Enable 2 Factor Authentication.': '二要素認証を有効にする',

  'Enable Two-Factor Authentication': '二要素認証を有効にする',
  'Make your RustDesk account more secure by requiring an extra authentication code when you log in.': 'ログイン時に追加の認証コードを要求することで、RustDeskアカウントをより安全にします。',
  '1. Download an authenticator app': '1. 認証アプリをダウンロード',
  'Download an authenticator app such as': 'Google AuthenticatorやAuthyなどの認証アプリをダウンロード',
  '2FA code': '2FAコード',
  'Authenticator': '認証器',
  'on your phone or desktop.': 'あなたの電話またはデスクトップで。',
  '2. Scan the QR code': '2. QRコードをスキャン',
  'Open your authenticator app, scan the QR code and enter the code that your app shows.': '認証アプリを開き、QRコードをスキャンして、アプリが表示するコードを入力します。',
  'You can change or remove 2FA at any time in your account settings.': 'アカウント設定でいつでも2FAを変更または削除できます。',
  'Please verify your 2FA code to disable current 2FA first.': '現在の2FAを無効にする前に、2FAコードを確認してください。',
  'Can\'t verify the code. Check that code and local time settings are correct!': 'コードを確認できません。コードとローカル時間設定が正しいか確認してください！',
  'First sign-in:': '最初のサインイン：',
  'Recent activity:': '最近の活動：',
  'pages.Settings.GeoOverride.reloadGeo': '地理位置データを再読み込み',
  'pages.Settings.GeoOverride.getRelayServer': '中継サーバーを取得',
  'pages.Settings.GeoOverride.relayServer': '中継サーバー',
  'SMTP Host': 'SMTPホスト',
  'SMTP Port': 'SMTPポート',
  'My Server Requires Authentication': '私のサーバーは認証が必要です',
  'smtp.From': '送信者',
  'Mail Account': 'メールアカウント',
  'Mail Password': 'メールパスワード',
  'Custom Certificate PEM File Path (on the server)': 'カスタム証明書PEMファイルパス（サーバー上）',
  Update: '更新',
  'Submit and Test Email': 'メールを送信してテスト',
  'he Receiver Email': '受信者メール',
  'LDAP Host': 'LDAPホスト',
  'LDAP Port': 'LDAPポート',
  'Filter': 'フィルター',
  'Username Attribute': 'ユーザー名属性',
  'The attribute used as the username when logging in. Default is "dn".': 'ログイン時にユーザー名として使用される属性。デフォルトは「dn」です。',
  'DraggableTab.Drag tab to reorder': 'タブをドラッグして順序を変更',
  'pages.Settings.OIDC.new.Auth Type': '認証タイプ',
  'pages.Settings.OIDC.New Auth Provider': '新しい認証プロバイダー',
  'pages.Settings.OIDC.new.New Auth Provider': '新しい認証プロバイダー',
  'pages.Settings.OIDC.Delete This Provider': 'このプロバイダーを削除',
  'pages.Settings.OIDC.new.Enable PKCE': 'PKCEを有効にする',
  'pages.Settings.OIDC.Enable PKCE': 'PKCEを有効にする',
  'pages.Settings.OIDC.new.Timeout(seconds) to force a refresh of metadata(e.g. JWKS) from OIDC providers': 'OIDCプロバイダーからメタデータ（例：JWKS）を強制的に更新するためのタイムアウト（秒）',
  'pages.Settings.OIDC.new.0 means no timeout, empty means default (3600)': '0はタイムアウトなし、空はデフォルト（3600）を意味します',
  'Callback URL': 'コールバックURL',
  'pages.Settings.Others.Session expiration time (Day)': 'セッションの有効期限（日）',
  'Or': 'または',
};
