export default {
  'app.title': 'RustDesk Konsole',
  'navBar.lang': 'Sprache',
  'layout.user.link.help': 'Hilfe',
  'layout.user.link.privacy': 'Datenschutz',
  'layout.user.link.terms': 'Nutzungsbedingungen',
  'app.preview.down.block': 'Diese Seite zum lokalen Projekt herunterladen',
  'app.welcome.link.fetch-blocks': 'Alle Blöcke abrufen',
  'app.welcome.link.block-list': 'Schnelle Entwicklung von Standardseiten basierend auf Blöcken',
  'The verification code is incorrect or has expired': 'Der Verifizierungscode ist falsch oder abgelaufen',
  Type: 'Typ',
  Remote: 'Ferngerät',
  remoteTip: 'Ferngesteuerter Computer oder anderes Endgerät',
  Local: 'Lokales Gerät',
  'Start Time': 'Startzeit',
  'End Time': 'Endzeit',
  Note: 'Notiz',
  'Log List': 'Protokollliste',
  'Device List': 'Geräteliste',
  'User List': 'Benutzerliste',
  'User Name': 'Benutzername',
  Updating: 'Wird aktualisiert',
  Adding: 'Wird hinzugefügt',
  Deleting: 'Wird gelöscht',
  Create: 'Erstellen',
  Add: 'Hinzufügen',
  Confirm: 'Bestätigen',
  Submit: 'Absenden',
  Warning: 'Warnung',
  Error: 'Fehler',
  Email: 'E-Mail',
  Status: 'Status',
  Action: 'Aktion',
  Password: 'Passwort',
  'New Password': 'Neues Passwort',
  Edit: 'Bearbeiten',
  Normal: 'Normal',
  Disabled: 'Deaktiviert',
  Disable: 'Deaktivieren',
  Enable: 'Aktivieren',
  Chosen: 'Ausgewählt',
  Items: 'Elemente',
  User: 'Benutzer',
  Info: 'Information',
  'Log out': 'Abmelden',
  Settings: 'Einstellungen',
  'Basic Settings': 'Grundeinstellungen',
  'Remote Desktop': 'Fernzugriff',
  'File Transfer': 'Dateiübertragung',
  'Port Transfer': 'Portweiterleitung',
  'View Camera': 'Kamera anzeigen',
  'Not Logged In': 'Nicht angemeldet',
  'Created Time': 'Erstellungszeit',
  Delete: 'Löschen',
  Group: 'Gruppe',
  'Device Group': 'Gerätegruppe',
  'User Group': 'Benutzergruppe',
  Name: 'Name',
  'Group List': 'Gruppenliste',
  deleteConfirm: 'Möchten Sie wirklich löschen?',
  groupTip: 'Benutzer in derselben Gruppe können auf einander zugreifen',
  document: 'Dokumentation',
  Detail: 'Details',
  Direction: 'Richtung',
  File: 'Datei',
  Directory: 'Verzeichnis',
  Disconnect: 'Verbindung trennen',
  Time: 'Zeit',
  'Export as csv': 'Als CSV exportieren',
  exportCsvTip: 'Maximal 1000 Einträge können gleichzeitig exportiert werden',
  'Not binded only': 'Nur nicht zugeordnete Geräte anzeigen',
  Close: 'Schließen',
  'Default Strategy': 'Standardstrategie',
  'applied device num': 'Anzahl angewendeter Geräte',
  'Device Name': 'Gerätename',
  'Strategy': 'Strategie',
  'Device Strategy': 'Gerätestrategie',
  'User Strategy': 'Benutzerstrategie',
  'Group Strategy': 'Gruppenstrategie',
  'strategy_tooltip': 'Format: "Gerätestrategie / Benutzerstrategie / Gerätegruppenstrategie". Nur "-" bedeutet, dass die Standardstrategie verwendet wird, "-" an einer Position bedeutet, dass keine Strategie auf dieser Ebene festgelegt ist',
  Search: 'Suchen',
  Select: 'Auswählen',
  Assign: 'Zuweisen',
  Device: 'Gerät',
  'Device Username': 'Gerätebenutzername',
  Role: 'Rolle',
  'Auth Type': 'Authentifizierungstyp',
  Invite: 'Einladen',
  'Please configure Email SMTP settings first': 'Bitte konfigurieren Sie zuerst die E-Mail-SMTP-Einstellungen',
  'Security Settings': 'Sicherheitseinstellungen',
  'Manage Signed Devices': 'Signierte Geräte verwalten',
  'Since 2FA is enabled, the email login verification option is not used. 2FA login verification will be used instead.': 'Da 2FA aktiviert ist, wird die E-Mail-Anmeldeverifizierung nicht verwendet. Stattdessen wird die 2FA-Anmeldeverifizierung verwendet.',
  'Enable alarm event email notification': 'E-Mail-Benachrichtigung für Alarmereignisse aktivieren',
  'Enable email login verification': 'E-Mail-Anmeldeverifizierung aktivieren',
  'License Type': 'Lizenztyp',
  'Renew / Upgrade License': 'Lizenz erneuern / upgraden',
  Save: 'Speichern',
  Back: 'Zurück',
  'Confirm Password': 'Passwort bestätigen',
  'username@device_name': 'benutzername@gerätename',
  'Hide username': 'Benutzername ausblenden',
  'Remote device': 'Ferngerät',
  'Within-group connections': 'Verbindungen innerhalb der Gruppe',
  'Cross-group connections': 'Gruppenübergreifende Verbindungen',
  welcomeTitle: 'Willkommen bei RustDesk',
  Cancel: 'Abbrechen',
  OK: 'OK',

  'pages.GroupList.User Group': 'Benutzergruppe',
  'pages.GroupList.User': 'Benutzer',

  'pages.CustomClient.Application name': 'Anwendungsname',
  'pages.CustomClient.Name': 'Name',
  'pages.CustomClient.Build': 'Build',
  'pages.CustomClient.Note': 'Notiz',
  'pages.CustomClient.Action': 'Aktion',
  'pages.CustomClient.Delete': 'Löschen',
  'pages.CustomClient.Edit': 'Bearbeiten',
  'pages.CustomClient.Duplicate': 'Duplizieren',
  'pages.CustomClient.Create': 'Erstellen',
  'pages.CustomClient.Custom Clients': 'Benutzerdefinierte Clients',
  'pages.CustomClient.General': 'Allgemein',
  'pages.CustomClient.Name of the configuration': 'Name der Konfiguration',
  'pages.CustomClient.MSI installer': 'MSI-Installer',
  'pages.CustomClient.Connection type': 'Verbindungstyp',
  'pages.CustomClient.Bidirectional': 'Bidirektional',
  'pages.CustomClient.Incoming': 'Eingehend',
  'pages.CustomClient.Outgoing': 'Ausgehend',
  'pages.CustomClient.Options': 'Optionen',
  'pages.CustomClient.Disable installation': 'Installation deaktivieren',
  'pages.CustomClient.Disable settings': 'Einstellungen deaktivieren',
  'pages.CustomClient.Disable address book': 'Adressbuch deaktivieren',
  'pages.CustomClient.Disable TCP listen port': 'TCP-Listening-Port deaktivieren',
  'pages.CustomClient.Disable user account': 'Benutzerkonto deaktivieren',
  'pages.CustomClient.Some note about this configuration': 'Hinweis zu dieser Konfiguration',
  'pages.CustomClient.Custom server': 'Benutzerdefinierter Server',
  'pages.CustomClient.Visual': 'Visuell',
  'pages.CustomClient.Application icon': 'Anwendungssymbol',
  'pages.CustomClient.Security': 'Sicherheit',
  'pages.CustomClient.Preset password': 'Voreingestelltes Passwort',
  'pages.CustomClient.Advanced': 'Erweitert',
  'pages.CustomClient.Override settings': 'Einstellungen überschreiben',
  'pages.CustomClient.Default settings': 'Standardeinstellungen',
  'pages.CustomClient.Note: ': 'Hinweis: ',
  'pages.CustomClient.Click or drag to upload': 'Klicken oder ziehen zum Hochladen',
  'pages.CustomClient.app_icon_desc_tip': 'Laden Sie ein quadratisches PNG-Bild mit 512x512 Pixeln als Anwendungssymbol hoch.',
  'pages.CustomClient.app_name_desc_tip': 'Geben Sie einen eindeutigen Namen an, der im Startmenü, in der Systemsteuerung usw. für den installierten RustDesk-Client angezeigt wird.',
  'pages.CustomClient.logo_desc_tip': 'Laden Sie ein PNG-Bild hoch, das oben im Hauptfenster des benutzerdefinierten Clients angezeigt wird. Die Größe wird automatisch angepasst. Wir empfehlen ein PNG mit 200x40 Pixeln.',
  'pages.CustomClient.This password will be used as a default permanent password for all new clients. It can be changed by the client.': 'Dieses Passwort wird als Standard-Dauerkennwort für alle neuen Clients verwendet. Es kann vom Client geändert werden.',
  'pages.CustomClient.The application icon must be a PNG file.': 'Das Anwendungssymbol muss eine PNG-Datei sein.',
  'component.tagSelect.expand': 'Erweitern',
  'component.tagSelect.collapse': 'Einklappen',
  'component.tagSelect.all': 'Alle',
  'component.LicenseAlert.Your license is invalid.': 'Ihre Lizenz ist ungültig.',
  'component.LicenseAlert.Your license is not set.': 'Ihre Lizenz ist nicht festgelegt.',
  'component.LicenseAlert.Your license is about to expire in 2 weeks.': 'Ihre Lizenz läuft in 2 Wochen ab.',
  'component.LicenseAlert.Your license has expired.': 'Ihre Lizenz ist abgelaufen.',
  'pages.CustomClient.Refresh status manually': 'Status manuell aktualisieren',
  'pages.CustomClient.the building task': 'Die Build-Aufgabe',
  'pages.CustomClient.Stop': 'Stoppen',
  'pages.CustomClient.Cancel': 'Abbrechen',
  'pages.CustomClient.Completed at %s, this file will be deleted in 1 day.': 'Abgeschlossen am %s, diese Datei wird in 1 Tag gelöscht.',

  'component.globalHeader.search': 'Suche',
  'component.globalHeader.help': 'Dokumentation',
  'component.globalHeader.notification': 'Benachrichtigungen',
  'component.globalHeader.notification.empty': 'Sie haben alle Benachrichtigungen angesehen',
  'component.globalHeader.message': 'Nachrichten',
  'component.globalHeader.message.empty': 'Sie haben alle Nachrichten gelesen',
  'component.globalHeader.event': 'Aufgaben',
  'component.globalHeader.event.empty': 'Sie haben alle Aufgaben erledigt',
  'component.noticeIcon.clear': 'Löschen',
  'component.noticeIcon.cleared': 'Gelöscht',
  'component.noticeIcon.empty': 'Keine Daten',
  'component.noticeIcon.view-more': 'Mehr anzeigen',
  'Token List': 'Token-Liste',
  Description: 'Beschreibung',
  'Device Permission': 'Geräteberechtigung',
  'Audit Log Permission': 'Audit-Log-Berechtigung',
  'User Permission': 'Benutzerberechtigung',
  'Group Permission': 'Gruppenberechtigung',
  'Strategy Permission': 'Strategieberechtigung',

  'menu.welcome': 'Willkommen',
  'menu.more-blocks': 'Weitere Blöcke',
  'menu.home': 'Startseite',
  'menu.admin': 'Admin',
  'menu.admin.sub-page': 'Unterseite',
  'menu.login': 'Anmelden',
  'menu.register': 'Registrieren',
  'menu.register-result': 'Registrierungsergebnis',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analyse',
  'menu.dashboard.monitor': 'Überwachung',
  'menu.dashboard.workplace': 'Arbeitsplatz',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Formulare',
  'menu.form.basic-form': 'Grundformular',
  'menu.form.step-form': 'Schrittformular',
  'menu.form.step-form.info': 'Schrittformular (Übertragungsinformationen)',
  'menu.form.step-form.confirm': 'Schrittformular (Übertragungsbestätigung)',
  'menu.form.step-form.result': 'Schrittformular (Abgeschlossen)',
  'menu.form.advanced-form': 'Erweitertes Formular',
  'menu.list': 'Listen',
  'menu.list.audit-list': 'Sicherheitsaudit',
  'menu.list.audit-list.Alarm': 'Risiko-/Anomaliealarme',
  'menu.list.audit-list.Connection': 'Verbindungsprotokoll',
  'menu.list.audit-list.File': 'Dateiübertragungsprotokoll',
  'menu.list.audit-list.Console': 'Konsolenoperationsprotokoll',
  'menu.list.device-list': 'Geräte',
  'menu.list.user-list': 'Benutzer',
  'menu.list.group-list': 'Gruppen',
  'menu.list.group-list.user': 'Benutzergruppen',
  'menu.list.group-list.device': 'Gerätegruppen',
  'menu.list.table-list': 'Suchtabelle',
  'menu.list.basic-list': 'Standardliste',
  'menu.list.card-list': 'Kartenliste',
  'menu.list.search-list': 'Suchliste',
  'menu.list.search-list.articles': 'Suchliste (Artikel)',
  'menu.list.search-list.projects': 'Suchliste (Projekte)',
  'menu.list.search-list.applications': 'Suchliste (Anwendungen)',
  'menu.profile': 'Profil',
  'menu.profile.basic': 'Grundprofil',
  'menu.profile.advanced': 'Erweitertes Profil',
  'menu.result': 'Ergebnis',
  'menu.result.success': 'Erfolg',
  'menu.result.fail': 'Fehler',
  'menu.exception': 'Ausnahmen',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Fehler auslösen',
  'menu.account': 'Konto',
  'menu.account.center': 'Kontocenter',
  'menu.account.settings': 'Kontoeinstellungen',
  'menu.account.trigger': 'Fehler auslösen',
  'menu.account.logout': 'Abmelden',
  'menu.editor': 'Grafikeditor',
  'menu.editor.flow': 'Flusseditor',
  'menu.editor.mind': 'Mind-Map-Editor',
  'menu.editor.koni': 'Topologie-Editor',
  'menu.Settings': 'Einstellungen',
  'menu.Strategy': 'Strategie',
  'menu.Settings.Key': 'Schlüssel',
  'menu.Settings.License': 'Lizenz',
  'menu.Settings.Relay': 'Relay-Verwaltung',
  'menu.Settings.Others': 'Sonstiges',
  'menu.Settings.Tokens': 'Tokens',
  'menu.Address Books': 'Adressbücher',
  'menu.Strategies': 'Strategien',
  'menu.Custom Clients': 'Benutzerdefinierte Clients',

  'pages.login.accountLogin.tab': 'Anmeldung mit Konto',
  'pages.login.accountLogin.errorMessage': 'Falscher Benutzername oder Passwort',
  'pages.login.failure': 'Anmeldung fehlgeschlagen, bitte versuchen Sie es erneut!',
  'pages.login.success': 'Anmeldung erfolgreich!',
  'pages.login.username.placeholder': 'Benutzername: admin oder user',
  'pages.login.username.required': 'Bitte geben Sie Ihren Benutzernamen ein!',
  'pages.login.password.placeholder': 'Passwort: ant.design',
  'pages.login.password.required': 'Bitte geben Sie Ihr Passwort ein!',
  'pages.login.phoneLogin.tab': 'Anmeldung mit Telefon',
  'pages.login.phoneLogin.errorMessage': 'Falscher Verifizierungscode',
  'pages.login.phoneNumber.placeholder': 'Bitte geben Sie Ihre Telefonnummer ein!',
  'pages.login.phoneNumber.required': 'Bitte geben Sie Ihre Telefonnummer ein!',
  'pages.login.phoneNumber.invalid': 'Ungültige Telefonnummer!',
  'pages.login.captcha.placeholder': 'Bitte geben Sie den Verifizierungscode ein!',
  'pages.login.captcha.required': 'Bitte geben Sie den Verifizierungscode ein!',
  'pages.login.phoneLogin.getVerificationCode': 'Code abrufen',
  'pages.getCaptchaSecondText': 'Sekunden bis zur erneuten Anforderung',
  'pages.login.rememberMe': 'Angemeldet bleiben',
  'pages.login.forgotPassword': 'Passwort vergessen?',
  'pages.login.submit': 'Anmelden',
  'pages.login.loginWith': 'Anmelden mit:',
  'pages.login.loginWithGoogle': 'Anmelden mit Google',
  'pages.login.loginWithGithub': 'Anmelden mit Github',
  'pages.login.loginWithOkta': 'Anmelden mit Okta',
  'pages.login.loginWith.requesting_auth': 'Autorisierungsseite wird angefordert',
  'pages.login.loginWith.waiting_auth': 'Bitte schließen Sie die Kontoautorisierung ab',
  'pages.login.registerAccount': 'Konto erstellen',
  'pages.welcome.advancedComponent': 'Erweiterte Komponente',
  'pages.welcome.link': 'Willkommen',
  'pages.welcome.advancedLayout': 'Erweitertes Layout',
  'pages.welcome.alertMessage': 'Schnellere und leistungsstärkere Komponenten wurden veröffentlicht.',
  'pages.account.tfaComponent.Enable 2FA': '2FA aktivieren',
  'pages.account.tfaComponent.2 Factor Authentication is enabled.': 'Zwei-Faktor-Authentifizierung ist aktiviert',
  'pages.account.tfaComponent.Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.': 'Ihr 2FA-Schlüssel wird seit längerer Zeit verwendet. Wir empfehlen, Ihren 2FA-Schlüssel zu aktualisieren, um maximale Kontosicherheit zu gewährleisten.',
  'pages.account.tfaComponent.Enable 2 Factor Authentication.': 'Zwei-Faktor-Authentifizierung aktivieren.',
  'pages.account.tfa.The code must be 6, 8, 10 digits.': 'Der Code muss 6, 8 oder 10 Ziffern haben.',
  'pages.account.tfa.The code must be 6 or 8 digits.': 'Der Code muss 6 oder 8 Ziffern haben.',
  'pages.admin.subPage.title': 'Diese Seite kann nur von Administratoren angezeigt werden',
  'pages.admin.subPage.alertMessage': 'umi ui ist verfügbar, Sie können mit npm run ui starten.',
  'pages.AuditList.FileAuditList.files': 'Dateien',
  'pages.AuditList.FileAuditList.pathTip': 'Pfad auf dem kontrollierten Gerät',
  'pages.AuditList.FileAuditList.limitedTip': 'Maximal 10 Dateien werden angezeigt',
  'pages.AuditList.FileAuditList.exportFilename': 'Dateiübertragungsprotokoll',
  'pages.AuditList.ConnAuditList.modal_title': 'Aktion bestätigen',
  'pages.AuditList.ConnAuditList.diconnect_confirm_tip': 'Möchten Sie die Verbindung zu diesem Gerät wirklich trennen?',
  'pages.AuditList.ConnAuditList.disconnect_succ': 'Verbindung erfolgreich getrennt!',
  'pages.AuditList.ConnAuditList.disconnect_fail': 'Trennen der Verbindung fehlgeschlagen!',
  'pages.AuditList.ConnAuditList.exportFilename': 'Verbindungsprotokoll',
  'pages.AuditList.AlarmAuditList.Login Device / Local Device': 'Anmeldegerät / Lokales Gerät',
  'pages.AuditList.AlarmAuditList.Login Account / Remote Device': 'Anmeldekonto / Ferngerät',
  'pages.AuditList.AlarmAuditList.Login Account': 'Anmeldekonto',
  'pages.AuditList.AlarmAuditList.Device ID': 'Geräte-ID',
  'pages.AuditList.AlarmAuditList.Device Name': 'Gerätename',
  'pages.AuditList.AlarmAuditList.Remote Device': 'Ferngerät',
  'pages.AuditList.AlarmAuditList.Event Time': 'Ereigniszeit',
  'pages.AuditList.AlarmAuditList.IP whiltelist': 'Zugriffsversuch von IP außerhalb der Whitelist',
  'pages.AuditList.AlarmAuditList.ExceedThirtyAccessAttempts': 'Aufeinanderfolgende Zugriffsversuche (30)',
  'pages.AuditList.AlarmAuditList.MultipleAccessAttemptsWithinOneMinute': 'Mehrere Zugriffsversuche innerhalb einer Minute',
  'pages.AuditList.AlarmAuditList.ExceedThirtyLoginAttempts': 'Aufeinanderfolgende Anmeldeversuche (30)',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneMinute': 'Mehrere Anmeldeversuche innerhalb einer Minute',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneHour': 'Mehrere Anmeldeversuche innerhalb einer Stunde',
  'pages.AuditList.AlarmAuditList.exportFilename': 'Risiko-/Anomaliealarme',
  'pages.AuditList.ConsoleAuditList.Operation': 'Operation',
  'pages.AuditList.ConsoleAuditList.Name': 'Name',
  'pages.AuditList.ConsoleAuditList.Name list': 'Namensliste',
  'pages.AuditList.ConsoleAuditList.ID list': 'ID-Liste',
  'pages.AuditList.ConsoleAuditList.Result': 'Ergebnis',
  'pages.AuditList.ConsoleAuditList.Remote': 'Fern',
  'pages.AuditList.ConsoleAuditList.Local': 'Lokal',
  'pages.AuditList.ConsoleAuditList.User Login': 'Benutzeranmeldung an der Konsole',
  'pages.AuditList.ConsoleAuditList.Add Group': 'Gruppe hinzufügen',
  'pages.AuditList.ConsoleAuditList.Add User': 'Benutzer hinzufügen',
  'pages.AuditList.ConsoleAuditList.Add Device': 'Gerät hinzufügen',
  'pages.AuditList.ConsoleAuditList.Delete Groups': 'Gruppen löschen',
  'pages.AuditList.ConsoleAuditList.Disconnect Device': 'Geräteverbindung trennen',
  'pages.AuditList.ConsoleAuditList.Enable Users': 'Benutzer aktivieren',
  'pages.AuditList.ConsoleAuditList.Disable Users': 'Benutzer deaktivieren',
  'pages.AuditList.ConsoleAuditList.Enable Devices': 'Geräte aktivieren',
  'pages.AuditList.ConsoleAuditList.Disable Devices': 'Geräte deaktivieren',
  'pages.AuditList.ConsoleAuditList.Update Group': 'Gruppe aktualisieren',
  'pages.AuditList.ConsoleAuditList.Update User': 'Benutzer aktualisieren',
  'pages.AuditList.ConsoleAuditList.Update Device': 'Gerät aktualisieren',
  'pages.AuditList.ConsoleAuditList.Delete User': 'Benutzer löschen',
  'pages.AuditList.ConsoleAuditList.Delete Device': 'Gerät löschen',
  'pages.AuditList.ConsoleAuditList.Add Address Book': 'Adressbuch hinzufügen',
  'pages.AuditList.ConsoleAuditList.Delete Address Book': 'Adressbuch löschen',
  'pages.AuditList.ConsoleAuditList.Change Address Book Name': 'Adressbuchname ändern',
  'pages.AuditList.ConsoleAuditList.Group Management': 'Gruppenverwaltung',
  'pages.AuditList.ConsoleAuditList.User Management': 'Benutzerverwaltung',
  'pages.AuditList.ConsoleAuditList.Device Management': 'Geräteverwaltung',
  'pages.AuditList.ConsoleAuditList.Address Book Management': 'Adressbuchverwaltung',
  'pages.AuditList.ConsoleAuditList.exportFilename': 'Konsolenprotokoll',
  'pages.AuditList.ConsoleAuditList.Delete Devices in the Address Book Recycle Bin': 'Geräte im Adressbuch-Papierkorb löschen',
  'pages.AuditList.ConsoleAuditList.Empty Address Book Recycle Bin': 'Adressbuch-Papierkorb leeren',
  'pages.AuditList.ConsoleAuditList.Add Address Book Permission': 'Adressbuch-Berechtigung hinzufügen',
  'pages.AuditList.ConsoleAuditList.Delete Address Book Permission': 'Adressbuch-Berechtigung löschen',
  'pages.AuditList.ConsoleAuditList.Update Address Book Permission': 'Adressbuch-Berechtigung aktualisieren',
  'pages.Settings.Strategy.Strategy list': 'Strategieliste',
  'pages.Settings.Strategy.Automatically close incoming sessions on user inactivity': 'Eingehende Sitzungen bei Benutzerinaktivität automatisch schließen',
  'pages.Settings.Strategy.delete_strategy_tip': 'Sind Sie sicher, dass Sie diese Strategie löschen möchten?',
  'pages.Settings.Strategy.New strategy': 'Neue Strategie',
  'pages.Settings.Strategy.Rename strategy': 'Strategie umbenennen',
  'pages.Settings.Strategy.Duplicate strategy': 'Strategie duplizieren',
  'pages.Settings.Strategy.Edit Devices': 'Geräte bearbeiten',
  'pages.Settings.Strategy.Edit Users': 'Benutzer bearbeiten',
  'pages.Settings.Strategy.Rename': 'Umbenennen',
  'pages.Settings.Strategy.Duplicate': 'Duplizieren',
  'pages.Settings.Strategy.Delete': 'Löschen',
  'pages.Settings.Strategy.Groups': 'Gruppen',
  'pages.Settings.Strategy.Select All': 'Alle auswählen',
  'pages.Settings.Strategy.Permissions': 'Berechtigungen',
  'pages.Settings.Strategy.Password': 'Passwort',
  'pages.Settings.Strategy.Security': 'Sicherheit',
  'pages.Settings.Strategy.Other': 'Sonstiges',
  'pages.Settings.Strategy.IP whitelist': 'IP-Whitelist',
  'pages.Settings.Strategy.Cancel': 'Abbrechen',
  'pages.Settings.Strategy.Submit': 'Bestätigen',
  'pages.Settings.Strategy.Edit': 'Bearbeiten',
  'pages.Settings.Strategy.Custom': 'Benutzerdefiniert',
  'pages.Settings.Strategy.Full access': 'Vollzugriff',
  'pages.Settings.Strategy.Screen share': 'Nur Bildschirmfreigabe',
  'pages.Settings.Strategy.Enable keyboard/mouse': 'Tastatur-/Maussteuerung erlauben',
  'pages.Settings.Strategy.Enable clipboard': 'Zwischenablage-Synchronisation erlauben',
  'pages.Settings.Strategy.Enable file transfer': 'Dateiübertragung erlauben',
  'pages.Settings.Strategy.Enable audio': 'Audioübertragung erlauben',
  'pages.Settings.Strategy.Enable TCP tunneling': 'TCP-Tunneling erlauben',
  'pages.Settings.Strategy.Enable remote restart': 'Fernstartoptionen erlauben',
  'pages.Settings.Strategy.Enable recording session': 'Sitzungsaufzeichnung erlauben',
  'pages.Settings.Strategy.Enable blocking user input': 'Blockieren der Benutzereingabe erlauben',
  'pages.Settings.Strategy.Enable remote configuration modification': 'Fernkonfigurationsänderung erlauben',
  'pages.Settings.Strategy.Permission type': 'Berechtigungstyp',
  'pages.Settings.Strategy.Approve mode': 'Genehmigungsmodus',
  'pages.Settings.Strategy.Password type': 'Passworttyp',
  'pages.Settings.Strategy.Hide connection management window': 'Verbindungsverwaltungsfenster ausblenden',
  'pages.Settings.Strategy.One-time password length': 'Länge des Einmalpassworts',
  'pages.Settings.Strategy.Accept sessions via password': 'Sitzungen nur per Passwort akzeptieren',
  'pages.Settings.Strategy.Accept sessions via click': 'Sitzungen nur per Klick akzeptieren',
  'pages.Settings.Strategy.Accept sessions via both': 'Sitzungen per Passwort oder Klick akzeptieren',
  'pages.Settings.Strategy.Use one-time password': 'Einmalpasswort verwenden',
  'pages.Settings.Strategy.Use permanent password': 'Dauerhaftes Passwort verwenden',
  'pages.Settings.Strategy.Use both passwords': 'Beide Passworttypen verwenden',
  'pages.Settings.Strategy.Deny LAN discovery': 'LAN-Erkennung verweigern',
  'pages.Settings.Strategy.Enable direct IP access': 'Direkten IP-Zugriff erlauben',
  'pages.Settings.Strategy.auto_disconnect_option_tip': 'Inaktive Sitzungen automatisch schließen',
  'pages.Settings.Strategy.deny_lan_discovery_desc_tip': 'Das Gerät wird nicht von anderen Geräten im selben lokalen Netzwerk erkannt',
  'pages.Settings.Strategy.enable_direct_ip_access_desc_tip': 'Direktverbindung per IP zum Gerät erlauben',
  'pages.Settings.Strategy.Remove wallpaper during incoming sessions': 'Hintergrundbild während eingehender Sitzungen entfernen',
  'pages.Settings.Strategy.minutes': 'Minuten',
  'pages.Settings.Strategy.Enable': 'Aktivieren',
  'pages.Settings.Strategy.Disable': 'Deaktivieren',
  'pages.Settings.Strategy.disabled': 'deaktiviert',
  'pages.Settings.Strategy.Default': 'Standardstrategie',
  'pages.Settings.Strategy.Apply changes of this page': 'Änderungen dieser Seite anwenden',
  'pages.Settings.Strategy.Invalid IP': 'Ungültige IP',
  'pages.Settings.Strategy.IP Whitelisting': 'IP-Whitelisting',
  'pages.Settings.Strategy.Clear': 'Löschen',
  'pages.Settings.Strategy.OK': 'OK',
  'pages.Settings.Strategy.whitelist_sep': 'Sie können Kommas, Semikolons, Leerzeichen oder Zeilenumbrüche als Trennzeichen verwenden',
  'pages.Settings.Strategy.Use IP whitelisting': 'Nur IPs aus der Whitelist zulassen',
  'pages.Settings.Strategy.whitelist_tip': 'Nur IPs aus der Whitelist können auf dieses Gerät zugreifen',
  'pages.CustomClient.bidirectional_desc_tip': 'Erstellen Sie einen Client, der sich mit einem anderen Gerät verbinden und Verbindungsanfragen empfangen kann',
  'pages.CustomClient.incoming_desc_tip': 'Erstellen Sie einen Client, der nur Verbindungsanfragen empfangen kann',
  'pages.CustomClient.outgoing_desc_tip': 'Erstellen Sie einen Client, der sich mit einem anderen Gerät verbinden kann',
  'pages.CustomClient.disable_installation_desc_tip': 'Benutzer daran hindern, den benutzerdefinierten Client zu installieren (nur Windows)',
  'pages.CustomClient.disable_settings_desc_tip': 'Zugriff auf Einstellungen im benutzerdefinierten Client deaktivieren',
  'pages.CustomClient.disable_ab_desc_tip': 'Adressbuchfunktion im benutzerdefinierten Client deaktivieren',
  'pages.CustomClient.disable_tcp_desc_tip': 'Vermeiden Sie den Windows-Firewall-Dialog beim Start von RustDesk, was direkte P2P-Verbindungsversuche überspringt und direkt die Relay-Verbindung verwendet',
  'pages.CustomClient.disable_account_desc_tip': 'Entfernen Sie die Kontoanmeldefunktion im RustDesk-Client',
  'pages.CustomClient.override_settings_desc_tip': 'Einstellungen überschreiben, können in RustDesk nicht geändert werden',
  'pages.CustomClient.default_settings_desc_tip': 'Ändern Sie die Standardeinstellungen, die von RustDesk verwendet werden',
  'pages.Settings.KeyPage.failedToFetchKey': 'Schlüssel konnte nicht abgerufen werden.',
  'pages.Settings.KeyPage.keyResetSuccess': 'Schlüsselreset erfolgreich, bitte starten Sie hbbr neu, um die Änderungen zu übernehmen.',
  'pages.Settings.KeyPage.errorResettingKey': 'Fehler beim Zurücksetzen des Schlüssels.',
  'pages.Settings.KeyPage.keyUpdateSuccess': 'Schlüsselaktualisierung erfolgreich, bitte starten Sie hbbr neu, um die Änderungen zu übernehmen.',
  'pages.Settings.KeyPage.errorUpdatingKey': 'Fehler beim Aktualisieren des Schlüssels, bitte überprüfen Sie den Schlüssel',
  'pages.Settings.KeyPage.key': 'Schlüssel',
  'pages.Settings.KeyPage.privateKey': 'Privater Schlüssel',
  'pages.Settings.KeyPage.publicKey': 'Öffentlicher Schlüssel',
  'pages.Settings.KeyPage.editKey': 'Schlüssel aktualisieren',
  'pages.Settings.KeyPage.reset': 'Zurücksetzen',
  'pages.Settings.KeyPage.custom': 'Manuell eingeben',
  'pages.Settings.KeyPage.pleaseInputPrivateKey': 'Bitte geben Sie Ihren privaten Schlüssel ein!',
  'pages.Settings.License.settingsUpdatedSuccess': 'Einstellungen erfolgreich aktualisiert.',
  'pages.Settings.License.licenseCannotBeEmpty': 'Lizenz darf nicht leer sein.',
  'pages.Settings.License.license': 'Lizenz',
  'pages.Settings.Relay.GeoOverrideDescription': 'Die Standortüberschreibungsfunktion kann nur verwendet werden, wenn mehrere Relay-Server vorhanden sind.',
  'pages.Settings.GeoOverride.ipOrDomain': 'IP/Domain',
  'pages.Settings.GeoOverride.latitude': 'Breitengrad',
  'pages.Settings.GeoOverride.longitude': 'Längengrad',
  'pages.Settings.GeoOverride.deleteSelectedConfirm': 'Sind Sie sicher, dass Sie die ausgewählten Standortüberschreibungen löschen möchten?',
  'pages.Settings.GeoOverride.deleteConfirm': 'Sind Sie sicher, dass Sie diese Standortüberschreibung löschen möchten?',
  'pages.Settings.GeoOverride.editGeoOverride': 'Überschreibung bearbeiten',
  'pages.Settings.GeoOverride.addGeoOverride': 'Überschreibung hinzufügen',
  'pages.Settings.GeoOverride.ipOrDomainRequired': 'IP/Domain darf nicht leer sein.',
  'pages.Settings.GeoOverride.latitudeRequired': 'Breitengrad darf nicht leer sein.',
  'pages.Settings.GeoOverride.longitudeRequired': 'Längengrad darf nicht leer sein.',
  'pages.Settings.GeoOverride.testGeo': 'Standort testen',
  'pages.Settings.GeoOverride.geofileFetchFailed': 'GeoFile-Informationen konnten nicht abgerufen werden.',
  'pages.Settings.GeoOverride.loadGeoOverrides': 'Überschreibungen laden',
  'pages.Settings.GeoOverride.geoOverride': 'Standortüberschreibung',
  'pages.Settings.GeoOverride.title': 'Standortüberschreibung',
  'pages.Settings.GeoOverride.RelaySettings': 'Einstellungen',
  'pages.Settings.GeoOverride.RelayServers': 'Relay-Server',
  'pages.Settings.GeoOverride.AddServer': 'Server hinzufügen',
  'pages.Settings.GeoOverride.AlwaysUseRelay': 'Immer Relay verwenden',
  'pages.Settings.GeoOverride.UpdateServers': 'Änderungen importieren',
  'pages.Settings.GeoOverride.UpdatedAlwaysUseRelay': '"Immer Relay verwenden" aktualisiert',
  'pages.Settings.GeoOverride.FailedToUpdateAlwaysUseRelay': 'Aktualisierung von "Immer Relay verwenden" fehlgeschlagen',
  'pages.Settings.GeoOverride.UpdatedRelayServers': 'Relay-Server aktualisiert',
  'pages.Settings.GeoOverride.FailedToUpdateRelayServers': 'Aktualisierung der Relay-Server fehlgeschlagen',
  'pages.Settings.GeoOverride.UpdatedChangeIDSetting': 'ID-Änderungseinstellung aktualisiert',
  'pages.Settings.GeoOverride.FailedToUpdateChangeIDSetting': 'Aktualisierung der ID-Änderungseinstellung fehlgeschlagen',
  "pages.Settings.Others.Allow change ID": "ID-Änderung erlauben",
  "pages.Settings.Others.Disable new devices": "Neue Geräte deaktivieren",
  "pages.Settings.Others.The control end need to login before access": "Steuerende muss sich vor dem Zugriff anmelden",
  "pages.Settings.Others.Only admin can access unassigned devices": "Nur Admin kann auf nicht zugeordnete Geräte zugreifen",
  "pages.Settings.Others.Allow non-admin to see other groups": "Nicht-Admins erlauben, andere Gruppen zu sehen",
  "pages.Settings.Others.allow_non_admin_see_other_group_tip": "Wenn diese Option deaktiviert ist, können Nicht-Admins Adressbücher nur innerhalb ihrer eigenen Gruppe teilen.",
  'pages.Settings.OIDC.Maximum Number of Authorized Users': 'Maximale Anzahl autorisierter Benutzer',
  'pages.Settings.OIDC.emptyPrompt': 'Derzeit keine Drittanbieter-Authentifizierungsanbieter, bitte fügen Sie einen hinzu, um diese Funktion zu nutzen.',
  'pages.Settings.OIDC.confirm-delete-title': 'Löschen bestätigen',
  'pages.Settings.OIDC.confirm-delete-content': 'Benutzer, die durch diesen Drittanbieter autorisiert wurden, können sich nicht mehr anmelden. Sie können die Benutzerverwaltungsschnittstelle aufrufen, um diese Benutzer zu löschen.',
  'pages.Settings.Others.Only admin can access logs': 'Nur Admin kann auf nicht zugewiesene Protokolle zugreifen',
  'pages.Welcome.Max Devices': 'Maximale Geräteanzahl',
  'pages.Welcome.Max Users': 'Maximale Benutzeranzahl',
  'pages.Welcome.Not set': 'Nicht festgelegt',
  'pages.Welcome.Invalid': 'Ungültig',
  'pages.Welcome.Demo': 'Demo',
  'pages.Welcome.Personal': 'Persönlich',
  'pages.Welcome.Business': 'Business',
  'pages.Welcome.Unknown': 'Andere',
  'pages.Welcome.Max Peers': 'Maximale Geräteanzahl',
  'pages.Welcome.RustDesk Server Status': 'RustDesk-Serverstatus',
  'pages.Welcome.License Expire Time': 'Ablaufzeit der Lizenz',
  'pages.Welcome.Document': 'Dokumentation',
  'pages.AddressBook.Permission': 'Berechtigung',
  'pages.AddressBook.Device count': 'Geräteanzahl',
  'pages.AddressBook.Address Books': 'Adressbücher',
  'pages.AddressBook.Tags': 'Tags',
  'pages.AddressBook.Devices': 'Geräte',
  'pages.AddressBook.Device': 'Gerät',
  'pages.AddressBook.Alias': 'Alias',
  'pages.AddressBook.Whether a password is set': 'Ob ein Passwort festgelegt ist',
  'pages.AddressBook.Recycle Bin': 'Papierkorb',
  'pages.AddressBook.Empty Recycle Bin': 'Papierkorb leeren',
  'pages.AddressBook.Personal': 'Persönlich',
  'pages.AddressBook.Personal (My address book)': 'Persönlich (Mein Adressbuch)',
  'pages.AddressBook.Shared': 'Geteilt',
  'pages.AddressBook.Share': 'Teilen',
  'pages.AddressBook.Delete': 'Löschen',
  'pages.AddressBook.Settings': 'Einstellungen',
  'pages.AddressBook.Owner': 'Besitzer',
  'pages.AddressBook.My Permission': 'Meine Berechtigung',
  'pages.AddressBook.Read-only': 'Nur Lesen',
  'pages.AddressBook.Read/Write': 'Lesen/Schreiben',
  'pages.AddressBook.Full Control': 'Vollzugriff',
  'pages.AddressBook.share_warning_tip': 'Die obigen Felder werden geteilt und sind für andere sichtbar.',
  'pages.AddressBook.permission_tip': 'Nur Lesen: kann Geräte und Tags sehen; Lesen/Schreiben: kann Geräte und Tags bearbeiten; Vollzugriff: kann Adressbuch erneut teilen, löschen oder umbenennen.',
  'pages.AddressBook.rule_priority_tip': 'Priorität: Benutzer > Gruppe > Alle',
  'pages.AddressBook.Remove password': 'Passwort entfernen',
  'pages.AddressBook.Import': 'Importieren',
  'pages.AddressBook.Filter by intersection': 'Nach Überschneidung filtern',
  'pages.AddressBook.Permissions': 'Berechtigungen',
  'pages.AddressBook.Type': 'Typ',
  'pages.AddressBook.Untagged': 'Ohne Tag',

  'app.pwa.offline': 'Sie sind jetzt offline',
  'app.pwa.serviceworker.updated': 'Neue Inhalte verfügbar',
  'app.pwa.serviceworker.updated.hint': 'Bitte klicken Sie auf die Schaltfläche "Aktualisieren" oder aktualisieren Sie die Seite manuell',
  'app.pwa.serviceworker.updated.ok': 'Aktualisieren',

  'app.setting.pagestyle': 'Globaler Stil-Einstellungen',
  'app.setting.pagestyle.dark': 'Dunkler Menüstil',
  'app.setting.pagestyle.light': 'Heller Menüstil',
  'app.setting.content-width': 'Inhaltsbereichsbreite',
  'app.setting.content-width.fixed': 'Fixiert',
  'app.setting.content-width.fluid': 'Fließend',
  'app.setting.themecolor': 'Themenfarbe',
  'app.setting.themecolor.dust': 'Staub',
  'app.setting.themecolor.volcano': 'Vulkan',
  'app.setting.themecolor.sunset': 'Sonnenuntergang',
  'app.setting.themecolor.cyan': 'Cyan',
  'app.setting.themecolor.green': 'Polarlicht Grün',
  'app.setting.themecolor.daybreak': 'Tagesanbruch Blau (Standard)',
  'app.setting.themecolor.geekblue': 'Geek Blau',
  'app.setting.themecolor.purple': 'Lila',
  'app.setting.navigationmode': 'Navigationsmodus',
  'app.setting.sidemenu': 'Seitenmenü-Layout',
  'app.setting.topmenu': 'Oberes Menü-Layout',
  'app.setting.fixedheader': 'Feste Kopfzeile',
  'app.setting.fixedsidebar': 'Festes Seitenmenü',
  'app.setting.fixedsidebar.hint': 'Funktioniert mit Seitenmenü-Layout',
  'app.setting.hideheader': 'Kopfzeile beim Scrollen ausblenden',
  'app.setting.hideheader.hint': 'Funktioniert, wenn die Kopfzeile fixiert ist',
  'app.setting.othersettings': 'Andere Einstellungen',
  'app.setting.weakmode': 'Farbenblindmodus',
  'app.setting.copy': 'Einstellungen kopieren',
  'app.setting.copyinfo': 'Erfolgreich kopiert, bitte ersetzen Sie die Standardkonfiguration in config/defaultSettings.js',
  'app.setting.production.hint':
    'Die Konfigurationsleiste wird nur in der Entwicklungsumgebung für die Vorschau angezeigt. Bitte ändern Sie die Konfigurationsdatei manuell in der Produktion.',

  'app.settings.menuMap.basic': 'Grundeinstellungen',
  'app.settings.menuMap.security': 'Sicherheitseinstellungen',
  'app.settings.menuMap.binding': 'Kontoverknüpfung',
  'app.settings.menuMap.notification': 'Neue Nachrichtenbenachrichtigungen',
  'app.settings.basic.avatar': 'Avatar',
  'app.settings.basic.change-avatar': 'Avatar ändern',
  'app.settings.basic.email': 'E-Mail',
  'app.settings.basic.email-message': 'Bitte geben Sie Ihre E-Mail ein!',
  'app.settings.basic.nickname': 'Nickname',
  'app.settings.basic.nickname-message': 'Bitte geben Sie Ihren Nickname ein!',
  'app.settings.basic.profile': 'Persönliches Profil',
  'app.settings.basic.profile-message': 'Bitte geben Sie Ihr persönliches Profil ein!',
  'app.settings.basic.profile-placeholder': 'Persönliches Profil',
  'app.settings.basic.country': 'Land/Region',
  'app.settings.basic.country-message': 'Bitte geben Sie Ihr Land oder Ihre Region ein!',
  'app.settings.basic.geographic': 'Provinz und Stadt',
  'app.settings.basic.geographic-message': 'Bitte geben Sie Ihre Provinz und Stadt ein!',
  'app.settings.basic.address': 'Adresse',
  'app.settings.basic.address-message': 'Bitte geben Sie Ihre Adresse ein!',
  'app.settings.basic.phone': 'Telefon',
  'app.settings.basic.phone-message': 'Bitte geben Sie Ihre Telefonnummer ein!',
  'app.settings.basic.update': 'Grundinformationen aktualisieren',
  'app.settings.security.strong': 'Stark',
  'app.settings.security.medium': 'Mittel',
  'app.settings.security.weak': 'Schwach',
  'app.settings.security.password': 'Kontopasswort',
  'app.settings.security.password-description': 'Aktuelle Passwortstärke',
  'app.settings.security.phone': 'Sicherheitstelefon',
  'app.settings.security.phone-description': 'Verknüpftes Telefon',
  'app.settings.security.question': 'Sicherheitsfrage',
  'app.settings.security.question-description': 'Sicherheitsfrage nicht eingerichtet, die effektiv die Kontosicherheit schützen kann',
  'app.settings.security.email': 'Backup-E-Mail',
  'app.settings.security.email-description': 'Verknüpfte E-Mail',
  'app.settings.security.mfa': 'MFA-Gerät',
  'app.settings.security.mfa-description': 'MFA-Gerät nicht verknüpft, nach der Verknüpfung kann eine sekundäre Verifizierung durchgeführt werden',
  'app.settings.security.modify': 'Ändern',
  'app.settings.security.set': 'Einstellen',
  'app.settings.security.bind': 'Verknüpfen',
  'app.settings.binding.taobao': 'Mit Taobao verknüpfen',
  'app.settings.binding.taobao-description': 'Taobao-Konto derzeit nicht verknüpft',
  'app.settings.binding.alipay': 'Mit Alipay verknüpfen',
  'app.settings.binding.alipay-description': 'Alipay-Konto derzeit nicht verknüpft',
  'app.settings.binding.dingding': 'Mit DingTalk verknüpfen',
  'app.settings.binding.dingding-description': 'DingTalk-Konto derzeit nicht verknüpft',
  'app.settings.binding.bind': 'Verknüpfen',
  'app.settings.notification.password': 'Kontopasswort',
  'app.settings.notification.password-description': 'Nachrichten von anderen Benutzern werden als interne Nachrichten benachrichtigt',
  'app.settings.notification.messages': 'Systemnachrichten',
  'app.settings.notification.messages-description': 'Systemnachrichten werden als interne Nachrichten benachrichtigt',
  'app.settings.notification.todo': 'Aufgaben',
  'app.settings.notification.todo-description': 'Aufgaben werden als interne Nachrichten benachrichtigt',
  'app.settings.open': 'Ein',
  'app.settings.close': 'Aus',
  'app.copyright.produced': 'Entwickelt vom RustDesk-Team',
  'pages.account.Settings.Security.Password': 'Passwort',
  'pages.account.Settings.Security.Change password': 'Passwort ändern',
  'pages.account.tfa.Enable 2FA': '2FA aktivieren',
  'pages.account.tfa.2 Factor Authentication is enabled.': 'Zwei-Faktor-Authentifizierung ist aktiviert',
  'pages.account.tfa.Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.': 'Ihr 2FA-Schlüssel wird seit längerer Zeit verwendet. Wir empfehlen, Ihren 2FA-Schlüssel zu aktualisieren, um maximale Kontosicherheit zu gewährleisten.',
  'pages.account.tfa.Enable 2 Factor Authentication.': 'Zwei-Faktor-Authentifizierung aktivieren.',

  'Enable Two-Factor Authentication': 'Zwei-Faktor-Authentifizierung aktivieren',
  'Make your RustDesk account more secure by requiring an extra authentication code when you log in.': 'Machen Sie Ihr RustDesk-Konto sicherer, indem Sie bei der Anmeldung einen zusätzlichen Authentifizierungscode verlangen.',
  '1. Download an authenticator app': '1. Laden Sie eine Authenticator-App herunter',
  'Download an authenticator app such as': 'Laden Sie eine Authenticator-App wie Google Authenticator oder Authy herunter',
  '2FA code': '2FA-Code',
  'Authenticator': 'Authenticator',
  'on your phone or desktop.': 'auf Ihrem Telefon oder Desktop.',
  '2. Scan the QR code': '2. Scannen Sie den QR-Code',
  'Open your authenticator app, scan the QR code and enter the code that your app shows.': 'Öffnen Sie Ihre Authenticator-App, scannen Sie den QR-Code und geben Sie den Code ein, den Ihre App anzeigt.',
  'You can change or remove 2FA at any time in your account settings.': 'Sie können 2FA jederzeit in Ihren Kontoeinstellungen ändern oder entfernen.',
  'Please verify your 2FA code to disable current 2FA first.': 'Bitte verifizieren Sie Ihren 2FA-Code, um die aktuelle 2FA zuerst zu deaktivieren.',
  'Can\'t verify the code. Check that code and local time settings are correct!': 'Code konnte nicht verifiziert werden. Überprüfen Sie, ob der Code und die lokalen Zeiteinstellungen korrekt sind!',
  'First sign-in:': 'Erste Anmeldung:',
  'Recent activity:': 'Letzte Aktivität:',
  'pages.Settings.GeoOverride.reloadGeo': 'Geolokalisierungsdaten neu laden',
  'pages.Settings.GeoOverride.getRelayServer': 'Relay-Server abrufen',
  'pages.Settings.GeoOverride.relayServer': 'Relay-Server',
  'SMTP Host': 'SMTP-Host',
  'SMTP Port': 'SMTP-Port',
  'My Server Requires Authentication': 'Mein Server erfordert Authentifizierung',
  'smtp.From': 'Absender',
  'Mail Account': 'E-Mail-Konto',
  'Mail Password': 'E-Mail-Passwort',
  'Custom Certificate PEM File Path (on the server)': 'Pfad zur benutzerdefinierten Zertifikat-PEM-Datei (auf dem Server)',
  Update: 'Aktualisieren',
  'Submit and Test Email': 'Absenden und E-Mail testen',
  'he Receiver Email': 'Empfänger-E-Mail',
  'LDAP Host': 'LDAP-Host',
  'LDAP Port': 'LDAP-Port',
  'Filter': 'Filter',
  'Username Attribute': 'Benutzernamen-Attribut',
  'The attribute used as the username when logging in. Default is "dn".': 'Das Attribut, das beim Anmelden als Benutzername verwendet wird. Standard ist "dn".',
  'DraggableTab.Drag tab to reorder': 'Tab ziehen, um die Reihenfolge zu ändern',
  'pages.Settings.OIDC.new.Auth Type': 'Authentifizierungstyp',
  'pages.Settings.OIDC.New Auth Provider': 'Neuer Authentifizierungsanbieter',
  'pages.Settings.OIDC.new.New Auth Provider': 'Neuer Authentifizierungsanbieter',
  'pages.Settings.OIDC.Delete This Provider': 'Diesen Anbieter löschen',
  'pages.Settings.OIDC.new.Enable PKCE': 'PKCE aktivieren',
  'pages.Settings.OIDC.Enable PKCE': 'PKCE aktivieren',
  'pages.Settings.OIDC.new.Timeout(seconds) to force a refresh of metadata(e.g. JWKS) from OIDC providers': 'Timeout (Sekunden) zum erzwungenen Aktualisieren der Metadaten (z.B. JWKS) von OIDC-Anbietern',
  'pages.Settings.OIDC.new.0 means no timeout, empty means default (3600)': '0 bedeutet kein Timeout, leer bedeutet Standard (3600)',
  'Callback URL': 'Callback-URL',
  'pages.Settings.Others.Session expiration time (Day)': 'Sitzungsablaufzeit (Tage)',
  'Or': 'Oder',
};
