export default {
  'app.title': 'RustDesk 控制台',
  'navBar.lang': '语言',
  'layout.user.link.help': '帮助',
  'layout.user.link.privacy': '隐私',
  'layout.user.link.terms': '条款',
  'app.preview.down.block': '下载此页面到本地项目',
  'app.welcome.link.fetch-blocks': '获取全部区块',
  'app.welcome.link.block-list': '基于 block 开发，快速构建标准页面',
  'The verification code is incorrect or has expired': '验证码错误或已超时',
  Type: '类型',
  Remote: '被控端',
  remoteTip: '远程被控制的电脑或其他终端',
  Local: '主控端',
  'Start Time': '开始时间',
  'End Time': '结束时间',
  Note: '备注',
  'Log List': '日志列表',
  'Device List': '设备列表',
  'User List': '用户列表',
  'User Name': '用户名',
  Updating: '正在更新',
  Adding: '正在添加',
  Deleting: '正在删除',
  Create: '创建',
  Add: '添加',
  Confirm: '确认',
  Submit: '提交',
  Warning: '注意',
  Error: '错误',
  Email: '电子邮箱',
  Status: '状态',
  Action: '动作',
  Password: '密码',
  'New Password': '新密码',
  Edit: '编辑',
  Normal: '正常',
  Disabled: '禁用',
  Disable: '禁用',
  Enable: '启用',
  Chosen: '选中',
  Items: '项',
  User: '用户',
  Info: '信息',
  'Log out': '退出登录',
  Settings: '设置',
  'Basic Settings': '基本设置',
  'Remote Desktop': '远程桌面',
  'File Transfer': '文件传输',
  'Port Transfer': '端口转发',
  'View Camera': '查看摄像头',
  'Not Logged In': '未登录',
  'Created Time': '创建时间',
  Delete: '删除',
  Group: '群组',
  'Device Group': '设备组',
  'User Group': '用户组',
  Name: '名称',
  'Group List': '群组列表',
  deleteConfirm: '确认要删除？',
  groupTip: '同一组用户可以相互访问',
  document: '文档',
  Detail: '详细信息',
  Direction: '方向',
  File: '文件',
  Directory: '目录',
  Disconnect: '断开连接',
  Time: '时间',
  'Export as csv': '导出csv',
  exportCsvTip: '一次最多导出1000条记录',
  'Not binded only': '只显示未绑定设备',
  Close: '关闭',
  'Default Strategy': '默认策略',
  'applied device num': '应用的设备数',
  'Device Name': '设备名称',
  'Strategy': '策略',
  'Device Strategy': '设备策略',
  'User Strategy': '用户策略',
  'Group Strategy': '设备组策略',
  'strategy_tooltip': "格式：\"设备策略 / 用户策略 / 设备组策略\"。单独显示 '-' 表示使用默认策略，某个位置显示 '-' 表示该级别未设置策略",
  Search: '搜索',
  Select: '挑选',
  Assign: '分配',
  Device: '设备',
  'Device Username': '设备用户名',
  Role: '角色',
  'Auth Type': '认证方式',
  Invite: '邀请',
  'Please configure Email SMTP settings first': '请先配置邮箱SMTP设置',
  'Security Settings': '安全设置',
  'Manage Signed Devices': '管理已签名的设备',
  'Since 2FA is enabled, the email login verification option is not used. 2FA login verification will be used instead.': '由于启用了2FA，电子邮件登录验证选项将不使用。将使用2FA登录验证代替。',
  'Enable alarm event email notification': '启用告警事件邮件通知',
  'Enable email login verification': '启用邮箱登录验证',
  'License Type': '许可证类型',
  'Renew / Upgrade License': '续费 / 升级许可证',
  Save: '保存',
  Back: '返回',
  'Confirm Password': '确认密码',
  'username@device_name': '用户名@设备名',
  'Hide username': '隐藏用户名',
  'Remote device': '被控设备',
  'Within-group connections': '群组内连接',
  'Cross-group connections': '跨群组连接',
  welcomeTitle: '欢迎使用 RustDesk',
  Cancel: '取消',
  OK: '确定',

  'pages.GroupList.User Group': '用户组',
  'pages.GroupList.User': '用户',

  'pages.CustomClient.Application name': '应用名称',
  'pages.CustomClient.Name': '名称',
  'pages.CustomClient.Build': '构建',
  'pages.CustomClient.Note': '备注',
  'pages.CustomClient.Action': '动作',
  'pages.CustomClient.Delete': '删除',
  'pages.CustomClient.Edit': '编辑',
  'pages.CustomClient.Duplicate': '复制',
  'pages.CustomClient.Create': '创建',
  'pages.CustomClient.Custom Clients': '定制客户端',
  'pages.CustomClient.General': '通用',
  'pages.CustomClient.Name of the configuration': '配置名称',
  'pages.CustomClient.MSI installer': 'MSI 安装程序',
  'pages.CustomClient.Connection type': '连接类型',
  'pages.CustomClient.Bidirectional': '双向',
  'pages.CustomClient.Incoming': '入站 (Incoming)',
  'pages.CustomClient.Outgoing': '出站 (Outgoing)',
  'pages.CustomClient.Options': '选项',
  'pages.CustomClient.Disable installation': '禁用安装',
  'pages.CustomClient.Disable settings': '禁用设置',
  'pages.CustomClient.Disable address book': '禁用地址簿',
  'pages.CustomClient.Disable TCP listen port': '禁用 TCP 监听端口',
  'pages.CustomClient.Disable user account': '禁用用户账户',
  'pages.CustomClient.Some note about this configuration': '关于此配置的注意事项',
  'pages.CustomClient.Custom server': '自定义服务器',
  'pages.CustomClient.Visual': '视觉',
  'pages.CustomClient.Application icon': '应用图标',
  'pages.CustomClient.Security': '安全',
  'pages.CustomClient.Preset password': '预设密码',
  'pages.CustomClient.Advanced': '高级',
  'pages.CustomClient.Override settings': '覆盖设置',
  'pages.CustomClient.Default settings': '默认设置',
  'pages.CustomClient.Note: ': '注意：',
  'pages.CustomClient.Click or drag to upload': '点击或拖拽上传',
  'pages.CustomClient.app_icon_desc_tip': '上传一个512x512像素的正方形PNG图像作为应用图标。',
  'pages.CustomClient.app_name_desc_tip': '指定一个唯一的名称，显示在开始菜单、控制面板等安装的RustDesk客户端上。',
  'pages.CustomClient.logo_desc_tip': '上传一个PNG图像，显示在自定义客户端的主窗口顶部。大小会自动缩放。我们建议使用200x40像素的PNG。',
  'pages.CustomClient.This password will be used as a default permanent password for all new clients. It can be changed by the client.': '此密码将作为所有新客户端的默认永久密码。可以通过客户端更改。',
  'pages.CustomClient.The application icon must be a PNG file.': '应用图标必须是一个PNG文件。',
  'component.tagSelect.expand': '展开',
  'component.tagSelect.collapse': '收起',
  'component.tagSelect.all': '全部',
  'component.LicenseAlert.Your license is invalid.': '许可证无效',
  'component.LicenseAlert.Your license is not set.': '尚未设置许可证',
  'component.LicenseAlert.Your license is about to expire in 2 weeks.': '许可证将在两周内过期',
  'component.LicenseAlert.Your license has expired.': '许可证已过期',
  'pages.CustomClient.Refresh status manually': '手动刷新状态',
  'pages.CustomClient.the building task': '构建任务',
  'pages.CustomClient.Stop': '停止',
  'pages.CustomClient.Cancel': '取消',
  'pages.CustomClient.Completed at %s, this file will be deleted in 1 day.': '完成于 %s，此文件将在1天后删除。',

  'component.globalHeader.search': '站内搜索',
  'component.globalHeader.help': '使用文档',
  'component.globalHeader.notification': '通知',
  'component.globalHeader.notification.empty': '你已查看所有通知',
  'component.globalHeader.message': '消息',
  'component.globalHeader.message.empty': '您已读完所有消息',
  'component.globalHeader.event': '待办',
  'component.globalHeader.event.empty': '你已完成所有待办',
  'component.noticeIcon.clear': '清空',
  'component.noticeIcon.cleared': '清空了',
  'component.noticeIcon.empty': '暂无数据',
  'component.noticeIcon.view-more': '查看更多',
  'Token List': '令牌列表',
  Description: '描述',
  'Device Permission': '设备权限',
  'Audit Log Permission': '审计日志权限',
  'User Permission': '用户权限',
  'Group Permission': '群组权限',
  'Strategy Permission': '策略权限',

  'menu.welcome': '欢迎',
  'menu.more-blocks': '更多区块',
  'menu.home': '首页',
  'menu.admin': '管理页',
  'menu.admin.sub-page': '二级管理页',
  'menu.login': '登录',
  'menu.register': '注册',
  'menu.register-result': '注册结果',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.monitor': '监控页',
  'menu.dashboard.workplace': '工作台',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': '表单页',
  'menu.form.basic-form': '基础表单',
  'menu.form.step-form': '分步表单',
  'menu.form.step-form.info': '分步表单（填写转账信息）',
  'menu.form.step-form.confirm': '分步表单（确认转账信息）',
  'menu.form.step-form.result': '分步表单（完成）',
  'menu.form.advanced-form': '高级表单',
  'menu.list': '列表页',
  'menu.list.audit-list': '安全审计',
  'menu.list.audit-list.Alarm': '风险/异常报警',
  'menu.list.audit-list.Connection': '连接日志',
  'menu.list.audit-list.File': '文件传输日志',
  'menu.list.audit-list.Console': '控制台操作日志',
  'menu.list.device-list': '设备',
  'menu.list.user-list': '用户',
  'menu.list.group-list': '群组',
  'menu.list.group-list.user': '用户组',
  'menu.list.group-list.device': '设备组',
  'menu.list.table-list': '查询表格',
  'menu.list.basic-list': '标准列表',
  'menu.list.card-list': '卡片列表',
  'menu.list.search-list': '搜索列表',
  'menu.list.search-list.articles': '搜索列表（文章）',
  'menu.list.search-list.projects': '搜索列表（项目）',
  'menu.list.search-list.applications': '搜索列表（应用）',
  'menu.profile': '详情页',
  'menu.profile.basic': '基础详情页',
  'menu.profile.advanced': '高级详情页',
  'menu.result': '结果页',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.trigger': '触发报错',
  'menu.account.logout': '退出登录',
  'menu.editor': '图形编辑器',
  'menu.editor.flow': '流程编辑器',
  'menu.editor.mind': '脑图编辑器',
  'menu.editor.koni': '拓扑编辑器',
  'menu.Settings': '设置',
  'menu.Strategy': '策略',
  'menu.Settings.Key': '密钥',
  'menu.Settings.License': '证书',
  'menu.Settings.Relay': '中继管理',
  'menu.Settings.Others': '其它',
  'menu.Settings.Tokens': '令牌 (Token)',
  'menu.Address Books': '地址簿',
  'menu.Strategies': '策略',
  'menu.Custom Clients': '定制客户端',

  'pages.login.accountLogin.tab': '账户密码登录',
  'pages.login.accountLogin.errorMessage': '错误的用户名或者密码',
  'pages.login.failure': '登录失败，请重试！',
  'pages.login.success': '登录成功！',
  'pages.login.username.placeholder': '用户名: admin or user',
  'pages.login.username.required': '用户名是必填项！',
  'pages.login.password.placeholder': '密码: ant.design',
  'pages.login.password.required': '密码是必填项！',
  'pages.login.phoneLogin.tab': '手机号登录',
  'pages.login.phoneLogin.errorMessage': '验证码错误',
  'pages.login.phoneNumber.placeholder': '请输入手机号！',
  'pages.login.phoneNumber.required': '手机号是必填项！',
  'pages.login.phoneNumber.invalid': '不合法的手机号！',
  'pages.login.captcha.placeholder': '请输入验证码！',
  'pages.login.captcha.required': '验证码是必填项！',
  'pages.login.phoneLogin.getVerificationCode': '获取验证码',
  'pages.getCaptchaSecondText': '秒后重新获取',
  'pages.login.rememberMe': '自动登录',
  'pages.login.forgotPassword': '忘记密码 ?',
  'pages.login.submit': '登录',
  'pages.login.loginWith': '其他登录方式 :',
  'pages.login.loginWithGoogle': '使用 Google 登录',
  'pages.login.loginWithGithub': '使用 Github 登录',
  'pages.login.loginWithOkta': '使用 Okta 登录',
  'pages.login.loginWith.requesting_auth': '正在请求授权页面',
  'pages.login.loginWith.waiting_auth': '请完成账户授权',
  'pages.login.registerAccount': '注册账户',
  'pages.welcome.advancedComponent': '高级表格',
  'pages.welcome.link': '欢迎使用',
  'pages.welcome.advancedLayout': '高级布局',
  'pages.welcome.alertMessage': '更快更强的重型组件，已经发布。',
  'pages.account.tfaComponent.Enable 2FA': '启用 2FA',
  'pages.account.tfaComponent.2 Factor Authentication is enabled.': '双因素身份认证已启用',
  'pages.account.tfaComponent.Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.': '您的 2FA 密钥已使用很长时间。我们建议更新您的 2FA 密钥以确保最大账户安全。',
  'pages.account.tfaComponent.Enable 2 Factor Authentication.': '启用双因素身份认证',
  'pages.account.tfa.The code must be 6, 8, 10 digits.': '验证码必须为6、8或10位数字。',
  'pages.account.tfa.The code must be 6 or 8 digits.': '验证码必须为6或8位数字。',
  'pages.admin.subPage.title': ' 这个页面只有 admin 权限才能查看',
  'pages.admin.subPage.alertMessage': 'umi ui 现已发布，欢迎使用 npm run ui 启动体验。',
  'pages.AuditList.FileAuditList.files': '个文件',
  'pages.AuditList.FileAuditList.pathTip': '被控设备上的路径',
  'pages.AuditList.FileAuditList.limitedTip': '显示最大的10个文件',
  'pages.AuditList.FileAuditList.exportFilename': '文件传输日志',
  'pages.AuditList.ConnAuditList.modal_title': '确认操作',
  'pages.AuditList.ConnAuditList.diconnect_confirm_tip': '是否确实要断开此设备?',
  'pages.AuditList.ConnAuditList.disconnect_succ': '断开成功!',
  'pages.AuditList.ConnAuditList.disconnect_fail': '断开失败!',
  'pages.AuditList.ConnAuditList.exportFilename': '连接日志',
  'pages.AuditList.AlarmAuditList.Login Device / Local Device': '登录设备 / 主控设备',
  'pages.AuditList.AlarmAuditList.Login Account / Remote Device': '登录账户 / 被控设备',
  'pages.AuditList.AlarmAuditList.Login Account': '登录账户',
  'pages.AuditList.AlarmAuditList.Device ID': '设备ID',
  'pages.AuditList.AlarmAuditList.Device Name': '设备用户名',
  'pages.AuditList.AlarmAuditList.Remote Device': '被控设备',
  'pages.AuditList.AlarmAuditList.Event Time': '发生时间',
  'pages.AuditList.AlarmAuditList.IP whiltelist': '白名单外的IP尝试访问',
  'pages.AuditList.AlarmAuditList.ExceedThirtyAccessAttempts': '连续30次尝试访问',
  'pages.AuditList.AlarmAuditList.MultipleAccessAttemptsWithinOneMinute': '1分钟内多次尝试访问',
  'pages.AuditList.AlarmAuditList.ExceedThirtyLoginAttempts': '连续30次尝试登录',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneMinute': '1分钟内多次尝试登录',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneHour': '1小时内多次尝试登录',
  'pages.AuditList.AlarmAuditList.exportFilename': '风险异常报警日志',
  'pages.AuditList.ConsoleAuditList.Operation': '操作',
  'pages.AuditList.ConsoleAuditList.Name': '名称',
  'pages.AuditList.ConsoleAuditList.Name list': '名称列表',
  'pages.AuditList.ConsoleAuditList.ID list': 'ID列表',
  'pages.AuditList.ConsoleAuditList.Result': '结果',
  'pages.AuditList.ConsoleAuditList.Remote': '被控端',
  'pages.AuditList.ConsoleAuditList.Local': '主控端',
  'pages.AuditList.ConsoleAuditList.User Login': '用户登录控制台',
  'pages.AuditList.ConsoleAuditList.Add Group': '新建群组',
  'pages.AuditList.ConsoleAuditList.Add User': '添加用户',
  'pages.AuditList.ConsoleAuditList.Add Device': '添加设备',
  'pages.AuditList.ConsoleAuditList.Delete Groups': '批量删除群组',
  'pages.AuditList.ConsoleAuditList.Disconnect Device': '断开设备连接',
  'pages.AuditList.ConsoleAuditList.Enable Users': '使能用户',
  'pages.AuditList.ConsoleAuditList.Disable Users': '禁用用户',
  'pages.AuditList.ConsoleAuditList.Enable Devices': '批量使能设备',
  'pages.AuditList.ConsoleAuditList.Disable Devices': '批量禁用设备',
  'pages.AuditList.ConsoleAuditList.Update Group': '更新群组',
  'pages.AuditList.ConsoleAuditList.Update User': '更新用户',
  'pages.AuditList.ConsoleAuditList.Update Device': '更新设备',
  'pages.AuditList.ConsoleAuditList.Delete User': '删除用户',
  'pages.AuditList.ConsoleAuditList.Delete Device': '删除设备',
  'pages.AuditList.ConsoleAuditList.Add Address Book': '添加地址簿',
  'pages.AuditList.ConsoleAuditList.Delete Address Book': '删除地址簿',
  'pages.AuditList.ConsoleAuditList.Change Address Book Name': '更改地址簿名称',
  'pages.AuditList.ConsoleAuditList.Group Management': '群组管理',
  'pages.AuditList.ConsoleAuditList.User Management': '用户管理',
  'pages.AuditList.ConsoleAuditList.Device Management': '设备管理',
  'pages.AuditList.ConsoleAuditList.Address Book Management': '地址簿管理',
  'pages.AuditList.ConsoleAuditList.exportFilename': '控制台日志',
  'pages.AuditList.ConsoleAuditList.Delete Devices in the Address Book Recycle Bin': '从地址簿回收站中删除设备',
  'pages.AuditList.ConsoleAuditList.Empty Address Book Recycle Bin': '清空地址簿回收站',
  'pages.AuditList.ConsoleAuditList.Add Address Book Permission': '添加地址簿权限',
  'pages.AuditList.ConsoleAuditList.Delete Address Book Permission': '删除地址簿权限',
  'pages.AuditList.ConsoleAuditList.Update Address Book Permission': '修改地址簿权限',
  'pages.Settings.Strategy.Strategy list': '策略列表',
  'pages.Settings.Strategy.Automatically close incoming sessions on user inactivity': '用户不活动时自动关闭入站会话',
  'pages.Settings.Strategy.delete_strategy_tip': '确定要删除该策略吗?',
  'pages.Settings.Strategy.New strategy': '新建策略',
  'pages.Settings.Strategy.Rename strategy': '重命名策略',
  'pages.Settings.Strategy.Duplicate strategy': '复制策略',
  'pages.Settings.Strategy.Edit Devices': '编辑设备',
  'pages.Settings.Strategy.Edit Users': '编辑用户',
  'pages.Settings.Strategy.Rename': '重命名',
  'pages.Settings.Strategy.Duplicate': '复制',
  'pages.Settings.Strategy.Delete': '删除',
  'pages.Settings.Strategy.Groups': '群组',
  'pages.Settings.Strategy.Select All': '全选',
  'pages.Settings.Strategy.Permissions': '权限',
  'pages.Settings.Strategy.Password': '密码',
  'pages.Settings.Strategy.Security': '安全',
  'pages.Settings.Strategy.Other': '其它',
  'pages.Settings.Strategy.IP whitelist': 'IP白名单',
  'pages.Settings.Strategy.Cancel': '取消',
  'pages.Settings.Strategy.Submit': '确定',
  'pages.Settings.Strategy.Edit': '编辑',
  'pages.Settings.Strategy.Custom': '自定义',
  'pages.Settings.Strategy.Full access': '完全访问',
  'pages.Settings.Strategy.Screen share': '仅共享屏幕',
  'pages.Settings.Strategy.Enable keyboard/mouse': '允许控制键盘/鼠标',
  'pages.Settings.Strategy.Enable clipboard': '允许同步剪切板',
  'pages.Settings.Strategy.Enable file transfer': '允许传输文件',
  'pages.Settings.Strategy.Enable audio': '允许传输音频',
  'pages.Settings.Strategy.Enable TCP tunneling': '允许建立TCP隧道',
  'pages.Settings.Strategy.Enable remote restart': '允许远程重启',
  'pages.Settings.Strategy.Enable recording session': '允许录制会话',
  'pages.Settings.Strategy.Enable blocking user input': '允许阻止用户输入',
  'pages.Settings.Strategy.Enable remote configuration modification': '允许远程修改配置',
  'pages.Settings.Strategy.Permission type': '权限类型',
  'pages.Settings.Strategy.Approve mode': '认证方式',
  'pages.Settings.Strategy.Password type': '密码类型',
  'pages.Settings.Strategy.Hide connection management window': '隐藏连接管理窗口',
  'pages.Settings.Strategy.One-time password length': '一次性密码长度',
  'pages.Settings.Strategy.Accept sessions via password': '只允许密码访问',
  'pages.Settings.Strategy.Accept sessions via click': '只允许点击访问',
  'pages.Settings.Strategy.Accept sessions via both': '允许密码或点击访问',
  'pages.Settings.Strategy.Use one-time password': '使用一次性密码',
  'pages.Settings.Strategy.Use permanent password': '使用固定密码',
  'pages.Settings.Strategy.Use both passwords': '同时使用两种密码',
  'pages.Settings.Strategy.Deny LAN discovery': '拒绝局域网发现',
  'pages.Settings.Strategy.Enable direct IP access': '允许IP直接访问',
  'pages.Settings.Strategy.auto_disconnect_option_tip': '自动关闭不活跃的会话',
  'pages.Settings.Strategy.deny_lan_discovery_desc_tip': '设备将不会被同一局域网内的其他设备发现',
  'pages.Settings.Strategy.enable_direct_ip_access_desc_tip': '允许直接IP连接到设备',
  'pages.Settings.Strategy.Remove wallpaper during incoming sessions': '接受会话时移除桌面壁纸',
  'pages.Settings.Strategy.minutes': '分钟',
  'pages.Settings.Strategy.Enable': '启用',
  'pages.Settings.Strategy.Disable': '禁用',
  'pages.Settings.Strategy.disabled': '禁用',
  'pages.Settings.Strategy.Default': '默认策略',
  'pages.Settings.Strategy.Apply changes of this page': '应用本页更改',
  'pages.Settings.Strategy.Invalid IP': '无效 IP',
  'pages.Settings.Strategy.IP Whitelisting': 'IP 白名单',
  'pages.Settings.Strategy.Clear': '清空',
  'pages.Settings.Strategy.OK': '确认',
  'pages.Settings.Strategy.whitelist_sep': '可以使用逗号，分号，空格或者换行符作为分隔符',
  'pages.Settings.Strategy.Use IP whitelisting': '只允许白名单上的 IP 访问',
  'pages.Settings.Strategy.whitelist_tip': '只有白名单里的 IP 才能访问本机',
  'pages.CustomClient.bidirectional_desc_tip': '创建一个客户端，可以连接另一台设备，也可以接收连接请求',
  'pages.CustomClient.incoming_desc_tip': '创建一个客户端，只能接收连接请求',
  'pages.CustomClient.outgoing_desc_tip': '创建一个客户端，可以连接另一台设备',
  'pages.CustomClient.disable_installation_desc_tip': '禁止用户安装自定义客户端（仅限Windows）',
  'pages.CustomClient.disable_settings_desc_tip': '禁用用户访问自定义客户端的设置',
  'pages.CustomClient.disable_ab_desc_tip': '禁用自定义客户端中的地址簿功能',
  'pages.CustomClient.disable_tcp_desc_tip': '启动RustDesk时避免Windows防火墙对话框, 这个将跳过P2P直连尝试, 直接使用中继连接',
  'pages.CustomClient.disable_account_desc_tip': '移除用户在RustDesk客户端中登录的功能',
  'pages.CustomClient.override_settings_desc_tip': '覆盖设置，无法在RustDesk中更改',
  'pages.CustomClient.default_settings_desc_tip': '更改RustDesk默认使用的默认设置',
  'pages.Settings.KeyPage.failedToFetchKey': '获取密钥失败。',
  'pages.Settings.KeyPage.keyResetSuccess': '密钥重置成功，请重启hbbr以使更改生效。',
  'pages.Settings.KeyPage.errorResettingKey': '重置密钥时发生错误。',
  'pages.Settings.KeyPage.keyUpdateSuccess': '密钥更新成功，请重启hbbr以使更改生效。',
  'pages.Settings.KeyPage.errorUpdatingKey': '更新密钥时发生错误，请检查密钥',
  'pages.Settings.KeyPage.key': '密钥',
  'pages.Settings.KeyPage.privateKey': '私钥',
  'pages.Settings.KeyPage.publicKey': '公钥',
  'pages.Settings.KeyPage.editKey': '更新密钥',
  'pages.Settings.KeyPage.reset': '重置',
  'pages.Settings.KeyPage.custom': '手动输入',
  'pages.Settings.KeyPage.pleaseInputPrivateKey': '请输入您的私钥！',
  'pages.Settings.License.settingsUpdatedSuccess': '设置成功更新。',
  'pages.Settings.License.licenseCannotBeEmpty': '证书不能为空。',
  'pages.Settings.License.license': '许可证',
  'pages.Settings.Relay.GeoOverrideDescription': '只有当存在多个中继服务器时，才能使用位置覆写功能。',
  'pages.Settings.GeoOverride.ipOrDomain': 'IP/域名',
  'pages.Settings.GeoOverride.latitude': '纬度',
  'pages.Settings.GeoOverride.longitude': '经度',
  'pages.Settings.GeoOverride.deleteSelectedConfirm': '确定要删除选中的位置覆写吗？',
  'pages.Settings.GeoOverride.deleteConfirm': '确定要删除该位置覆写吗？',
  'pages.Settings.GeoOverride.editGeoOverride': '编辑覆写',
  'pages.Settings.GeoOverride.addGeoOverride': '添加覆写',
  'pages.Settings.GeoOverride.ipOrDomainRequired': 'IP/域名不能为空。',
  'pages.Settings.GeoOverride.latitudeRequired': '纬度不能为空。',
  'pages.Settings.GeoOverride.longitudeRequired': '经度不能为空。',
  'pages.Settings.GeoOverride.testGeo': '测试位置',
  'pages.Settings.GeoOverride.geofileFetchFailed': '获取GeoFile信息失败。',
  'pages.Settings.GeoOverride.loadGeoOverrides': '加载覆写',
  'pages.Settings.GeoOverride.geoOverride': '位置覆写',
  'pages.Settings.GeoOverride.title': '位置覆写',
  'pages.Settings.GeoOverride.RelaySettings': '设置',
  'pages.Settings.GeoOverride.RelayServers': '中继服务器',
  'pages.Settings.GeoOverride.AddServer': '添加服务器',
  'pages.Settings.GeoOverride.AlwaysUseRelay': '始终使用中继',
  'pages.Settings.GeoOverride.UpdateServers': '导入修改',
  'pages.Settings.GeoOverride.UpdatedAlwaysUseRelay': '已更新始终使用中继',
  'pages.Settings.GeoOverride.FailedToUpdateAlwaysUseRelay': '更新始终使用中继失败',
  'pages.Settings.GeoOverride.UpdatedRelayServers': '已更新中继服务器',
  'pages.Settings.GeoOverride.FailedToUpdateRelayServers': '更新中继服务器失败',
  'pages.Settings.GeoOverride.UpdatedChangeIDSetting': '已更新更改ID设置',
  'pages.Settings.GeoOverride.FailedToUpdateChangeIDSetting': '更新更改ID设置失败',
  "pages.Settings.Others.Allow change ID": "允许更改ID",
  "pages.Settings.Others.Disable new devices": "禁用新设备",
  "pages.Settings.Others.The control end need to login before access": "控制端登录后才能发起连接",
  "pages.Settings.Others.Only admin can access unassigned devices": "只用管理员可以访问未分配的设备",
  "pages.Settings.Others.Allow non-admin to see other groups": "允许非管理员查看其他组",
  "pages.Settings.Others.allow_non_admin_see_other_group_tip": "当该选项关闭时，非管理员只能在自己的组内共享地址簿。",
  'pages.Settings.OIDC.Maximum Number of Authorized Users': '最多认证用户数',
  'pages.Settings.OIDC.emptyPrompt': '当前没有第三方认证提供者，请添加一个以使用此功能。',
  'pages.Settings.OIDC.confirm-delete-title': '确认删除',
  'pages.Settings.OIDC.confirm-delete-content': '相关的第三方授权用户无法再登录。您可以进入用户管理界面删除这些用户。',
  'pages.Settings.Others.Only admin can access logs': '只有管理员可以访问未分配的日志',
  'pages.Welcome.Max Devices': '最大设备数',
  'pages.Welcome.Max Users': '最大用户数',
  'pages.Welcome.Not set': '未设置',
  'pages.Welcome.Invalid': '无效',
  'pages.Welcome.Demo': '试用',
  'pages.Welcome.Personal': '个人',
  'pages.Welcome.Business': '商业',
  'pages.Welcome.Unknown': '其它',
  'pages.Welcome.Max Peers': '最大设备数',
  'pages.Welcome.RustDesk Server Status': 'RustDesk 服务器状态',
  'pages.Welcome.License Expire Time': '许可证到期时间',
  'pages.Welcome.Document': '文档',
  'pages.AddressBook.Permission': '权限',
  'pages.AddressBook.Device count': '设备数量',
  'pages.AddressBook.Address Books': '地址簿',
  'pages.AddressBook.Tags': '标签',
  'pages.AddressBook.Devices': '设备',
  'pages.AddressBook.Device': '设备',
  'pages.AddressBook.Alias': '别名',
  'pages.AddressBook.Whether a password is set': '是否设置了密码',
  'pages.AddressBook.Recycle Bin': '回收站',
  'pages.AddressBook.Empty Recycle Bin': '清空回收站',
  'pages.AddressBook.Personal': '个人',
  'pages.AddressBook.Personal (My address book)': '个人（我的地址簿）',
  'pages.AddressBook.Shared': '共享',
  'pages.AddressBook.Share': '共享',
  'pages.AddressBook.Delete': '删除',
  'pages.AddressBook.Settings': '设置',
  'pages.AddressBook.Owner': '所有者',
  'pages.AddressBook.My Permission': '我的权限',
  'pages.AddressBook.Read-only': '只读',
  'pages.AddressBook.Read/Write': '读写',
  'pages.AddressBook.Full Control': '完全控制',
  'pages.AddressBook.share_warning_tip': '上述字段是共享的并且对其他人可见。',
  'pages.AddressBook.permission_tip': '只读：可以查看设备和标签；读/写：可以编辑设备和标签；完全控制：可以重新共享、删除或重命名地址簿。',
  'pages.AddressBook.rule_priority_tip': '优先级: 用户 > 群组 > 所有人',
  'pages.AddressBook.Remove password': '删除密码',
  'pages.AddressBook.Import': '导入',
  'pages.AddressBook.Filter by intersection': '按交集过滤',
  'pages.AddressBook.Permissions': '权限',
  'pages.AddressBook.Type': '类型',
  'pages.AddressBook.Untagged': '无标签',

  'app.pwa.offline': '当前处于离线状态',
  'app.pwa.serviceworker.updated': '有新内容',
  'app.pwa.serviceworker.updated.hint': '请点击"刷新"按钮或者手动刷新页面',
  'app.pwa.serviceworker.updated.ok': '刷新',

  'app.setting.pagestyle': '整体风格设置',
  'app.setting.pagestyle.dark': '暗色菜单风格',
  'app.setting.pagestyle.light': '亮色菜单风格',
  'app.setting.content-width': '内容区域宽度',
  'app.setting.content-width.fixed': '定宽',
  'app.setting.content-width.fluid': '流式',
  'app.setting.themecolor': '主题色',
  'app.setting.themecolor.dust': '薄暮',
  'app.setting.themecolor.volcano': '火山',
  'app.setting.themecolor.sunset': '日暮',
  'app.setting.themecolor.cyan': '明青',
  'app.setting.themecolor.green': '极光绿',
  'app.setting.themecolor.daybreak': '拂晓蓝（默认）',
  'app.setting.themecolor.geekblue': '极客蓝',
  'app.setting.themecolor.purple': '酱紫',
  'app.setting.navigationmode': '导航模式',
  'app.setting.sidemenu': '侧边菜单布局',
  'app.setting.topmenu': '顶部菜单布局',
  'app.setting.fixedheader': '固定 Header',
  'app.setting.fixedsidebar': '固定侧边菜单',
  'app.setting.fixedsidebar.hint': '侧边菜单布局时可配置',
  'app.setting.hideheader': '下滑时隐藏 Header',
  'app.setting.hideheader.hint': '固定 Header 时可配置',
  'app.setting.othersettings': '其他设置',
  'app.setting.weakmode': '色弱模式',
  'app.setting.copy': '拷贝设置',
  'app.setting.copyinfo': '拷贝成功，请到 config/defaultSettings.js 中替换默认配置',
  'app.setting.production.hint':
    '配置栏只在开发环境用于预览，生产环境不会展现，请拷贝后手动修改配置文件',

  'app.settings.menuMap.basic': '基本设置',
  'app.settings.menuMap.security': '安全设置',
  'app.settings.menuMap.binding': '账号绑定',
  'app.settings.menuMap.notification': '新消息通知',
  'app.settings.basic.avatar': '头像',
  'app.settings.basic.change-avatar': '更换头像',
  'app.settings.basic.email': '邮箱',
  'app.settings.basic.email-message': '请输入您的邮箱!',
  'app.settings.basic.nickname': '昵称',
  'app.settings.basic.nickname-message': '请输入您的昵称!',
  'app.settings.basic.profile': '个人简介',
  'app.settings.basic.profile-message': '请输入个人简介!',
  'app.settings.basic.profile-placeholder': '个人简介',
  'app.settings.basic.country': '国家/地区',
  'app.settings.basic.country-message': '请输入您的国家或地区!',
  'app.settings.basic.geographic': '所在省市',
  'app.settings.basic.geographic-message': '请输入您的所在省市!',
  'app.settings.basic.address': '街道地址',
  'app.settings.basic.address-message': '请输入您的街道地址!',
  'app.settings.basic.phone': '联系电话',
  'app.settings.basic.phone-message': '请输入您的联系电话!',
  'app.settings.basic.update': '更新基本信息',
  'app.settings.security.strong': '强',
  'app.settings.security.medium': '中',
  'app.settings.security.weak': '弱',
  'app.settings.security.password': '账户密码',
  'app.settings.security.password-description': '当前密码强度',
  'app.settings.security.phone': '密保手机',
  'app.settings.security.phone-description': '已绑定手机',
  'app.settings.security.question': '密保问题',
  'app.settings.security.question-description': '未设置密保问题，密保问题可有效保护账户安全',
  'app.settings.security.email': '备用邮箱',
  'app.settings.security.email-description': '已绑定邮箱',
  'app.settings.security.mfa': 'MFA 设备',
  'app.settings.security.mfa-description': '未绑定 MFA 设备，绑定后，可以进行二次确认',
  'app.settings.security.modify': '修改',
  'app.settings.security.set': '设置',
  'app.settings.security.bind': '绑定',
  'app.settings.binding.taobao': '绑定淘宝',
  'app.settings.binding.taobao-description': '当前未绑定淘宝账号',
  'app.settings.binding.alipay': '绑定支付宝',
  'app.settings.binding.alipay-description': '当前未绑定支付宝账号',
  'app.settings.binding.dingding': '绑定钉钉',
  'app.settings.binding.dingding-description': '当前未绑定钉钉账号',
  'app.settings.binding.bind': '绑定',
  'app.settings.notification.password': '账户密码',
  'app.settings.notification.password-description': '其他用户的消息将以站内信的形式通知',
  'app.settings.notification.messages': '系统消息',
  'app.settings.notification.messages-description': '系统消息将以站内信的形式通知',
  'app.settings.notification.todo': '待办任务',
  'app.settings.notification.todo-description': '待办任务将以站内信的形式通知',
  'app.settings.open': '开',
  'app.settings.close': '关',
  'app.copyright.produced': '由 RustDesk 团队开发',
  'pages.account.Settings.Security.Password': '密码',
  'pages.account.Settings.Security.Change password': '修改密码',
  'pages.account.tfa.Enable 2FA': '启用 2FA',
  'pages.account.tfa.2 Factor Authentication is enabled.': '双因素身份认证已启用',
  'pages.account.tfa.Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.': '您的 2FA 密钥已使用很长时间。我们建议更新您的 2FA 密钥以确保最大账户安全。',
  'pages.account.tfa.Enable 2 Factor Authentication.': '启用双因素身份认证',

  'Enable Two-Factor Authentication': '启用双因素认证',
  'Make your RustDesk account more secure by requiring an extra authentication code when you log in.': '通过要求在登录时输入额外的认证代码，使您的 RustDesk 账户更加安全。',
  '1. Download an authenticator app': '1. 下载一个认证器应用',
  'Download an authenticator app such as': '下载一个认证器应用，如 Google Authenticator 或 Authy',
  '2FA code': '2FA 代码',
  'Authenticator': '认证器',
  'on your phone or desktop.': '在您的手机或桌面上。',
  '2. Scan the QR code': '2. 扫描二维码',
  'Open your authenticator app, scan the QR code and enter the code that your app shows.': '打开您的认证器应用，扫描二维码并输入应用显示的代码。',
  'You can change or remove 2FA at any time in your account settings.': '您可以随时在账户设置中更改或删除 2FA。',
  'Please verify your 2FA code to disable current 2FA first.': '请先验证您的 2FA 代码以禁用当前的 2FA。',
  'Can\'t verify the code. Check that code and local time settings are correct!': '无法验证代码。请检查代码和本地时间设置是否正确！',
  'First sign-in:': '首次登录：',
  'Recent activity:': '最近活动：',
  'pages.Settings.GeoOverride.reloadGeo': '重新加载地理位置数据',
  'pages.Settings.GeoOverride.getRelayServer': '获取中继服务器',
  'pages.Settings.GeoOverride.relayServer': '中继服务器',
  'SMTP Host': 'SMTP 主机',
  'SMTP Port': 'SMTP 端口',
  'My Server Requires Authentication': '我的服务器需要身份验证',
  'smtp.From': '发件人',
  'Mail Account': '邮件账户',
  'Mail Password': '邮件密码',
  'Custom Certificate PEM File Path (on the server)': '自定义证书 PEM 文件路径（在服务器上）',
  Update: '更新',
  'Submit and Test Email': '提交并测试邮件',
  'he Receiver Email': '接收者邮箱',
  'LDAP Host': 'LDAP 主机',
  'LDAP Port': 'LDAP 端口',
  'Filter': '过滤器',
  'Username Attribute': '用户名属性',
  'The attribute used as the username when logging in. Default is "dn".': '登录时使用的属性。默认值为 "dn"',
  'DraggableTab.Drag tab to reorder': '拖动标签以重新排序',
  'pages.Settings.OIDC.new.Auth Type': '认证类型',
  'pages.Settings.OIDC.New Auth Provider': '新建认证提供者',
  'pages.Settings.OIDC.new.New Auth Provider': '新建认证提供者',
  'pages.Settings.OIDC.Delete This Provider': '删除此提供者',
  'pages.Settings.OIDC.new.Enable PKCE': '启用 PKCE',
  'pages.Settings.OIDC.Enable PKCE': '启用 PKCE',
  'pages.Settings.OIDC.new.Timeout(seconds) to force a refresh of metadata(e.g. JWKS) from OIDC providers': '超时（秒）强制刷新 OIDC 提供者的元数据（例如 JWKS）',
  'pages.Settings.OIDC.new.0 means no timeout, empty means default (3600)': '0 表示没有超时，空表示默认（3600）',
  'Callback URL': '回调 URL',
  'pages.Settings.Others.Session expiration time (Day)': '会话过期时间 (天)',
  'Or': '或者',
};
