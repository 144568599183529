export default {
  'app.title': 'Console RustDesk',
  'navBar.lang': 'Langue',
  'layout.user.link.help': 'Aide',
  'layout.user.link.privacy': 'Confidentialité',
  'layout.user.link.terms': 'Conditions',
  'app.preview.down.block': 'Télécharger cette page vers le projet local',
  'app.welcome.link.fetch-blocks': 'Obtenir tous les blocs',
  'app.welcome.link.block-list': 'Développer rapidement des pages standard basées sur des blocs',
  'The verification code is incorrect or has expired': 'Le code de vérification est incorrect ou a expiré',
  Type: 'Type',
  Remote: 'Appareil distant',
  remoteTip: 'Ordinateur ou autre terminal contrôlé à distance',
  Local: 'Appareil local',
  'Start Time': 'Heure de début',
  'End Time': 'Heure de fin',
  Note: 'Note',
  'Log List': 'Liste des journaux',
  'Device List': 'Liste des appareils',
  'User List': 'Liste des utilisateurs',
  'User Name': "Nom d'utilisateur",
  Updating: 'Mise à jour en cours',
  Adding: 'Ajout en cours',
  Deleting: 'Suppression en cours',
  Create: 'Créer',
  Add: 'Ajouter',
  Confirm: 'Confirmer',
  Submit: 'Soumettre',
  Warning: 'Attention',
  Error: 'Erreur',
  Email: 'E-mail',
  Status: 'Statut',
  Action: 'Action',
  Password: 'Mot de passe',
  'New Password': 'Nouveau mot de passe',
  Edit: 'Modifier',
  Normal: 'Normal',
  Disabled: 'Désactivé',
  Disable: 'Désactiver',
  Enable: 'Activer',
  Chosen: 'Sélectionné',
  Items: 'éléments',
  User: 'Utilisateur',
  Info: 'Information',
  'Log out': 'Déconnexion',
  Settings: 'Paramètres',
  'Basic Settings': 'Paramètres de base',
  'Remote Desktop': 'Bureau à distance',
  'File Transfer': 'Transfert de fichiers',
  'Port Transfer': 'Transfert de port',
  'View Camera': 'Voir la caméra',
  'Not Logged In': 'Non connecté',
  'Created Time': 'Date de création',
  Delete: 'Supprimer',
  Group: 'Groupe',
  'Device Group': "Groupe d'appareils",
  'User Group': "Groupe d'utilisateurs",
  Name: 'Nom',
  'Group List': 'Liste des groupes',
  deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ?',
  groupTip: 'Les utilisateurs du même groupe peuvent accéder les uns aux autres',
  document: 'Documentation',
  Detail: 'Détails',
  Direction: 'Direction',
  File: 'Fichier',
  Directory: 'Répertoire',
  Disconnect: 'Déconnecter',
  Time: 'Temps',
  'Export as csv': 'Exporter en CSV',
  exportCsvTip: '1000 enregistrements maximum peuvent être exportés à la fois',
  'Not binded only': 'Afficher uniquement les appareils non liés',
  Close: 'Fermer',
  'Default Strategy': 'Stratégie par défaut',
  'applied device num': "Nombre d'appareils appliqués",
  'Device Name': "Nom de l'appareil",
  'Strategy': 'Stratégie',
  'Device Strategy': "Stratégie d'appareil",
  'User Strategy': "Stratégie d'utilisateur",
  'Group Strategy': 'Stratégie de groupe',
  'strategy_tooltip': 'Format : "Stratégie de dispositif / Stratégie d\'utilisateur / Stratégie de groupe". "-" seul signifie utiliser la stratégie par défaut, "-" à une position signifie qu\'aucune stratégie n\'est définie à ce niveau',
  Search: 'Rechercher',
  Select: 'Sélectionner',
  Assign: 'Attribuer',
  Device: 'Appareil',
  'Device Username': "Nom d'utilisateur de l'appareil",
  Role: 'Rôle',
  'Auth Type': "Type d'authentification",
  Invite: 'Inviter',
  'Please configure Email SMTP settings first': "Veuillez d'abord configurer les paramètres SMTP de l'e-mail",
  'Security Settings': 'Paramètres de sécurité',
  'Manage Signed Devices': 'Gérer les appareils signés',
  'Since 2FA is enabled, the email login verification option is not used. 2FA login verification will be used instead.': "Comme l'authentification à deux facteurs est activée, l'option de vérification de connexion par e-mail n'est pas utilisée. La vérification de connexion 2FA sera utilisée à la place.",
  'Enable alarm event email notification': "Activer la notification par e-mail des événements d'alarme",
  'Enable email login verification': 'Activer la vérification de connexion par e-mail',
  'License Type': 'Type de licence',
  'Renew / Upgrade License': 'Renouveler / Mettre à niveau la licence',
  Save: 'Enregistrer',
  Back: 'Retour',
  'Confirm Password': 'Confirmer le mot de passe',
  'username@device_name': 'nom_utilisateur@nom_appareil',
  'Hide username': "Masquer le nom d'utilisateur",
  'Remote device': 'Appareil distant',
  'Within-group connections': 'Connexions au sein du groupe',
  'Cross-group connections': 'Connexions entre groupes',
  welcomeTitle: 'Bienvenue sur RustDesk',
  Cancel: 'Annuler',
  OK: 'OK',

  'pages.GroupList.User Group': "Groupe d'utilisateurs",
  'pages.GroupList.User': 'Utilisateur',

  'pages.CustomClient.Application name': "Nom de l'application",
  'pages.CustomClient.Name': 'Nom',
  'pages.CustomClient.Build': 'Compilation',
  'pages.CustomClient.Note': 'Note',
  'pages.CustomClient.Action': 'Action',
  'pages.CustomClient.Delete': 'Supprimer',
  'pages.CustomClient.Edit': 'Modifier',
  'pages.CustomClient.Duplicate': 'Dupliquer',
  'pages.CustomClient.Create': 'Créer',
  'pages.CustomClient.Custom Clients': 'Clients personnalisés',
  'pages.CustomClient.General': 'Général',
  'pages.CustomClient.Name of the configuration': 'Nom de la configuration',
  'pages.CustomClient.MSI installer': 'Installateur MSI',
  'pages.CustomClient.Connection type': 'Type de connexion',
  'pages.CustomClient.Bidirectional': 'Bidirectionnel',
  'pages.CustomClient.Incoming': 'Entrant',
  'pages.CustomClient.Outgoing': 'Sortant',
  'pages.CustomClient.Options': 'Options',
  'pages.CustomClient.Disable installation': "Désactiver l'installation",
  'pages.CustomClient.Disable settings': 'Désactiver les paramètres',
  'pages.CustomClient.Disable address book': "Désactiver le carnet d'adresses",
  'pages.CustomClient.Disable TCP listen port': 'Désactiver le port d\'écoute TCP',
  'pages.CustomClient.Disable user account': "Désactiver le compte d'utilisateur",
  'pages.CustomClient.Some note about this configuration': 'Note sur cette configuration',
  'pages.CustomClient.Custom server': 'Serveur personnalisé',
  'pages.CustomClient.Visual': 'Visuel',
  'pages.CustomClient.Application icon': "Icône de l'application",
  'pages.CustomClient.Security': 'Sécurité',
  'pages.CustomClient.Preset password': 'Mot de passe prédéfini',
  'pages.CustomClient.Advanced': 'Avancé',
  'pages.CustomClient.Override settings': 'Remplacer les paramètres',
  'pages.CustomClient.Default settings': 'Paramètres par défaut',
  'pages.CustomClient.Note: ': 'Note : ',
  'pages.CustomClient.Click or drag to upload': 'Cliquez ou faites glisser pour télécharger',
  'pages.CustomClient.app_icon_desc_tip': 'Téléchargez une image PNG carrée de 512x512 pixels comme icône d\'application.',
  'pages.CustomClient.app_name_desc_tip': 'Spécifiez un nom unique qui apparaîtra dans le menu Démarrer, le Panneau de configuration, etc. pour le client RustDesk installé.',
  'pages.CustomClient.logo_desc_tip': 'Téléchargez une image PNG qui sera affichée en haut de la fenêtre principale du client personnalisé. La taille sera automatiquement mise à l\'échelle. Nous recommandons un PNG de 200x40 pixels.',
  'pages.CustomClient.This password will be used as a default permanent password for all new clients. It can be changed by the client.': 'Ce mot de passe sera utilisé comme mot de passe permanent par défaut pour tous les nouveaux clients. Il peut être modifié par le client.',
  'pages.CustomClient.The application icon must be a PNG file.': "L'icône de l'application doit être un fichier PNG.",
  'component.tagSelect.expand': 'Développer',
  'component.tagSelect.collapse': 'Réduire',
  'component.tagSelect.all': 'Tous',
  'component.LicenseAlert.Your license is invalid.': 'Votre licence est invalide.',
  'component.LicenseAlert.Your license is not set.': "Votre licence n'est pas définie.",
  'component.LicenseAlert.Your license is about to expire in 2 weeks.': 'Votre licence expirera dans 2 semaines.',
  'component.LicenseAlert.Your license has expired.': 'Votre licence a expiré.',
  'pages.CustomClient.Refresh status manually': 'Actualiser manuellement le statut',
  'pages.CustomClient.the building task': 'la tâche de compilation',
  'pages.CustomClient.Stop': 'Arrêter',
  'pages.CustomClient.Cancel': 'Annuler',
  'pages.CustomClient.Completed at %s, this file will be deleted in 1 day.': 'Terminé à %s, ce fichier sera supprimé dans 1 jour.',

  'component.globalHeader.search': 'Rechercher sur le site',
  'component.globalHeader.help': 'Documentation',
  'component.globalHeader.notification': 'Notifications',
  'component.globalHeader.notification.empty': 'Vous avez consulté toutes les notifications',
  'component.globalHeader.message': 'Messages',
  'component.globalHeader.message.empty': 'Vous avez lu tous les messages',
  'component.globalHeader.event': 'À faire',
  'component.globalHeader.event.empty': 'Vous avez terminé toutes les tâches',
  'component.noticeIcon.clear': 'Effacer',
  'component.noticeIcon.cleared': 'Effacé',
  'component.noticeIcon.empty': 'Pas de données',
  'component.noticeIcon.view-more': 'Voir plus',
  'Token List': 'Liste des jetons',
  Description: 'Description',
  'Device Permission': "Permission d'appareil",
  'Audit Log Permission': "Permission de journal d'audit",
  'User Permission': "Permission d'utilisateur",
  'Group Permission': 'Permission de groupe',
  'Strategy Permission': 'Permission de stratégie',

  'menu.welcome': 'Bienvenue',
  'menu.more-blocks': 'Plus de blocs',
  'menu.home': 'Accueil',
  'menu.admin': 'Administration',
  'menu.admin.sub-page': 'Sous-page',
  'menu.login': 'Connexion',
  'menu.register': 'Inscription',
  'menu.register-result': "Résultat d'inscription",
  'menu.dashboard': 'Tableau de bord',
  'menu.dashboard.analysis': 'Analyse',
  'menu.dashboard.monitor': 'Surveillance',
  'menu.dashboard.workplace': 'Espace de travail',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Formulaires',
  'menu.form.basic-form': 'Formulaire de base',
  'menu.form.step-form': 'Formulaire par étapes',
  'menu.form.step-form.info': 'Formulaire par étapes (saisir les informations de transfert)',
  'menu.form.step-form.confirm': 'Formulaire par étapes (confirmer les informations de transfert)',
  'menu.form.step-form.result': 'Formulaire par étapes (terminé)',
  'menu.form.advanced-form': 'Formulaire avancé',
  'menu.list': 'Listes',
  'menu.list.audit-list': 'Audit de sécurité',
  'menu.list.audit-list.Alarm': 'Alarmes de risque/anomalie',
  'menu.list.audit-list.Connection': 'Journal des connexions',
  'menu.list.audit-list.File': 'Journal des transferts de fichiers',
  'menu.list.audit-list.Console': "Journal des opérations de la console",
  'menu.list.device-list': 'Appareils',
  'menu.list.user-list': 'Utilisateurs',
  'menu.list.group-list': 'Groupes',
  'menu.list.group-list.user': "Groupes d'utilisateurs",
  'menu.list.group-list.device': "Groupes d'appareils",
  'menu.list.table-list': 'Tableau de recherche',
  'menu.list.basic-list': 'Liste standard',
  'menu.list.card-list': 'Liste de cartes',
  'menu.list.search-list': 'Liste de recherche',
  'menu.list.search-list.articles': 'Liste de recherche (articles)',
  'menu.list.search-list.projects': 'Liste de recherche (projets)',
  'menu.list.search-list.applications': 'Liste de recherche (applications)',
  'menu.profile': 'Profil',
  'menu.profile.basic': 'Profil de base',
  'menu.profile.advanced': 'Profil avancé',
  'menu.result': 'Résultat',
  'menu.result.success': 'Succès',
  'menu.result.fail': 'Échec',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': "Déclencher l'erreur",
  'menu.account': 'Compte',
  'menu.account.center': 'Centre du compte',
  'menu.account.settings': 'Paramètres du compte',
  'menu.account.trigger': "Déclencher l'erreur",
  'menu.account.logout': 'Déconnexion',
  'menu.editor': 'Éditeur graphique',
  'menu.editor.flow': 'Éditeur de flux',
  'menu.editor.mind': 'Éditeur de carte mentale',
  'menu.editor.koni': 'Éditeur de topologie',
  'menu.Settings': 'Paramètres',
  'menu.Strategy': 'Stratégie',
  'menu.Settings.Key': 'Clé',
  'menu.Settings.License': 'Licence',
  'menu.Settings.Relay': 'Gestion des relais',
  'menu.Settings.Others': 'Autres',
  'menu.Settings.Tokens': 'Jetons',
  'menu.Address Books': "Carnets d'adresses",
  'menu.Strategies': 'Stratégies',
  'menu.Custom Clients': 'Clients personnalisés',

  'pages.login.accountLogin.tab': 'Connexion par compte',
  'pages.login.accountLogin.errorMessage': "Nom d'utilisateur ou mot de passe incorrect",
  'pages.login.failure': 'Échec de connexion, veuillez réessayer!',
  'pages.login.success': 'Connexion réussie!',
  'pages.login.username.placeholder': "Nom d'utilisateur: admin ou user",
  'pages.login.username.required': "Le nom d'utilisateur est obligatoire!",
  'pages.login.password.placeholder': 'Mot de passe: ant.design',
  'pages.login.password.required': 'Le mot de passe est obligatoire!',
  'pages.login.phoneLogin.tab': 'Connexion par téléphone',
  'pages.login.phoneLogin.errorMessage': 'Code de vérification incorrect',
  'pages.login.phoneNumber.placeholder': 'Veuillez saisir le numéro de téléphone!',
  'pages.login.phoneNumber.required': 'Le numéro de téléphone est obligatoire!',
  'pages.login.phoneNumber.invalid': 'Numéro de téléphone invalide!',
  'pages.login.captcha.placeholder': 'Veuillez saisir le code de vérification!',
  'pages.login.captcha.required': 'Le code de vérification est obligatoire!',
  'pages.login.phoneLogin.getVerificationCode': 'Obtenir le code',
  'pages.getCaptchaSecondText': 'secondes avant de pouvoir renvoyer',
  'pages.login.rememberMe': 'Se souvenir de moi',
  'pages.login.forgotPassword': 'Mot de passe oublié?',
  'pages.login.submit': 'Connexion',
  'pages.login.loginWith': 'Se connecter avec:',
  'pages.login.loginWithGoogle': 'Se connecter avec Google',
  'pages.login.loginWithGithub': 'Se connecter avec Github',
  'pages.login.loginWithOkta': 'Se connecter avec Okta',
  'pages.login.loginWith.requesting_auth': "Demande de la page d'autorisation",
  'pages.login.loginWith.waiting_auth': "Veuillez terminer l'autorisation du compte",
  'pages.login.registerAccount': 'Créer un compte',
  'pages.welcome.advancedComponent': 'Composant avancé',
  'pages.welcome.link': 'Bienvenue',
  'pages.welcome.advancedLayout': 'Mise en page avancée',
  'pages.welcome.alertMessage': 'Des composants plus rapides et plus puissants ont été publiés.',
  'pages.account.tfaComponent.Enable 2FA': 'Activer 2FA',
  'pages.account.tfaComponent.2 Factor Authentication is enabled.': "L'authentification à deux facteurs est activée",
  'pages.account.tfaComponent.Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.': 'Votre clé 2FA est utilisée depuis longtemps. Nous vous recommandons de mettre à jour votre clé 2FA pour assurer une sécurité maximale du compte.',
  'pages.account.tfaComponent.Enable 2 Factor Authentication.': "Activer l'authentification à deux facteurs.",
  'pages.account.tfa.The code must be 6, 8, 10 digits.': 'Le code doit comporter 6, 8 ou 10 chiffres.',
  'pages.account.tfa.The code must be 6 or 8 digits.': 'Le code doit comporter 6 ou 8 chiffres.',
  'pages.admin.subPage.title': 'Cette page ne peut être consultée que par les administrateurs',
  'pages.admin.subPage.alertMessage': 'umi ui est disponible, vous pouvez démarrer avec npm run ui.',
  'pages.AuditList.FileAuditList.files': 'fichiers',
  'pages.AuditList.FileAuditList.pathTip': "Chemin sur l'appareil contrôlé",
  'pages.AuditList.FileAuditList.limitedTip': 'Afficher un maximum de 10 fichiers',
  'pages.AuditList.FileAuditList.exportFilename': 'Journal des transferts de fichiers',
  'pages.AuditList.ConnAuditList.modal_title': 'Confirmer l\'action',
  'pages.AuditList.ConnAuditList.diconnect_confirm_tip': 'Voulez-vous vraiment déconnecter cet appareil?',
  'pages.AuditList.ConnAuditList.disconnect_succ': 'Déconnexion réussie!',
  'pages.AuditList.ConnAuditList.disconnect_fail': 'Échec de la déconnexion!',
  'pages.AuditList.ConnAuditList.exportFilename': 'Journal des connexions',
  'pages.AuditList.AlarmAuditList.Login Device / Local Device': 'Appareil de connexion / Appareil local',
  'pages.AuditList.AlarmAuditList.Login Account / Remote Device': 'Compte de connexion / Appareil distant',
  'pages.AuditList.AlarmAuditList.Login Account': 'Compte de connexion',
  'pages.AuditList.AlarmAuditList.Device ID': 'ID de l\'appareil',
  'pages.AuditList.AlarmAuditList.Device Name': "Nom de l'appareil",
  'pages.AuditList.AlarmAuditList.Remote Device': 'Appareil distant',
  'pages.AuditList.AlarmAuditList.Event Time': "Heure de l'événement",
  'pages.AuditList.AlarmAuditList.IP whiltelist': 'Tentative d\'accès d\'une IP hors liste blanche',
  'pages.AuditList.AlarmAuditList.ExceedThirtyAccessAttempts': 'Tentatives d\'accès consécutives (30)',
  'pages.AuditList.AlarmAuditList.MultipleAccessAttemptsWithinOneMinute': 'Plusieurs tentatives d\'accès en 1 minute',
  'pages.AuditList.AlarmAuditList.ExceedThirtyLoginAttempts': 'Tentatives de connexion consécutives (30)',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneMinute': 'Plusieurs tentatives de connexion en 1 minute',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneHour': 'Plusieurs tentatives de connexion en 1 heure',
  'pages.AuditList.AlarmAuditList.exportFilename': "Journal des alarmes de risque/anomalie",
  'pages.AuditList.ConsoleAuditList.Operation': 'Opération',
  'pages.AuditList.ConsoleAuditList.Name': 'Nom',
  'pages.AuditList.ConsoleAuditList.Name list': 'Liste des noms',
  'pages.AuditList.ConsoleAuditList.ID list': 'Liste des ID',
  'pages.AuditList.ConsoleAuditList.Result': 'Résultat',
  'pages.AuditList.ConsoleAuditList.Remote': 'Distant',
  'pages.AuditList.ConsoleAuditList.Local': 'Local',
  'pages.AuditList.ConsoleAuditList.User Login': "Connexion de l'utilisateur à la console",
  'pages.AuditList.ConsoleAuditList.Add Group': 'Créer un groupe',
  'pages.AuditList.ConsoleAuditList.Add User': 'Ajouter un utilisateur',
  'pages.AuditList.ConsoleAuditList.Add Device': 'Ajouter un appareil',
  'pages.AuditList.ConsoleAuditList.Delete Groups': 'Supprimer des groupes',
  'pages.AuditList.ConsoleAuditList.Disconnect Device': 'Déconnecter un appareil',
  'pages.AuditList.ConsoleAuditList.Enable Users': 'Activer des utilisateurs',
  'pages.AuditList.ConsoleAuditList.Disable Users': 'Désactiver des utilisateurs',
  'pages.AuditList.ConsoleAuditList.Enable Devices': 'Activer des appareils',
  'pages.AuditList.ConsoleAuditList.Disable Devices': 'Désactiver des appareils',
  'pages.AuditList.ConsoleAuditList.Update Group': 'Mettre à jour un groupe',
  'pages.AuditList.ConsoleAuditList.Update User': 'Mettre à jour un utilisateur',
  'pages.AuditList.ConsoleAuditList.Update Device': 'Mettre à jour un appareil',
  'pages.AuditList.ConsoleAuditList.Delete User': 'Supprimer un utilisateur',
  'pages.AuditList.ConsoleAuditList.Delete Device': 'Supprimer un appareil',
  'pages.AuditList.ConsoleAuditList.Add Address Book': "Ajouter un carnet d'adresses",
  'pages.AuditList.ConsoleAuditList.Delete Address Book': "Supprimer un carnet d'adresses",
  'pages.AuditList.ConsoleAuditList.Change Address Book Name': "Modifier le nom du carnet d'adresses",
  'pages.AuditList.ConsoleAuditList.Group Management': 'Gestion des groupes',
  'pages.AuditList.ConsoleAuditList.User Management': 'Gestion des utilisateurs',
  'pages.AuditList.ConsoleAuditList.Device Management': 'Gestion des appareils',
  'pages.AuditList.ConsoleAuditList.Address Book Management': "Gestion des carnets d'adresses",
  'pages.AuditList.ConsoleAuditList.exportFilename': 'Journal de la console',
  'pages.AuditList.ConsoleAuditList.Delete Devices in the Address Book Recycle Bin': "Supprimer des appareils de la corbeille du carnet d'adresses",
  'pages.AuditList.ConsoleAuditList.Empty Address Book Recycle Bin': "Vider la corbeille du carnet d'adresses",
  'pages.AuditList.ConsoleAuditList.Add Address Book Permission': "Ajouter une permission de carnet d'adresses",
  'pages.AuditList.ConsoleAuditList.Delete Address Book Permission': "Supprimer une permission de carnet d'adresses",
  'pages.AuditList.ConsoleAuditList.Update Address Book Permission': "Modifier une permission de carnet d'adresses",
  'pages.Settings.Strategy.Strategy list': 'Liste des stratégies',
  'pages.Settings.Strategy.Automatically close incoming sessions on user inactivity': "Fermer automatiquement les sessions entrantes lors de l'inactivité de l'utilisateur",
  'pages.Settings.Strategy.delete_strategy_tip': 'Êtes-vous sûr de vouloir supprimer cette stratégie?',
  'pages.Settings.Strategy.New strategy': 'Nouvelle stratégie',
  'pages.Settings.Strategy.Rename strategy': 'Renommer la stratégie',
  'pages.Settings.Strategy.Duplicate strategy': 'Dupliquer la stratégie',
  'pages.Settings.Strategy.Edit Devices': 'Modifier les appareils',
  'pages.Settings.Strategy.Edit Users': 'Modifier les utilisateurs',
  'pages.Settings.Strategy.Rename': 'Renommer',
  'pages.Settings.Strategy.Duplicate': 'Dupliquer',
  'pages.Settings.Strategy.Delete': 'Supprimer',
  'pages.Settings.Strategy.Groups': 'Groupes',
  'pages.Settings.Strategy.Select All': 'Tout sélectionner',
  'pages.Settings.Strategy.Permissions': 'Permissions',
  'pages.Settings.Strategy.Password': 'Mot de passe',
  'pages.Settings.Strategy.Security': 'Sécurité',
  'pages.Settings.Strategy.Other': 'Autre',
  'pages.Settings.Strategy.IP whitelist': 'Liste blanche IP',
  'pages.Settings.Strategy.Cancel': 'Annuler',
  'pages.Settings.Strategy.Submit': 'Confirmer',
  'pages.Settings.Strategy.Edit': 'Modifier',
  'pages.Settings.Strategy.Custom': 'Personnalisé',
  'pages.Settings.Strategy.Full access': 'Accès complet',
  'pages.Settings.Strategy.Screen share': "Partage d'écran uniquement",
  'pages.Settings.Strategy.Enable keyboard/mouse': 'Autoriser le contrôle du clavier/souris',
  'pages.Settings.Strategy.Enable clipboard': 'Autoriser la synchronisation du presse-papiers',
  'pages.Settings.Strategy.Enable file transfer': 'Autoriser le transfert de fichiers',
  'pages.Settings.Strategy.Enable audio': 'Autoriser la transmission audio',
  'pages.Settings.Strategy.Enable TCP tunneling': 'Autoriser le tunneling TCP',
  'pages.Settings.Strategy.Enable remote restart': 'Autoriser le redémarrage à distance',
  'pages.Settings.Strategy.Enable recording session': "Autoriser l'enregistrement de session",
  'pages.Settings.Strategy.Enable blocking user input': "Autoriser le blocage de l'entrée utilisateur",
  'pages.Settings.Strategy.Enable remote configuration modification': 'Autoriser la modification de configuration à distance',
  'pages.Settings.Strategy.Permission type': 'Type de permission',
  'pages.Settings.Strategy.Approve mode': "Mode d'approbation",
  'pages.Settings.Strategy.Password type': 'Type de mot de passe',
  'pages.Settings.Strategy.Hide connection management window': 'Masquer la fenêtre de gestion des connexions',
  'pages.Settings.Strategy.One-time password length': 'Longueur du mot de passe à usage unique',
  'pages.Settings.Strategy.Accept sessions via password': 'Autoriser les sessions uniquement par mot de passe',
  'pages.Settings.Strategy.Accept sessions via click': 'Autoriser les sessions uniquement par clic',
  'pages.Settings.Strategy.Accept sessions via both': 'Autoriser les sessions par mot de passe ou clic',
  'pages.Settings.Strategy.Use one-time password': 'Utiliser un mot de passe à usage unique',
  'pages.Settings.Strategy.Use permanent password': 'Utiliser un mot de passe permanent',
  'pages.Settings.Strategy.Use both passwords': 'Utiliser les deux types de mot de passe',
  'pages.Settings.Strategy.Deny LAN discovery': 'Refuser la découverte sur le réseau local',
  'pages.Settings.Strategy.Enable direct IP access': "Autoriser l'accès direct par IP",
  'pages.Settings.Strategy.auto_disconnect_option_tip': 'Fermer automatiquement les sessions inactives',
  'pages.Settings.Strategy.deny_lan_discovery_desc_tip': 'L\'appareil ne sera pas découvert par les autres appareils du même réseau local',
  'pages.Settings.Strategy.enable_direct_ip_access_desc_tip': 'Autoriser la connexion directe par IP à l\'appareil',
  'pages.Settings.Strategy.Remove wallpaper during incoming sessions': 'Supprimer le fond d\'écran pendant les sessions entrantes',
  'pages.Settings.Strategy.minutes': 'minutes',
  'pages.Settings.Strategy.Enable': 'Activer',
  'pages.Settings.Strategy.Disable': 'Désactiver',
  'pages.Settings.Strategy.disabled': 'désactivé',
  'pages.Settings.Strategy.Default': 'Stratégie par défaut',
  'pages.Settings.Strategy.Apply changes of this page': 'Appliquer les modifications de cette page',
  'pages.Settings.Strategy.Invalid IP': 'IP invalide',
  'pages.Settings.Strategy.IP Whitelisting': 'Liste blanche IP',
  'pages.Settings.Strategy.Clear': 'Effacer',
  'pages.Settings.Strategy.OK': 'OK',
  'pages.Settings.Strategy.whitelist_sep': 'Vous pouvez utiliser des virgules, des points-virgules, des espaces ou des sauts de ligne comme séparateurs',
  'pages.Settings.Strategy.Use IP whitelisting': 'Autoriser uniquement les IP de la liste blanche',
  'pages.Settings.Strategy.whitelist_tip': 'Seules les IP de la liste blanche peuvent accéder à cet appareil',
  'pages.CustomClient.bidirectional_desc_tip': 'Créer un client qui peut se connecter à un autre appareil et recevoir des demandes de connexion',
  'pages.CustomClient.incoming_desc_tip': 'Créer un client qui peut uniquement recevoir des demandes de connexion',
  'pages.CustomClient.outgoing_desc_tip': 'Créer un client qui peut se connecter à un autre appareil',
  'pages.CustomClient.disable_installation_desc_tip': 'Empêcher les utilisateurs d\'installer le client personnalisé (Windows uniquement)',
  'pages.CustomClient.disable_settings_desc_tip': 'Désactiver l\'accès aux paramètres du client personnalisé',
  'pages.CustomClient.disable_ab_desc_tip': 'Désactiver la fonctionnalité de carnet d\'adresses dans le client personnalisé',
  'pages.CustomClient.disable_tcp_desc_tip': 'Éviter la boîte de dialogue du pare-feu Windows au démarrage de RustDesk, cela ignorera les tentatives de connexion P2P directes et utilisera directement la connexion relais',
  'pages.CustomClient.disable_account_desc_tip': 'Supprimer la fonctionnalité de connexion au compte dans le client RustDesk',
  'pages.CustomClient.override_settings_desc_tip': 'Remplacer les paramètres, ne peuvent pas être modifiés dans RustDesk',
  'pages.CustomClient.default_settings_desc_tip': 'Modifier les paramètres par défaut utilisés par RustDesk',
  'pages.Settings.KeyPage.failedToFetchKey': 'Échec de récupération de la clé.',
  'pages.Settings.KeyPage.keyResetSuccess': 'Réinitialisation de la clé réussie, veuillez redémarrer hbbr pour appliquer les modifications.',
  'pages.Settings.KeyPage.errorResettingKey': 'Erreur lors de la réinitialisation de la clé.',
  'pages.Settings.KeyPage.keyUpdateSuccess': 'Mise à jour de la clé réussie, veuillez redémarrer hbbr pour appliquer les modifications.',
  'pages.Settings.KeyPage.errorUpdatingKey': 'Erreur lors de la mise à jour de la clé, veuillez vérifier la clé',
  'pages.Settings.KeyPage.key': 'Clé',
  'pages.Settings.KeyPage.privateKey': 'Clé privée',
  'pages.Settings.KeyPage.publicKey': 'Clé publique',
  'pages.Settings.KeyPage.editKey': 'Mettre à jour la clé',
  'pages.Settings.KeyPage.reset': 'Réinitialiser',
  'pages.Settings.KeyPage.custom': 'Saisie manuelle',
  'pages.Settings.KeyPage.pleaseInputPrivateKey': 'Veuillez saisir votre clé privée!',
  'pages.Settings.License.settingsUpdatedSuccess': 'Paramètres mis à jour avec succès.',
  'pages.Settings.License.licenseCannotBeEmpty': 'La licence ne peut pas être vide.',
  'pages.Settings.License.license': 'Licence',
  'pages.Settings.Relay.GeoOverrideDescription': 'La fonction de remplacement de localisation ne peut être utilisée que lorsque plusieurs serveurs relais existent.',
  'pages.Settings.GeoOverride.ipOrDomain': 'IP/Domaine',
  'pages.Settings.GeoOverride.latitude': 'Latitude',
  'pages.Settings.GeoOverride.longitude': 'Longitude',
  'pages.Settings.GeoOverride.deleteSelectedConfirm': 'Êtes-vous sûr de vouloir supprimer les remplacements de localisation sélectionnés?',
  'pages.Settings.GeoOverride.deleteConfirm': 'Êtes-vous sûr de vouloir supprimer ce remplacement de localisation?',
  'pages.Settings.GeoOverride.editGeoOverride': 'Modifier le remplacement',
  'pages.Settings.GeoOverride.addGeoOverride': 'Ajouter un remplacement',
  'pages.Settings.GeoOverride.ipOrDomainRequired': 'L\'IP/Domaine ne peut pas être vide.',
  'pages.Settings.GeoOverride.latitudeRequired': 'La latitude ne peut pas être vide.',
  'pages.Settings.GeoOverride.longitudeRequired': 'La longitude ne peut pas être vide.',
  'pages.Settings.GeoOverride.testGeo': 'Tester la localisation',
  'pages.Settings.GeoOverride.geofileFetchFailed': 'Échec de récupération des informations GeoFile.',
  'pages.Settings.GeoOverride.loadGeoOverrides': 'Charger les remplacements',
  'pages.Settings.GeoOverride.geoOverride': 'Remplacement de localisation',
  'pages.Settings.GeoOverride.title': 'Remplacement de localisation',
  'pages.Settings.GeoOverride.RelaySettings': 'Paramètres',
  'pages.Settings.GeoOverride.RelayServers': 'Serveurs relais',
  'pages.Settings.GeoOverride.AddServer': 'Ajouter un serveur',
  'pages.Settings.GeoOverride.AlwaysUseRelay': 'Toujours utiliser le relais',
  'pages.Settings.GeoOverride.UpdateServers': 'Importer les modifications',
  'pages.Settings.GeoOverride.UpdatedAlwaysUseRelay': 'Toujours utiliser le relais mis à jour',
  'pages.Settings.GeoOverride.FailedToUpdateAlwaysUseRelay': 'Échec de mise à jour de Toujours utiliser le relais',
  'pages.Settings.GeoOverride.UpdatedRelayServers': 'Serveurs relais mis à jour',
  'pages.Settings.GeoOverride.FailedToUpdateRelayServers': 'Échec de mise à jour des serveurs relais',
  'pages.Settings.GeoOverride.UpdatedChangeIDSetting': 'Paramètre de modification d\'ID mis à jour',
  'pages.Settings.GeoOverride.FailedToUpdateChangeIDSetting': 'Échec de mise à jour du paramètre de modification d\'ID',
  "pages.Settings.Others.Allow change ID": "Autoriser le changement d'ID",
  "pages.Settings.Others.Disable new devices": "Désactiver les nouveaux appareils",
  "pages.Settings.Others.The control end need to login before access": "L'extrémité de contrôle doit se connecter avant d'accéder",
  "pages.Settings.Others.Only admin can access unassigned devices": "Seul l'administrateur peut accéder aux appareils non attribués",
  "pages.Settings.Others.Allow non-admin to see other groups": "Autoriser les non-administrateurs à voir d'autres groupes",
  "pages.Settings.Others.allow_non_admin_see_other_group_tip": "Lorsque cette option est désactivée, les non-administrateurs ne peuvent partager des carnets d'adresses qu'au sein de leur propre groupe.",
  'pages.Settings.OIDC.Maximum Number of Authorized Users': 'Nombre maximum d\'utilisateurs autorisés',
  'pages.Settings.OIDC.emptyPrompt': 'Aucun fournisseur d\'authentification tiers actuellement, veuillez en ajouter un pour utiliser cette fonctionnalité.',
  'pages.Settings.OIDC.confirm-delete-title': 'Confirmer la suppression',
  'pages.Settings.OIDC.confirm-delete-content': 'Les utilisateurs autorisés par ce tiers ne pourront plus se connecter. Vous pouvez accéder à l\'interface de gestion des utilisateurs pour supprimer ces utilisateurs.',
  'pages.Settings.Others.Only admin can access logs': 'Seul l\'administrateur peut accéder aux journaux non attribués',
  'pages.Welcome.Max Devices': 'Nombre maximal d\'appareils',
  'pages.Welcome.Max Users': 'Nombre maximal d\'utilisateurs',
  'pages.Welcome.Not set': 'Non défini',
  'pages.Welcome.Invalid': 'Non valide',
  'pages.Welcome.Demo': 'Démo',
  'pages.Welcome.Personal': 'Personnel',
  'pages.Welcome.Business': 'Entreprise',
  'pages.Welcome.Unknown': 'Autre',
  'pages.Welcome.Max Peers': 'Nombre maximal d\'appareils',
  'pages.Welcome.RustDesk Server Status': 'État du serveur RustDesk',
  'pages.Welcome.License Expire Time': 'Date d\'expiration de la licence',
  'pages.Welcome.Document': 'Documentation',
  'pages.AddressBook.Permission': 'Permission',
  'pages.AddressBook.Device count': 'Nombre d\'appareils',
  'pages.AddressBook.Address Books': 'Carnets d\'adresses',
  'pages.AddressBook.Tags': 'Étiquettes',
  'pages.AddressBook.Devices': 'Appareils',
  'pages.AddressBook.Device': 'Appareil',
  'pages.AddressBook.Alias': 'Alias',
  'pages.AddressBook.Whether a password is set': 'Si un mot de passe est défini',
  'pages.AddressBook.Recycle Bin': 'Corbeille',
  'pages.AddressBook.Empty Recycle Bin': 'Vider la corbeille',
  'pages.AddressBook.Personal': 'Personnel',
  'pages.AddressBook.Personal (My address book)': 'Personnel (Mon carnet d\'adresses)',
  'pages.AddressBook.Shared': 'Partagé',
  'pages.AddressBook.Share': 'Partager',
  'pages.AddressBook.Delete': 'Supprimer',
  'pages.AddressBook.Settings': 'Paramètres',
  'pages.AddressBook.Owner': 'Propriétaire',
  'pages.AddressBook.My Permission': 'Ma permission',
  'pages.AddressBook.Read-only': 'Lecture seule',
  'pages.AddressBook.Read/Write': 'Lecture/Écriture',
  'pages.AddressBook.Full Control': 'Contrôle total',
  'pages.AddressBook.share_warning_tip': 'Les champs ci-dessus sont partagés et visibles par d\'autres personnes.',
  'pages.AddressBook.permission_tip': 'Lecture seule : peut voir les appareils et les étiquettes; Lecture/Écriture : peut modifier les appareils et les étiquettes; Contrôle total : peut repartager, supprimer ou renommer le carnet d\'adresses.',
  'pages.AddressBook.rule_priority_tip': 'Priorité : Utilisateur > Groupe > Tout le monde',
  'pages.AddressBook.Remove password': 'Supprimer le mot de passe',
  'pages.AddressBook.Import': 'Importer',
  'pages.AddressBook.Filter by intersection': 'Filtrer par intersection',
  'pages.AddressBook.Permissions': 'Permissions',
  'pages.AddressBook.Type': 'Type',
  'pages.AddressBook.Untagged': 'Sans étiquette',

  'app.pwa.offline': 'Vous êtes actuellement hors ligne',
  'app.pwa.serviceworker.updated': 'Nouveau contenu disponible',
  'app.pwa.serviceworker.updated.hint': 'Veuillez cliquer sur le bouton "Actualiser" ou actualiser manuellement la page',
  'app.pwa.serviceworker.updated.ok': 'Actualiser',

  'app.setting.pagestyle': 'Paramètres de style global',
  'app.setting.pagestyle.dark': 'Style de menu sombre',
  'app.setting.pagestyle.light': 'Style de menu clair',
  'app.setting.content-width': 'Largeur de la zone de contenu',
  'app.setting.content-width.fixed': 'Fixe',
  'app.setting.content-width.fluid': 'Fluide',
  'app.setting.themecolor': 'Couleur du thème',
  'app.setting.themecolor.dust': 'Poussière',
  'app.setting.themecolor.volcano': 'Volcan',
  'app.setting.themecolor.sunset': 'Crépuscule',
  'app.setting.themecolor.cyan': 'Cyan',
  'app.setting.themecolor.green': 'Vert aurore',
  'app.setting.themecolor.daybreak': 'Bleu aube (par défaut)',
  'app.setting.themecolor.geekblue': 'Bleu geek',
  'app.setting.themecolor.purple': 'Violet',
  'app.setting.navigationmode': 'Mode de navigation',
  'app.setting.sidemenu': 'Disposition avec menu latéral',
  'app.setting.topmenu': 'Disposition avec menu supérieur',
  'app.setting.fixedheader': 'En-tête fixe',
  'app.setting.fixedsidebar': 'Menu latéral fixe',
  'app.setting.fixedsidebar.hint': 'Fonctionne avec la disposition du menu latéral',
  'app.setting.hideheader': 'Masquer l\'en-tête lors du défilement',
  'app.setting.hideheader.hint': 'Fonctionne quand l\'en-tête est fixe',
  'app.setting.othersettings': 'Autres paramètres',
  'app.setting.weakmode': 'Mode daltonien',
  'app.setting.copy': 'Copier les paramètres',
  'app.setting.copyinfo': 'Copie réussie, veuillez remplacer la configuration par défaut dans config/defaultSettings.js',
  'app.setting.production.hint':
    'La barre de configuration n\'est visible qu\'en mode développement pour prévisualisation. Veuillez modifier manuellement le fichier de configuration en production.',

  'app.settings.menuMap.basic': 'Paramètres de base',
  'app.settings.menuMap.security': 'Paramètres de sécurité',
  'app.settings.menuMap.binding': 'Liaison de compte',
  'app.settings.menuMap.notification': 'Notifications de nouveaux messages',
  'app.settings.basic.avatar': 'Avatar',
  'app.settings.basic.change-avatar': 'Changer d\'avatar',
  'app.settings.basic.email': 'E-mail',
  'app.settings.basic.email-message': 'Veuillez saisir votre e-mail!',
  'app.settings.basic.nickname': 'Pseudo',
  'app.settings.basic.nickname-message': 'Veuillez saisir votre pseudo!',
  'app.settings.basic.profile': 'Profil personnel',
  'app.settings.basic.profile-message': 'Veuillez saisir votre profil personnel!',
  'app.settings.basic.profile-placeholder': 'Profil personnel',
  'app.settings.basic.country': 'Pays/Région',
  'app.settings.basic.country-message': 'Veuillez saisir votre pays ou région!',
  'app.settings.basic.geographic': 'Province et ville',
  'app.settings.basic.geographic-message': 'Veuillez saisir votre province et ville!',
  'app.settings.basic.address': 'Adresse',
  'app.settings.basic.address-message': 'Veuillez saisir votre adresse!',
  'app.settings.basic.phone': 'Téléphone',
  'app.settings.basic.phone-message': 'Veuillez saisir votre téléphone!',
  'app.settings.basic.update': 'Mettre à jour les informations de base',
  'app.settings.security.strong': 'Fort',
  'app.settings.security.medium': 'Moyen',
  'app.settings.security.weak': 'Faible',
  'app.settings.security.password': 'Mot de passe du compte',
  'app.settings.security.password-description': 'Force actuelle du mot de passe',
  'app.settings.security.phone': 'Téléphone de sécurité',
  'app.settings.security.phone-description': 'Téléphone lié',
  'app.settings.security.question': 'Question de sécurité',
  'app.settings.security.question-description': 'Question de sécurité non définie, qui peut protéger efficacement la sécurité du compte',
  'app.settings.security.email': 'E-mail de secours',
  'app.settings.security.email-description': 'E-mail lié',
  'app.settings.security.mfa': 'Appareil MFA',
  'app.settings.security.mfa-description': 'Appareil MFA non lié, après la liaison, une vérification secondaire peut être effectuée',
  'app.settings.security.modify': 'Modifier',
  'app.settings.security.set': 'Définir',
  'app.settings.security.bind': 'Lier',
  'app.settings.binding.taobao': 'Lier Taobao',
  'app.settings.binding.taobao-description': 'Compte Taobao non lié actuellement',
  'app.settings.binding.alipay': 'Lier Alipay',
  'app.settings.binding.alipay-description': 'Compte Alipay non lié actuellement',
  'app.settings.binding.dingding': 'Lier DingTalk',
  'app.settings.binding.dingding-description': 'Compte DingTalk non lié actuellement',
  'app.settings.binding.bind': 'Lier',
  'app.settings.notification.password': 'Mot de passe du compte',
  'app.settings.notification.password-description': 'Les messages des autres utilisateurs seront notifiés sous forme de messages internes',
  'app.settings.notification.messages': 'Messages système',
  'app.settings.notification.messages-description': 'Les messages système seront notifiés sous forme de messages internes',
  'app.settings.notification.todo': 'Tâches à faire',
  'app.settings.notification.todo-description': 'Les tâches à faire seront notifiées sous forme de messages internes',
  'app.settings.open': 'Activé',
  'app.settings.close': 'Désactivé',
  'app.copyright.produced': 'Développé par l\'équipe RustDesk',
  'pages.account.Settings.Security.Password': 'Mot de passe',
  'pages.account.Settings.Security.Change password': 'Changer le mot de passe',
  'pages.account.tfa.Enable 2FA': 'Activer 2FA',
  'pages.account.tfa.2 Factor Authentication is enabled.': 'L\'authentification à deux facteurs est activée',
  'pages.account.tfa.Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.': 'Votre clé 2FA est utilisée depuis longtemps. Nous vous recommandons de mettre à jour votre clé 2FA pour assurer une sécurité maximale du compte.',
  'pages.account.tfa.Enable 2 Factor Authentication.': 'Activer l\'authentification à deux facteurs.',

  'Enable Two-Factor Authentication': 'Activer l\'authentification à deux facteurs',
  'Make your RustDesk account more secure by requiring an extra authentication code when you log in.': 'Rendez votre compte RustDesk plus sécurisé en exigeant un code d\'authentification supplémentaire lors de la connexion.',
  '1. Download an authenticator app': '1. Téléchargez une application d\'authentification',
  'Download an authenticator app such as': 'Téléchargez une application d\'authentification comme Google Authenticator ou Authy',
  '2FA code': 'Code 2FA',
  'Authenticator': 'Authentificateur',
  'on your phone or desktop.': 'sur votre téléphone ou ordinateur.',
  '2. Scan the QR code': '2. Scannez le code QR',
  'Open your authenticator app, scan the QR code and enter the code that your app shows.': 'Ouvrez votre application d\'authentification, scannez le code QR et saisissez le code affiché par votre application.',
  'You can change or remove 2FA at any time in your account settings.': 'Vous pouvez modifier ou supprimer la 2FA à tout moment dans les paramètres de votre compte.',
  'Please verify your 2FA code to disable current 2FA first.': 'Veuillez vérifier votre code 2FA pour désactiver d\'abord la 2FA actuelle.',
  'Can\'t verify the code. Check that code and local time settings are correct!': 'Impossible de vérifier le code. Vérifiez que le code et les paramètres d\'heure locale sont corrects!',
  'First sign-in:': 'Première connexion:',
  'Recent activity:': 'Activité récente:',
  'pages.Settings.GeoOverride.reloadGeo': 'Recharger les données de géolocalisation',
  'pages.Settings.GeoOverride.getRelayServer': 'Obtenir le serveur relais',
  'pages.Settings.GeoOverride.relayServer': 'Serveur relais',
  'SMTP Host': 'Hôte SMTP',
  'SMTP Port': 'Port SMTP',
  'My Server Requires Authentication': 'Mon serveur nécessite une authentification',
  'smtp.From': 'Expéditeur',
  'Mail Account': 'Compte de messagerie',
  'Mail Password': 'Mot de passe de messagerie',
  'Custom Certificate PEM File Path (on the server)': 'Chemin du fichier PEM de certificat personnalisé (sur le serveur)',
  Update: 'Mettre à jour',
  'Submit and Test Email': "Soumettre et tester l'e-mail",
  'he Receiver Email': "E-mail du destinataire",
  'LDAP Host': 'Hôte LDAP',
  'LDAP Port': 'Port LDAP',
  'Filter': 'Filtre',
  'Username Attribute': "Attribut nom d'utilisateur",
  'The attribute used as the username when logging in. Default is "dn".': 'L\'attribut utilisé comme nom d\'utilisateur lors de la connexion. La valeur par défaut est "dn".',
  'DraggableTab.Drag tab to reorder': 'Faites glisser l\'onglet pour réorganiser',
  'pages.Settings.OIDC.new.Auth Type': "Type d'authentification",
  'pages.Settings.OIDC.New Auth Provider': "Nouveau fournisseur d'authentification",
  'pages.Settings.OIDC.new.New Auth Provider': "Nouveau fournisseur d'authentification",
  'pages.Settings.OIDC.Delete This Provider': 'Supprimer ce fournisseur',
  'pages.Settings.OIDC.new.Enable PKCE': 'Activer PKCE',
  'pages.Settings.OIDC.Enable PKCE': 'Activer PKCE',
  'pages.Settings.OIDC.new.Timeout(seconds) to force a refresh of metadata(e.g. JWKS) from OIDC providers': 'Délai d\'attente (secondes) pour forcer l\'actualisation des métadonnées (ex. JWKS) des fournisseurs OIDC',
  'pages.Settings.OIDC.new.0 means no timeout, empty means default (3600)': '0 signifie pas de délai, vide signifie valeur par défaut (3600)',
  'Callback URL': 'URL de rappel',
  'pages.Settings.Others.Session expiration time (Day)': "Délai d'expiration de session (jours)",
  'Or': 'Ou',
};
