export default {
  'app.title': 'Consola RustDesk',
  'navBar.lang': 'Idioma',
  'layout.user.link.help': 'Ayuda',
  'layout.user.link.privacy': 'Privacidad',
  'layout.user.link.terms': 'Términos',
  'app.preview.down.block': 'Descargar esta página al proyecto local',
  'app.welcome.link.fetch-blocks': 'Obtener todos los bloques',
  'app.welcome.link.block-list': 'Desarrollar rápidamente páginas estándar basadas en bloques',
  'The verification code is incorrect or has expired': 'El código de verificación es incorrecto o ha caducado',
  Type: 'Tipo',
  Remote: 'Dispositivo remoto',
  remoteTip: 'Computadora u otro terminal controlado de forma remota',
  Local: 'Dispositivo local',
  'Start Time': 'Hora de inicio',
  'End Time': 'Hora de finalización',
  Note: 'Nota',
  'Log List': 'Lista de registros',
  'Device List': 'Lista de dispositivos',
  'User List': 'Lista de usuarios',
  'User Name': 'Nombre de usuario',
  Updating: 'Actualizando',
  Adding: 'Añadiendo',
  Deleting: 'Eliminando',
  Create: 'Crear',
  Add: 'Añadir',
  Confirm: 'Confirmar',
  Submit: 'Enviar',
  Warning: 'Advertencia',
  Error: 'Error',
  Email: 'Correo electrónico',
  Status: 'Estado',
  Action: 'Acción',
  Password: 'Contraseña',
  'New Password': 'Nueva contraseña',
  Edit: 'Editar',
  Normal: 'Normal',
  Disabled: 'Deshabilitado',
  Disable: 'Deshabilitar',
  Enable: 'Habilitar',
  Chosen: 'Seleccionado',
  Items: 'elementos',
  User: 'Usuario',
  Info: 'Información',
  'Log out': 'Cerrar sesión',
  Settings: 'Configuración',
  'Basic Settings': 'Configuración básica',
  'Remote Desktop': 'Escritorio remoto',
  'File Transfer': 'Transferencia de archivos',
  'Port Transfer': 'Reenvío de puertos',
  'View Camera': 'Ver cámara',
  'Not Logged In': 'No ha iniciado sesión',
  'Created Time': 'Fecha de creación',
  Delete: 'Eliminar',
  Group: 'Grupo',
  'Device Group': 'Grupo de dispositivos',
  'User Group': 'Grupo de usuarios',
  Name: 'Nombre',
  'Group List': 'Lista de grupos',
  deleteConfirm: '¿Está seguro de que desea eliminar?',
  groupTip: 'Los usuarios del mismo grupo pueden acceder entre sí',
  document: 'Documentación',
  Detail: 'Detalles',
  Direction: 'Dirección',
  File: 'Archivo',
  Directory: 'Directorio',
  Disconnect: 'Desconectar',
  Time: 'Tiempo',
  'Export as csv': 'Exportar como CSV',
  exportCsvTip: 'Máximo 1000 registros pueden ser exportados a la vez',
  'Not binded only': 'Mostrar solo dispositivos no vinculados',
  Close: 'Cerrar',
  'Default Strategy': 'Estrategia predeterminada',
  'applied device num': 'Número de dispositivos aplicados',
  'Device Name': 'Nombre del dispositivo',
  'Strategy': 'Estrategia',
  'Device Strategy': 'Estrategia de dispositivo',
  'User Strategy': 'Estrategia de usuario',
  'Group Strategy': 'Estrategia de grupo',
  'strategy_tooltip': 'Formato: "Estrategia de dispositivo / Estrategia de usuario / Estrategia de grupo". "-" solo significa usar la estrategia predeterminada, "-" en una posición significa que no hay estrategia establecida a ese nivel',
  Search: 'Buscar',
  Select: 'Seleccionar',
  Assign: 'Asignar',
  Device: 'Dispositivo',
  'Device Username': 'Nombre de usuario del dispositivo',
  Role: 'Rol',
  'Auth Type': 'Tipo de autenticación',
  Invite: 'Invitar',
  'Please configure Email SMTP settings first': 'Configure primero la configuración SMTP del correo electrónico',
  'Security Settings': 'Configuración de seguridad',
  'Manage Signed Devices': 'Gestionar dispositivos firmados',
  'Since 2FA is enabled, the email login verification option is not used. 2FA login verification will be used instead.': 'Como 2FA está habilitado, la opción de verificación de inicio de sesión por correo electrónico no se utiliza. En su lugar, se utilizará la verificación de inicio de sesión 2FA.',
  'Enable alarm event email notification': 'Habilitar notificación por correo de eventos de alarma',
  'Enable email login verification': 'Habilitar verificación de inicio de sesión por correo',
  'License Type': 'Tipo de licencia',
  'Renew / Upgrade License': 'Renovar / Actualizar licencia',
  Save: 'Guardar',
  Back: 'Volver',
  'Confirm Password': 'Confirmar contraseña',
  'username@device_name': 'usuario@nombre_dispositivo',
  'Hide username': 'Ocultar nombre de usuario',
  'Remote device': 'Dispositivo remoto',
  'Within-group connections': 'Conexiones dentro del grupo',
  'Cross-group connections': 'Conexiones entre grupos',
  welcomeTitle: 'Bienvenido a RustDesk',
  Cancel: 'Cancelar',
  OK: 'Aceptar',

  'pages.GroupList.User Group': 'Grupo de usuarios',
  'pages.GroupList.User': 'Usuario',

  'pages.CustomClient.Application name': 'Nombre de la aplicación',
  'pages.CustomClient.Name': 'Nombre',
  'pages.CustomClient.Build': 'Compilación',
  'pages.CustomClient.Note': 'Nota',
  'pages.CustomClient.Action': 'Acción',
  'pages.CustomClient.Delete': 'Eliminar',
  'pages.CustomClient.Edit': 'Editar',
  'pages.CustomClient.Duplicate': 'Duplicar',
  'pages.CustomClient.Create': 'Crear',
  'pages.CustomClient.Custom Clients': 'Clientes personalizados',
  'pages.CustomClient.General': 'General',
  'pages.CustomClient.Name of the configuration': 'Nombre de la configuración',
  'pages.CustomClient.MSI installer': 'Instalador MSI',
  'pages.CustomClient.Connection type': 'Tipo de conexión',
  'pages.CustomClient.Bidirectional': 'Bidireccional',
  'pages.CustomClient.Incoming': 'Entrante',
  'pages.CustomClient.Outgoing': 'Saliente',
  'pages.CustomClient.Options': 'Opciones',
  'pages.CustomClient.Disable installation': 'Deshabilitar instalación',
  'pages.CustomClient.Disable settings': 'Deshabilitar configuración',
  'pages.CustomClient.Disable address book': 'Deshabilitar libreta de direcciones',
  'pages.CustomClient.Disable TCP listen port': 'Deshabilitar puerto de escucha TCP',
  'pages.CustomClient.Disable user account': 'Deshabilitar cuenta de usuario',
  'pages.CustomClient.Some note about this configuration': 'Algunas notas sobre esta configuración',
  'pages.CustomClient.Custom server': 'Servidor personalizado',
  'pages.CustomClient.Visual': 'Visual',
  'pages.CustomClient.Application icon': 'Icono de la aplicación',
  'pages.CustomClient.Security': 'Seguridad',
  'pages.CustomClient.Preset password': 'Contraseña predeterminada',
  'pages.CustomClient.Advanced': 'Avanzado',
  'pages.CustomClient.Override settings': 'Sobrescribir configuración',
  'pages.CustomClient.Default settings': 'Configuración predeterminada',
  'pages.CustomClient.Note: ': 'Nota: ',
  'pages.CustomClient.Click or drag to upload': 'Haga clic o arrastre para cargar',
  'pages.CustomClient.app_icon_desc_tip': 'Cargue una imagen PNG cuadrada de 512x512 píxeles como icono de la aplicación.',
  'pages.CustomClient.app_name_desc_tip': 'Especifique un nombre único que aparecerá en el menú Inicio, Panel de control, etc. para el cliente RustDesk instalado.',
  'pages.CustomClient.logo_desc_tip': 'Cargue una imagen PNG que se mostrará en la parte superior de la ventana principal del cliente personalizado. El tamaño se redimensionará automáticamente. Recomendamos un PNG de 200x40 píxeles.',
  'pages.CustomClient.This password will be used as a default permanent password for all new clients. It can be changed by the client.': 'Esta contraseña se utilizará como contraseña permanente predeterminada para todos los nuevos clientes. Puede ser cambiada por el cliente.',
  'pages.CustomClient.The application icon must be a PNG file.': 'El icono de la aplicación debe ser un archivo PNG.',
  'component.tagSelect.expand': 'Expandir',
  'component.tagSelect.collapse': 'Colapsar',
  'component.tagSelect.all': 'Todos',
  'component.LicenseAlert.Your license is invalid.': 'Su licencia no es válida.',
  'component.LicenseAlert.Your license is not set.': 'Su licencia no está configurada.',
  'component.LicenseAlert.Your license is about to expire in 2 weeks.': 'Su licencia está a punto de caducar en 2 semanas.',
  'component.LicenseAlert.Your license has expired.': 'Su licencia ha caducado.',
  'pages.CustomClient.Refresh status manually': 'Actualizar estado manualmente',
  'pages.CustomClient.the building task': 'la tarea de compilación',
  'pages.CustomClient.Stop': 'Detener',
  'pages.CustomClient.Cancel': 'Cancelar',
  'pages.CustomClient.Completed at %s, this file will be deleted in 1 day.': 'Completado el %s, este archivo se eliminará en 1 día.',

  'component.globalHeader.search': 'Buscar en el sitio',
  'component.globalHeader.help': 'Documentación',
  'component.globalHeader.notification': 'Notificaciones',
  'component.globalHeader.notification.empty': 'Ha visto todas las notificaciones',
  'component.globalHeader.message': 'Mensajes',
  'component.globalHeader.message.empty': 'Ha leído todos los mensajes',
  'component.globalHeader.event': 'Eventos',
  'component.globalHeader.event.empty': 'Ha completado todos los eventos',
  'component.noticeIcon.clear': 'Limpiar',
  'component.noticeIcon.cleared': 'Limpiado',
  'component.noticeIcon.empty': 'Sin datos',
  'component.noticeIcon.view-more': 'Ver más',
  'Token List': 'Lista de tokens',
  Description: 'Descripción',
  'Device Permission': 'Permiso de dispositivo',
  'Audit Log Permission': 'Permiso de registro de auditoría',
  'User Permission': 'Permiso de usuario',
  'Group Permission': 'Permiso de grupo',
  'Strategy Permission': 'Permiso de estrategia',

  'menu.welcome': 'Bienvenido',
  'menu.more-blocks': 'Más bloques',
  'menu.home': 'Inicio',
  'menu.admin': 'Administración',
  'menu.admin.sub-page': 'Subpágina',
  'menu.login': 'Iniciar sesión',
  'menu.register': 'Registrarse',
  'menu.register-result': 'Resultado del registro',
  'menu.dashboard': 'Panel',
  'menu.dashboard.analysis': 'Análisis',
  'menu.dashboard.monitor': 'Monitoreo',
  'menu.dashboard.workplace': 'Espacio de trabajo',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Formulario',
  'menu.form.basic-form': 'Formulario básico',
  'menu.form.step-form': 'Formulario por pasos',
  'menu.form.step-form.info': 'Formulario por pasos (información de transferencia)',
  'menu.form.step-form.confirm': 'Formulario por pasos (confirmar transferencia)',
  'menu.form.step-form.result': 'Formulario por pasos (completado)',
  'menu.form.advanced-form': 'Formulario avanzado',
  'menu.list': 'Listas',
  'menu.list.audit-list': 'Auditoría de seguridad',
  'menu.list.audit-list.Alarm': 'Alarmas de riesgo/anomalía',
  'menu.list.audit-list.Connection': 'Registros de conexión',
  'menu.list.audit-list.File': 'Registros de transferencia de archivos',
  'menu.list.audit-list.Console': 'Registros de operaciones de consola',
  'menu.list.device-list': 'Dispositivos',
  'menu.list.user-list': 'Usuarios',
  'menu.list.group-list': 'Grupos',
  'menu.list.group-list.user': 'Grupos de usuarios',
  'menu.list.group-list.device': 'Grupos de dispositivos',
  'menu.list.table-list': 'Tabla de búsqueda',
  'menu.list.basic-list': 'Lista básica',
  'menu.list.card-list': 'Lista de tarjetas',
  'menu.list.search-list': 'Lista de búsqueda',
  'menu.list.search-list.articles': 'Lista de búsqueda (artículos)',
  'menu.list.search-list.projects': 'Lista de búsqueda (proyectos)',
  'menu.list.search-list.applications': 'Lista de búsqueda (aplicaciones)',
  'menu.profile': 'Perfil',
  'menu.profile.basic': 'Perfil básico',
  'menu.profile.advanced': 'Perfil avanzado',
  'menu.result': 'Resultado',
  'menu.result.success': 'Éxito',
  'menu.result.fail': 'Fallo',
  'menu.exception': 'Excepción',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Disparador de error',
  'menu.account': 'Cuenta',
  'menu.account.center': 'Centro de cuenta',
  'menu.account.settings': 'Configuración de cuenta',
  'menu.account.trigger': 'Disparador de error',
  'menu.account.logout': 'Cerrar sesión',
  'menu.editor': 'Editor gráfico',
  'menu.editor.flow': 'Editor de flujo',
  'menu.editor.mind': 'Editor de mapa mental',
  'menu.editor.koni': 'Editor de topología',
  'menu.Settings': 'Configuración',
  'menu.Strategy': 'Estrategia',
  'menu.Settings.Key': 'Clave',
  'menu.Settings.License': 'Licencia',
  'menu.Settings.Relay': 'Gestión de relés',
  'menu.Settings.Others': 'Otros',
  'menu.Settings.Tokens': 'Tokens',
  'menu.Address Books': 'Libretas de direcciones',
  'menu.Strategies': 'Estrategias',
  'menu.Custom Clients': 'Clientes personalizados',

  'pages.login.accountLogin.tab': 'Iniciar sesión con cuenta',
  'pages.login.accountLogin.errorMessage': 'Nombre de usuario o contraseña incorrectos',
  'pages.login.failure': '¡Error al iniciar sesión, inténtelo de nuevo!',
  'pages.login.success': '¡Inicio de sesión exitoso!',
  'pages.login.username.placeholder': 'Nombre de usuario: admin o user',
  'pages.login.username.required': '¡Por favor, ingrese su nombre de usuario!',
  'pages.login.password.placeholder': 'Contraseña: ant.design',
  'pages.login.password.required': '¡Por favor, ingrese su contraseña!',
  'pages.login.phoneLogin.tab': 'Iniciar sesión con teléfono',
  'pages.login.phoneLogin.errorMessage': 'Código de verificación incorrecto',
  'pages.login.phoneNumber.placeholder': '¡Por favor, ingrese su número de teléfono!',
  'pages.login.phoneNumber.required': '¡El número de teléfono es obligatorio!',
  'pages.login.phoneNumber.invalid': '¡Número de teléfono no válido!',
  'pages.login.captcha.placeholder': '¡Por favor, ingrese el código de verificación!',
  'pages.login.captcha.required': '¡El código de verificación es obligatorio!',
  'pages.login.phoneLogin.getVerificationCode': 'Obtener código',
  'pages.getCaptchaSecondText': 'segundos antes de poder solicitar de nuevo',
  'pages.login.rememberMe': 'Recordarme',
  'pages.login.forgotPassword': '¿Olvidó su contraseña?',
  'pages.login.submit': 'Iniciar sesión',
  'pages.login.loginWith': 'Iniciar sesión con:',
  'pages.login.loginWithGoogle': 'Iniciar sesión con Google',
  'pages.login.loginWithGithub': 'Iniciar sesión con Github',
  'pages.login.loginWithOkta': 'Iniciar sesión con Okta',
  'pages.login.loginWith.requesting_auth': 'Solicitando página de autorización',
  'pages.login.loginWith.waiting_auth': 'Por favor, complete la autorización de la cuenta',
  'pages.login.registerAccount': 'Crear cuenta',
  'pages.welcome.advancedComponent': 'Componente avanzado',
  'pages.welcome.link': 'Bienvenido',
  'pages.welcome.advancedLayout': 'Diseño avanzado',
  'pages.welcome.alertMessage': 'Se han publicado componentes más rápidos y potentes.',
  'pages.account.tfaComponent.Enable 2FA': 'Habilitar 2FA',
  'pages.account.tfaComponent.2 Factor Authentication is enabled.': 'La autenticación de dos factores está habilitada.',
  'pages.account.tfaComponent.Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.': 'Su clave 2FA ha estado en uso durante un período prolongado. Recomendamos actualizar su clave 2FA para garantizar la máxima seguridad de la cuenta.',
  'pages.account.tfaComponent.Enable 2 Factor Authentication.': 'Habilitar autenticación de dos factores.',
  'pages.account.tfa.The code must be 6, 8, 10 digits.': 'El código debe tener 6, 8 o 10 dígitos.',
  'pages.account.tfa.The code must be 6 or 8 digits.': 'El código debe tener 6 u 8 dígitos.',
  'pages.admin.subPage.title': 'Esta página solo puede ser vista por administradores',
  'pages.admin.subPage.alertMessage': 'umi ui está disponible, puede comenzar con npm run ui.',
  'pages.AuditList.FileAuditList.files': 'archivos',
  'pages.AuditList.FileAuditList.pathTip': 'Ruta en el dispositivo controlado',
  'pages.AuditList.FileAuditList.limitedTip': 'Visualización limitada a 10 archivos',
  'pages.AuditList.FileAuditList.exportFilename': 'Registros de transferencia de archivos',
  'pages.AuditList.ConnAuditList.modal_title': 'Confirmar acción',
  'pages.AuditList.ConnAuditList.diconnect_confirm_tip': '¿Está seguro de que desea desconectar este dispositivo?',
  'pages.AuditList.ConnAuditList.disconnect_succ': '¡Desconexión exitosa!',
  'pages.AuditList.ConnAuditList.disconnect_fail': '¡Error al desconectar!',
  'pages.AuditList.ConnAuditList.exportFilename': 'Registros de conexión',
  'pages.AuditList.AlarmAuditList.Login Device / Local Device': 'Dispositivo de inicio de sesión / Dispositivo local',
  'pages.AuditList.AlarmAuditList.Login Account / Remote Device': 'Cuenta de inicio de sesión / Dispositivo remoto',
  'pages.AuditList.AlarmAuditList.Login Account': 'Cuenta de inicio de sesión',
  'pages.AuditList.AlarmAuditList.Device ID': 'ID del dispositivo',
  'pages.AuditList.AlarmAuditList.Device Name': 'Nombre del dispositivo',
  'pages.AuditList.AlarmAuditList.Remote Device': 'Dispositivo remoto',
  'pages.AuditList.AlarmAuditList.Event Time': 'Hora del evento',
  'pages.AuditList.AlarmAuditList.IP whiltelist': 'Intento de acceso desde IP fuera de la lista blanca',
  'pages.AuditList.AlarmAuditList.ExceedThirtyAccessAttempts': 'Intentos de acceso consecutivos (30)',
  'pages.AuditList.AlarmAuditList.MultipleAccessAttemptsWithinOneMinute': 'Múltiples intentos de acceso en 1 minuto',
  'pages.AuditList.AlarmAuditList.ExceedThirtyLoginAttempts': 'Intentos de inicio de sesión consecutivos (30)',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneMinute': 'Múltiples intentos de inicio de sesión en 1 minuto',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneHour': 'Múltiples intentos de inicio de sesión en 1 hora',
  'pages.AuditList.AlarmAuditList.exportFilename': 'Registros de alarmas de riesgo/anomalía',
  'pages.AuditList.ConsoleAuditList.Operation': 'Operación',
  'pages.AuditList.ConsoleAuditList.Name': 'Nombre',
  'pages.AuditList.ConsoleAuditList.Name list': 'Lista de nombres',
  'pages.AuditList.ConsoleAuditList.ID list': 'Lista de IDs',
  'pages.AuditList.ConsoleAuditList.Result': 'Resultado',
  'pages.AuditList.ConsoleAuditList.Remote': 'Remoto',
  'pages.AuditList.ConsoleAuditList.Local': 'Local',
  'pages.AuditList.ConsoleAuditList.User Login': 'Inicio de sesión de usuario en consola',
  'pages.AuditList.ConsoleAuditList.Add Group': 'Añadir grupo',
  'pages.AuditList.ConsoleAuditList.Add User': 'Añadir usuario',
  'pages.AuditList.ConsoleAuditList.Add Device': 'Añadir dispositivo',
  'pages.AuditList.ConsoleAuditList.Delete Groups': 'Eliminar grupos',
  'pages.AuditList.ConsoleAuditList.Disconnect Device': 'Desconectar dispositivo',
  'pages.AuditList.ConsoleAuditList.Enable Users': 'Habilitar usuarios',
  'pages.AuditList.ConsoleAuditList.Disable Users': 'Deshabilitar usuarios',
  'pages.AuditList.ConsoleAuditList.Enable Devices': 'Habilitar dispositivos',
  'pages.AuditList.ConsoleAuditList.Disable Devices': 'Deshabilitar dispositivos',
  'pages.AuditList.ConsoleAuditList.Update Group': 'Actualizar grupo',
  'pages.AuditList.ConsoleAuditList.Update User': 'Actualizar usuario',
  'pages.AuditList.ConsoleAuditList.Update Device': 'Actualizar dispositivo',
  'pages.AuditList.ConsoleAuditList.Delete User': 'Eliminar usuario',
  'pages.AuditList.ConsoleAuditList.Delete Device': 'Eliminar dispositivo',
  'pages.AuditList.ConsoleAuditList.Add Address Book': 'Añadir libreta de direcciones',
  'pages.AuditList.ConsoleAuditList.Delete Address Book': 'Eliminar libreta de direcciones',
  'pages.AuditList.ConsoleAuditList.Change Address Book Name': 'Cambiar nombre de libreta de direcciones',
  'pages.AuditList.ConsoleAuditList.Group Management': 'Gestión de grupos',
  'pages.AuditList.ConsoleAuditList.User Management': 'Gestión de usuarios',
  'pages.AuditList.ConsoleAuditList.Device Management': 'Gestión de dispositivos',
  'pages.AuditList.ConsoleAuditList.Address Book Management': 'Gestión de libretas de direcciones',
  'pages.AuditList.ConsoleAuditList.exportFilename': 'Registros de consola',
  'pages.AuditList.ConsoleAuditList.Delete Devices in the Address Book Recycle Bin': 'Eliminar dispositivos de la papelera de la libreta de direcciones',
  'pages.AuditList.ConsoleAuditList.Empty Address Book Recycle Bin': 'Vaciar papelera de la libreta de direcciones',
  'pages.AuditList.ConsoleAuditList.Add Address Book Permission': 'Añadir permiso de libreta de direcciones',
  'pages.AuditList.ConsoleAuditList.Delete Address Book Permission': 'Eliminar permiso de libreta de direcciones',
  'pages.AuditList.ConsoleAuditList.Update Address Book Permission': 'Actualizar permiso de libreta de direcciones',
  'pages.Settings.Strategy.Strategy list': 'Lista de estrategias',
  'pages.Settings.Strategy.Automatically close incoming sessions on user inactivity': 'Cerrar automáticamente sesiones entrantes por inactividad del usuario',
  'pages.Settings.Strategy.delete_strategy_tip': '¿Está seguro de que desea eliminar esta estrategia?',
  'pages.Settings.Strategy.New strategy': 'Nueva estrategia',
  'pages.Settings.Strategy.Rename strategy': 'Renombrar estrategia',
  'pages.Settings.Strategy.Duplicate strategy': 'Duplicar estrategia',
  'pages.Settings.Strategy.Edit Devices': 'Editar dispositivos',
  'pages.Settings.Strategy.Edit Users': 'Editar usuarios',
  'pages.Settings.Strategy.Rename': 'Renombrar',
  'pages.Settings.Strategy.Duplicate': 'Duplicar',
  'pages.Settings.Strategy.Delete': 'Eliminar',
  'pages.Settings.Strategy.Groups': 'Grupos',
  'pages.Settings.Strategy.Select All': 'Seleccionar todo',
  'pages.Settings.Strategy.Permissions': 'Permisos',
  'pages.Settings.Strategy.Password': 'Contraseña',
  'pages.Settings.Strategy.Security': 'Seguridad',
  'pages.Settings.Strategy.Other': 'Otros',
  'pages.Settings.Strategy.IP whitelist': 'Lista blanca de IP',
  'pages.Settings.Strategy.Cancel': 'Cancelar',
  'pages.Settings.Strategy.Submit': 'Confirmar',
  'pages.Settings.Strategy.Edit': 'Editar',
  'pages.Settings.Strategy.Custom': 'Personalizado',
  'pages.Settings.Strategy.Full access': 'Acceso completo',
  'pages.Settings.Strategy.Screen share': 'Solo compartir pantalla',
  'pages.Settings.Strategy.Enable keyboard/mouse': 'Permitir control de teclado/ratón',
  'pages.Settings.Strategy.Enable clipboard': 'Permitir sincronización del portapapeles',
  'pages.Settings.Strategy.Enable file transfer': 'Permitir transferencia de archivos',
  'pages.Settings.Strategy.Enable audio': 'Permitir transmisión de audio',
  'pages.Settings.Strategy.Enable TCP tunneling': 'Permitir túnel TCP',
  'pages.Settings.Strategy.Enable remote restart': 'Permitir reinicio remoto',
  'pages.Settings.Strategy.Enable recording session': 'Permitir grabación de sesión',
  'pages.Settings.Strategy.Enable blocking user input': 'Permitir bloqueo de entrada del usuario',
  'pages.Settings.Strategy.Enable remote configuration modification': 'Permitir modificación remota de configuración',
  'pages.Settings.Strategy.Permission type': 'Tipo de permiso',
  'pages.Settings.Strategy.Approve mode': 'Modo de aprobación',
  'pages.Settings.Strategy.Password type': 'Tipo de contraseña',
  'pages.Settings.Strategy.Hide connection management window': 'Ocultar ventana de gestión de conexión',
  'pages.Settings.Strategy.One-time password length': 'Longitud de contraseña de un solo uso',
  'pages.Settings.Strategy.Accept sessions via password': 'Aceptar sesiones solo con contraseña',
  'pages.Settings.Strategy.Accept sessions via click': 'Aceptar sesiones solo con clic',
  'pages.Settings.Strategy.Accept sessions via both': 'Aceptar sesiones con contraseña o clic',
  'pages.Settings.Strategy.Use one-time password': 'Usar contraseña de un solo uso',
  'pages.Settings.Strategy.Use permanent password': 'Usar contraseña permanente',
  'pages.Settings.Strategy.Use both passwords': 'Usar ambos tipos de contraseña',
  'pages.Settings.Strategy.Deny LAN discovery': 'Denegar descubrimiento LAN',
  'pages.Settings.Strategy.Enable direct IP access': 'Permitir acceso directo por IP',
  'pages.Settings.Strategy.auto_disconnect_option_tip': 'Cerrar automáticamente sesiones inactivas',
  'pages.Settings.Strategy.deny_lan_discovery_desc_tip': 'El dispositivo no será descubierto por otros dispositivos en la misma red local',
  'pages.Settings.Strategy.enable_direct_ip_access_desc_tip': 'Permitir conexión directa IP al dispositivo',
  'pages.Settings.Strategy.Remove wallpaper during incoming sessions': 'Eliminar fondo de pantalla durante sesiones entrantes',
  'pages.Settings.Strategy.minutes': 'minutos',
  'pages.Settings.Strategy.Enable': 'Habilitar',
  'pages.Settings.Strategy.Disable': 'Deshabilitar',
  'pages.Settings.Strategy.disabled': 'deshabilitado',
  'pages.Settings.Strategy.Default': 'Estrategia predeterminada',
  'pages.Settings.Strategy.Apply changes of this page': 'Aplicar cambios de esta página',
  'pages.Settings.Strategy.Invalid IP': 'IP no válida',
  'pages.Settings.Strategy.IP Whitelisting': 'Lista blanca de IP',
  'pages.Settings.Strategy.Clear': 'Limpiar',
  'pages.Settings.Strategy.OK': 'Aceptar',
  'pages.Settings.Strategy.whitelist_sep': 'Puede usar comas, punto y coma, espacios o saltos de línea como separadores',
  'pages.Settings.Strategy.Use IP whitelisting': 'Permitir solo IPs de la lista blanca',
  'pages.Settings.Strategy.whitelist_tip': 'Solo las IPs en la lista blanca pueden acceder a este dispositivo',
  'pages.CustomClient.bidirectional_desc_tip': 'Crear un cliente que puede conectarse a otro dispositivo y recibir solicitudes de conexión',
  'pages.CustomClient.incoming_desc_tip': 'Crear un cliente que solo puede recibir solicitudes de conexión',
  'pages.CustomClient.outgoing_desc_tip': 'Crear un cliente que puede conectarse a otro dispositivo',
  'pages.CustomClient.disable_installation_desc_tip': 'Impedir que los usuarios instalen el cliente personalizado (solo Windows)',
  'pages.CustomClient.disable_settings_desc_tip': 'Deshabilitar el acceso a la configuración en el cliente personalizado',
  'pages.CustomClient.disable_ab_desc_tip': 'Deshabilitar la funcionalidad de libreta de direcciones en el cliente personalizado',
  'pages.CustomClient.disable_tcp_desc_tip': 'Evitar el diálogo del firewall de Windows al iniciar RustDesk, esto omitirá los intentos de conexión P2P directa y usará directamente la conexión de relé',
  'pages.CustomClient.disable_account_desc_tip': 'Eliminar la funcionalidad de inicio de sesión de cuenta en el cliente RustDesk',
  'pages.CustomClient.override_settings_desc_tip': 'Sobrescribir configuración, no se puede cambiar en RustDesk',
  'pages.CustomClient.default_settings_desc_tip': 'Cambiar la configuración predeterminada utilizada por RustDesk',
  'pages.Settings.KeyPage.failedToFetchKey': 'Error al obtener la clave.',
  'pages.Settings.KeyPage.keyResetSuccess': 'Restablecimiento de clave exitoso, reinicie hbbr para aplicar los cambios.',
  'pages.Settings.KeyPage.errorResettingKey': 'Error al restablecer la clave.',
  'pages.Settings.KeyPage.keyUpdateSuccess': 'Actualización de clave exitosa, reinicie hbbr para aplicar los cambios.',
  'pages.Settings.KeyPage.errorUpdatingKey': 'Error al actualizar la clave, verifique la clave',
  'pages.Settings.KeyPage.key': 'Clave',
  'pages.Settings.KeyPage.privateKey': 'Clave privada',
  'pages.Settings.KeyPage.publicKey': 'Clave pública',
  'pages.Settings.KeyPage.editKey': 'Actualizar clave',
  'pages.Settings.KeyPage.reset': 'Restablecer',
  'pages.Settings.KeyPage.custom': 'Entrada manual',
  'pages.Settings.KeyPage.pleaseInputPrivateKey': '¡Por favor, ingrese su clave privada!',
  'pages.Settings.License.settingsUpdatedSuccess': 'Configuración actualizada con éxito.',
  'pages.Settings.License.licenseCannotBeEmpty': 'La licencia no puede estar vacía.',
  'pages.Settings.License.license': 'Licencia',
  'pages.Settings.Relay.GeoOverrideDescription': 'La función de sobreescritura de ubicación solo se puede utilizar cuando existen múltiples servidores de relé.',
  'pages.Settings.GeoOverride.ipOrDomain': 'IP/Dominio',
  'pages.Settings.GeoOverride.latitude': 'Latitud',
  'pages.Settings.GeoOverride.longitude': 'Longitud',
  'pages.Settings.GeoOverride.deleteSelectedConfirm': '¿Está seguro de que desea eliminar las sobreescrituras de ubicación seleccionadas?',
  'pages.Settings.GeoOverride.deleteConfirm': '¿Está seguro de que desea eliminar esta sobreescritura de ubicación?',
  'pages.Settings.GeoOverride.editGeoOverride': 'Editar sobreescritura',
  'pages.Settings.GeoOverride.addGeoOverride': 'Añadir sobreescritura',
  'pages.Settings.GeoOverride.ipOrDomainRequired': 'IP/Dominio no puede estar vacío.',
  'pages.Settings.GeoOverride.latitudeRequired': 'Latitud no puede estar vacía.',
  'pages.Settings.GeoOverride.longitudeRequired': 'Longitud no puede estar vacía.',
  'pages.Settings.GeoOverride.testGeo': 'Probar ubicación',
  'pages.Settings.GeoOverride.geofileFetchFailed': 'Error al obtener información GeoFile.',
  'pages.Settings.GeoOverride.loadGeoOverrides': 'Cargar sobreescrituras',
  'pages.Settings.GeoOverride.geoOverride': 'Sobreescritura de ubicación',
  'pages.Settings.GeoOverride.title': 'Sobreescritura de ubicación',
  'pages.Settings.GeoOverride.RelaySettings': 'Configuración',
  'pages.Settings.GeoOverride.RelayServers': 'Servidores de relé',
  'pages.Settings.GeoOverride.AddServer': 'Añadir servidor',
  'pages.Settings.GeoOverride.AlwaysUseRelay': 'Usar siempre relé',
  'pages.Settings.GeoOverride.UpdateServers': 'Importar cambios',
  'pages.Settings.GeoOverride.UpdatedAlwaysUseRelay': 'Configuración "Usar siempre relé" actualizada',
  'pages.Settings.GeoOverride.FailedToUpdateAlwaysUseRelay': 'Error al actualizar "Usar siempre relé"',
  'pages.Settings.GeoOverride.UpdatedRelayServers': 'Servidores de relé actualizados',
  'pages.Settings.GeoOverride.FailedToUpdateRelayServers': 'Error al actualizar los servidores de relé',
  'pages.Settings.GeoOverride.UpdatedChangeIDSetting': 'Configuración de cambio de ID actualizada',
  'pages.Settings.GeoOverride.FailedToUpdateChangeIDSetting': 'Error al actualizar la configuración de cambio de ID',
  "pages.Settings.Others.Allow change ID": "Permitir cambio de ID",
  "pages.Settings.Others.Disable new devices": "Deshabilitar nuevos dispositivos",
  "pages.Settings.Others.The control end need to login before access": "El controlador debe iniciar sesión antes de acceder",
  "pages.Settings.Others.Only admin can access unassigned devices": "Solo el administrador puede acceder a dispositivos no asignados",
  "pages.Settings.Others.Allow non-admin to see other groups": "Permitir a no administradores ver otros grupos",
  "pages.Settings.Others.allow_non_admin_see_other_group_tip": "Cuando esta opción está deshabilitada, los no administradores solo pueden compartir libretas de direcciones dentro de su propio grupo.",
  'pages.Settings.OIDC.Maximum Number of Authorized Users': 'Número máximo de usuarios autorizados',
  'pages.Settings.OIDC.emptyPrompt': 'Actualmente no hay proveedores de autenticación de terceros, agregue uno para usar esta función.',
  'pages.Settings.OIDC.confirm-delete-title': 'Confirmar eliminación',
  'pages.Settings.OIDC.confirm-delete-content': 'Los usuarios autorizados por este proveedor de terceros ya no podrán acceder. Puede acceder a la interfaz de gestión de usuarios para eliminar estos usuarios.',
  'pages.Settings.Others.Only admin can access logs': 'Solo el administrador puede acceder a registros no asignados',
  'pages.Welcome.Max Devices': 'Número máximo de dispositivos',
  'pages.Welcome.Max Users': 'Número máximo de usuarios',
  'pages.Welcome.Not set': 'No establecido',
  'pages.Welcome.Invalid': 'No válido',
  'pages.Welcome.Demo': 'Demo',
  'pages.Welcome.Personal': 'Personal',
  'pages.Welcome.Business': 'Empresarial',
  'pages.Welcome.Unknown': 'Otro',
  'pages.Welcome.Max Peers': 'Número máximo de dispositivos',
  'pages.Welcome.RustDesk Server Status': 'Estado del servidor RustDesk',
  'pages.Welcome.License Expire Time': 'Fecha de vencimiento de licencia',
  'pages.Welcome.Document': 'Documentación',
  'pages.AddressBook.Permission': 'Permiso',
  'pages.AddressBook.Device count': 'Número de dispositivos',
  'pages.AddressBook.Address Books': 'Libretas de direcciones',
  'pages.AddressBook.Tags': 'Etiquetas',
  'pages.AddressBook.Devices': 'Dispositivos',
  'pages.AddressBook.Device': 'Dispositivo',
  'pages.AddressBook.Alias': 'Alias',
  'pages.AddressBook.Whether a password is set': 'Si se ha establecido una contraseña',
  'pages.AddressBook.Recycle Bin': 'Papelera de reciclaje',
  'pages.AddressBook.Empty Recycle Bin': 'Vaciar papelera',
  'pages.AddressBook.Personal': 'Personal',
  'pages.AddressBook.Personal (My address book)': 'Personal (Mi libreta de direcciones)',
  'pages.AddressBook.Shared': 'Compartida',
  'pages.AddressBook.Share': 'Compartir',
  'pages.AddressBook.Delete': 'Eliminar',
  'pages.AddressBook.Settings': 'Configuración',
  'pages.AddressBook.Owner': 'Propietario',
  'pages.AddressBook.My Permission': 'Mi permiso',
  'pages.AddressBook.Read-only': 'Solo lectura',
  'pages.AddressBook.Read/Write': 'Lectura/Escritura',
  'pages.AddressBook.Full Control': 'Control total',
  'pages.AddressBook.share_warning_tip': 'Los campos anteriores son compartidos y visibles para otras personas.',
  'pages.AddressBook.permission_tip': 'Solo lectura: puede ver dispositivos y etiquetas; Lectura/Escritura: puede editar dispositivos y etiquetas; Control total: puede volver a compartir, eliminar o renombrar la libreta de direcciones.',
  'pages.AddressBook.rule_priority_tip': 'Prioridad: Usuario > Grupo > Todos',
  'pages.AddressBook.Remove password': 'Eliminar contraseña',
  'pages.AddressBook.Import': 'Importar',
  'pages.AddressBook.Filter by intersection': 'Filtrar por intersección',
  'pages.AddressBook.Permissions': 'Permisos',
  'pages.AddressBook.Type': 'Tipo',
  'pages.AddressBook.Untagged': 'Sin etiquetar',

  'app.pwa.offline': 'Está desconectado',
  'app.pwa.serviceworker.updated': 'Nuevo contenido disponible',
  'app.pwa.serviceworker.updated.hint': 'Haga clic en el botón "Actualizar" o actualice manualmente la página',
  'app.pwa.serviceworker.updated.ok': 'Actualizar',

  'app.setting.pagestyle': 'Configuración de estilo global',
  'app.setting.pagestyle.dark': 'Estilo de menú oscuro',
  'app.setting.pagestyle.light': 'Estilo de menú claro',
  'app.setting.content-width': 'Ancho del área de contenido',
  'app.setting.content-width.fixed': 'Fijo',
  'app.setting.content-width.fluid': 'Fluido',
  'app.setting.themecolor': 'Color del tema',
  'app.setting.themecolor.dust': 'Polvo',
  'app.setting.themecolor.volcano': 'Volcán',
  'app.setting.themecolor.sunset': 'Atardecer',
  'app.setting.themecolor.cyan': 'Cian',
  'app.setting.themecolor.green': 'Verde aurora',
  'app.setting.themecolor.daybreak': 'Azul amanecer (predeterminado)',
  'app.setting.themecolor.geekblue': 'Azul geek',
  'app.setting.themecolor.purple': 'Púrpura',
  'app.setting.navigationmode': 'Modo de navegación',
  'app.setting.sidemenu': 'Diseño de menú lateral',
  'app.setting.topmenu': 'Diseño de menú superior',
  'app.setting.fixedheader': 'Cabecera fija',
  'app.setting.fixedsidebar': 'Menú lateral fijo',
  'app.setting.fixedsidebar.hint': 'Funciona con diseño de menú lateral',
  'app.setting.hideheader': 'Ocultar cabecera al desplazarse',
  'app.setting.hideheader.hint': 'Funciona cuando la cabecera está fija',
  'app.setting.othersettings': 'Otras configuraciones',
  'app.setting.weakmode': 'Modo daltónico',
  'app.setting.copy': 'Copiar configuración',
  'app.setting.copyinfo': 'Copiado con éxito, reemplace la configuración predeterminada en config/defaultSettings.js',
  'app.setting.production.hint': 'La barra de configuración solo se muestra en el entorno de desarrollo para vista previa. Modifique manualmente el archivo de configuración en producción.',

  'app.settings.menuMap.basic': 'Configuración básica',
  'app.settings.menuMap.security': 'Configuración de seguridad',
  'app.settings.menuMap.binding': 'Vinculación de cuenta',
  'app.settings.menuMap.notification': 'Notificaciones de nuevos mensajes',
  'app.settings.basic.avatar': 'Avatar',
  'app.settings.basic.change-avatar': 'Cambiar avatar',
  'app.settings.basic.email': 'Correo electrónico',
  'app.settings.basic.email-message': '¡Ingrese su correo electrónico!',
  'app.settings.basic.nickname': 'Apodo',
  'app.settings.basic.nickname-message': '¡Ingrese su apodo!',
  'app.settings.basic.profile': 'Perfil personal',
  'app.settings.basic.profile-message': '¡Ingrese su perfil personal!',
  'app.settings.basic.profile-placeholder': 'Perfil personal',
  'app.settings.basic.country': 'País/Región',
  'app.settings.basic.country-message': '¡Ingrese su país o región!',
  'app.settings.basic.geographic': 'Provincia y ciudad',
  'app.settings.basic.geographic-message': '¡Ingrese su provincia y ciudad!',
  'app.settings.basic.address': 'Dirección',
  'app.settings.basic.address-message': '¡Ingrese su dirección!',
  'app.settings.basic.phone': 'Teléfono',
  'app.settings.basic.phone-message': '¡Ingrese su número de teléfono!',
  'app.settings.basic.update': 'Actualizar información básica',
  'app.settings.security.strong': 'Fuerte',
  'app.settings.security.medium': 'Media',
  'app.settings.security.weak': 'Débil',
  'app.settings.security.password': 'Contraseña de cuenta',
  'app.settings.security.password-description': 'Fortaleza actual de la contraseña',
  'app.settings.security.phone': 'Teléfono de seguridad',
  'app.settings.security.phone-description': 'Teléfono vinculado',
  'app.settings.security.question': 'Pregunta de seguridad',
  'app.settings.security.question-description': 'Pregunta de seguridad no establecida, puede proteger eficazmente la seguridad de la cuenta',
  'app.settings.security.email': 'Correo electrónico de respaldo',
  'app.settings.security.email-description': 'Correo electrónico vinculado',
  'app.settings.security.mfa': 'Dispositivo MFA',
  'app.settings.security.mfa-description': 'Dispositivo MFA no vinculado, después de vincular puede realizar una verificación secundaria',
  'app.settings.security.modify': 'Modificar',
  'app.settings.security.set': 'Establecer',
  'app.settings.security.bind': 'Vincular',
  'app.settings.binding.taobao': 'Vincular Taobao',
  'app.settings.binding.taobao-description': 'Cuenta de Taobao actualmente no vinculada',
  'app.settings.binding.alipay': 'Vincular Alipay',
  'app.settings.binding.alipay-description': 'Cuenta de Alipay actualmente no vinculada',
  'app.settings.binding.dingding': 'Vincular DingTalk',
  'app.settings.binding.dingding-description': 'Cuenta de DingTalk actualmente no vinculada',
  'app.settings.binding.bind': 'Vincular',
  'app.settings.notification.password': 'Contraseña de cuenta',
  'app.settings.notification.password-description': 'Los mensajes de otros usuarios se notificarán como mensajes internos',
  'app.settings.notification.messages': 'Mensajes del sistema',
  'app.settings.notification.messages-description': 'Los mensajes del sistema se notificarán como mensajes internos',
  'app.settings.notification.todo': 'Tareas pendientes',
  'app.settings.notification.todo-description': 'Las tareas pendientes se notificarán como mensajes internos',
  'app.settings.open': 'Activado',
  'app.settings.close': 'Desactivado',
  'app.copyright.produced': 'Producido por el equipo RustDesk',
  'pages.account.Settings.Security.Password': 'Contraseña',
  'pages.account.Settings.Security.Change password': 'Cambiar contraseña',
  'pages.account.tfa.Enable 2FA': 'Habilitar 2FA',
  'pages.account.tfa.2 Factor Authentication is enabled.': 'La autenticación de dos factores está habilitada',
  'pages.account.tfa.Your 2FA key has been in use for an extended period. We recommend updating your 2FA key to ensure maximum account security.': 'Su clave 2FA ha estado en uso durante un período prolongado. Recomendamos actualizar su clave 2FA para garantizar la máxima seguridad de la cuenta.',
  'pages.account.tfa.Enable 2 Factor Authentication.': 'Habilitar autenticación de dos factores.',

  'Enable Two-Factor Authentication': 'Habilitar autenticación de dos factores',
  'Make your RustDesk account more secure by requiring an extra authentication code when you log in.': 'Haga que su cuenta RustDesk sea más segura requiriendo un código de autenticación adicional cuando inicie sesión.',
  '1. Download an authenticator app': '1. Descargue una aplicación de autenticación',
  'Download an authenticator app such as': 'Descargue una aplicación de autenticación como Google Authenticator o Authy',
  '2FA code': 'Código 2FA',
  'Authenticator': 'Autenticador',
  'on your phone or desktop.': 'en su teléfono o escritorio.',
  '2. Scan the QR code': '2. Escanee el código QR',
  'Open your authenticator app, scan the QR code and enter the code that your app shows.': 'Abra su aplicación de autenticación, escanee el código QR e introduzca el código que muestra su aplicación.',
  'You can change or remove 2FA at any time in your account settings.': 'Puede cambiar o eliminar 2FA en cualquier momento en la configuración de su cuenta.',
  'Please verify your 2FA code to disable current 2FA first.': 'Por favor, verifique su código 2FA para deshabilitar primero el 2FA actual.',
  'Can\'t verify the code. Check that code and local time settings are correct!': 'No se puede verificar el código. ¡Compruebe que el código y la configuración de hora local sean correctos!',
  'First sign-in:': 'Primer inicio de sesión:',
  'Recent activity:': 'Actividad reciente:',
  'pages.Settings.GeoOverride.reloadGeo': 'Recargar datos de geolocalización',
  'pages.Settings.GeoOverride.getRelayServer': 'Obtener servidor de relé',
  'pages.Settings.GeoOverride.relayServer': 'Servidor de relé',
  'SMTP Host': 'Host SMTP',
  'SMTP Port': 'Puerto SMTP',
  'My Server Requires Authentication': 'Mi servidor requiere autenticación',
  'smtp.From': 'Remitente',
  'Mail Account': 'Cuenta de correo',
  'Mail Password': 'Contraseña de correo',
  'Custom Certificate PEM File Path (on the server)': 'Ruta del archivo PEM de certificado personalizado (en el servidor)',
  Update: 'Actualizar',
  'Submit and Test Email': 'Enviar y probar correo',
  'The Receiver Email': 'Correo del destinatario',
  'LDAP Host': 'Host LDAP',
  'LDAP Port': 'Puerto LDAP',
  'Filter': 'Filtro',
  'Username Attribute': 'Atributo de nombre de usuario',
  'The attribute used as the username when logging in. Default is "dn".': 'El atributo utilizado como nombre de usuario al iniciar sesión. El valor predeterminado es "dn".',
  'DraggableTab.Drag tab to reorder': 'Arrastre la pestaña para reordenar',
  'pages.Settings.OIDC.new.Auth Type': 'Tipo de autenticación',
  'pages.Settings.OIDC.New Auth Provider': 'Nuevo proveedor de autenticación',
  'pages.Settings.OIDC.new.New Auth Provider': 'Nuevo proveedor de autenticación',
  'pages.Settings.OIDC.Delete This Provider': 'Eliminar este proveedor',
  'pages.Settings.OIDC.new.Enable PKCE': 'Habilitar PKCE',
  'pages.Settings.OIDC.Enable PKCE': 'Habilitar PKCE',
  'pages.Settings.OIDC.new.Timeout(seconds) to force a refresh of metadata(e.g. JWKS) from OIDC providers': 'Tiempo de espera (segundos) para forzar una actualización de metadatos (p. ej., JWKS) de proveedores OIDC',
  'pages.Settings.OIDC.new.0 means no timeout, empty means default (3600)': '0 significa sin tiempo de espera, vacío significa valor predeterminado (3600)',
  'Callback URL': 'URL de retorno',
  'pages.Settings.Others.Session expiration time (Day)': 'Tiempo de caducidad de sesión (días)',
  'Or': 'O',
};
